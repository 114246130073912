import React from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text/Text'

const TextCategory = ({text, width, color='black', type='normal'}) => {
    return (
        <Layout margin={[0,8,0,8]} style={{
            "width": width,
            }} alignItems="start">
            <Text text={text} color={color} type={type}/>
        </Layout>

    )
}

export default TextCategory