import React from 'react'
// import { useSelector } from 'react-redux'
import Layout from '../../components/Layout'
// import Loading from '../../components/Loading'
import UserActionsMenu from '../../components/UserActionMenu/UserActionsMenu'
import TextCategory from './TextCategory'



const UserContent = ({ users, type }) => {

    // const {loading} = useSelector(state => state.permissions)
    // console.log(loading)
    return (
        <>
            {
                users.map((user) => {
                    
                    return (
                        // <Loading loading={user.email in loading[user.email] ? loading[user.email] : false} key={user.email}>
                            <Layout fullWidth row justifyContent='space-between' padding={[8, 16]} margin={[4]} border color='white'>
                                <Layout fullWidth row justifyContent='start' padding={[0, 16]} gap={8}>
                                    <TextCategory text={user.email} width={"282px"} overflow />
                                    <TextCategory text={user.displayName} width={"144px"} overflow />
                                </Layout>
                                <UserActionsMenu email={user.email} type={type} />
                            </Layout>
                        // </Loading>
                    )
                })
            }
        </>
)
}

export default UserContent





// import React from 'react'
// import Layout from '../../components/Layout'
// import TextCategory from './TextCategory'
// import { MARGIN } from '../../Constants'
// import Label from '../../components/Label/Label'



// const UserContent = ({ users, type }) => {
//     return (
//         <>
//             {
//                 Object.keys(users).map((email) => {
//                     return (
//                         <Layout key={email} fullWidth row justifyContent='space-between' padding={[MARGIN / 2, MARGIN]} margin={[MARGIN / 4]} border color='white'>
//                             <Layout fullWidth row justifyContent='start' padding={[0, MARGIN]}>
//                                 <TextCategory text={email} width={"25%"} overflow />
//                                 {
//                                     users[email]?.labels !== undefined ?
//                                         <Layout row gap={MARGIN / 2} margin={[0, MARGIN]} >
//                                             {
//                                                 users[email].labels.map(label =>
//                                                     <Label
//                                                         key={label}
//                                                         label={label} />)
//                                             }
//                                         </Layout> : null
//                                 }

//                                 {/* <TextCategory text={user.labels} width={"20%"} overflow/> */}

//                             </Layout>
//                             <UserActionsMenu email={email} type={type} />
//                         </Layout>
//                     )
//                 })
//             }
//         </>
//     )
// }
