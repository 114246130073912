export const getOnboardString = (onboard) => {
    const getExperience = (e) => {
        return {
            'Complete beginner': 'Beginner',
            'Some experience, but need a refresher': 'Intermidiate',
            'Confident in my coding skills': 'Advanced',
            'Expert at coding': 'Expert',
        }[e] ?? '-'
    }

    const getMotivation = (e) => {
        return {
            'Explore what is coding': 'Explore coding',
            'Challenge my brain': 'Brain challenge',
            'Boost my career': 'Boost career',
            'Just for fun': 'Fun',
            'Support my education': 'Support education',
            'Build my own apps': 'Build apps',
            'Expand creative skills': 'Creative skills',
            'Other': 'Other',
        }[e] ?? '-'
    }

    const getPrefrence = (e) => {
        if (e === 'I have no idea') return 'No idea'
        return e ?? '-'
    }

    const getCommitment = (e) => {
        if (e) {
            return e.split(' per')[0]
        }
        return '-'
    }

    const getStartingPoint = (e) => {
        return {
            'Start from scratch': 'Start scratch',
            'Explore relevant courses': 'Explore relevant',
            'Explore all Courses': 'Explore all',
            'Explore all courses': 'Explore all',
        }[e] ?? '-'
    }

    if (onboard) {
        return `${getMotivation(onboard.learningMotivation)}/${getExperience(onboard.codingExperience)}/${getPrefrence(onboard.learningPreference)}/${getCommitment(onboard.dailyCommitment)}/${getStartingPoint(onboard.startingPoint)}`
    } else {
        return '-'
    }
}