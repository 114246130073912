import Status from "../status"
import { toast } from "react-toastify"

export const fetchUserLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchUserFulfilled = (state, action) => {
    state.fetchStatus = Status.Success

    if (action.payload === null) {
        state.data = {}
        state.isLogged = false
    } else {
        state.data = action.payload
        state.isLogged = true
    }
}

export const fetchUserRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
    state.isLogged = false
    state.data = {}
}

export const updateMarkedUserLoading = (state, action) => {
    if (state.data?.markedUsers === undefined) {
        state.data.markedUsers = {}
    }
    state.data.markedUsers[action.meta.arg.uidToMark] = action.meta.arg.isMarked
}

export const updateMarkedUserRejected = (state, action) => {
    toast.error(action.error.message)
}

export const addUsersLabelFulfilled = (state, action) => {
    if (state.data.usersLabels === undefined) {
        state.data.usersLabels = []
    }
    state.data.usersLabels.push(action.meta.arg)
}

export const addUsersLabelRejected = (state, action) => {
    if (action.payload) {
        toast.error(action.payload)
    } else {
        toast.error(action.error.message)
    }
}

export const deleteUsersLabelLoading = (state, action) => {
    state.data.usersLabels = state.data.usersLabels.filter(l => l !== action.meta.arg)
}

export const deleteUsersLabelRejected = (state, action) => {
    toast.error(action.error.message)
}

export const updateUserLabelLoading = (state, action) => {
    if (state.data.usersLabelsMap === undefined) {
        state.data.usersLabelsMap = {}
    }
    if (state.data.usersLabelsMap[action.meta.arg.uidToLabel] === undefined) {
        state.data.usersLabelsMap[action.meta.arg.uidToLabel] = []
    }
    if (action.meta.arg.isAddLabel) {
        state.data.usersLabelsMap[action.meta.arg.uidToLabel].push(action.meta.arg.label)
    } else {
        state.data.usersLabelsMap[action.meta.arg.uidToLabel] = state.data.usersLabelsMap[action.meta.arg.uidToLabel].filter(u => u !== action.meta.arg.label)
    }
}

export const updateUserLabelRejected = (state, action) => {
    toast.error(action.error.message)
}

export const toggleSidebarActionFulfilled = (state, action) => {
    if (state.data.sidebar === undefined) {
        state.data.sidebar = []
    }
    if (state.data.sidebar.includes(action.meta.arg.action)) {
        state.data.sidebar.splice(state.data.sidebar.indexOf(action.meta.arg.action), 1)
    } else {
        state.data.sidebar.push(action.meta.arg.action)
    }
}
