import React, { useState } from 'react'
import styles from './FilterTag.module.css'
import Layout from '../Layout/Layout'
import Text from '../Text/Text'
import Plus from '../../assets/icons/Plus'
import Button from '../Button/Button'
import Cross from '../../assets/icons/Cross'

const FilterTag = ({ placeholder = '-', values = [], initialSelected, onApply }) => {
    const [selected, setSelected] = useState(initialSelected)

    const handleReset = (e) => {
        e.stopPropagation()
        setSelected(undefined)
        onApply(undefined)
    }

    return (
        <Layout classes={[styles.container]} row gap={8}>
            {
                initialSelected ?
                    <Cross size={16} onClick={handleReset} /> :
                    <Plus size={16} />
            }
            <Text text={initialSelected ?? placeholder} />
            <Layout classes={[styles.popupWrapper]}>
                <Layout classes={[styles.popup]} padding={[8]} justifyContent='space-between'>
                    <select value={selected} name="funnels" id="funnels" className={styles.input} onChange={(e) => setSelected(e.target.value)}>
                        {
                            values.map((key) => <option key={key} value={key}>{key}</option>)
                        }
                    </select>
                    <Button text={'Apply'} onClick={() => onApply(selected)} />
                </Layout>
            </Layout>
        </Layout>
    )
}

export default FilterTag