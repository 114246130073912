import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchTags, fetchTerms } from '../../store/tagging/tagging.slice'
import Courses from './Courses'
import Popup from './Popup'
import styles from './Tagging.module.css'
import Tags from './Tags'
import Terms from './Terms'

const Tagging = ({ add = false, popup }) => {
    const dispatch = useDispatch()
    const [isTags, setIsTags] = useState(true)

    useEffect(() => {
        dispatch(fetchTags())
        dispatch(fetchTerms())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={32}>
            <Layout alignItems='normal' justifyContent='normal' gap={16}>
                <Layout row gap={16} justifyContent='flex-start'>
                    <Text
                        text={'Tags'}
                        onClick={() => setIsTags(true)}
                        className={[styles.textButton, isTags ? styles.textButtonSelected : null].join(' ')} />
                    <Text
                        text={'Terms'}
                        onClick={() => setIsTags(false)}
                        className={[styles.textButton, !isTags ? styles.textButtonSelected : null].join(' ')} />
                </Layout>
                {
                    isTags ?
                        <Tags /> :
                        <Terms />
                }
            </Layout>
            <Courses />
            <Popup type={popup} add={add} />
        </Layout>
    )
}

export default Tagging