import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Status from '../status'
import {
    fetchChallengesDataFulfilled,
    fetchChallengesDataLoading,
    fetchChallengesDataRejected,
    fetchLastGeneratedChallengesFulfilled,
    fetchLastGeneratedChallengesLoading,
    fetchLastGeneratedChallengesRejected,
} from './challenges.actions'
import FunctionsService from '../../services/FunctionsService'

export const fetchLastGeneratedChallenges = createAsyncThunk('challenges/fetchLastGeneratedChallenges', async (_, { getState }) => {
    const data = getState().challenges.lastGenerated
    if (data.length === 0) {
        return FunctionsService.getLastGeneratedChallenges()
    } else {
        return data
    }
})

export const fetchChallengesData = createAsyncThunk('challenges/fetchChallengesData', async (_, { getState }) => {
    const data = getState().challenges.data
    if (Object.keys(data).length === 0) {
        return FunctionsService.getChallengesData()
    } else {
        return data
    }
})

export const challengesSlice = createSlice({
    name: 'challenges',
    initialState: {
        data: [],
        lastGenerated: [],
        fetchDataStatus: Status.None,
        fetchLastGeneratedStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLastGeneratedChallenges.pending, fetchLastGeneratedChallengesLoading)
            .addCase(fetchLastGeneratedChallenges.fulfilled, fetchLastGeneratedChallengesFulfilled)
            .addCase(fetchLastGeneratedChallenges.rejected, fetchLastGeneratedChallengesRejected)
            .addCase(fetchChallengesData.pending, fetchChallengesDataLoading)
            .addCase(fetchChallengesData.fulfilled, fetchChallengesDataFulfilled)
            .addCase(fetchChallengesData.rejected, fetchChallengesDataRejected)
    },
})

export default challengesSlice.reducer