import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import FunctionsService from '../../services/FunctionsService'
import LessonView from './LessonView'


const LessonsProgress = ({uid, course, courseId}) => {
    const [progress, setProgress] = useState({})
    const [activeLessonId, setActiveLessonId] = useState('')

    useEffect(() => {
        FunctionsService.getCoddyUserProgress(uid)
            .then(d => {setProgress(d)})
    }, [uid])

    const handleLessonClick = (lessonId) => {
        setActiveLessonId(lessonId)
    }

    

    const lessons = course?.chapters?.flatMap(c => c.lessons) ?? []
    return (
        <Layout scroll justifyContent='start' alignItems='start' fullWidth>
             {
                lessons.map(lesson => {
                        const chapterName = course.chapters.find(c => c.lessons.some(l => l.id === lesson.id)).name
                        return (
                            <LessonView
                                key={lesson.id}
                                uid={uid}
                                isActive={activeLessonId===lesson.id}
                                lesson={lesson}
                                courseId={courseId}
                                chapterName={chapterName}
                                progress={progress[courseId][lesson.id]}
                                onClick={handleLessonClick}
                                // onClick={() => onLessonClick(courseId, lesson.id, lesson.name, chapterName)} />
                                />
                        )
                    })
                }
        </Layout>
    )
}

export default LessonsProgress