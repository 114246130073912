import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './Insights.module.css'
import Layout from '../../components/Layout'
import { fetchInsightsByContext, fetchInsightsCount, fetchLastInsights } from '../../store/insights/insights.slice'
import { useNavigate, useParams } from 'react-router-dom'
import InsightPopup from './InsightPopup'
import Text from '../../components/Text'
import { fetchCourses } from '../../store/tagging/tagging.slice'
import DatabaseService from '../../services/DatabaseService'
import Button from '../../components/Button'


const Insights = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const lastInsights = useSelector(state => state.insights.lastInsights)
    const insightsByContext = useSelector(state => state.insights.insightsByContext)
    const insightsCount = useSelector(state => state.insights.insightsCount)
    const courses = useSelector(state => state.tagging.courses)
    const [course, setCourse] = useState()
    const [lesson, setLesson] = useState()
    const [lessons, setLessons] = useState([])
    const [view, setView] = useState('last')

    useEffect(() => {
        dispatch(fetchLastInsights())
        dispatch(fetchInsightsCount())
        dispatch(fetchCourses())
    }, [dispatch])

    useEffect(() => {
        if (course) {
            DatabaseService.getCourse(course)
                .then(d => setLessons(d.chapters.flatMap(c => c.lessons)))
        }
    }, [course])

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            // dispatch(fetchMoreSupport())
        }
    }

    const handleGenerateByContext = () => {
        setView('context')
        dispatch(fetchInsightsByContext({ courseId: course, lessonId: lesson }))
    }

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <Layout fullWidth row justifyContent='flex-start' gap={16}>
                <Layout style={{ width: '100px', height: '80px', borderRadius: '8px' }} color={'white'}>
                    <Text text={insightsCount > 0 ? insightsCount : '-'} type='title' bold />
                    <Text text='Insights' />
                </Layout>
                <Layout row style={{ height: '80px', borderRadius: '8px' }} color={'white'} padding={[0, 16]} gap={32}>
                    <Layout gap={8} alignItems='flex-start'>
                        <Layout row gap={16}>
                            <Text text='Course:' />
                            <select onChange={e => setCourse(e.target.value)}>
                                {
                                    courses.map(c => <option key={c.id} value={c.id}>{c.title}</option>)
                                }
                            </select>
                        </Layout>
                        <Layout row gap={16}>
                            <Text text='Lesson:' />
                            <select onChange={e => setLesson(e.target.value)}>
                                {
                                    lessons.map(l => <option key={l.id} value={l.id}>{l.name}</option>)
                                }
                            </select>
                        </Layout>
                    </Layout>
                    <Layout>
                        <Button text={'Generate'} onClick={handleGenerateByContext} disabled={false} />
                    </Layout>
                </Layout>
            </Layout>
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.context}>Context</th>
                    <th className={styles.insights}>Insights</th>
                    <th className={styles.lastUpdated}>Last Updated</th>
                </tr>
                {
                    (view === 'context' ? insightsByContext : view === 'last' ? lastInsights : []).map(insight =>
                        <InsightView
                            key={insight.id}
                            insight={insight} />
                    )
                }
            </table>
            <InsightPopup id={id} />
        </Layout>
    )
}

const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR

const InsightView = ({ insight }) => {

    const navigate = useNavigate()

    let lastActivity = ''
    if (insight?.lastUpdated !== undefined) {
        const time = insight.lastUpdated
        const now = Date.now()
        const diff = now - time;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/insights/${insight.id}`) }}>
            <td className={styles.context}>{insight.context.courseId + '/' + insight.context.lessonId}</td>
            <td className={styles.insights}>{Object.keys(insight.insights).join(', ')}</td>
            <td className={styles.lastUpdated}>{lastActivity}</td>
        </tr>
    )
}

export default Insights