import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar, CartesianGrid, ComposedChart, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchDailyPremiumData, fetchMonthlyPremiumData, fetchWeeklyPremiumData } from '../../store/home/home.slice'
import Status from '../../store/status'
import { NUM_TO_MONTH } from './Main'
import styles from './Main.module.css'


const PremiumMembersView = () => {
    const dispatch = useDispatch()
    const {
        fetchDailyPremiumDataStatus,
        fetchWeeklyPremiumDataStatus,
        fetchMonthlyPremiumDataStatus,
        dailyPremiumData,
        weeklyPremiumData,
        monthlyPremiumData,
    } = useSelector(state => state.home)

    const [cohortType, setCohortType] = useState('daily')
    const [showGrowth, setShowGrowth] = useState(false)
    const [selected, setSelected] = useState()

    let data = dailyPremiumData
    let status = fetchDailyPremiumDataStatus

    if (cohortType === 'weekly') {
        data = weeklyPremiumData
        status = fetchWeeklyPremiumDataStatus
    } else if (cohortType === 'monthly') {
        data = monthlyPremiumData
        status = fetchMonthlyPremiumDataStatus
    }

    useEffect(() => {
        if (cohortType === 'daily') {
            dispatch(fetchDailyPremiumData())
        } else if (cohortType === 'weekly') {
            dispatch(fetchWeeklyPremiumData())
        } else if (cohortType === 'monthly') {
            dispatch(fetchMonthlyPremiumData())
        }
    }, [cohortType, dispatch])

    const [currPoint, setCurrPoint] = useState(data[0] ?? {})
    const [currIndex, setCurrIndex] = useState(0)

    useEffect(() => {
        setCurrPoint(data[0] ?? {})
    }, [data])

    const Topbar = <Layout row fullWidth justifyContent='space-between' padding={[12, 24]}>
        <Layout row gap={16}>
            <Text text={'Premium Members'} type='subTitle' />
            <Text
                text={'Growth'}
                type='normal'
                onClick={() => setShowGrowth(o => !o)}
                className={[styles.textButton, showGrowth ? styles.textButtonSelected : null].join(' ')} />
        </Layout>
        <Layout row gap={16}>
            <Text
                text={'Daily'}
                type='normal'
                onClick={() => setCohortType('daily')}
                className={[styles.textButton, cohortType === 'daily' ? styles.textButtonSelected : null].join(' ')} />
            <Text
                text={'Weekly'}
                type='normal'
                onClick={() => setCohortType('weekly')}
                className={[styles.textButton, cohortType === 'weekly' ? styles.textButtonSelected : null].join(' ')} />
            <Text
                text={'Monthly'}
                type='normal'
                onClick={() => setCohortType('monthly')}
                className={[styles.textButton, cohortType === 'monthly' ? styles.textButtonSelected : null].join(' ')} />
        </Layout>
    </Layout>

    if (status !== Status.Success) {
        return (
            <Layout classes={[styles.cardContainer]}>
                {Topbar}
                <Layout classes={[styles.activeCard]}>
                    <Text text='Loading...' />
                </Layout>
            </Layout>
        )
    }

    const handleMouseMove = (e) => {
        const currIndex = data.findIndex(d => d.date === e?.activeLabel)
        const curr = data.find(d => d.date === e?.activeLabel)
        setCurrPoint(curr ?? (data[0] ?? {}))
        setCurrIndex(currIndex ?? 0)
    }

    const handleMouseLeave = () => {
        setCurrPoint(data[0] ?? {})
        setCurrIndex(0)
    }

    let diff = NaN
    let isZero = false
    let isPositive = false
    let percentage = 0.0
    if (data && currIndex + 1 < data?.length) {
        const lastPoint = data[currIndex + 1]
        const totalAddedCurr = currPoint.totalAdd + currPoint.totalLost
        const totalAddedLast = lastPoint.totalAdd + lastPoint.totalLost
        diff = showGrowth ?
            totalAddedCurr - totalAddedLast :
            currPoint?.total - lastPoint?.total
        isZero = diff === 0
        isPositive = diff >= 0
        percentage = isPositive ?
            (((currPoint?.total / lastPoint?.total) - 1) * 100).toFixed(2) :
            ((1 - (currPoint?.total / lastPoint?.total)) * 100).toFixed(2)
    }

    let dateExtension = ''
    if (currPoint?.dateTime && cohortType === 'weekly') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setDate(d.getDate() + 7)
        dateExtension += d.toUTCString().slice(5, 11)
    }

    let currDate = ''
    if (currPoint?.dateTime) {
        currDate = `${currPoint.date.split('-')[1]} ${NUM_TO_MONTH[currPoint.date.split('-')[0]]}`
        if (cohortType === 'monthly') {
            const d = new Date(currPoint.dateTime)
            d.setDate(d.getDate() + 1)
            currDate = d.toUTCString().slice(8, 11) + ' ' + d.toUTCString().slice(14, 16)
        }
    }

    let monthlyGrowth = ''
    if (currPoint.monthAdd !== null) {
        if (currPoint.monthAdd > 0 && currPoint.monthLost < 0) {
            monthlyGrowth = `(+${currPoint.monthAdd}/${currPoint.monthLost})`
        } else if (currPoint.monthAdd > 0) {
            monthlyGrowth = `(+${currPoint.monthAdd})`
        } else if (currPoint.monthLost < 0) {
            monthlyGrowth = `(${currPoint.monthLost})`
        }
    }

    let yearlyGrowth = ''
    if (currPoint.yearAdd !== null) {
        if (currPoint.yearAdd > 0 && currPoint.yearLost < 0) {
            yearlyGrowth = `(+${currPoint.yearAdd}/${currPoint.yearLost})`
        } else if (currPoint.yearAdd > 0) {
            yearlyGrowth = `(+${currPoint.yearAdd})`
        } else if (currPoint.yearLost < 0) {
            yearlyGrowth = `(${currPoint.yearLost})`
        }
    }

    let semiYearlyGrowth = ''
    if (currPoint.semiYearAdd !== null) {
        if (currPoint.semiYearAdd > 0 && currPoint.semiYearLost < 0) {
            semiYearlyGrowth = `(+${currPoint.semiYearAdd}/${currPoint.semiYearLost})`
        } else if (currPoint.semiYearAdd > 0) {
            semiYearlyGrowth = `(+${currPoint.semiYearAdd})`
        } else if (currPoint.semiYearLost < 0) {
            semiYearlyGrowth = `(${currPoint.semiYearLost})`
        }
    }

    let totalGrowth = '0'
    if (currPoint.totalAdd > 0 && currPoint.totalLost < 0) {
        totalGrowth = `+${currPoint.totalAdd}/${currPoint.totalLost}`
    } else if (currPoint.totalAdd > 0) {
        totalGrowth = `+${currPoint.totalAdd}`
    } else if (currPoint.totalLost < 0) {
        totalGrowth = `${currPoint.totalLost}`
    }

    return (
        <Layout classes={[styles.cardContainer]}>
            {Topbar}
            <Layout classes={[styles.activeCard]} row alignItems='flex-start' padding={[24, 24, 0, 24]}>
                <Layout alignItems='flex-start' gap={24} style={{ width: '160px', height: '100%' }} padding={[0, 0, 28, 0]}>
                    <Layout alignItems='flex-start' justifyContent='flex-start' gap={8} style={{ flex: 1 }}>
                        <Layout alignItems='flex-start'>
                            <Text text={showGrowth ? `${currPoint.totalChange > 0 ? '+' : ''}${currPoint.totalChange}` : currPoint.total} type='title' bold />
                            {
                                showGrowth ?
                                    <Text
                                        text={`(${totalGrowth})`}
                                        type='small'
                                        color={'mainColor'} /> :
                                    <Text
                                        text={`${isZero ? '' : isPositive ? '+' : '-'}${Math.abs(diff)} (${isZero ? '' : isPositive ? '+' : '-'}${percentage}%)`}
                                        type='small'
                                        color={isZero ? 'mainColor' : isPositive ? 'green' : 'red'} />
                            }
                            <Text text={'Users'} type='medium' color='darkGray' />
                        </Layout>
                        <Layout alignItems='flex-start' onClick={() => setSelected(o => o === 'month' ? null : 'month')} classes={[styles.clickable]}>
                            <Layout alignItems='baseline' row gap={8}>
                                <Text text={currPoint.month} type='medium' />
                                <Text text={monthlyGrowth} type='small' />
                            </Layout>
                            <Text text={'Monthly'} type='small' color='darkGray' bold={selected === 'month'} />
                        </Layout>
                        <Layout alignItems='flex-start' onClick={() => setSelected(o => o === 'year' ? null : 'year')} classes={[styles.clickable]}>
                            <Layout alignItems='baseline' row gap={8}>
                                <Text text={currPoint.year} type='medium' />
                                <Text text={yearlyGrowth} type='small' />
                            </Layout>
                            <Text text={'Annually'} type='small' color='darkGray' bold={selected === 'year'} />
                        </Layout>
                        <Layout alignItems='flex-start' onClick={() => setSelected(o => o === 'semiYear' ? null : 'semiYear')} classes={[styles.clickable]}>
                            <Layout alignItems='baseline' row gap={8}>
                                <Text text={currPoint.semiYear} type='medium' />
                                <Text text={semiYearlyGrowth} type='small' />
                            </Layout>
                            <Text text={'Semi-Annually'} type='small' color='darkGray' bold={selected === 'semiYear'} />
                        </Layout>
                    </Layout>
                    {
                        currPoint.date ?
                            <Text text={`${currDate}${dateExtension}`} type='medium' />
                            : null
                    }
                </Layout>
                {
                    showGrowth ?
                        <ResponsiveContainer
                            width='100%'
                            height={'100%'}>
                            <ComposedChart
                                data={data.filter(d => d.monthAdd !== null)}
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseLeave}
                                stackOffset="sign"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" reversed tick={false} />
                                <YAxis />
                                <ReferenceLine y={0} stroke="#000" />
                                <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                                <Bar dataKey={selected ? selected + 'Add' : "totalAdd"} fill="#8884d8" stackId={'stack'} />
                                <Bar dataKey={selected ? selected + 'Lost' : "totalLost"} fill="red" stackId={'stack'} />
                                <Line type="monotone" dataKey={selected ? selected + 'Change' : "totalChange"} stroke="#82ca9d" strokeDasharray="3 4 5 2" />
                            </ComposedChart>
                        </ResponsiveContainer> :
                        <ResponsiveContainer
                            width='100%'
                            height={'100%'}>
                            <LineChart
                                data={data}
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseLeave}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" reversed tick={false} />
                                <YAxis dataKey={selected ?? 'total'} />
                                <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                                <Line type="monotone" dataKey={selected ?? 'total'} stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                }
            </Layout>
        </Layout>
    )
}

export default PremiumMembersView