import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Status from '../status'
import {
    fetchCanceledSubscriptionsFulfilled,
    fetchCanceledSubscriptionsLoading,
    fetchCanceledSubscriptionsRejected,
} from './cancellations.actions'
import FunctionsService from '../../services/FunctionsService'

export const fetchCanceledSubscriptions = createAsyncThunk('cancellations/fetchCanceledSubscriptions', async (_, { getState }) => {
    const data = getState().cancellations.data
    if (data.length === 0) {
        return FunctionsService.getCanceledSubscriptions()
    } else {
        return data
    }
})

export const cancellationsSlice = createSlice({
    name: 'cancellations',
    initialState: {
        data: [],
        fetchStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCanceledSubscriptions.pending, fetchCanceledSubscriptionsLoading)
            .addCase(fetchCanceledSubscriptions.fulfilled, fetchCanceledSubscriptionsFulfilled)
            .addCase(fetchCanceledSubscriptions.rejected, fetchCanceledSubscriptionsRejected)
    },
})

export default cancellationsSlice.reducer