import { toast } from "react-toastify"

export const addReviewersAction = (state, action) => {
    state.reviewers = [...state.reviewers, ...action.payload]

}

export const addCreatorsAction = (state, action) => {
    state.creators = [...state.creators, ...action.payload]

}

export const addStudentsAction = (state, action) => {
    state.students = [...state.students, ...action.students]
}

export const fetchPermissionsDataFulfilled = (state, action) => {
    if (action.meta.arg.type === 'creators') {
        state.creators = [...state.creators, ...action.payload]
    } else if (action.meta.arg.type === 'reviewers') {
        state.reviewers = [...state.reviewers, ...action.payload]
    }
    // else if (action.meta.arg.type === 'affiliators') {
    //     state.affiliators = [...state.affiliators, ...action.payload]
    // }
}

export const fetchPermissionsDataRejected = (state, action) => {

    toast.error(action.error.message)
}


export const setNewPermissionsFulfilled = (state, action) => {
    if (action.meta.arg.type === 'creators') {
        state.creators = [...action.payload ,...state.creators]
    } else if (action.meta.arg.type === 'reviewers') {
        state.reviewers = [...action.payload, ...state.reviewers]
    } 
    // else if (action.meta.arg.type === 'affiliators') {
    //     state.affiliators = [...action.payload, ...state.affiliators]
    // }
}

export const setNewPermissionsRejected = (state, action) => {
    toast.error(action.error.message)
}


export const setResetPermissionLoading = (state, action) => {
    state.loading = {
        [action.payload]: true
    }
}

export const setResetPermissionFulfilled = (state, action) => {
    if (action.meta.arg.type === 'creators') {
        state.creators = state.creators.filter(item => item.email !== action.payload)
    } else if (action.meta.arg.type === 'reviewers') {
        state.reviewers = state.reviewers.filter(item => item.email !== action.payload)
    } 
    state.loading = {
        [action.payload]: false
    }
    // else if (action.meta.arg.type === 'affiliators') {
    //     state.affiliators = [...action.payload, ...state.affiliators]
    // }
}

export const setResetPermissionRejected = (state, action) => {
    toast.error(action.error.message)
    state.loading = {
        [action.payload]: false
    }
}




