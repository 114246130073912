import React, { useEffect, useState } from 'react'
import styles from './Courses.module.css'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCourses } from '../../store/tagging/tagging.slice'
import FunctionsService from '../../services/FunctionsService'
import Divider from '../../components/Divider/Divider'
import { CartesianGrid, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Line } from 'recharts'
import { NUM_TO_MONTH } from '../Main/Main'

const CreateCourseReport = ({ addCourseData }) => {
    const [loading, setLoading] = useState(false)
    const [cohort, setCohort] = useState('DAY')
    const [course, setCourse] = useState()
    const courses = useSelector(state => state.tagging.courses)


    const handleClick = () => {
        if (course && course.length > 0) {
            setLoading(true)
            FunctionsService.getCourseData(cohort, course)
            .then((d) => {
                    addCourseData({
                        funnel: d,
                        data: {
                            courseId: course,
                            cohort: cohort,
                        }
                    })
                    setLoading(false)
                })
        }
    }

    return (
        <Layout fullWidth alignItems='flex-start' gap={8}>
            <Layout justifyContent='flex-start' row style={{ flexWrap: 'wrap', gap: '16px 32px' }} margin={[0, 0, 16, 0]} >
                <Layout row gap={16}>
                    <Text text='Course:' />
                    <select onChange={e => setCourse(e.target.value)}>
                        {
                            courses.map(c => <option key={c.id} value={c.id}>{c.title}</option>)
                        }
                    </select>
                </Layout>
                <Layout row gap={16}>
                    <Text text='Cohort:' />
                    <select onChange={e => setCohort(e.target.value)}>
                        <option value={'DAY'}>Daily</option>
                        <option value={'WEEK'}>Weekly</option>
                        <option value={'MONTH'}>Monthly</option>
                        <option value={'QUARTER'}>Quarterly</option>
                    </select>
                </Layout>
            </Layout>
            <Button text={'Generate'} onClick={handleClick} disabled={loading} />
        </Layout>
    )
}

const CourseData = ({ data, funnel }) => {
    const courses = useSelector(state => state.tagging.courses)
    const [currPoint, setCurrPoint] = useState(funnel[0] ?? {})

    const handleMouseMove = (e) => {
        const curr = funnel.find(d => d.date === e?.activeLabel)
        setCurrPoint(curr ?? (funnel[0] ?? {}))
    }

    const handleMouseLeave = () => {
        setCurrPoint(funnel[0] ?? {})
    }

    let dateExtension = ''
    if (currPoint?.dateTime && data.cohort === 'WEEK') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setDate(d.getDate() + 7)
        dateExtension += d.toUTCString().slice(5, 11)
    }

    let currDate = ''
    if (currPoint?.dateTime) {
        currDate = `${currPoint.date.split('-')[1]} ${NUM_TO_MONTH[currPoint.date.split('-')[0]]}`
        if (data.cohort === 'MONTH') {
            const d = new Date(currPoint.dateTime)
            d.setDate(d.getDate() + 1)
            currDate = d.toUTCString().slice(8, 11) + ' ' + d.toUTCString().slice(14, 16)
        }
    }

    return (
        <Layout classes={[styles.activeCard]} padding={[24, 24, 0, 24]} gap={24}>
            <Layout row fullWidth justifyContent='flex-start' gap={8} alignItems='baseline'>
                <Text text={`${courses.find(c => c.id === data.courseId)?.title ?? ''} (${data.cohort})`} />
            </Layout>
            <Layout fullWidth row alignItems='flex-start' style={{ flex: 1, height: '100%' }}>
                <Layout alignItems='flex-start' gap={24} style={{ width: '160px', height: '100%' }} padding={[0, 0, 28, 0]}>
                    <Layout alignItems='flex-start' justifyContent='flex-start' gap={8} style={{ flex: 1 }}>
                        <Layout alignItems='flex-start'>
                            <Text text={currPoint.submissions} type='title' bold />
                            <Text text={'Submissions'} type='medium' color='darkGray' />
                        </Layout>
                        <Layout alignItems='flex-start'>
                            <Text text={`${currPoint.users}`} type='medium' />
                            <Text text={'Users'} type='small' color='darkGray' />
                        </Layout>
                    </Layout>
                    {
                        currPoint.date ?
                            <Text text={`${currDate}${dateExtension}`} type='medium' />
                            : null
                    }
                </Layout>

                <ResponsiveContainer
                    width='100%'
                    height={'100%'}>
                    <LineChart
                        data={funnel}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" reversed tick={false} />
                        <YAxis dataKey={'submissions'} yAxisId={`left`} />
                        <YAxis dataKey={'users'} yAxisId={`right`} orientation='right' />
                        <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                        <Line type="monotone" dataKey="submissions" stroke="#8884d8" activeDot={{ r: 8 }} yAxisId={`left`} />
                        <Line type="monotone" dataKey="users" stroke="#82ca9d" strokeDasharray="3 4 5 2" yAxisId={`right`} />
                    </LineChart>
                </ResponsiveContainer>
            </Layout>
        </Layout>
    )
}

const Courses = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])

    const handleAddCourseData = (d) => {
        setData(o => ([d]).concat(o))
    }

    useEffect(() => {
        dispatch(fetchCourses())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <CreateCourseReport addCourseData={handleAddCourseData} />
            <Divider color={'rgba(0, 0, 0, 0.3)'} />
            <Layout fullWidth style={{ height: '100%', overflow: 'auto' }} justifyContent='flex-start' gap={32}>
                {
                    data.map((d, i) => {
                        return (
                            <CourseData data={d.data} funnel={d.funnel} key={`_${i}`} />
                        )
                    })
                }
            </Layout>
        </Layout>
    )
}

export default Courses