import React from 'react'
import styles from './Button.module.css'
import stylesSecondary from './ButtonSecondary.module.css'
import stylesHome from './ButtonHome.module.css'
import stylesHomeWhite from './ButtonHomeWhite.module.css'
import stylesHomeBordered from './ButtonHomeBordered.module.css'
import stylesSideBar from './ButtonSideBar.module.css'
import stylesSelection from './ButtonSelection.module.css'
import stylesNoDecoration from './ButtonNoDecoration.module.css'
import ButtonBase from './ButtonBase'

const TYPE_SECONDARY = 'secondary'
export const TYPE_HOME = 'home'
export const TYPE_SIDEBAR = 'side_bar'
export const TYPE_HOME_WHITE = 'home_white'
export const TYPE_HOME_BORDERED = 'home_bordered'
export const TYPE_SELECTION = 'selection'
export const TYPE_NO_DECORATION = 'no_decoration'

const Button = ({
    text,
    classNameContainer,
    classNameText,
    onClick,
    type,
    style = null,
    color = null,
    Icon = null,
    classNameIcon,
    selected,
    children,
    disabled,
}) => {
    let stylesToUse = null

    switch (type) {
        case TYPE_SECONDARY:
            stylesToUse = stylesSecondary
            break;
        case TYPE_HOME:
            stylesToUse = stylesHome
            break
        case TYPE_HOME_WHITE:
            stylesToUse = stylesHomeWhite
            break
        case TYPE_HOME_BORDERED:
            stylesToUse = stylesHomeBordered
            break
        case TYPE_SIDEBAR:
            stylesToUse = stylesSideBar
            break
        case TYPE_NO_DECORATION:
            stylesToUse = stylesNoDecoration
            break
        case TYPE_SELECTION:
            stylesToUse = stylesSelection
            break
        default:
            stylesToUse = styles
    }


    return (
        <ButtonBase
            style={TYPE_NO_DECORATION === type ? { boxShadow: 'none', minWidth: 'auto' } : { background: color, ...style }}
            withBorder={type === TYPE_SECONDARY || type === TYPE_HOME_BORDERED || type === TYPE_SIDEBAR || type === TYPE_SELECTION}
            onClick={disabled ? null : onClick}
            disabled={disabled}
            className={[
                stylesToUse.container,
                classNameContainer ? classNameContainer : null,
                selected ? stylesToUse.selected : null,
                disabled ? stylesToUse.disabled : null].join(' ')}>
            {Icon && <Icon className={classNameIcon} />}
            <p className={[stylesToUse.text, classNameText].join(' ')}>{children ? children : text}</p>
        </ButtonBase>
    )
}

export default Button
