import React, { useState } from 'react'
import Layout from '../../components/Layout'
import KPIs from './KPIs'
import PremiumMembersView from './PremiumMembersView'
import UsersViewWrapper from './UsersViewWrapper'
import SubmissionsView from './SubmissionsView'
import AIResponsesView from './AIResponsesView'
import ReferralViewWrapper from './ReferralViewWrapper'
import Button from '../../components/Button/Button'
import SupportViewWrapper from './SupportViewWrapper'
// import UsersRetentionWrapper from './UsersRetentionWrapper'

export const NUM_TO_MONTH = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
}

const Main = () => {
  const [showMore, setShowMore] = useState(false)
  return (
    <Layout
      justifyContent='flex-start'
      padding={[32, 32, 64, 32]}
      gap={32}
      fullWidth
      style={{ minWidth: '1000px', maxHeight: '100vh', overflow: 'auto' }}>
      <KPIs />
      <PremiumMembersView />
      {/* <UsersRetentionWrapper /> */}
      <UsersViewWrapper />
      {
        !showMore ?
          <Button text={'Show more'} onClick={() => setShowMore(true)} /> :
          <>
            <SubmissionsView />
            <AIResponsesView />
            <ReferralViewWrapper />
            <SupportViewWrapper />
          </>
      }
    </Layout>
  )
}

export default Main