import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import Status from '../status'
import {
  fetchUsersLoading,
  fetchUsersFulfilled,
  fetchUsersRejected,
  fetchMoreUsersLoading,
  fetchMoreUsersFulfilled,
  fetchMoreUsersRejected,
  changeOrderFieldAction,
  setFromAction,
  addFilterLabelAction,
  removeFilterLabelAction,
  setFilterMarkedAction,
} from './users.actions'
import FunctionsService from '../../services/FunctionsService'

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (action, { getState }) => {
  const order = getState().users.order
  const orderDirection = getState().users.orderDirection
  const data = getState().users.data
  const filterLabels = getState().users.filterLabels
  const filterMarked = getState().users.filterMarked
  const fetchStatus = getState().users.fetchStatus
  const from = getState().users.from
  const userData = getState().user.data
  if (filterLabels.length > 0 || filterMarked) {
    let uidsToDownloadLabels = []
    filterLabels.forEach(l => {
      uidsToDownloadLabels = uidsToDownloadLabels.concat(Object.keys(userData.usersLabelsMap).filter(uid => filterLabels.every(ll => userData.usersLabelsMap[uid].includes(ll))))
    })

    let uidsToDownload = []
    if (filterMarked) {
      uidsToDownload = uidsToDownload.concat(Object.keys(userData.markedUsers)).filter(u => userData.markedUsers[u] === true)
      if (filterLabels.length > 0) {
        uidsToDownload = uidsToDownload.filter(uid => uidsToDownloadLabels.some(u => u === uid))
      }
    } else {
      uidsToDownload = uidsToDownloadLabels
    }

    uidsToDownload = [...new Set(uidsToDownload)]
    const existingData = data.filter(d => uidsToDownload.some(uid => d.uid === uid))
    uidsToDownload = uidsToDownload.filter(uid => !data.some(d => d.uid === uid))
    let res = await Promise.all(uidsToDownload.map(uid => DatabaseService.FunctionsService(uid)))
    res = res.concat(existingData)
    res.sort((a, b) =>
      orderDirection === 'desc' ?
        Object.byString(b, order) - Object.byString(a, order) :
        Object.byString(a, order) - Object.byString(b, order))
    return res
  }

  let whereObject = null
  if (userData?.ref !== undefined) {
    whereObject = {
      field: 'ref',
      operator: '==',
      value: userData.ref,
    }
  } else if (from !== undefined) {
    whereObject = {
      field: 'from',
      operator: '==',
      value: from,
    }
  }

  if (action?.force || fetchStatus === Status.None || fetchStatus === Status.Fail || data.length === 0)
    return FunctionsService.getCoddyUsers(
      order,
      orderDirection,
      whereObject)
  else
    return data
})

export const fetchMoreUsers = createAsyncThunk('users/fetchMoreUsers', async (_, { getState }) => {
  const order = getState().users.order
  const orderDirection = getState().users.orderDirection
  const data = getState().users.data
  const userData = getState().user.data
  let whereObject = null
  if (order === 'metadata.lastChallengeRunDate') {
    whereObject = {
      field: order,
      operator: orderDirection === 'desc' ? '<' : '>',
      value: new Date(data[data.length - 1]?.metadata?.lastChallengeRunDate).getTime(),
    }
  } else if (order === 'createdDate') {
    whereObject = {
      field: order,
      operator: orderDirection === 'desc' ? '<' : '>',
      value: new Date(data[data.length - 1]?.createdDate).getTime(),
    }
  } else if (order === 'streak') {
    whereObject = {
      field: order,
      operator: orderDirection === 'desc' ? '<' : '>',
      value: data[data.length - 1]?.streak,
    }
  } else if (order === 'score') {
    whereObject = {
      field: order,
      operator: orderDirection === 'desc' ? '<' : '>',
      value: data[data.length - 1]?.score,
    }
  } else if (order === 'metadata.daysUsed') {
    whereObject = {
      field: order,
      operator: orderDirection === 'desc' ? '<' : '>',
      value: data[data.length - 1]?.metadata?.daysUsed,
    }
  } else if (order === 'metadata.compilesNum') {
    whereObject = {
      field: order,
      operator: orderDirection === 'desc' ? '<' : '>',
      value: data[data.length - 1]?.metadata?.compilesNum,
    }
  } else if (order === 'metadata.todayCompilesNum') {
    whereObject = {
      field: order,
      operator: orderDirection === 'desc' ? '<' : '>',
      value: data[data.length - 1]?.metadata?.todayCompilesNum,
    }
  }
  const from = getState().users.from
  let whereObject2 = null
  if (userData?.ref !== undefined) {
    whereObject = {
      field: 'ref',
      operator: '==',
      value: userData.ref,
    }
  } else if (from !== undefined) {
    whereObject2 = {
      field: 'from',
      operator: '==',
      value: from,
    }
  }
  return FunctionsService.getCoddyUsers(order, orderDirection, whereObject, whereObject2)
})


export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    fetchStatus: Status.None,
    fetchMoreStatus: Status.None,
    order: 'metadata.lastChallengeRunDate',
    orderDirection: 'desc',
    from: undefined,
    filterLabels: [],
    filterMarked: false,
  },
  reducers: {
    changeOrderField: changeOrderFieldAction,
    setFrom: setFromAction,
    addFilterLabel: addFilterLabelAction,
    removeFilterLabel: removeFilterLabelAction,
    setFilterMarked: setFilterMarkedAction,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, fetchUsersLoading)
      .addCase(fetchUsers.fulfilled, fetchUsersFulfilled)
      .addCase(fetchUsers.rejected, fetchUsersRejected)
      .addCase(fetchMoreUsers.pending, fetchMoreUsersLoading)
      .addCase(fetchMoreUsers.fulfilled, fetchMoreUsersFulfilled)
      .addCase(fetchMoreUsers.rejected, fetchMoreUsersRejected)
  },
})

export const {
  changeOrderField,
  setFrom,
  addFilterLabel,
  removeFilterLabel,
  setFilterMarked,
} = usersSlice.actions

export default usersSlice.reducer