import { addDays, format } from 'date-fns';
import React from 'react'
import Layout from '../Layout'
import Text from '../Text'
import style from './Presets.module.css'

const Presets = ({onChange, ranges}) => {
    const range = ranges[0]
    const dateFormat = 'dd LLL';
    return (
        <Layout spacing={4} padding={[18, 0]} fullWidth>
            <Layout padding={[8, 12]} row justifyContent='space-between' classes={[style.container]} fullWidth onClick={() => {
                onChange([
                    {
                      startDate: addDays(new Date(), -7),
                      endDate: new Date(),
                      key: 'selection'
                }], 7)
            }}>
                <Text text="Last 7 Days" type={"medium"}/>
                <Text text={`${format(addDays(range.endDate, -7), dateFormat)} - ${format(range.endDate, dateFormat)}`} type={"medium"}/>
            </Layout>
            <Layout padding={[8, 12]} row justifyContent='space-between' classes={[style.container]} fullWidth onClick={() => {
                 onChange([
                    {
                      startDate: addDays(new Date(), -30),
                      endDate: new Date(),
                      key: 'selection'
                }], 30)
            }}>
                <Text text="Last 30 Days" type={"medium"}/>
                <Text text={`${format(addDays(range.endDate, -30), dateFormat)} - ${format(range.endDate, dateFormat)}`} type={"medium"}/>
            </Layout>
            <Layout padding={[8, 12]} row justifyContent='space-between' classes={[style.container]} fullWidth onClick={() => {
                 onChange([
                    {
                      startDate: addDays(new Date(), -90),
                      endDate: new Date(),
                      key: 'selection'
                }], 90)
            }}>
                <Text text="Last 90 Days" type={"medium"}/>
                <Text text={`${format(addDays(range.endDate, -90), dateFormat)} - ${format(range.endDate, dateFormat)}`} type={"medium"}/>
            </Layout>

            <Layout padding={[8, 12]} row justifyContent='space-between' classes={[style.container]} fullWidth onClick={() => {
                 onChange([
                    {
                      startDate: addDays(new Date(), -180),
                      endDate: new Date(),
                      key: 'selection'
                }], 180)
            }}>
                <Text text="Last 180 Days" type={"medium"}/>
                <Text text={`${format(addDays(range.endDate, -180), dateFormat)} - ${format(range.endDate, dateFormat)}`} type={"medium"}/>
            </Layout>
            {/* <Layout>
                <Text text="Last 7 Days"/>
            </Layout> */}
        </Layout>
    )
}

export default Presets