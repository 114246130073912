import { toast } from "react-toastify"

export const fetchLeaderboardsFulfilled = (state, action) => {
    state.data = action.payload
}

export const fetchLeaderboardsRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchLeaderboardGroupsLoading = (state, action) => {
    state.groupsData = []
    state.usersData = []
    state.selectedGID = undefined
    state.selectedLID = undefined
}

export const fetchLeaderboardGroupsFulfilled = (state, action) => {
    state.groupsData = action.payload
    state.selectedLID = action.meta.arg.lid
}

export const fetchLeaderboardGroupsRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchLeaderboardMoreGroupsFulfilled = (state, action) => {
    state.groupsData = state.groupsData.concat(action.payload)
}

export const fetchLeaderboardGroupDataLoading = (state, action) => {
    state.usersData = []
    state.selectedGID = undefined
}

export const fetchLeaderboardGroupDataFulfilled = (state, action) => {
    state.usersData = action.payload
    state.selectedGID = action.meta.arg.gid
}

export const fetchLeaderboardGroupDataRejected = (state, action) => {
    toast.error(action.error.message)
}

