import React, { useState } from 'react'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import InputBar from '../../components/InputBar'
import Text from '../../components/Text'
import { MARGIN } from '../../Constants'
import Loading from '../../components/Loading'

const Item = ({ creator, money, lastUpdated, onUpdate, loading }) => {
    const [isUpdate, setIsUpdate] = useState(false)
    const [newMoney, setNewMoney] = useState('')

    const isNumeric = (value) => {
        return /^\d+(.\d+|d*)$/.test(value);
    }
    return (
        <Loading loading={loading}>
            <Layout fullWidth row justifyContent='space-between' padding={[MARGIN]} border key={creator.email} color={creator.creatorVisibility === 'coddy' ? 'rgba(255, 0, 0, 0.25)' : 'white'}>
                <Layout alignItems='flex-start' gap={MARGIN / 2}>
                    <Layout row gap={32} alignItems='center'>
                        <Layout alignItems='flex-start'>
                            <Text text={creator.displayName} type='subTitle' />
                            <Text text={creator.email} type='small' />
                        </Layout>
                    </Layout>
                    {
                        isUpdate ?
                            <InputBar
                                style={{ 'padding': '6px', 'margin': '0px' }}
                                hint={money}
                                value={newMoney}
                                onValueChange={(e) => setNewMoney(e)}
                                error={newMoney.length > 0 && !isNumeric(newMoney)} errorMsg={'Numeric value only'} /> :
                            <Layout padding={[8, 0, 0, 0]} row gap={16}>
                                <Text text={money} type='subTitle' />
                                <Text text={'(' + new Date(lastUpdated).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' }) + ')'} type='medium' color="darkGray" />
                            </Layout>
                    }
                </Layout>

                <Layout row>
                    {
                        isUpdate ?
                            <>
                                <Button text="Accept" onClick={() => {
                                    onUpdate(newMoney)
                                    setIsUpdate(false)
                                }} />
                                <Button text="Cancel" onClick={() => { setIsUpdate(false) }} type={'secondary'} />
                            </>
                            :
                            <Button text="Update" onClick={() => { setIsUpdate(true) }} />
                    }
                </Layout>
            </Layout>
        </Loading>
    )
}

export default Item