import React, { useEffect, useState } from 'react'
import styles from './Courses.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Right from '../../assets/icons/Right'
import IconButton from '../../components/IconButton'
import Layout from '../../components/Layout'
import SearchBar from '../../components/SearchBar/SearchBar'
import Text from '../../components/Text'
import { fetchCourses } from '../../store/tagging/tagging.slice'
import DatabaseService from '../../services/DatabaseService'

const Courses = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const courses = useSelector(state => state.tagging.courses)
    const [filterValue, setFilterValue] = useState('')
    const [filterValue2, setFilterValue2] = useState('')
    const [course, setCourse] = useState()

    useEffect(() => {
        dispatch(fetchCourses())
    }, [dispatch])

    const handleShowLessons = (e, courseId) => {
        e.stopPropagation()
        DatabaseService.getCourse(courseId)
            .then(d => setCourse(d))
    }

    return (
        <Layout row justifyContent='flex-start' classes={[styles.container]} gap={32}>
            <Layout classes={[styles.leftContainer]} alignItems='flex-start' justifyContent='flex-start' gap={16}>
                <SearchBar onChange={setFilterValue} />
                <Layout fullWidth padding={[16, 0]} gap={8} classes={[styles.coursesContainer]} justifyContent='flex-start'>
                    {
                        courses.filter(course =>
                            course.id.toLowerCase().includes(filterValue.toLowerCase()) ||
                            course.title.toLowerCase().includes(filterValue.toLowerCase()))
                            .map(course =>
                                <Layout
                                    row
                                    key={course.id}
                                    fullWidth
                                    justifyContent='space-between'
                                    classes={[styles.courseContainer]}
                                    padding={[4, 12]}
                                    onClick={() => navigate(`/tagging/course/${course.id}`)}>
                                    <Text text={course.title} />
                                    <Layout row>
                                        <IconButton Icon={Right} size='small' type={'white'} onClick={(e) => handleShowLessons(e, course.id)} />
                                    </Layout>
                                </Layout>
                            )
                    }
                </Layout>
            </Layout>
            {
                course ?
                    <Layout classes={[styles.rightContainer]} alignItems='flex-start' justifyContent='flex-start' gap={16}>
                        <SearchBar onChange={setFilterValue2} />
                        <Layout fullWidth padding={[16, 0]} gap={8} classes={[styles.coursesContainer]} justifyContent='flex-start'>
                            {
                                course.chapters.flatMap(c => c.lessons).filter(lesson =>
                                    lesson.id.toLowerCase().includes(filterValue2.toLowerCase()) ||
                                    lesson.name.toLowerCase().includes(filterValue2.toLowerCase()))
                                    .map(lesson =>
                                        <Layout
                                            row
                                            key={lesson.id}
                                            fullWidth
                                            justifyContent='space-between'
                                            classes={[styles.courseContainer]}
                                            onClick={() => navigate(`/tagging/lesson/${course.id}/${lesson.id}`)}
                                            padding={[10, 12]}>
                                            <Text text={`${course.chapters.find(c => c.lessons.some(l => l.id === lesson.id)).name}/${lesson.name}`} />
                                        </Layout>
                                    )
                            }
                        </Layout>
                    </Layout> :
                    null
            }
        </Layout>
    )
}

export default Courses