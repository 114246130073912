import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import styles from './BillingEditPopup.module.css'
import Button from '../../components/Button'
import { setShowEditBilling } from '../../store/analytics/analytics.slice'
import { toast } from 'react-toastify'
import FunctionsService from '../../services/FunctionsService'

const INTERVAL_MAP = {
    'month': 'Monthly',
    'semi-year': 'Semi Yearly',
    'year': 'Yearly',
    'once': 'Once',
}

const BillingEditPopup = ({ uid, data }) => {
    const dispatch = useDispatch()
    const show = useSelector(state => state.analytics.showBillingEdit)
    const [reason, setReason] = useState(data?.reason ?? 'none')
    const [expiresAt, setExpiresAt] = useState(data?.expiresAt ? new Date(data.expiresAt).toISOString().substring(0, 10) : undefined)
    const [isPremium, setIsPremium] = useState(data?.isPremium ?? 'none')
    const [loading, setLoading] = useState(false)

    const handleSave = () => {
        const toSave = {
            uid: uid,
            isManual: true
        }
        if (isPremium !== 'none') toSave.isPremium = isPremium === 'true'
        else {
            toast.error('Is premium is required!')
            return
        }
        if (reason !== 'none') toSave.reason = reason
        if (expiresAt !== undefined) toSave.expiresAt = new Date(expiresAt).getTime()
        if (data?.sid !== undefined) toSave.sid = data.sid
        if (data?.interval !== undefined) toSave.interval = data.interval
        setLoading(true)
        FunctionsService.setUserBillingData(uid, toSave)
            .then(() =>{
                setLoading(false)
                toast.success("Updated")
            })
    }

    useEffect(() => {
        setReason(data?.reason ?? 'none')
        setExpiresAt(data?.expiresAt ? new Date(data.expiresAt).toISOString().substring(0, 10) : undefined)
        setIsPremium(data?.isPremium ?? 'none')
    }, [data])

    return (
        <Layout classes={[styles.backdrop, show ? styles.display : null]} alignItems='flex-end' onClick={() => dispatch(setShowEditBilling(false))}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16]} alignItems='flex-start' justifyContent='flex-start' gap={16}>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Customer Id (from Stripe):' />
                    <input
                        value={data?.sid}
                        className={styles.input}
                        placeholder='None'
                        disabled />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Interval (from Stripe):' />
                    <input
                        value={INTERVAL_MAP[data?.interval]}
                        className={styles.input}
                        placeholder='None'
                        disabled />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Is premium:' />
                    <select
                        className={styles.input}
                        value={isPremium}
                        onChange={e => setIsPremium(e.target.value)} >
                        <option disabled value={'none'}> -- Select option -- </option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Expires at:' />
                    <input
                        value={expiresAt}
                        onChange={e => setExpiresAt(e.target.value)}
                        type='date'
                        className={styles.input} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Reason:' />
                    <select
                        value={reason}
                        className={styles.input}
                        onChange={e => setReason(e.target.value)} >
                        <option value={'none'}> -- None -- </option>
                        <option value={'friend'}>Friends & Family</option>
                        <option value={'store'}>Store</option>
                        <option value={'special'}>Special</option>
                        <option value={'creator'}>Creator/Influencer</option>
                    </select>
                </Layout>
                <div style={{ height: '32px' }} />
                <Button text={'Save'} onClick={handleSave} disabled={loading} />
            </Layout>
        </Layout>
    )
}

export default BillingEditPopup