import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchDailyUsersData, fetchMonthlyUsersData, fetchWeeklyUsersData } from '../../store/home/home.slice'
import Status from '../../store/status'
import { NUM_TO_MONTH } from './Main'
import styles from './Main.module.css'


const UsersView = ({ cohortType }) => {
    const dispatch = useDispatch()
    const {
        fetchDailyUsersDataStatus,
        fetchWeeklyUsersDataStatus,
        fetchMonthlyUsersDataStatus,
        dailyUsersData,
        weeklyUsersData,
        monthlyUsersData,
    } = useSelector(state => state.home)

    let data = dailyUsersData
    let status = fetchDailyUsersDataStatus

    if (cohortType === 'weekly') {
        data = weeklyUsersData
        status = fetchWeeklyUsersDataStatus
    } else if (cohortType === 'monthly') {
        data = monthlyUsersData
        status = fetchMonthlyUsersDataStatus
    }

    useEffect(() => {
        if (cohortType === 'daily') {
            dispatch(fetchDailyUsersData())
        } else if (cohortType === 'weekly') {
            dispatch(fetchWeeklyUsersData())
        } else if (cohortType === 'monthly') {
            dispatch(fetchMonthlyUsersData())
        }
    }, [cohortType, dispatch])

    const [currPoint, setCurrPoint] = useState(data[0] ?? {})
    const [currIndex, setCurrIndex] = useState(0)
    const [selected, setSelected] = useState()

    useEffect(() => {
        setCurrPoint(data[0] ?? {})
    }, [data])

    if (status !== Status.Success) {
        return (
            <Layout classes={[styles.activeCard]}>
                <Text text='Loading...' />
            </Layout>
        )
    }

    const handleMouseMove = (e) => {
        const currIndex = data.findIndex(d => d.date === e?.activeLabel)
        const curr = data.find(d => d.date === e?.activeLabel)
        setCurrPoint(curr ?? (data[0] ?? {}))
        setCurrIndex(currIndex ?? 0)
    }

    const handleMouseLeave = () => {
        setCurrPoint(data[0] ?? {})
        setCurrIndex(0)
    }

    let diff = NaN
    let isZero = false
    let isPositive = false
    let percentage = 0.0
    if (data && currIndex + 1 < data?.length) {
        const lastPoint = data[currIndex + 1]
        diff = currPoint?.num - lastPoint?.num
        isZero = diff === 0
        isPositive = diff >= 0
        percentage = isPositive ?
            (((currPoint?.num / lastPoint?.num) - 1) * 100).toFixed(2) :
            ((1 - (currPoint?.num / lastPoint?.num)) * 100).toFixed(2)
    }

    let dateExtension = ''
    if (currPoint?.dateTime && cohortType === 'weekly') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setDate(d.getDate() + 7)
        dateExtension += d.toUTCString().slice(5, 11)
    }

    let currDate = ''
    if (currPoint?.dateTime) {
        currDate = `${currPoint.date.split('-')[1]} ${NUM_TO_MONTH[currPoint.date.split('-')[0]]}`
        if (cohortType === 'monthly') {
            const d = new Date(currPoint.dateTime)
            d.setDate(d.getDate() + 1)
            currDate = d.toUTCString().slice(8, 11) + ' ' + d.toUTCString().slice(14, 16)
        }
    }

    return (
        <Layout classes={[styles.activeCard]} row alignItems='flex-start' padding={[24, 24, 0, 24]}>
            <Layout alignItems='flex-start' gap={24} style={{ width: '160px', height: '100%' }} padding={[0, 0, 28, 0]}>
                <Layout alignItems='flex-start' justifyContent='flex-start' gap={8} style={{ flex: 1 }}>
                    <Layout alignItems='flex-start'>
                        <Text text={currPoint.num} type='title' bold />
                        <Text
                            text={`${isZero ? '' : isPositive ? '+' : '-'}${Math.abs(diff)} (${isZero ? '' : isPositive ? '+' : '-'}${percentage}%)`}
                            type='small'
                            color={isZero ? 'mainColor' : isPositive ? 'green' : 'red'} />
                        <Text text={'Users'} type='medium' color='darkGray' />
                    </Layout>
                    <Layout alignItems='flex-start' onClick={() => setSelected(o => o === 'new' ? null : 'new')} classes={[styles.clickable]}>
                        <Text text={`${currPoint.new} (${((currPoint.new / currPoint.num) * 100).toFixed(2)}%)`} type='medium' />
                        <Text text={'New Users'} type='medium' color='darkGray' bold={selected === 'new'} />
                    </Layout>
                    <Layout alignItems='flex-start' onClick={() => setSelected(o => o === 'old' ? null : 'old')} classes={[styles.clickable]}>
                        <Text text={`${currPoint.old} (${((currPoint.old / currPoint.num) * 100).toFixed(2)}%)`} type='medium' />
                        <Text text={'Returning Users'} type='medium' color='darkGray' bold={selected === 'old'} />
                    </Layout>
                    {
                        currPoint.premium !== null ?
                            <Layout alignItems='baseline' row gap={8} margin={[8, 0, 0, 0]} onClick={() => setSelected(o => o === 'premium' ? null : 'premium')} classes={[styles.clickable]}>
                                <Text text={currPoint.premium} type='medium' />
                                <Text text={'Premium'} type='small' color='darkGray' bold={selected === 'premium'} />
                            </Layout> :
                            null
                    }
                </Layout>
                {
                    currPoint.date ?
                        <Text text={`${currDate}${dateExtension}`} type='medium' />
                        : null
                }
            </Layout>
            <ResponsiveContainer
                width='100%'
                height={'100%'}>
                <LineChart
                    data={data}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" reversed tick={false} />
                    <YAxis dataKey={selected ?? 'num'} />
                    <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                    <Line type="monotone" dataKey={selected ?? 'num'} stroke="#8884d8" activeDot={{ r: 8 }} />
                    {
                        selected ?
                            null :
                            <Line type="monotone" dataKey="new" stroke="#82ca9d" strokeDasharray="3 4 5 2" />
                    }
                </LineChart>
            </ResponsiveContainer>
        </Layout>
    )
}

export default UsersView