import React, { useEffect } from 'react'
import styles from './Journeys.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import Popup from './Popup'
import { fetchJourneys } from '../../store/journeys/journeys.slice'

const Journeys = ({ add }) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const data = useSelector(state => state.journeys.data)

    useEffect(() => {
        dispatch(fetchJourneys())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <table className={styles.table}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.title}>Title</th>
                    <th className={styles.num}></th>
                </tr>
                {
                    data.map(d =>
                        <RowView
                            key={d.id}
                            id={d.id}
                            title={d.title} />
                    )
                }
            </table>
            <Popup id={id} add={add} />
        </Layout>
    )
}

const RowView = ({ title, id }) => {

    const navigate = useNavigate()

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/journeys/${id}`) }}>
            <td className={styles.title}>{title}</td>
            <td className={styles.num}></td>
        </tr>
    )
}

export default Journeys