import { toast } from "react-toastify"
import Status from "../status"

export const setShowCloseAction = (state, action) => {
    state.showClose = action.payload
}

export const fetchSupportLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchSupportFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.tickets = action.payload
}

export const fetchSupportRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    console.log(action.error.message);
    toast.error(action.error.message)
}

export const fetchMoreSupportFulfilled = (state, action) => {
    state.tickets = state.tickets.concat(action.payload)
}

export const fetchMoreSupportRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchTicketFulfilled = (state, action) => {
    state.currTicket = action.payload
    state.sendMessageStatus = Status.None
}

export const fetchTicketRejected = (state, action) => {
    toast.error(action.error.message)
    state.sendMessageStatus = Status.None
}

export const sendTicketMessageLoading = (state, _) => {
    state.sendMessageStatus = Status.Loading
}

export const sendTicketMessageFulfilled = (state, action) => {
    state.sendMessageStatus = Status.Success
    const newMessageData = {
        sender: 'Coddy Team',
        body: action.meta.arg.message,
        time: Date.now()
    }
    state.currTicket.messages = state.currTicket.messages.concat(newMessageData)
    state.currTicket.lastUpdated = Date.now()
    state.tickets = state.tickets.map(ticket => {
        if (ticket.id === action.meta.arg.tid) {
            return state.currTicket
        }
        return ticket
    })
}

export const sendTicketMessageRejected = (state, action) => {
    state.sendMessageStatus = Status.Fail
    toast.error(action.error.message)
}

export const setTicketStatusFulfilled = (state, action) => {
    state.currTicket.status = action.meta.arg.status
    state.currTicket.lastUpdated = Date.now()
    state.tickets = state.tickets.map(ticket => {
        if (ticket.id === action.meta.arg.tid) {
            return state.currTicket
        }
        return ticket
    })
}

export const setTicketStatusRejected = (state, action) => {
    toast.error(action.error.message)
}