import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchDailyActiveData, fetchMonthlyActiveData, fetchWeeklyActiveData } from '../../store/home/home.slice'
import Status from '../../store/status'
import { NUM_TO_MONTH } from './Main'
import styles from './Main.module.css'


const ActiveUsersView = ({ cohortType }) => {
    const dispatch = useDispatch()
    const {
        fetchDailyActiveDataStatus,
        fetchWeeklyActiveDataStatus,
        fetchMonthlyActiveDataStatus,
        dailyActiveData,
        weeklyActiveData,
        monthlyActiveData,
    } = useSelector(state => state.home)

    let data = dailyActiveData
    let status = fetchDailyActiveDataStatus

    if (cohortType === 'weekly') {
        data = weeklyActiveData
        status = fetchWeeklyActiveDataStatus
    } else if (cohortType === 'monthly') {
        data = monthlyActiveData
        status = fetchMonthlyActiveDataStatus
    }

    useEffect(() => {
        if (cohortType === 'daily') {
            dispatch(fetchDailyActiveData())
        } else if (cohortType === 'weekly') {
            dispatch(fetchWeeklyActiveData())
        } else if (cohortType === 'monthly') {
            dispatch(fetchMonthlyActiveData())
        }
    }, [cohortType, dispatch])

    const [currPoint, setCurrPoint] = useState(data[0] ?? {})

    useEffect(() => {
        setCurrPoint(data[0] ?? {})
    }, [data])

    if (status !== Status.Success) {
        return (
            <Layout classes={[styles.activeCard]}>
                <Text text='Loading...' />
            </Layout>
        )
    }

    const handleMouseMove = (e) => {
        const curr = data.find(d => d.date === e?.activeLabel)
        setCurrPoint(curr ?? (data[0] ?? {}))
    }

    const handleMouseLeave = () => {
        setCurrPoint(data[0] ?? {})
    }

    const diff = currPoint?.curr - currPoint?.last
    const isZero = diff === 0
    const isPositive = diff >= 0
    let percentage = isPositive ?
        (((currPoint?.curr / currPoint?.last) - 1) * 100).toFixed(2) :
        ((1 - (currPoint?.curr / currPoint?.last)) * 100).toFixed(2)

    let dateExtension = ''
    if (currPoint?.dateTime && cohortType === 'weekly') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setDate(d.getDate() + 7)
        dateExtension += d.toUTCString().slice(5, 11)
    }

    let currDate = ''
    if (currPoint?.dateTime) {
        currDate = `${currPoint.date.split('-')[1]} ${NUM_TO_MONTH[currPoint.date.split('-')[0]]}`
        if (cohortType === 'monthly') {
            const d = new Date(currPoint.dateTime)
            d.setDate(d.getDate() + 1)
            currDate = d.toUTCString().slice(8, 11) + ' ' + d.toUTCString().slice(14, 16)
        }
    }

    return (
        <Layout classes={[styles.activeCard]} row alignItems='flex-start' padding={[24, 24, 0, 24]}>
            <Layout alignItems='flex-start' gap={24} style={{ width: '160px', height: '100%' }} padding={[0, 0, 28, 0]}>
                <Layout alignItems='flex-start' justifyContent='flex-start' gap={8} style={{ flex: 1 }}>
                    <Layout alignItems='flex-start'>
                        <Text text={currPoint.curr} type='title' bold />
                        <Text
                            text={`${isZero ? '' : isPositive ? '+' : '-'}${Math.abs(diff)} (${isZero ? '' : isPositive ? '+' : '-'}${percentage}%)`}
                            type='small'
                            color={isZero ? 'mainColor' : isPositive ? 'green' : 'red'} />
                        <Text text={'Users'} type='medium' color='darkGray' />
                    </Layout>
                    <Layout alignItems='flex-start'>
                        <Text text={`${currPoint.ret}% (${currPoint.both})`} type='medium' />
                        <Text text={'Retention'} type='medium' color='darkGray' />
                    </Layout>
                    {
                        currPoint.premium !== null ?
                            <Layout alignItems='baseline' row gap={8} margin={[8, 0, 0, 0]}>
                                <Text text={currPoint.premium} type='medium' />
                                <Text text={'Premium'} type='small' color='darkGray' />
                            </Layout> :
                            null
                    }
                </Layout>
                {
                    currPoint.date ?
                        <Text text={`${currDate}${dateExtension}`} type='medium' />
                        : null
                }
            </Layout>
            <ResponsiveContainer
                width='100%'
                height={'100%'}>
                <LineChart
                    data={data}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" reversed tick={false} />
                    <YAxis dataKey={'curr'} yAxisId={`left`} />
                    <YAxis domain={[0, 100]} dataKey={'ret'} yAxisId={`right`} orientation='right' unit={'%'} />
                    <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                    <Line type="monotone" dataKey="curr" stroke="#8884d8" activeDot={{ r: 8 }} yAxisId={`left`} />
                    <Line type="monotone" dataKey="ret" stroke="#82ca9d" strokeDasharray="3 4 5 2" yAxisId={`right`} />
                </LineChart>
            </ResponsiveContainer>
        </Layout>
    )
}

export default ActiveUsersView