import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import styles from './BlogPopup.module.css'
import Button from '../../components/Button'
import FunctionsService from '../../services/FunctionsService'


const BlogPopup = ({ id, add }) => {
    const [newData, setNewData] = useState({})
    const navigate = useNavigate()
    const blogDate = useSelector(state => state.blogs.data.find(b => b.id === id))

    const handleShow = () => {
        window.open(`https://coddy.tech/blog/${blogDate.id}`)
    }

    const handleSave = () => {
        if (add) {
            FunctionsService.publishBlog(newData)
                .then(d => console.log(d))
                .catch(e => console.log(e))
        } else {

        }
    }

    return (
        <Layout classes={[styles.backdrop, id !== undefined || add ? styles.display : null]} alignItems='flex-end' onClick={() => { navigate(`/blogs`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16, 16, 0, 16]} justifyContent='flex-start' gap={32}>
                {
                    add ?
                        null :
                        <Layout gap={4} alignItems='flex-start' fullWidth>
                            <Text text='Id:' />
                            <input
                                value={blogDate?.id}
                                className={styles.input}
                                disabled />
                        </Layout>
                }
                {
                    add ?
                        null :
                        <Layout gap={4} alignItems='flex-start' fullWidth>
                            <Text text='Date:' />
                            <input
                                value={new Date(blogDate?.date).toLocaleDateString()}
                                className={styles.input}
                                disabled />
                        </Layout>
                }
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Title:' />
                    <input
                        value={add ? (newData?.title ?? '') : blogDate?.title}
                        className={styles.input}
                        onChange={e => setNewData(o => { return { ...o, title: e.target.value } })} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Type:' />
                    <input
                        value={add ? (newData?.type ?? '') : blogDate?.type}
                        className={styles.input}
                        onChange={e => setNewData(o => { return { ...o, type: e.target.value } })} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Image url:' />
                    <input
                        value={add ? (newData?.img ?? '') : blogDate?.img}
                        className={styles.input}
                        onChange={e => setNewData(o => { return { ...o, img: e.target.value } })} />
                    <img src={add ? (newData?.img ?? '') : blogDate?.img} alt='' style={{ maxHeight: '120px' }} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Body:' />
                    <textarea
                        value={add ? (newData?.body ?? '') : blogDate?.body}
                        className={styles.input}
                        onChange={e => setNewData(o => { return { ...o, body: e.target.value } })} />
                    <div dangerouslySetInnerHTML={{ __html: add ? (newData?.body ?? '') : blogDate?.body }} className={styles.htmlPreview} />
                </Layout>
                <Layout row classes={[styles.bottomContainer]} fullWidth justifyContent='flex-start' padding={[8, 0]}>
                    <Button
                        text={add ? 'Publish' : 'Save'}
                        onClick={handleSave}
                        disabled={false} />
                    {
                        add ?
                            null :
                            <Button text={'Show'} type='secondary' onClick={handleShow} />
                    }
                </Layout>
            </Layout>
        </Layout>
    )
}

export default BlogPopup