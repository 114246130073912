import { toast } from "react-toastify"
import Status from "../status"

export const fetchDailyActiveDataLoading = (state, _) => {
    state.fetchDailyActiveDataStatus = Status.Loading
}

export const fetchDailyActiveDataFulfilled = (state, action) => {
    state.fetchDailyActiveDataStatus = Status.Success
    state.dailyActiveData = action.payload
}

export const fetchDailyActiveDataRejected = (state, action) => {
    state.fetchDailyActiveDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchWeeklyActiveDataLoading = (state, _) => {
    state.fetchWeeklyActiveDataStatus = Status.Loading
}

export const fetchWeeklyActiveDataFulfilled = (state, action) => {
    state.fetchWeeklyActiveDataStatus = Status.Success
    state.weeklyActiveData = action.payload
}

export const fetchWeeklyActiveDataRejected = (state, action) => {
    state.fetchWeeklyActiveDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMonthlyActiveDataLoading = (state, _) => {
    state.fetchMonthlyActiveDataStatus = Status.Loading
}

export const fetchMonthlyActiveDataFulfilled = (state, action) => {
    state.fetchMonthlyActiveDataStatus = Status.Success
    state.monthlyActiveData = action.payload
}

export const fetchMonthlyActiveDataRejected = (state, action) => {
    state.fetchMonthlyActiveDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchDailyPremiumDataLoading = (state, _) => {
    state.fetchDailyPremiumDataStatus = Status.Loading
}

export const fetchDailyPremiumDataFulfilled = (state, action) => {
    state.fetchDailyPremiumDataStatus = Status.Success
    state.dailyPremiumData = action.payload
}

export const fetchDailyPremiumDataRejected = (state, action) => {
    state.fetchDailyPremiumDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchWeeklyPremiumDataLoading = (state, _) => {
    state.fetchWeeklyPremiumDataStatus = Status.Loading
}

export const fetchWeeklyPremiumDataFulfilled = (state, action) => {
    state.fetchWeeklyPremiumDataStatus = Status.Success
    state.weeklyPremiumData = action.payload
}

export const fetchWeeklyPremiumDataRejected = (state, action) => {
    state.fetchWeeklyPremiumDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMonthlyPremiumDataLoading = (state, _) => {
    state.fetchMonthlyPremiumDataStatus = Status.Loading
}

export const fetchMonthlyPremiumDataFulfilled = (state, action) => {
    state.fetchMonthlyPremiumDataStatus = Status.Success
    state.monthlyPremiumData = action.payload
}

export const fetchMonthlyPremiumDataRejected = (state, action) => {
    state.fetchMonthlyPremiumDataStatus = Status.Fail
    toast.error(action.error.message)
}
export const fetchDailyUserRetentionDataLoading = (state, _) => {
    state.fetchDailyUserRetentionDataStatus = Status.Loading
}

export const fetchDailyUserRetentionDataFulfilled = (state, action) => {
    state.fetchDailyUserRetentionDataStatus = Status.Success
    state.dailyUserRetentionData = action.payload
}

export const fetchDailyUserRetentionDataRejected = (state, action) => {
    state.fetchDailyUserRetentionDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchWeeklyUserRetentionDataLoading = (state, _) => {
    state.fetchWeeklyUserRetentionDataStatus = Status.Loading
}

export const fetchWeeklyUserRetentionDataFulfilled = (state, action) => {
    state.fetchWeeklyUserRetentionDataStatus = Status.Success
    state.weeklyUserRetentionData = action.payload
}

export const fetchWeeklyUserRetentionDataRejected = (state, action) => {
    state.fetchWeeklyUserRetentionDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMonthlyUserRetentionDataLoading = (state, _) => {
    state.fetchMonthlyUserRetentionDataStatus = Status.Loading
}

export const fetchMonthlyUserRetentionDataFulfilled = (state, action) => {
    state.fetchMonthlyUserRetentionDataStatus = Status.Success
    state.monthlyUserRetentionData = action.payload
}

export const fetchMonthlyUserRetentionDataRejected = (state, action) => {
    state.fetchMonthlyUserRetentionDataStatus = Status.Fail
    toast.error(action.error.message)
}


export const fetchDailyUsersDataLoading = (state, _) => {
    state.fetchDailyUsersDataStatus = Status.Loading
}

export const fetchDailyUsersDataFulfilled = (state, action) => {
    state.fetchDailyUsersDataStatus = Status.Success
    state.dailyUsersData = action.payload
}

export const fetchDailyUsersDataRejected = (state, action) => {
    state.fetchDailyUsersDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchWeeklyUsersDataLoading = (state, _) => {
    state.fetchWeeklyUsersDataStatus = Status.Loading
}

export const fetchWeeklyUsersDataFulfilled = (state, action) => {
    state.fetchWeeklyUsersDataStatus = Status.Success
    state.weeklyUsersData = action.payload
}

export const fetchWeeklyUsersDataRejected = (state, action) => {
    state.fetchWeeklyUsersDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMonthlyUsersDataLoading = (state, _) => {
    state.fetchMonthlyUsersDataStatus = Status.Loading
}

export const fetchMonthlyUsersDataFulfilled = (state, action) => {
    state.fetchMonthlyUsersDataStatus = Status.Success
    state.monthlyUsersData = action.payload
}

export const fetchMonthlyUsersDataRejected = (state, action) => {
    state.fetchMonthlyUsersDataStatus = Status.Fail
    toast.error(action.error.message)
}


export const fetchDailyReferralDataLoading = (state, _) => {
    state.fetchDailyReferralDataStatus = Status.Loading
}

export const fetchDailyReferralDataFulfilled = (state, action) => {
    state.fetchDailyReferralDataStatus = Status.Success
    state.dailyReferralData = action.payload
}

export const fetchDailyReferralDataRejected = (state, action) => {
    state.fetchDailyReferralDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchWeeklyReferralDataLoading = (state, _) => {
    state.fetchWeeklyReferralDataStatus = Status.Loading
}

export const fetchWeeklyReferralDataFulfilled = (state, action) => {
    state.fetchWeeklyReferralDataStatus = Status.Success
    state.weeklyReferralData = action.payload
}

export const fetchWeeklyReferralDataRejected = (state, action) => {
    state.fetchWeeklyReferralDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMonthlyReferralDataLoading = (state, _) => {
    state.fetchMonthlyReferralDataStatus = Status.Loading
}

export const fetchMonthlyReferralDataFulfilled = (state, action) => {
    state.fetchMonthlyReferralDataStatus = Status.Success
    state.monthlyReferralData = action.payload
}

export const fetchMonthlyReferralDataRejected = (state, action) => {
    state.fetchMonthlyReferralDataStatus = Status.Fail
    toast.error(action.error.message)
}


export const fetchDailySupportDataLoading = (state, _) => {
    state.fetchDailySupportDataStatus = Status.Loading
}

export const fetchDailySupportDataFulfilled = (state, action) => {
    state.fetchDailySupportDataStatus = Status.Success
    state.dailySupportData = action.payload
}

export const fetchDailySupportDataRejected = (state, action) => {
    state.fetchDailySupportDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchWeeklySupportDataLoading = (state, _) => {
    state.fetchWeeklySupportDataStatus = Status.Loading
}

export const fetchWeeklySupportDataFulfilled = (state, action) => {
    state.fetchWeeklySupportDataStatus = Status.Success
    state.weeklySupportData = action.payload
}

export const fetchWeeklySupportDataRejected = (state, action) => {
    state.fetchWeeklySupportDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMonthlySupportDataLoading = (state, _) => {
    state.fetchMonthlySupportDataStatus = Status.Loading
}

export const fetchMonthlySupportDataFulfilled = (state, action) => {
    state.fetchMonthlySupportDataStatus = Status.Success
    state.monthlySupportData = action.payload
}

export const fetchMonthlySupportDataRejected = (state, action) => {
    state.fetchMonthlySupportDataStatus = Status.Fail
    toast.error(action.error.message)
}


export const fetchDailySubmissionsDataLoading = (state, _) => {
    state.fetchDailySubmissionsDataStatus = Status.Loading
}

export const fetchDailySubmissionsDataFulfilled = (state, action) => {
    state.fetchDailySubmissionsDataStatus = Status.Success
    state.dailySubmissionsData = action.payload
}

export const fetchDailySubmissionsDataRejected = (state, action) => {
    state.fetchDailySubmissionsDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchWeeklySubmissionsDataLoading = (state, _) => {
    state.fetchWeeklySubmissionsDataStatus = Status.Loading
}

export const fetchWeeklySubmissionsDataFulfilled = (state, action) => {
    state.fetchWeeklySubmissionsDataStatus = Status.Success
    state.weeklySubmissionsData = action.payload
}

export const fetchWeeklySubmissionsDataRejected = (state, action) => {
    state.fetchWeeklySubmissionsDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMonthlySubmissionsDataLoading = (state, _) => {
    state.fetchMonthlySubmissionsDataStatus = Status.Loading
}

export const fetchMonthlySubmissionsDataFulfilled = (state, action) => {
    state.fetchMonthlySubmissionsDataStatus = Status.Success
    state.monthlySubmissionsData = action.payload
}

export const fetchMonthlySubmissionsDataRejected = (state, action) => {
    state.fetchMonthlySubmissionsDataStatus = Status.Fail
    toast.error(action.error.message)
}


export const fetchDailyAIResponsesDataLoading = (state, _) => {
    state.fetchDailyAIResponsesDataStatus = Status.Loading
}

export const fetchDailyAIResponsesDataFulfilled = (state, action) => {
    state.fetchDailyAIResponsesDataStatus = Status.Success
    state.dailyAIResponsesData = action.payload
}

export const fetchDailyAIResponsesDataRejected = (state, action) => {
    state.fetchDailyAIResponsesDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchWeeklyAIResponsesDataLoading = (state, _) => {
    state.fetchWeeklyAIResponsesDataStatus = Status.Loading
}

export const fetchWeeklyAIResponsesDataFulfilled = (state, action) => {
    state.fetchWeeklyAIResponsesDataStatus = Status.Success
    state.weeklyAIResponsesData = action.payload
}

export const fetchWeeklyAIResponsesDataRejected = (state, action) => {
    state.fetchWeeklyAIResponsesDataStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMonthlyAIResponsesDataLoading = (state, _) => {
    state.fetchMonthlyAIResponsesDataStatus = Status.Loading
}

export const fetchMonthlyAIResponsesDataFulfilled = (state, action) => {
    state.fetchMonthlyAIResponsesDataStatus = Status.Success
    state.monthlyAIResponsesData = action.payload
}

export const fetchMonthlyAIResponsesDataRejected = (state, action) => {
    state.fetchMonthlyAIResponsesDataStatus = Status.Fail
    toast.error(action.error.message)
}


export const fetchKPIsDataFulfilled = (state, action) => {
    state.kpisData[action.meta.arg] = action.payload
}

export const fetchKPIsDataRejected = (state, action) => {
    toast.error(action.error.message)
}
