import React from 'react'

const Profile = ({ withImage = false }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#007ACC" />
            <ellipse cx="12.0002" cy="7.83335" rx="4.16667" ry="4.16667" fill={withImage ? 'red' : "#F1F5F9"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.90675 20.2236C5.1969 19.5646 4.5072 17.4996 5.70978 16.117C7.23759 14.3604 9.48907 13.25 11.9999 13.25C14.5107 13.25 16.7622 14.3604 18.29 16.117C19.4926 17.4996 18.8029 19.5646 17.093 20.2236C15.5125 20.8327 13.7952 21.1667 11.9999 21.1667C10.2046 21.1667 8.48731 20.8327 6.90675 20.2236Z" fill="#F1F5F9" />
        </svg>
    )
}

export default Profile
