import React, { useEffect } from 'react'
import styles from './Blogs.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchBlogs, fetchMoreBlogs } from '../../store/blogs/blogs.slice'
import Layout from '../../components/Layout'
import BlogPopup from './BlogPopup'

const Blogs = ({ add }) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const data = useSelector(state => state.blogs.data)

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchMoreBlogs())
        }
    }

    useEffect(() => {
        dispatch(fetchBlogs())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.type}>Type</th>
                    <th className={styles.title}>Title</th>
                    <th className={styles.time}>Time</th>
                </tr>
                {
                    data.map(d =>
                        <FormView
                            key={d.id}
                            data={d} />
                    )
                }
            </table>
            <BlogPopup id={id} add={add} />
        </Layout>
    )
}

const FormView = ({ data }) => {

    const navigate = useNavigate()

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/blogs/${data.id}`) }}>
            <td className={styles.type}>{data.type}</td>
            <td className={styles.title}>{data.title}</td>
            <td className={styles.time}>{new Date(data.date).toLocaleDateString()}</td>
        </tr>
    )
}


export default Blogs