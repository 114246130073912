import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import userReducer from './user/user.slice'
import analyticsReducer from './analytics/analytics.slice';
import usersSlice from './users/users.slice';
import permissionsSlice from './permissions/permissions.slice';
import homeSlice from './home/home.slice';
import supportSlice from './support/support.slice';
import paymentsSlice from './payments/payments.slice';
import taggingSlice from './tagging/tagging.slice';
import contactSlice from './contact/contact.slice';
import carouselsSlice from './carousels/carousels.slice';
import aiFeedbacksSlice from './aiFeedbacks/aiFeedbacks.slice';
import blogsSlice from './blogs/blogs.slice';
import feedbacksSlice from './feedbacks/feedbacks.slice';
import journeysSlice from './journeys/journeys.slice';
import cancellationsSlice from './cancellations/cancellations.slice';
import challengesSlice from './challenges/challenges.slice';
import insightsSlice from './insights/insights.slice';
import funnelOptionsSlice from './funnelOptions/funnelOptions.slice';
import leaderboardsSlice from './leaderboards/leaderboards.slice';
import marketingSlice from './marketing/marketing.slice';

export const rootReducer = combineReducers({
    user: userReducer,
    analytics: analyticsReducer,
    users: usersSlice,
    permissions: permissionsSlice,
    home: homeSlice,
    support: supportSlice,
    payments: paymentsSlice,
    tagging: taggingSlice,
    contact: contactSlice,
    carousels: carouselsSlice,
    aiFeedbacks: aiFeedbacksSlice,
    blogs: blogsSlice,
    feedbacks: feedbacksSlice,
    journeys: journeysSlice,
    cancellations: cancellationsSlice,
    challenges: challengesSlice,
    insights: insightsSlice,
    funnelOptions: funnelOptionsSlice,
    leaderboards: leaderboardsSlice,
    marketing: marketingSlice,
});

export default configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})