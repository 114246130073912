import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    setCurrTicketIdAction,
    fetchFunnelsInfoFulfilled,
    fetchFunnelsInfoRejected,
    saveMarketingTicketLoading,
    saveMarketingTicketFulfilled,
    saveMarketingTicketRejected,
    deleteMarketingTicketLoading,
    deleteMarketingTicketFulfilled,
    deleteMarketingTicketRejected,
    saveMarketingFunnelLoading,
    saveMarketingFunnelFulfilled,
    saveMarketingFunnelRejected,
    saveMarketingPerformanceLoading,
    saveMarketingPerformanceFulfilled,
    saveMarketingPerformanceRejected,
    fetchLastMarketingTicketsFulfilled,
    fetchLastMarketingTicketsRejected,
    fetchLastMarketingDeliverablesFulfilled,
    fetchLastMarketingDeliverablesRejected,
    fetchLastMarketingFunnelsFulfilled,
    fetchLastMarketingFunnelsRejected,
    fetchMarketingPerformanceFulfilled,
    fetchMarketingPerformanceRejected,
    fetchMarketingTicketLoading,
    fetchMarketingTicketFulfilled,
    fetchMarketingTicketRejected,
    fetchMarketingFunnelLoading,
    fetchMarketingFunnelFulfilled,
    fetchMarketingFunnelRejected,
} from './marketing.actions'
import DatabaseService from '../../services/DatabaseService'
import Status from '../status'

export const fetchFunnelsInfo = createAsyncThunk('marketing/fetchContact', async (_, { getState }) => {
    const funnelsInfo = getState().marketing.funnelsInfo
    if (Object.keys(funnelsInfo).length > 0) {
        return funnelsInfo;
    }
    return DatabaseService.getMarketingFunnelsInfo()
})

export const saveMarketingTicket = createAsyncThunk('marketing/saveMarketingTicket', async ({ tid, data }) => {
    return DatabaseService.saveMarketingTicket(tid, data)
})

export const deleteMarketingTicket = createAsyncThunk('marketing/deleteMarketingTicket', async ({ tid, data }) => {
    return DatabaseService.deleteMarketingTicket(tid, data)
})

export const saveMarketingFunnel = createAsyncThunk('marketing/saveMarketingFunnel', async ({ fid, data, isNew }, { getState, rejectWithValue }) => {
    if (isNew) {
        const funnelsInfo = getState().marketing.funnelsInfo
        if (funnelsInfo.length === 0) {
            return rejectWithValue('No funnels info')
        }
        if (funnelsInfo[fid] !== undefined) {
            return rejectWithValue('Funnel ID already exists')
        }
        const d = { ...funnelsInfo }
        d[fid] = {}
        await DatabaseService.saveMarketingFunnelsInfo(d)
    }
    return DatabaseService.saveMarketingFunnel(fid, data)
})

export const saveMarketingPerformance = createAsyncThunk('marketing/saveMarketingPerformance', async ({ pid, targetPrice }) => {
    return DatabaseService.saveMarketingPerformance(pid, targetPrice)
})

export const fetchLastMarketingTickets = createAsyncThunk('marketing/fetchLastMarketingTickets', async ({ more = false }, { getState }) => {
    const tickets = getState().marketing.tickets
    if (!more && tickets.length > 0) {
        return tickets
    } else {
        return DatabaseService.getLastMarketingTickets(more ? tickets[tickets.length - 1].lastUpdated : undefined)
    }
})

export const fetchLastMarketingDeliverables = createAsyncThunk('marketing/fetchLastMarketingDeliverables', async ({ more = false, fid, force = false }, { getState }) => {
    const deliverables = getState().marketing.deliverables
    if (!more && deliverables.length > 0 && !force) {
        return deliverables
    } else {
        return DatabaseService.getLastMarketingDeliverables(more ? deliverables[deliverables.length - 1].publishTime : undefined, fid)
    }
})

export const fetchLastMarketingFunnels = createAsyncThunk('marketing/fetchLastMarketingFunnels', async ({ more = false }, { getState }) => {
    const funnels = getState().marketing.funnels
    if (!more && funnels.length > 0) {
        return funnels
    } else {
        return DatabaseService.getLastMarketingFunnels(more ? funnels[funnels.length - 1].lastUpdated : undefined)
    }
})

export const fetchMarketingTicket = createAsyncThunk('marketing/fetchMarketingTicket', async ({ tid }, { getState }) => {
    const tickets = getState().marketing.tickets
    const currTicket = tickets.find(t => t.tid === tid)
    if (currTicket) {
        return currTicket
    } else {
        return DatabaseService.getMarketingTicket(tid)
    }
})

export const fetchMarketingFunnel = createAsyncThunk('marketing/fetchMarketingFunnel', async ({ fid }, { getState }) => {
    const funnels = getState().marketing.funnels
    const currFunnel = funnels.find(t => t.fid === fid)
    if (currFunnel) {
        return currFunnel
    } else {
        return DatabaseService.getMarketingFunnel(fid)
    }
})

export const fetchMarketingPerformance = createAsyncThunk('marketing/fetchMarketingPerformance', async (_, { getState }) => {
    const performance = getState().marketing.performance
    if (performance.length > 0) {
        return performance
    } else {
        const res = await DatabaseService.getMarketingPerformance()
        res.forEach((r, i) => {
            if (r?.revenueInfo?.funnels === undefined) return
            let undefinedAmount = r.revenueInfo.funnels['undefined']?.amount ?? 0
            let undefinedCustomers = r.revenueInfo.funnels['undefined']?.customers ?? 0
            let totalAmount = r.revenueInfo?.total?.amount ?? 0
            let totalCustomers = r.revenueInfo?.total?.customers ?? 0

            // calculate facebook ads
            if (r.revenueInfo.funnels['facebook_ads'] !== undefined) {
                const undefinedAmountUS = (r.revenueInfo.funnels['undefined']?.details['undefined_us']?.amount ?? 0) +
                    (r.revenueInfo.funnels['undefined']?.details['instagram_us']?.amount ?? 0)
    
                let undefinedPart = undefinedAmountUS / undefinedAmount
                let fbPart = r.revenueInfo.funnels['facebook_ads']?.amount / (totalAmount - undefinedAmount)
                let assumedPart = Math.min(1, fbPart / undefinedPart)
    
                res[i].revenueInfo.funnels['facebook_ads'].amountEst = r.revenueInfo.funnels['facebook_ads']?.amount + (assumedPart * undefinedAmountUS)
                undefinedAmount -= assumedPart * undefinedAmountUS;
                totalAmount -= (r.revenueInfo.funnels['facebook_ads']?.amount + assumedPart * undefinedAmountUS)

                const undefinedCustomersUS = (r.revenueInfo.funnels['undefined']?.details['undefined_us']?.customers ?? 0) +
                    (r.revenueInfo.funnels['undefined']?.details['instagram_us']?.customers ?? 0)
    
                undefinedPart = undefinedCustomersUS / undefinedCustomers
                fbPart = r.revenueInfo.funnels['facebook_ads']?.customers / (totalCustomers - undefinedCustomers)
                assumedPart = Math.min(1, fbPart / undefinedPart)
    
                res[i].revenueInfo.funnels['facebook_ads'].customersEst = r.revenueInfo.funnels['facebook_ads']?.customers + (assumedPart * undefinedCustomersUS)
                undefinedCustomers -= assumedPart * undefinedCustomersUS;
                totalCustomers -= (r.revenueInfo.funnels['facebook_ads']?.customers + assumedPart * undefinedCustomersUS)
            }

            // calculate other
            Object.keys(r?.revenueInfo?.funnels ?? {}).forEach(fid => {
                if (fid === 'facebook_ads') return;

                let a = (r.revenueInfo.funnels[fid]?.amount ?? 0)
                let part = a / (totalAmount - undefinedAmount)
                res[i].revenueInfo.funnels[fid].amountEst = (undefinedAmount * part) + a

                a = (r.revenueInfo.funnels[fid]?.customers ?? 0)
                part = a / (totalCustomers - undefinedCustomers)
                res[i].revenueInfo.funnels[fid].customersEst = (undefinedCustomers * part) + a
            })

            res[i].revenueInfo.funnels['undefined'].customersEst = 0
        })
        return res
    }
})

export const marketingSlice = createSlice({
    name: 'marketing',
    initialState: {
        funnelsInfo: {},
        tickets: [],
        deliverables: [],
        funnels: [],
        performance: [],
        currTicketId: null,
        currTicket: null,
        currFunnel: null,
        saveStatus: Status.None,
    },
    reducers: {
        setCurrTicketId: setCurrTicketIdAction,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFunnelsInfo.fulfilled, fetchFunnelsInfoFulfilled)
            .addCase(fetchFunnelsInfo.rejected, fetchFunnelsInfoRejected)
            .addCase(saveMarketingTicket.pending, saveMarketingTicketLoading)
            .addCase(saveMarketingTicket.fulfilled, saveMarketingTicketFulfilled)
            .addCase(saveMarketingTicket.rejected, saveMarketingTicketRejected)
            .addCase(deleteMarketingTicket.pending, deleteMarketingTicketLoading)
            .addCase(deleteMarketingTicket.fulfilled, deleteMarketingTicketFulfilled)
            .addCase(deleteMarketingTicket.rejected, deleteMarketingTicketRejected)
            .addCase(saveMarketingFunnel.pending, saveMarketingFunnelLoading)
            .addCase(saveMarketingFunnel.fulfilled, saveMarketingFunnelFulfilled)
            .addCase(saveMarketingFunnel.rejected, saveMarketingFunnelRejected)
            .addCase(saveMarketingPerformance.pending, saveMarketingPerformanceLoading)
            .addCase(saveMarketingPerformance.fulfilled, saveMarketingPerformanceFulfilled)
            .addCase(saveMarketingPerformance.rejected, saveMarketingPerformanceRejected)
            .addCase(fetchLastMarketingTickets.fulfilled, fetchLastMarketingTicketsFulfilled)
            .addCase(fetchLastMarketingTickets.rejected, fetchLastMarketingTicketsRejected)
            .addCase(fetchLastMarketingDeliverables.fulfilled, fetchLastMarketingDeliverablesFulfilled)
            .addCase(fetchLastMarketingDeliverables.rejected, fetchLastMarketingDeliverablesRejected)
            .addCase(fetchLastMarketingFunnels.fulfilled, fetchLastMarketingFunnelsFulfilled)
            .addCase(fetchLastMarketingFunnels.rejected, fetchLastMarketingFunnelsRejected)
            .addCase(fetchMarketingPerformance.fulfilled, fetchMarketingPerformanceFulfilled)
            .addCase(fetchMarketingPerformance.rejected, fetchMarketingPerformanceRejected)
            .addCase(fetchMarketingTicket.pending, fetchMarketingTicketLoading)
            .addCase(fetchMarketingTicket.fulfilled, fetchMarketingTicketFulfilled)
            .addCase(fetchMarketingTicket.rejected, fetchMarketingTicketRejected)
            .addCase(fetchMarketingFunnel.pending, fetchMarketingFunnelLoading)
            .addCase(fetchMarketingFunnel.fulfilled, fetchMarketingFunnelFulfilled)
            .addCase(fetchMarketingFunnel.rejected, fetchMarketingFunnelRejected)
    },
})

export const {
    setCurrTicketId,
} = marketingSlice.actions

export default marketingSlice.reducer