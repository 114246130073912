import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import DatabaseService from '../../services/DatabaseService'
import styles from './FeedbackPopup.module.css'
import FunctionsService from '../../services/FunctionsService'
import Base64 from '../../util/Base64'


const FeedbackPopup = ({ id, uid }) => {
    const [user, setUser] = useState(null)
    const [data, setData] = useState(null)
    
    const [lesson, setLesson] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        FunctionsService.getCoddyUser(uid).then(d => setUser(d))
        FunctionsService.getAskAiResponse(uid, id).then(d => {
            setData(d.data)
            DatabaseService.getLesson(d.data.context.courseId, d.data.context.lessonId).then(d => setLesson(d))
        })
    }, [id, uid])

    const handleOpenUser = () => {
        window.open(`/users/${user.uid}`)
    }

    const handleOpenContext = () => {
        window.open(`https://coddy.tech/courses/${data?.context.courseId}/${data?.context.lessonId}`)
    }
    return (
        <Layout classes={[styles.backdrop, id === undefined || uid === undefined ? null : styles.display]} alignItems='flex-end' onClick={() => { navigate(`/ai_feedbacks`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16]} justifyContent='flex-start' gap={32}>
                <Layout row justifyContent='space-between' fullWidth alignItems='flex-start'>
                    <Layout alignItems='flex-start' fullWidth>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Time' type='small' color='darkGray' />
                            <Text text={data?.date ? new Date(data.date).toLocaleString() : null} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Context' type='small' color='darkGray' />
                            <Text text={`${data?.context?.courseId}/${data?.context?.lessonId}`} onClick={handleOpenContext} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Email' type='small' color='darkGray' />
                            <Text text={user?.email} onClick={user === null ? null : handleOpenUser} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Name' type='small' color='darkGray' />
                            <Text text={user?.displayName} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Model' type='small' color='darkGray' />
                            <Text text={data?.model} />
                        </Layout>
                    </Layout>
                </Layout>
                <Layout classes={[styles.messageContainer]} alignItems='flex-start'>
                    <pre className={styles.message}>
                        {data?.response}
                    </pre>
                </Layout>
                <Layout alignItems='flex-start' fullWidth>
                    <Text text="User's attempt" bold />
                    <pre style={{ overflow: 'auto', width: '100%' }}>
                        {
                            data?.request[data.request?.length - 6]?.content
                        }
                    </pre>
                </Layout>
                <Layout alignItems='flex-start' fullWidth>
                    <Text text="Creator's solution" bold />
                    <pre style={{ overflow: 'auto', width: '100%' }}>
                        {
                            Base64.decode(lesson?.task?.solutionCodes[lesson.task.languages] ?? '')
                        }
                    </pre>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default FeedbackPopup