import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchDailySubmissionsData, fetchMonthlySubmissionsData, fetchWeeklySubmissionsData } from '../../store/home/home.slice'
import Status from '../../store/status'
import { NUM_TO_MONTH } from './Main'
import styles from './Main.module.css'


const SubmissionsView = () => {
    const dispatch = useDispatch()
    const {
        fetchDailySubmissionsDataStatus,
        fetchWeeklySubmissionsDataStatus,
        fetchMonthlySubmissionsDataStatus,
        dailySubmissionsData,
        weeklySubmissionsData,
        monthlySubmissionsData,
    } = useSelector(state => state.home)

    const [cohortType, setCohortType] = useState('daily')

    let data = dailySubmissionsData
    let status = fetchDailySubmissionsDataStatus

    if (cohortType === 'weekly') {
        data = weeklySubmissionsData
        status = fetchWeeklySubmissionsDataStatus
    } else if (cohortType === 'monthly') {
        data = monthlySubmissionsData
        status = fetchMonthlySubmissionsDataStatus
    }

    useEffect(() => {
        if (cohortType === 'daily') {
            dispatch(fetchDailySubmissionsData())
        } else if (cohortType === 'weekly') {
            dispatch(fetchWeeklySubmissionsData())
        } else if (cohortType === 'monthly') {
            dispatch(fetchMonthlySubmissionsData())
        }
    }, [cohortType, dispatch])

    const [currPoint, setCurrPoint] = useState(data[0] ?? {})
    const [currIndex, setCurrIndex] = useState(0)

    useEffect(() => {
        setCurrPoint(data[0] ?? {})
    }, [data])

    const Topbar = <Layout row fullWidth justifyContent='space-between' padding={[12, 24]}>
        <Layout row gap={16}>
            <Text text={'Code Executions'} type='subTitle' />
        </Layout>
        <Layout row gap={16}>
            <Text
                text={'Daily'}
                type='normal'
                onClick={() => setCohortType('daily')}
                className={[styles.textButton, cohortType === 'daily' ? styles.textButtonSelected : null].join(' ')} />
            <Text
                text={'Weekly'}
                type='normal'
                onClick={() => setCohortType('weekly')}
                className={[styles.textButton, cohortType === 'weekly' ? styles.textButtonSelected : null].join(' ')} />
            <Text
                text={'Monthly'}
                type='normal'
                onClick={() => setCohortType('monthly')}
                className={[styles.textButton, cohortType === 'monthly' ? styles.textButtonSelected : null].join(' ')} />
        </Layout>
    </Layout>

    if (status !== Status.Success) {
        return (
            <Layout classes={[styles.cardContainer]}>
                {Topbar}
                <Layout classes={[styles.activeCard]}>
                    <Text text='Loading...' />
                </Layout>
            </Layout>
        )
    }

    const handleMouseMove = (e) => {
        const currIndex = data.findIndex(d => d.date === e?.activeLabel)
        const curr = data.find(d => d.date === e?.activeLabel)
        setCurrPoint(curr ?? (data[0] ?? {}))
        setCurrIndex(currIndex ?? 0)
    }

    const handleMouseLeave = () => {
        setCurrPoint(data[0] ?? {})
        setCurrIndex(0)
    }

    let diff = NaN
    let isZero = false
    let isPositive = false
    let percentage = 0.0
    let challengesPercentage = '(0.00%)'
    if (data && currIndex + 1 < data?.length) {
        const lastPoint = data[currIndex + 1]
        diff = currPoint?.submissions - lastPoint?.submissions
        isZero = diff === 0
        isPositive = diff >= 0
        percentage = isPositive ?
            (((currPoint?.submissions / lastPoint?.submissions) - 1) * 100).toFixed(2) :
            ((1 - (currPoint?.submissions / lastPoint?.submissions)) * 100).toFixed(2)
    }

    if (currPoint.challenges !== null) {
        challengesPercentage = `(${((currPoint.challenges / (currPoint?.submissions ?? 0)) * 100).toFixed(2)}%)`
    }

    let dateExtension = ''
    if (currPoint?.dateTime && cohortType === 'weekly') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setDate(d.getDate() + 7)
        dateExtension += d.toUTCString().slice(5, 11)
    }

    let currDate = ''
    if (currPoint?.dateTime) {
        currDate = `${currPoint.date.split('-')[1]} ${NUM_TO_MONTH[currPoint.date.split('-')[0]]}`
        if (cohortType === 'monthly') {
            const d = new Date(currPoint.dateTime)
            d.setDate(d.getDate() + 1)
            currDate = d.toUTCString().slice(8, 11) + ' ' + d.toUTCString().slice(14, 16)
        }
    }

    return (
        <Layout classes={[styles.cardContainer]}>
            {Topbar}
            <Layout classes={[styles.activeCard]} row alignItems='flex-start' padding={[24, 24, 0, 24]}>
                <Layout alignItems='flex-start' gap={24} style={{ width: '160px', height: '100%' }} padding={[0, 0, 28, 0]}>
                    <Layout alignItems='flex-start' justifyContent='flex-start' gap={8} style={{ flex: 1 }}>
                        <Layout alignItems='flex-start'>
                            <Text text={currPoint.submissions} type='title' bold />
                            <Text
                                text={`${isZero ? '' : isPositive ? '+' : '-'}${Math.abs(diff)} (${isZero ? '' : isPositive ? '+' : '-'}${percentage}%)`}
                                type='small'
                                color={isZero ? 'mainColor' : isPositive ? 'green' : 'red'} />
                            <Text text={'Submissions'} type='medium' color='darkGray' />
                        </Layout>
                        <Layout alignItems='flex-start'>
                            <Layout alignItems='baseline' row gap={8}>
                                <Text text={currPoint.challenges} type='medium' />
                                <Text text={challengesPercentage} type='small' />
                            </Layout>
                            <Text text={'Challenges'} type='small' color='darkGray' />
                        </Layout>
                        <Layout alignItems='flex-start'>
                            <Layout alignItems='baseline' row gap={8}>
                                <Text text={currPoint.usersNum === 0 ? currPoint.usersNum : (currPoint.submissions / currPoint.usersNum).toFixed(2)} type='medium' />
                            </Layout>
                            <Text text={'Average p/User'} type='small' color='darkGray' />
                        </Layout>
                    </Layout>
                    {
                        currPoint.date ?
                            <Text text={`${currDate}${dateExtension}`} type='medium' />
                            : null
                    }
                </Layout>

                <ResponsiveContainer
                    width='100%'
                    height={'100%'}>
                    <LineChart
                        data={data}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" reversed tick={false} />
                        <YAxis dataKey={'submissions'} />
                        <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                        <Line type="monotone" dataKey="submissions" stroke="#8884d8" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="challenges" stroke="#82ca9d" strokeDasharray="3 4 5 2" />
                    </LineChart>
                </ResponsiveContainer>

            </Layout>
        </Layout>
    )
}

export default SubmissionsView