import React, { useEffect, useState } from 'react'
import UserInfo from './UserInfo'
import { useLocation, useParams } from "react-router-dom";
import Layout from '../../components/Layout';
import Divider from '../../components/Divider/Divider';
import MainInfo from './MainInfo';
import Courses from './Courses';
import { useSelector } from 'react-redux';
import Challenges from './Challenges';
import Events from './Events';
import FunctionsService from '../../services/FunctionsService';
import BillingData from './BillingData'
import DotLoader from "react-spinners/DotLoader";
import WeeklyGoals from './WeeklyGoals'

const UserAnalytics = () => {
    const currView = new URLSearchParams(useLocation().search).get('view') ?? 'main'
    let { uid } = useParams();

    let user = useSelector(state => state.users.data.find(u => u.uid === uid))
    const [userDownloaded, setUserDownloaded] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (user === undefined && userDownloaded === undefined) {
            if (uid?.includes('@')) {
                FunctionsService.getCoddyUserByEmail(uid)
                    .then(d => {
                        window.location = `https://data.coddy.tech/users/${d.uid}`
                    })
            } else {
                if (uid.length === 6) {
                    FunctionsService.getCoddyUserFromShortenUid(uid)
                        .then(d => {
                            console.log(d);
                            window.location = `https://data.coddy.tech/users/${d.uid}`
                        })
                } else {
                    setIsLoading(true)
                    FunctionsService.getCoddyUser(uid)
                        .then(d => {
                            setUserDownloaded(d)
                            setIsLoading(false)
                        })
                }
            }
        }
    }, [uid, user, userDownloaded])

    let finalUser = {}
    if (user !== undefined) finalUser = user
    else if (userDownloaded !== undefined) finalUser = userDownloaded

    let content = null;
    switch (currView) {
        case 'main':
            content = <MainInfo finalUser={finalUser} />
            break;
        case 'courses':
            content = <Courses />
            break;
        case 'challenges':
            content = <Challenges />
            break
        case 'events':
            content = <Events />
            break
        case 'billing':
            content = <BillingData user={finalUser} />
            break
        case 'goals':
            content = <WeeklyGoals uid={finalUser.uid} />
            break
        default:
            break;
    }

    return (
        <Layout fullWidth page justifyContent='start' alignItems='start' style={{ minWidth: '800px', overflow: 'auto' }} gap={8}>
            {
                isLoading ? <Layout fullWidth fullHeight><DotLoader loading={true} size={24} /></Layout> : <>                
                    <UserInfo uid={uid} finalUser={finalUser} />
                    <Divider color='#D4D4D4' margin={[4, 0]} />
                    {content}
                </>
            }
        </Layout>
    )
}

export default React.memo(UserAnalytics)