import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
    fetchCreatorsFulfilled,
    fetchCreatorsRejected,
    fetchPaymentsFulfilled,
    fetchPaymentsRejected,
    setPaymentLoading,
    setPaymentFulfilled,
    setPaymentRejected,
  } from './payments.actions'

export const fetchCreators = createAsyncThunk('payments/fetchCreators', async (_, { getState }) => {
    const creators = getState().payments.creators
   
    if (creators.length === 0) {
      return DatabaseService.getPermissionsData({type: 'creators'})
    } else {
      return creators
    }
  })


  export const fetchPayments = createAsyncThunk('payments/fetchPayments', async (_, { getState }) => {
    const payments = getState().payments.payments
   
    if (payments.length === 0) {
      return DatabaseService.getPayments()
    } else {
      return payments
    }
  })

  export const setPayment = createAsyncThunk('payments/setPayment', async ({uid, currentMonth}, _) => {
    return await FunctionsService.setPayment({uid: uid, currentMonth: currentMonth});
  })


export const paymentsSlice = createSlice({
    name: 'payments',
    initialState: {
      loading: {},
      fetchStatus: Status.None,
      creators: [],
      payments: [],
    },
    reducers: {
    },
    extraReducers(builder) {
      builder
        .addCase(fetchCreators.fulfilled, fetchCreatorsFulfilled)
        .addCase(fetchCreators.rejected, fetchCreatorsRejected)
        .addCase(fetchPayments.fulfilled, fetchPaymentsFulfilled)
        .addCase(fetchPayments.rejected, fetchPaymentsRejected)
        .addCase(setPayment.pending, setPaymentLoading)
        .addCase(setPayment.fulfilled, setPaymentFulfilled)
        .addCase(setPayment.rejected, setPaymentRejected)
    },
  })
  
  // export const {
  
  // } = paymentsSlice.actions
  
  export default paymentsSlice.reducer
  