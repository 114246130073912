import React, { useEffect, useState } from 'react'
import styles from './LessonFlow.module.css'
import { useSelector } from 'react-redux';
import Base64 from '../../../util/Base64';
import Success from '../../../assets/icons/Success';
import Failure from '../../../assets/icons/Failure';
import { LANGUAGES, LANGUAGE_MAPPING_CODE_EDITOR } from '../../../util/types';
import Layout from '../../../components/Layout';
import Tag from '../../../components/Tag';
import AceEditor from "react-ace";
import ReactMarkdown from 'react-markdown'

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";
import 'ace-builds/src-noconflict/mode-csharp';
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import FunctionsService from '../../../services/FunctionsService';


export const SubmissionView = ({ submission, index, firstDone }) => {
    const [selectedFile, setSelectedFile] = useState(0)

    return (
        <div className={styles.submissionContainer}>
            <div className={styles.submissionTopContainer}>
                {submission?.failed || submission?.regexFailed || submission?.testsResult?.some(t => t?.failed) ? <Failure /> : <Success />}
                <span className={styles.submissionTime}>{new Date(submission?.date).toLocaleString()}</span>
                {firstDone ? <Tag text={'First Done'} colorType='success' /> : null}
                <Tag text={LANGUAGES[submission.lang]} className={styles.submissionLang} />
                <Tag className={styles.submissionIndex} text={`#${index}`} />
            </div>
            <Layout fullWidth alignItems='flex-start'>
                {
                    submission.payloads ?
                        <Layout row gap={8} margin={[8, 0, 4, 0]}>
                            {
                                submission.payloads.map((p, i) =>
                                    <p className={[styles.fileButton, selectedFile === i ? styles.selected : null].join(' ')} onClick={() => setSelectedFile(i)}>
                                        {p.path}
                                    </p>)
                            }
                        </Layout> :
                        null
                }
                <AceEditor
                    width="100%"
                    mode={submission.lang !== undefined ? LANGUAGE_MAPPING_CODE_EDITOR[submission.lang] : "python"}
                    theme="monokai"
                    fontSize={16}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    maxLines={Infinity}
                    value={submission.payloads ? Base64.decode(submission.payloads[selectedFile].payload) : Base64.decode(submission.payload)}
                    setOptions={{
                        showLineNumbers: true,
                        readOnly: true
                    }} />
            </Layout>
        </div>
    )
}

const OtherView = ({ type, hintData }) => {
    return type === 'askAi' ?
        <div className={styles.askAiContainer}>
            <ReactMarkdown className={styles.content}>{hintData.response}</ReactMarkdown>
        </div> :
        <div className={styles.hintSeenContainer}>
            {
                type === 'quizProgress' ?
                    <div className={styles.hintSeenInnerContainer}>
                        {hintData.isCorrect ? <Success size={16} /> : <Failure size={16} />}
                        <p>
                            {`Question ${Number(hintData.quizIndex) + 1}/${Number(hintData.questionIndex) + 1}`}
                        </p>
                    </div> :
                    <span>
                        {
                            type === 'hintSeen' ?
                                `Hint ${parseInt(hintData.index) + 1} seen` :
                                `Solution seen`
                        }
                    </span>
            }
        </div>
}

const Divider = ({ timeDelta }) => {
    const seconds = Math.floor(timeDelta / 1000) % 60
    let content = seconds !== 0 ? `${seconds}s` : ''
    if (timeDelta >= 60000) {
        const minutes = Math.floor(timeDelta / 60000) % 60
        content = minutes !== 0 ? `${minutes}m` + content : content
    } if (timeDelta >= 3600000) {
        const hours = Math.floor(timeDelta / 3600000) % 24
        content = hours !== 0 ? `${hours}h` + content : content
    } if (timeDelta >= 3600000 * 24) {
        const days = Math.floor(timeDelta / (3600000 * 24))
        content = days !== 0 ? `${days}d` + content : content
    }
    return (
        <div className={styles.divider}>
            <span className={styles.timeDelta}>{content}</span>
        </div>
    )
}

const LessonFlow = () => {

    const [submissions, setSubmissions] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const uid = useSelector(state => state.analytics.trackingUid)
    const courseId = useSelector(state => state.analytics.trackingCourseId)
    const lessonId = useSelector(state => state.analytics.trackingLessonId)
    const hintsSeen = useSelector(state => state.analytics.hintsSeen)
    const solutionDate = useSelector(state => state.analytics.solutionDate)
    const askedAi = useSelector(state => state.analytics.askedAi)
    const quizProgress = useSelector(state => state.analytics.quizProgress)

    useEffect(() => {
        if (uid !== undefined && courseId !== undefined && lessonId !== undefined) {
            setIsLoading(true)
            FunctionsService.getSubmissionsByUserFromContext(uid, `${courseId}-${lessonId}`)
                .then(async d => {
                    const hints = Object.keys(hintsSeen).map(k => {
                        return { index: k, type: 'hintSeen', date: hintsSeen[k] }
                    })
                    let res = d.concat(hints);
                    if (solutionDate) {
                        res.push({
                            type: 'solutionSeen',
                            date: solutionDate
                        });
                    }
                    if (askedAi) {
                        const r = await FunctionsService.getAskAiDataWithContext(uid, `${courseId}-${lessonId}`)
                        res = res.concat(r.data.map(d => {
                            d.type = 'askAi'
                            return d
                        }));
                    }
                    Object.keys(quizProgress).forEach(k => {
                        Object.keys(quizProgress[k]).forEach(j => {
                            res.push({ quizIndex: k, questionIndex: j, isCorrect: quizProgress[k][j].isCorrect, type: 'quizProgress', date: quizProgress[k][j].timestamp })
                        })
                    })
                    res.sort((a, b) => a.date - b.date)
                    setSubmissions(res)
                    setIsLoading(false)
                })
                .catch(e => {
                    console.log(e)
                    setIsLoading(false)
                })
        } else {
            setSubmissions(undefined)
        }
    }, [courseId, lessonId, uid, hintsSeen, solutionDate, askedAi, quizProgress])

    return (
        <Layout fullWidth>
            {
                isLoading ? <Layout>Loading</Layout> : submissions?.map((submission, i) =>
                    <React.Fragment key={submission.id + i.toString()}>
                        {
                            i > 0 ? <Divider
                                timeDelta={submission.date - submissions[i - 1].date} /> :
                                null
                        }
                        {
                            submission?.type === 'hintSeen' || submission?.type === 'solutionSeen' || submission?.type === 'askAi' || submission?.type === 'quizProgress' ?
                                <OtherView type={submission.type} hintData={submission} /> :
                                <SubmissionView
                                    submission={submission}
                                    firstDone={
                                        !submission.failed &&
                                        !submission.regexFailed &&
                                        submission.testsResult.every(t => !t.failed) &&
                                        submissions.slice(0, i).every(
                                            s =>
                                                s?.type === 'hintSeen' ||
                                                s?.failed ||
                                                s?.regexFailed ||
                                                s?.testsResult?.some(t => t.failed))}
                                    index={i + 1} />
                        }
                    </ React.Fragment>)
            }
        </Layout>
    )
}

export default LessonFlow