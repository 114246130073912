import React, { useState } from 'react'
import styles from './Main.module.css'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import ActiveUsersView from './ActiveUsersView'
import UsersView from './UsersView'

const UsersViewWrapper = () => {
    const [cohortType, setCohortType] = useState('daily')
    const [showActive, setShowActive] = useState(false)

    return (
        <Layout classes={[styles.cardContainer]}>
            <Layout row fullWidth justifyContent='space-between' padding={[12, 24]}>
                <Layout row gap={16}>
                    <Text text={'Users'} type='subTitle' />
                    <Text
                        text={'Active'}
                        type='normal'
                        onClick={() => setShowActive(o => !o)}
                        className={[styles.textButton, showActive ? styles.textButtonSelected : null].join(' ')} />
                </Layout>
                <Layout row gap={16}>
                    <Text
                        text={'Daily'}
                        type='normal'
                        onClick={() => setCohortType('daily')}
                        className={[styles.textButton, cohortType === 'daily' ? styles.textButtonSelected : null].join(' ')} />
                    <Text
                        text={'Weekly'}
                        type='normal'
                        onClick={() => setCohortType('weekly')}
                        className={[styles.textButton, cohortType === 'weekly' ? styles.textButtonSelected : null].join(' ')} />
                    <Text
                        text={'Monthly'}
                        type='normal'
                        onClick={() => setCohortType('monthly')}
                        className={[styles.textButton, cohortType === 'monthly' ? styles.textButtonSelected : null].join(' ')} />
                </Layout>
            </Layout>
            {
                showActive ?
                    <ActiveUsersView cohortType={cohortType} /> :
                    <UsersView cohortType={cohortType} />
            }
        </Layout>
    )
}

export default UsersViewWrapper