import React, { useEffect, useState } from 'react'
import styles from './Main.module.css'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { useDispatch, useSelector } from 'react-redux'
import { fetchKPIsData } from '../../store/home/home.slice'
import Left from '../../assets/icons/Left'
import Right from '../../assets/icons/Right'
import Success from '../../assets/icons/Success'
import Failure from '../../assets/icons/Failure'

const KPIs = () => {
    const dispatch = useDispatch()
    const data = useSelector(state => state.home.kpisData)
    const [cohortType, setCohortType] = useState('monthly')
    const [currIndex, setCurrIndex] = useState(0)

    useEffect(() => {
        dispatch(fetchKPIsData(cohortType))
    }, [dispatch, cohortType])

    useEffect(() => {
        if (data[cohortType]?.data) {
            const keyData = data[cohortType]?.data
            dispatch(fetchKPIsData(keyData[keyData.length - 1 - currIndex].id))
        }
    }, [dispatch, currIndex, data, cohortType])

    const keyData = data[cohortType]?.data
    let currData = undefined
    if (keyData !== undefined) {
        currData = data[keyData[keyData.length - 1 - currIndex].id]
    }

    return (
        <Layout fullWidth>
            <Layout row fullWidth justifyContent='space-between' padding={[12, 24]}>
                <Text text={'KPIs'} type='subTitle' />
                <Layout row gap={16}>
                    <Text
                        text={'Monthly'}
                        type='normal'
                        onClick={() => {
                            setCurrIndex(0)
                            setCohortType('monthly')
                        }}
                        className={[styles.textButton, cohortType === 'monthly' ? styles.textButtonSelected : null].join(' ')} />
                    <Text
                        text={'Quarterly'}
                        type='normal'
                        onClick={() => {
                            setCurrIndex(0)
                            setCohortType('quarterly')
                        }}
                        className={[styles.textButton, cohortType === 'quarterly' ? styles.textButtonSelected : null].join(' ')} />
                </Layout>
            </Layout>
            <Layout classes={[styles.activeCard]} padding={[24, 24, 12, 24]} style={{ height: '280px' }}>
                {
                    keyData === undefined ?
                        <Text text='Loading...' /> :
                        <Layout fullWidth justifyContent='space-between' style={{ height: '100%' }}>
                            {
                                currData === undefined ?
                                    <div /> :
                                    <Layout fullWidth gap={16}>
                                        <Layout fullWidth alignItems='flex-start'>
                                            <Text text='Main Objective' color='darkGray' type='small'/>
                                            <Text text={currData?.obj ?? ''}/>
                                        </Layout>
                                        <table
                                            style={{
                                                textAlign: 'left',
                                                width: '100%',
                                                borderCollapse: 'collapse'
                                            }}>
                                            <tbody>
                                                <tr style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                                    <th style={{ paddingBottom: '8px' }}>
                                                        <Text text='KPI' bold type='medium' color='darkGray' />
                                                    </th>
                                                    <th style={{ paddingBottom: '8px' }}>
                                                        <Text text='Actual' bold type='medium' color='darkGray' />
                                                    </th>
                                                </tr>
                                                {
                                                    currData?.items?.map(item => {
                                                        return (
                                                            <tr key={item.kpi}>
                                                                <td style={{ paddingTop: '8px' }}>
                                                                    <Layout row gap={8} justifyContent='flex-start'>
                                                                        {
                                                                            item?.done !== undefined ?
                                                                                item.done ?
                                                                                    <Success size={16} /> :
                                                                                    <Failure size={16} /> :
                                                                                <></>
                                                                        }
                                                                        <Text text={item.kpi} />
                                                                    </Layout>
                                                                </td>
                                                                <td style={{ paddingTop: '8px' }}>
                                                                    <Text text={item?.actual ?? 'TBA'} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Layout>
                            }
                            <Layout row gap={32}>
                                <Left
                                    className={[
                                        styles.iconButton,
                                        currIndex >= keyData.length - 1 ? styles.iconButtonDisabled : null
                                    ].join(' ')}
                                    onClick={currIndex >= keyData.length - 1 ? null : () => setCurrIndex(o => o + 1)} />
                                <Text text={keyData[keyData.length - currIndex - 1].date} />
                                <Right
                                    className={[
                                        styles.iconButton,
                                        currIndex <= 0 ? styles.iconButtonDisabled : null
                                    ].join(' ')}
                                    onClick={currIndex <= 0 ? null : () => setCurrIndex(o => o - 1)} />
                            </Layout>
                        </Layout>
                }
            </Layout>
        </Layout>
    )
}

export default KPIs