import { toast } from "react-toastify"
import Status from "../status"

export const setShowCloseAction = (state, action) => {
    state.showClose = action.payload
}

export const fetchCanceledSubscriptionsLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchCanceledSubscriptionsFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.data = action.payload
}

export const fetchCanceledSubscriptionsRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}
