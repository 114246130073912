import React from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import ActivityCalendar from './Info/ActivityCalendar'
import ChallengesCalendar from './Info/ChallengesCalendar'
import Button from '../../components/Button/Button'
import { toast } from 'react-toastify'
import FunctionsService from '../../services/FunctionsService'
import Linkedin from '../../assets/icons/Linkedin'
import Github from '../../assets/icons/Github'
import Facebook from '../../assets/icons/Facebook'
import Instagram from '../../assets/icons/Instagram'
import Twitter from '../../assets/icons/Twitter'
import Youtube from '../../assets/icons/Youtube'
import PersonalWeb from '../../assets/icons/PersonalWeb'
import { getOnboardString } from '../../util/helpers'

const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR

const MainInfo = ({ finalUser }) => {
    let lastActivity = ''
    if (finalUser?.metadata?.lastChallengeRunDate !== undefined) {
        const time = finalUser.metadata.lastChallengeRunDate
        const now = Date.now()
        const diff = now - time;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    const handleDelete = () => {
        const handleClick = async () => {
            const id = toast.loading("Deleting...")
            const res = await FunctionsService.deleteUser(finalUser.uid)
            if (res === true) {
                toast.update(id, { render: "Deleted", type: "success", isLoading: false })
            } else {
                if (res?.error) {
                    toast.update(id, { render: res.error, type: "error", isLoading: false })
                } else {
                    toast.update(id, { render: "Some error", type: "error", isLoading: false })
                }
            }
        }
        toast.warn(<Layout row fullWidth justifyContent='space-between'>Are you sure? deleting {finalUser.email} <Button text={'YES'} onClick={handleClick} /></Layout>)
    }
    const openUrl = (url) => {
        window.open(url, '_blank').focus();
    }
    const isEmpty = (s) => {
        return s === undefined || s === null || s?.length === 0
    }

    return (
        <Layout padding={[16]} fullWidth alignItems='flex-start' gap={32}>
            <Layout row gap={32} alignItems='flex-start'>
                <Layout row alignItems='flex-start' padding={[16]}>
                    <Layout style={{ width: '200px' }} alignItems='start'>
                        <Layout row gap={8} padding={[8, 0]}>
                            {isEmpty(finalUser?.linkedInPage) ? null : <Linkedin size={24} onClick={() => openUrl(finalUser.linkedInPage)} />}
                            {isEmpty(finalUser?.githubPage) ? null : <Github size={24} onClick={() => openUrl(finalUser.githubPage)} />}
                            {isEmpty(finalUser?.facebookPage) ? null : <Facebook size={24} onClick={() => openUrl(finalUser.facebookPage)} />}
                            {isEmpty(finalUser?.instagramPage) ? null : <Instagram size={24} onClick={() => openUrl(finalUser.instagramPage)} />}
                            {isEmpty(finalUser?.twitterPage) ? null : <Twitter size={24} onClick={() => openUrl(finalUser.twitterPage)} />}
                            {isEmpty(finalUser?.youtubePage) ? null : <Youtube size={24} onClick={() => openUrl(finalUser.youtubePage)} />}
                            {isEmpty(finalUser?.personalWebPage) ? null : <PersonalWeb size={24} onClick={() => openUrl(finalUser.personalWebPage)} />}
                        </Layout>
                        <Layout row>

                            <Layout style={{ width: "100px" }} alignItems='flex-start' gap={2}>
                                <Text text={`Rating:`} color='darkGray' type='small' />
                                <Text overflow text={`Feedback:`} color='darkGray' type='small' />
                                <Text text={`Profile level:`} color='darkGray' type='small' />
                                <Text text={`Journey:`} color='darkGray' type='small' />
                                <Text text={`Goals Intensity:`} color='darkGray' type='small' />
                                <Text text={`From:`} color='darkGray' type='small' />
                                <Text text={"Ref:"} color='darkGray' type='small' />
                                <Text text={`Reminder:`} color='darkGray' type='small' />
                                <Text text={`Exposure:`} color='darkGray' type='small' />
                                <Text text={`Experiment:`} color='darkGray' type='small' />
                                <Text text={`Score:`} color='darkGray' type='small' />
                                <Text text={`Streak:`} color='darkGray' type='small' />
                                <Text text={`Last activity:`} color='darkGray' type='small' />
                                <Text text={`Limits:`} color='darkGray' type='small' />
                                <Text text={`Last limit:`} color='darkGray' type='small' />
                                <Text text={`Created date:`} color='darkGray' type='small' />
                                <Text text={`Days used:`} color='darkGray' type='small' />
                                <Text text={`Compiles:`} color='darkGray' type='small' />
                                <Text text={`AI Responses:`} color='darkGray' type='small' />
                                <Text text={`Challenges:`} color='darkGray' type='small' />
                                <Text text={`Country:`} color='darkGray' type='small' />
                                <Text text={`-`} color='darkGray' type='small' />
                                <Text text={`-`} color='darkGray' type='small' />
                                <Text text={`Onboard:`} color='darkGray' type='small' />
                            </Layout>
                            <Layout style={{ width: "200px" }} alignItems='flex-start' gap={2}>
                                <Text text={`${finalUser?.metadata?.rating ?? '-'}/3`} color='darkGray' type='small' />
                                <Text overflow text={`${finalUser?.metadata?.feedback ?? '-'}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.badges?.profile ?? 0}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.currentRoadmap ?? '-'}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.goalsIntensity ?? '-'}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.from ?? '-'}`} color='darkGray' type='small' />
                                <Text text={<><a href={`https://data.coddy.tech/users/${finalUser?.ref ?? '-'}`} target='_blank' rel="noreferrer">{finalUser?.ref ?? '-'}</a></>} color='darkGray' type='small' />
                                <Text
                                    text={`${finalUser?.metadata?.reminder ?
                                        `${finalUser?.metadata?.reminder?.days}|${finalUser?.metadata?.reminder?.duration}|${finalUser?.metadata?.reminder?.time}`
                                        : '-'}`}
                                    color='darkGray'
                                    type='small' />
                                <Text text={`${finalUser?.metadata?.exposureWay?.how ? finalUser.metadata.exposureWay.how + '/' : '-'}${finalUser?.metadata?.exposureWay?.more ?? '-'}${finalUser?.metadata?.exposureWay?.info ? ('/' + finalUser?.metadata?.exposureWay?.info) : '-'}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.experiment?.experimentId ?? '-'}/${finalUser?.experiment?.groupId ?? '-'}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.score ?? 0}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.streak ?? 0}`} color='darkGray' type='small' />
                                <Text text={`${lastActivity}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.metadata?.limitReachedNum ?? 0}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.metadata?.lastLimitReachedDate ? new Date(finalUser?.metadata?.lastLimitReachedDate).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' }) : '-'}`} color='darkGray' type='small' />
                                <Text text={`${new Date(finalUser?.createdDate).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.metadata?.daysUsed ?? '-'}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.metadata?.todayCompilesNum ?? 0}/${finalUser?.metadata?.compilesNum ?? 0}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.metadata?.todayAskAiNum ?? 0}/${finalUser?.metadata?.askAiNum ?? 0}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.metadata?.challengesDone ?? 0}`} color='darkGray' type='small' />
                                <Text text={`${finalUser?.geo?.country ? finalUser?.geo?.country : (finalUser?.geo?.startCountry ? finalUser?.geo?.startCountry : '-')}`} color='darkGray' type='small' />
                                <Text text={`-`} color='darkGray' type='small' />
                                <Text text={`-`} color='darkGray' type='small' />
                                <Text text={getOnboardString(finalUser.onboard)} color='darkGray' type='small' />
                            </Layout>
                        </Layout>
                    </Layout>
                </Layout>
                <Layout padding={[16]}>
                    <ActivityCalendar uid={finalUser.uid} />
                    <ChallengesCalendar uid={finalUser.uid} />
                </Layout>
            </Layout>
            <Layout margin={[32, 0, 0, 0]}>
                <Button text={'Delete User'} onClick={handleDelete} />
            </Layout>
        </Layout>
    )
}

export default MainInfo