import React from 'react'

const CoddyText = ({ size = 24, white }) => {
    return (
        <svg width={size} height={(size / 33) * 9} viewBox="0 0 33 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_325_15)">
                <path d="M6.17446 6.31384C6.17446 6.6358 5.898 6.8968 5.55696 6.8968H1.72961C1.61588 6.8968 1.49268 6.88484 1.35999 6.86094C1.23047 6.83402 1.10095 6.79366 0.971425 6.73985C0.84506 6.68604 0.723435 6.61728 0.606547 6.53358C0.489661 6.44688 0.385411 6.34373 0.293797 6.22415C0.205342 6.10158 0.134262 5.96107 0.0805572 5.80264C0.0268525 5.64119 0 5.46033 0 5.26003V2.10309C0 1.99545 0.0126364 1.88035 0.0379093 1.7578C0.0663412 1.63223 0.108989 1.50966 0.165853 1.39008C0.222717 1.2675 0.296956 1.15091 0.388569 1.0403C0.480183 0.929687 0.589173 0.832527 0.715537 0.74882C0.84506 0.662123 0.993538 0.593364 1.16097 0.542542C1.3284 0.49172 1.51795 0.466309 1.72961 0.466309H5.55696C5.898 0.466309 6.17446 0.727309 6.17446 1.04927C6.17446 1.37123 5.898 1.63223 5.55696 1.63223H1.72961C1.5685 1.63223 1.44529 1.67259 1.35999 1.7533C1.2747 1.83402 1.23205 1.9536 1.23205 2.11205V5.26003C1.23205 5.40951 1.2747 5.5261 1.35999 5.60981C1.44845 5.69052 1.57165 5.73088 1.72961 5.73088H5.55696C5.898 5.73088 6.17446 5.99188 6.17446 6.31384Z" fill={white ? "#fff" : "#8096F1"} />
                <path d="M12.8517 5.26007C12.8517 5.46037 12.8249 5.64123 12.7711 5.80268C12.7174 5.96111 12.6464 6.10162 12.5579 6.22419C12.4695 6.34377 12.3668 6.44692 12.2499 6.53362C12.133 6.61732 12.0098 6.68608 11.8803 6.73989C11.7539 6.7937 11.6244 6.83406 11.4917 6.86098C11.3622 6.88488 11.239 6.89684 11.1221 6.89684H8.89968C8.72909 6.89684 8.54269 6.86844 8.34051 6.81165C8.13833 6.75485 7.95036 6.66216 7.77661 6.53362C7.60602 6.40208 7.46227 6.23317 7.3454 6.02688C7.23166 5.81762 7.1748 5.56202 7.1748 5.26007V3.68159C7.1748 3.38265 7.23166 3.13003 7.3454 2.92375C7.46227 2.71449 7.60602 2.54558 7.77661 2.41702C7.95036 2.28548 8.13833 2.19131 8.34051 2.1345C8.54269 2.07772 8.72909 2.04932 8.89968 2.04932H11.1221C11.438 2.04932 11.7065 2.10313 11.9277 2.21075C12.1488 2.31836 12.3273 2.45439 12.4631 2.61881C12.599 2.78025 12.6969 2.95664 12.7569 3.14796C12.8201 3.33929 12.8517 3.51716 12.8517 3.68159V5.26007ZM11.6197 3.69057C11.6197 3.52912 11.577 3.40955 11.4917 3.33182C11.4064 3.25409 11.2832 3.21523 11.1221 3.21523H8.90914C8.74487 3.21523 8.62009 3.25559 8.53479 3.3363C8.4495 3.41403 8.40684 3.52912 8.40684 3.68159V5.26007C8.40684 5.41253 8.4495 5.52913 8.53479 5.60986C8.62009 5.69056 8.74487 5.73092 8.90914 5.73092H11.1221C11.2895 5.73092 11.4143 5.69056 11.4964 5.60986C11.5786 5.52913 11.6197 5.41253 11.6197 5.26007V3.69057Z" fill={white ? "#fff" : "#8096F1"} />
                <path d="M19.5285 5.26008C19.5285 5.46039 19.5017 5.64125 19.448 5.8027C19.3943 5.96113 19.3231 6.10164 19.2348 6.22421C19.1462 6.34379 19.0435 6.44694 18.9268 6.53364C18.8098 6.61734 18.6866 6.6861 18.557 6.73991C18.4307 6.79372 18.3012 6.83408 18.1685 6.86099C18.039 6.8849 17.9158 6.89686 17.7989 6.89686H15.5764C15.3648 6.89686 15.1752 6.87145 15.0078 6.82063C14.8404 6.7698 14.6919 6.70254 14.5624 6.61884C14.436 6.53213 14.327 6.43348 14.2354 6.32286C14.1469 6.21225 14.0743 6.09715 14.0174 5.97758C13.9606 5.855 13.9179 5.73243 13.8895 5.60987C13.8642 5.48431 13.8516 5.36772 13.8516 5.26008V3.68161C13.8516 3.38267 13.9084 3.13004 14.0222 2.92377C14.139 2.7145 14.2828 2.5456 14.4534 2.41704C14.6271 2.2855 14.8151 2.19133 15.0173 2.13452C15.2195 2.07774 15.4058 2.04933 15.5764 2.04933H17.1814C17.5224 2.04933 17.7989 2.31033 17.7989 2.63229C17.7989 2.95425 17.5224 3.21525 17.1814 3.21525H15.5859C15.4216 3.21525 15.2969 3.25561 15.2116 3.33632C15.1263 3.41405 15.0836 3.52914 15.0836 3.68161V5.25112C15.0836 5.40957 15.1247 5.52915 15.2068 5.60987C15.2921 5.69058 15.4153 5.73094 15.5764 5.73094H17.7989C17.9663 5.73094 18.0911 5.69058 18.1732 5.60987C18.2554 5.52915 18.2964 5.41255 18.2964 5.26008V0.581563C18.2964 0.260375 18.5722 0 18.9125 0C19.2527 0 19.5285 0.260375 19.5285 0.581563V5.26008Z" fill={white ? "#fff" : "#8096F1"} />
                <path d="M26.2052 5.26008C26.2052 5.46039 26.1785 5.64125 26.1248 5.8027C26.071 5.96113 25.9999 6.10164 25.9114 6.22421C25.823 6.34379 25.7203 6.44694 25.6034 6.53364C25.4866 6.61734 25.3634 6.6861 25.2338 6.73991C25.1074 6.79372 24.978 6.83408 24.8453 6.86099C24.7157 6.8849 24.5925 6.89686 24.4757 6.89686H22.2531C22.0416 6.89686 21.852 6.87145 21.6846 6.82063C21.5172 6.7698 21.3687 6.70254 21.2391 6.61884C21.1128 6.53213 21.0037 6.43348 20.9122 6.32286C20.8237 6.21225 20.7511 6.09715 20.6942 5.97758C20.6374 5.855 20.5947 5.73243 20.5663 5.60987C20.541 5.48431 20.5283 5.36772 20.5283 5.26008V3.68161C20.5283 3.38267 20.5852 3.13004 20.699 2.92377C20.8158 2.7145 20.9595 2.5456 21.1302 2.41704C21.3038 2.2855 21.4919 2.19133 21.694 2.13452C21.8962 2.07774 22.0826 2.04933 22.2531 2.04933H23.8582C24.1992 2.04933 24.4757 2.31033 24.4757 2.63229C24.4757 2.95425 24.1992 3.21525 23.8582 3.21525H22.2627C22.0984 3.21525 21.9735 3.25561 21.8883 3.33632C21.803 3.41405 21.7603 3.52914 21.7603 3.68161V5.25112C21.7603 5.40957 21.8014 5.52915 21.8835 5.60987C21.969 5.69058 22.0922 5.73094 22.2531 5.73094H24.4757C24.6431 5.73094 24.7679 5.69058 24.85 5.60987C24.9322 5.52915 24.9732 5.41255 24.9732 5.26008V0.581563C24.9732 0.260375 25.249 0 25.5892 0C25.9295 0 26.2052 0.260375 26.2052 0.581563V5.26008Z" fill={white ? "#fff" : "#8096F1"} />
                <path d="M32.8825 7.36321C32.8825 7.56351 32.8557 7.74289 32.802 7.90132C32.7483 8.06277 32.6771 8.20327 32.5886 8.32285C32.5002 8.44543 32.3976 8.54856 32.2806 8.63226C32.1638 8.71896 32.0406 8.78923 31.911 8.84304C31.7847 8.89685 31.6567 8.9357 31.5273 8.95962C31.3977 8.98653 31.2745 8.99998 31.1577 8.99998H29.5526C29.2116 8.99998 28.9351 8.73898 28.9351 8.41702C28.9351 8.09507 29.2116 7.83407 29.5526 7.83407H31.1577C31.3219 7.83407 31.4451 7.79371 31.5273 7.71298C31.6094 7.63228 31.6505 7.51567 31.6505 7.36321V2.63087C31.6505 2.30968 31.9262 2.04932 32.2665 2.04932C32.6067 2.04932 32.8825 2.30968 32.8825 2.63087V7.36321ZM31.1577 6.31388C31.1577 6.63584 30.8812 6.89684 30.54 6.89684H28.9351C28.7582 6.89684 28.5686 6.86844 28.3666 6.81165C28.1675 6.75485 27.9811 6.66216 27.8074 6.53362C27.6336 6.40208 27.4899 6.23317 27.3762 6.02688C27.2624 5.81762 27.2056 5.56202 27.2056 5.26007V2.63087C27.2056 2.30968 27.4813 2.04932 27.8216 2.04932C28.1618 2.04932 28.4376 2.30968 28.4376 2.63087V5.26007C28.4376 5.41253 28.4803 5.52913 28.5655 5.60986C28.6508 5.69056 28.7773 5.73092 28.9447 5.73092H30.54C30.8812 5.73092 31.1577 5.99192 31.1577 6.31388Z" fill={white ? "#fff" : "#8096F1"} />
            </g>
            <defs>
                <clipPath id="clip0_325_15">
                    <rect width={size} height={(size / 33) * 9} fill="white" />
                </clipPath>
            </defs>
        </svg>



    )
}

export default CoddyText