import { toast } from "react-toastify"
import Status from "../status"

export const fetchBlogsLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchBlogsFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.data = action.payload
}

export const fetchBlogsRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMoreBlogsFulfilled = (state, action) => {
    state.data = state.data.concat(action.payload)
}

export const fetchMoreBlogsRejected = (state, action) => {
    toast.error(action.error.message)
}
