import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import Divider from '../../components/Divider/Divider'
import CoursesProgress from './CoursesProgress'
import { MARGIN } from '../../Constants'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetLessonFlow } from '../../store/analytics/analytics.slice'
import LessonFlow from './LessonFlow/LessonFlow'

const Challenges = () => {
    const dispatch = useDispatch()
    let { uid } = useParams();
    // const [currentCourseId, setCurrentCourseId] = useState(uid)

    useEffect(() => {
        dispatch(resetLessonFlow())
        return () => {
            dispatch(resetLessonFlow())
        }
    }, [dispatch])


    const handleCourseClick = (/* courseId */) => {
        // setCurrentCourseId(courseId)
    }

    return (
        <>
            <Layout fullWidth color='white' border alignItems='start' justifyContent='start' padding={[8]} margin={[16, 0, 0, 0]}>
                <Layout row fullWidth alignItems='start' justifyContent='start' padding={[0]} >
                    <Layout style={{ "width": "100%" }} alignItems='start' justifyContent='start' padding={[MARGIN / 4]}>
                        <Text text='Challenges' />
                    </Layout>
                </Layout>
                <Layout fullWidth>
                    <Divider margin={[0]} color='#D4D4D4' />
                </Layout>
                <Layout row fullWidth alignItems='start' justifyContent='start' padding={[0]} style={{ height: '25vh', minHeight: '200px' }}>
                    <Layout style={{ "width": "100%", height: '100%' }} alignItems='start' justifyContent='start' padding={[MARGIN / 2]}>
                        <CoursesProgress uid={uid} onCourseClick={handleCourseClick} isChallenges />
                    </Layout>
                </Layout>
            </Layout>
            <Layout color='white' fullWidth border scroll style={{ height: "60vh", minHeight: '400px' }} margin={[MARGIN, 0, MARGIN, 0]} padding={[MARGIN]} alignItems='start' justifyContent='start'>
                <LessonFlow />
            </Layout>
        </>
    )
}

export default Challenges