import React from 'react'
import styles from './IconButton.module.css'
import { Link } from "react-router-dom";

const IconButton = ({ Icon, onClick, type, size, disabled, label, labelDirection, text, hide, className, href, replace = false, selected=false }) => {

    let mainStyle = styles.main
    if (type === 'home')
        mainStyle = styles.home
    else if (type === 'dark')
        mainStyle = styles.dark
    else if (type === 'white')
        mainStyle = styles.white

    let sizeStyle = styles.normal
    if (size === 'tiny') sizeStyle = styles.tiny
    if (size === 'small') sizeStyle = styles.small
    if (text) {
        if (sizeStyle === styles.normal) sizeStyle = styles.normalWithText
        else if (sizeStyle === styles.small) sizeStyle = styles.smallWithText
    }

    let iconSize = 24
    if (size === 'tiny') iconSize = 16
    if (size === 'small') iconSize = 20
    if (size === 'medium') iconSize = 40

    let iconColor = '#29ABE2'
    if (type === 'home' || type === 'dark') iconColor = 'white'
    if (type === 'white') iconColor='black'

    if (hide) return null

    let content = <div
        className={[styles.base,  mainStyle, sizeStyle, disabled ? styles.disabled : null, className].join(' ')}
        onClick={disabled ? null : onClick}>
        {<Icon color={iconColor} size={iconSize} />}
        {text ? <p className={styles.text}>{text}</p> : null}
        {label ? <span className={[styles.tooltip, labelDirection === 'right' ? styles.tooltipRight : null].join(' ')}>{label}</span> : null}
    </div>

    if (href) {
        content = <Link to={href} replace={replace}>{content}</Link>
    }

    return content
}

export default IconButton