import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleLessonFlow } from '../../store/analytics/analytics.slice'

import { LANGUAGES } from '../../util/types'
import styles from './CourseView.module.css'
import Mark from '../../assets/icons/Mark'
import Reload from '../../assets/icons/Reload'
import Tag from '../../components/Tag'
import Layout from '../../components/Layout'
import { MARGIN } from '../../Constants'
import Text from '../../components/Text/Text'

const LessonView = ({ uid, lesson, courseId, progress, onClick, chapterName, isActive }) => {
    const dispatch = useDispatch()
    const doneWithCode = Object.keys(progress ?? {}).some(k => Object.keys(LANGUAGES).includes(k))

    const handleLessonClick = (courseId, lessonId, lessonName, chapterName) => {
        onClick(lessonId)

        const hintsSeen = {}
        if (progress !== undefined) {
            Object.keys(progress.hints ?? {})
                .forEach(k => hintsSeen[k] = progress.hints[k])
        }
        const solutionDate = progress?.solution ?? undefined
        const askedAi = progress?.askAiNum > 0
        const quizProgress = progress?.quiz ?? {}

        dispatch(toggleLessonFlow({ uid, courseId, lessonId, lessonName, chapterName, hintsSeen, solutionDate, askedAi, quizProgress }))
    }

    return (
        <Layout row fullWidth border
            onClick={() => handleLessonClick(courseId, lesson.id, lesson.name, chapterName)}
            padding={[MARGIN / 4, MARGIN / 2, MARGIN / 4, MARGIN / 2]}
            margin={[MARGIN / 4]}
            classes={[styles.itemContainer, isActive ? styles.itemActive : null]}

        >
            {/* <div className={styles.lessonContainer} onClick={onClick}> */}
            <div className={[styles.progressIndicator, progress !== undefined ? styles.progressIndicatorFilled : null].join(' ')}>
                {progress?.done === true ? <Mark color='white' width='2.4' /> : null}

            </div>
            <span className={styles.lessonName}>
                <span>{chapterName}/</span>
                {lesson.name}
            </span>
            {
                Object.keys(LANGUAGES).filter(lang => progress && progress[lang]).map(lang =>
                    <Tag key={lang} text={LANGUAGES[lang]} />
                )
            }
            {
                progress?.askAiNum > 0 ?
                    <Text text={`AI (${progress.askAiNum})`} type='small' className={styles.askAiNum} /> :
                    null
            }
            {
                progress?.doneRehearsal || (progress?.done && progress?.failedNum >= 3) ?
                    <Reload
                        className={[styles.rehearsalIcon, progress?.doneRehearsal !== true && progress?.failedNum >= 3 && progress?.done ? styles.rehearsalIconNotDone : null].join(' ')} /> :
                    null
            }
            <span className={styles.failedNum}>{progress?.failedNum ?? (doneWithCode ? '0' : '')}</span>
        </Layout>
    )
}

export default LessonView