import React from 'react'

const Plus = ({ size = 24, color = '#262F56', onClick }) => {
    return (
        <svg onClick={onClick} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3V12M12 21V12M12 12H21M12 12H3" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Plus