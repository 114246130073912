import React from 'react'

const SearchEngine = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3771_562)">
                <path d="M10.9186 20.8372C16.3965 20.8372 20.8372 16.3965 20.8372 10.9186C20.8372 5.44074 16.3965 1 10.9186 1C5.44074 1 1 5.44074 1 10.9186C1 16.3965 5.44074 20.8372 10.9186 20.8372Z" fill="#2F88FF" stroke="black" strokeWidth="2" strokeLinejoin="round" />
                <path d="M14.2191 7.03456C13.3745 6.1899 12.2076 5.66748 10.9186 5.66748C9.62972 5.66748 8.46282 6.1899 7.61816 7.03456" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.0493 18.0493L23 23" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3771_562">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SearchEngine