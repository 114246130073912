export const LANGUAGES = {
    'python': 'Python',
    'cpp': 'C++',
    'java': 'Java',
    'csharp': 'C#',
    'r': 'R',
    'c': 'C',
    'go': 'Go',
    'js': 'JavaScript',
}

export const DIFFICULTIES = {
    1: 'Easy',
    2: 'Medium',
    3: 'Hard',
    4: 'Expert',
}

export const LANGUAGE_MAPPING_CODE_EDITOR = {
    "python": "python",
    "cpp": "c_cpp",
    "java": "java",
    "csharp": "csharp",
    "c": "c_cpp",
    "js": "javascript"
}
