import React, { useEffect, useState } from 'react'
import styles from './Permissions.module.css'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button'
import Divider from '../../components/Divider/Divider'
import Layout from '../../components/Layout'
import InputBar from '../../components/InputBar'
import Search from '../../assets/icons/Search'
import { MARGIN } from '../../Constants'
import PeopleTypeButton from './PermissionsTypeButton'
import TextCategory from './TextCategory'
import FlexBoxAddUser from './FlexBoxAddUser';
import UserContent from './UserContent';
import { fetchPermissionsData } from '../../store/permissions/permissions.slice';

const Permissions = () => {
    const dispatch = useDispatch()

    // const affiliators = useSelector(state => state.permissions.affiliators)

    const {creators,reviewers} = useSelector(state => state.permissions)


    const [peoplePage, setPeoplePage] = useState('creators')
    const [isAddUserOpen, setIsAddUserOpen] = useState(false)
    const [searchText, setSearchText] = useState('')


    const switchPage = (pageString) => {
        setIsAddUserOpen(false)
        setPeoplePage(pageString)
    }


    const filter = (arr) => {
        if (searchText !== '') {
            return arr.filter(item => item.email.includes(searchText))    
        } 
        return arr
    }

    const getUsers = () => {
        switch (peoplePage) {
            case 'reviewers':
                return filter(reviewers)
            case 'creators':
                return filter(creators)
            // case 'affiliators':
            //     return filter(affiliators)
            default:
                return []
        }
    }
    useEffect(() => {
        dispatch(fetchPermissionsData({type: peoplePage}))
    }, [dispatch, peoplePage])

    return (
        <Layout fullWidth justifyContent='start' classes={[styles.container]}>
            <Layout alignItems='start' fullWidth>
                <Layout row>
                    <PeopleTypeButton text="Creators" onClick={() => { switchPage('creators') }} selected={peoplePage === 'creators'} />
                    {/* <PeopleTypeButton text="Affiliators" onClick={() => { switchPage('affiliators') }} selected={peoplePage === 'affiliators'} /> */}
                    <PeopleTypeButton text="Reviewers" onClick={() => { switchPage('reviewers') }} selected={peoplePage === 'reviewers'} />
                </Layout>
                <Divider margin={[0]} color='#D4D4D4' />
            </Layout>

            <Layout color="white" margin={[MARGIN * 2, MARGIN * 2, MARGIN, MARGIN * 2]} border content>
                <Layout fullWidth row padding={[MARGIN]}>
                <InputBar Icon={Search} hint='Search Email' value={searchText} onValueChange={(e) => setSearchText(e)} style={{"width": "100%"}}/>
                    <Button text="Add User" onClick={() => { setIsAddUserOpen(true) }} />
                </Layout>
            </Layout>

            <Layout fullWidth padding={[0, MARGIN * 2]} justifyContent='start'>
                <Layout fullWidth row justifyContent='start' padding={[0, MARGIN]} gap={8}>
                    <TextCategory text='Email' width={"282px"} color='darkGray' type='medium' />
                    <TextCategory text='Label' width={"144px"} color='darkGray' type='medium' />

                </Layout>
                <Divider margin={[0]} color='#D4D4D4' />
                <div style={{ "height": MARGIN }}></div>

            </Layout>

            <Layout justifyContent='start' content margin={[0, MARGIN * 2]} maxHeightScroll={'100%'}>
                <UserContent users={getUsers()} type={peoplePage} />
            </Layout>
            <FlexBoxAddUser
                peoplePage={peoplePage}
                isAddUserOpen={isAddUserOpen}
                closeAddUsers={() => setIsAddUserOpen(false)}
            />
        </Layout>
    )
}

export default Permissions