import React from 'react'

const Journeys = ({ size = 24, color = "black" }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 22.5H3C2.6023 22.4996 2.221 22.3414 1.93978 22.0602C1.65856 21.779 1.5004 21.3977 1.5 21V18C1.5004 17.6023 1.65856 17.221 1.93978 16.9398C2.221 16.6586 2.6023 16.5004 3 16.5H9C9.3977 16.5004 9.779 16.6586 10.0602 16.9398C10.3414 17.221 10.4996 17.6023 10.5 18V21C10.4996 21.3977 10.3414 21.779 10.0602 22.0602C9.779 22.3414 9.3977 22.4996 9 22.5ZM3 18V21H9V18H3ZM21 15H9C8.6023 14.9996 8.221 14.8414 7.93978 14.5602C7.65856 14.279 7.5004 13.8977 7.5 13.5V10.5C7.5004 10.1023 7.65856 9.721 7.93978 9.43978C8.221 9.15856 8.6023 9.0004 9 9H21C21.3977 9.0004 21.779 9.15856 22.0602 9.43978C22.3414 9.721 22.4996 10.1023 22.5 10.5V13.5C22.4996 13.8977 22.3414 14.279 22.0602 14.5602C21.779 14.8414 21.3977 14.9996 21 15ZM9 10.5V13.5H21V10.5H9ZM12 7.5H3C2.6023 7.4996 2.221 7.34144 1.93978 7.06022C1.65856 6.779 1.5004 6.3977 1.5 6V3C1.5004 2.6023 1.65856 2.221 1.93978 1.93978C2.221 1.65856 2.6023 1.5004 3 1.5H12C12.3977 1.5004 12.779 1.65856 13.0602 1.93978C13.3414 2.221 13.4996 2.6023 13.5 3V6C13.4996 6.3977 13.3414 6.779 13.0602 7.06022C12.779 7.34144 12.3977 7.4996 12 7.5ZM3 3V6H12V3H3Z" fill={color} />
        </svg>
    )
}

export default Journeys