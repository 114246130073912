import React from 'react'

const Dots = ({ color = '#262F56', size = '24' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="5" r="2" fill={color} />
            <circle cx="12" cy="12" r="2" fill={color} />
            <circle cx="12" cy="19" r="2" fill={color} />
        </svg>

    )
}

export default Dots