import { addDays, format, differenceInDays } from 'date-fns';
import React, { useState } from 'react'
import IconLeft from '../../assets/icons/Left'
import IconRight from '../../assets/icons/Right'
import style from './DateButton.module.css'
import Text from '../Text'
import Layout from '../Layout'
import Button, { TYPE_SELECTION } from './Button/Button';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import Presets from './Presets';
import { useComponentVisible } from '../../util/hooks';
import Range from './Range';


const DateButton = ({ isActive, startDate, endDate, setStartDate, setEndDate }) => {
    const [typeSelection, setTypeSelection] = useState("Presets")
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const [inc, setInc] = useState(Math.abs(differenceInDays(startDate, endDate)) + 1)
    const [date, setDate] = useState(null)
    const [state, setState] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: 'selection'
        }]
    );


    const dateFormatYear = "dd LLL yy";
    const dateFormat = "dd LLL";

    const formatRange = (date1, date2, date) => {
        if (date1.getFullYear === date2.getFullYear) {
            return format(date, dateFormat)
        } else {
            return format(date, dateFormatYear)
        }
    }

    const goBack = () => {
        if (date != null) {
            setDate(addDays(date, -1))
            setStartDate(addDays(date, -1))
            setEndDate(addDays(date, -1))
        } else {

            setState([{
                startDate: addDays(state[0].startDate, -inc),
                endDate: addDays(state[0].endDate, -inc),
                key: 'selection'
            }])
            setStartDate(addDays(state[0].startDate, -inc))
            setEndDate(addDays(state[0].endDate, -inc))
        }
    }

    const goForward = () => {
        if (date != null) {
            if (addDays(date, 1).getDate() <= new Date().getDate())
                setDate(addDays(date, 1))
            setStartDate(addDays(date, 1))
            setEndDate(addDays(date, 1))
        } else {
            if (addDays(state[0].endDate, inc).getDate() <= new Date().getDate()) {
                setState([{
                    startDate: addDays(state[0].startDate, inc),
                    endDate: addDays(state[0].endDate, inc),
                    key: 'selection'
                }])
                setStartDate(addDays(state[0].startDate, inc))
                setEndDate(addDays(state[0].endDate, inc))
            } else {
                setState([{
                    startDate: addDays(new Date(), -inc),
                    endDate: new Date(),
                    key: 'selection'
                }])
                setStartDate(addDays(new Date(), -inc))
                setEndDate(new Date())
            }
        }
    }


    return (
        <div ref={ref} style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Layout style={{ position: 'relative', pointerEvents: isActive ? 'auto' : 'none', opacity: isActive ? '1' : '0.6' }}>
                <Layout color="linear-gradient(to left,#29ABE2, #0077FF)" style={{ borderRadius: "80px" }}>
                    <Layout row padding={[4, 0]} spacing={24} justifyContent="space-between">
                        <button className={[style.base].join(' ')} onClick={() => goBack()}>
                            <IconLeft color="white" />
                        </button>

                        <button className={[style.base].join(' ')} onClick={() => { setIsComponentVisible(true) }}>
                            {
                                date !== null ?
                                    <Text text={`${format(date, dateFormat)}`} color="white" /> :
                                    <Text text={`${formatRange(state[0].startDate, state[0].endDate, state[0].startDate)} - ${formatRange(state[0].startDate, state[0].endDate, state[0].endDate)}`} color="white" />

                            }

                        </button>
                        <button className={[style.base].join(' ')} onClick={() => goForward()} style={{ opacity: state[0].endDate.getDate() === new Date().getDate() ? 0 : 1 }}>
                            <IconRight color="white" />
                        </button>
                    </Layout>
                </Layout>
                {
                    isComponentVisible &&
                    <Layout style={{
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
                        width: "350px",
                        zIndex: '100',
                        position: 'absolute',
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        top: '48px'
                    }} padding={[16, 24]
                    } spacing={16}>
                        <Layout row>
                            <Button
                                text={"Presets"}
                                type={TYPE_SELECTION}
                                selected={typeSelection === 'Presets'}
                                onClick={() => setTypeSelection("Presets")}
                                style={{ borderRadius: "10px 0px 0px 10px" }}
                            />
                            <Button
                                text={"Days"}
                                type={TYPE_SELECTION}
                                selected={typeSelection === 'Days'}
                                onClick={() => setTypeSelection("Days")}
                            />
                            <Button
                                text={"Range"}
                                type={TYPE_SELECTION}
                                selected={typeSelection === 'Range'}
                                onClick={() => setTypeSelection("Range")}
                                style={{ borderRadius: "0px 10px 10px 0px" }}
                            />
                        </Layout>

                        {
                            {
                                'Presets': <Presets
                                    onChange={(item, diff) => {
                                        setIsComponentVisible(false)
                                        setDate(null)
                                        setState(item)
                                        setInc(diff)
                                        setStartDate(item[0].startDate)
                                        setEndDate(item[0].endDate)

                                    }}
                                    ranges={state}
                                />,
                                'Days': <Calendar onChange={item => {
                                    setIsComponentVisible(false)
                                    setDate(item)
                                    setState([{
                                        startDate: item,
                                        endDate: item,
                                        key: 'selection'
                                    }])
                                    setStartDate(item)
                                    setEndDate(item)

                                }
                                } date={date} style={{ padding: 0, margin: 0 }} />,
                                'Weeks': <Text text="Weeks!" />,
                                'Months': <Text text="Months!" />,
                                'Range': <Range onSubmit={(item, diff) => {
                                    setIsComponentVisible(false)
                                    setDate(null)
                                    setInc(diff)
                                    setState(item)
                                    setStartDate(item[0].startDate)
                                    setEndDate(item[0].endDate)
                                }} initRange={state[0]} />
                            }[typeSelection]
                        }

                    </Layout>
                }
            </Layout>
        </div>
    )
}

export default DateButton