import React from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text/Text'
import { useSelector } from 'react-redux'

const Profile = () => {
    const user = useSelector(state => state.user.data)
    return (
        <Layout gap={16} style={{ background: 'white', borderRadius: '8px' }} alignItems='flex-start' padding={[16]}>
            <Text text='Profile' type='title' />
            <Layout row gap={8} justifyContent='flex-start'>
                <Text text='Connected Coddy UID:' />
                <Text
                    text={user?.connectedUid ?? 'None'}
                    color='mainColor'
                    onClick={user?.connectedUid === undefined ?
                        null :
                        () => window.open(`https://coddy.tech/user/${user.connectedUid}`, '_blank')} />
            </Layout>
        </Layout>
    )
}

export default Profile