import React, { useEffect, useState } from 'react'
import styles from './AIFeedbacks.module.css'
import Layout from '../../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAIFeedbacks } from '../../store/aiFeedbacks/aiFeedbacks.slice'
import ThumbsUp from '../../assets/icons/ThumbsUp'
import ThumbsDown from '../../assets/icons/ThumbsDown'
import { useNavigate, useParams } from 'react-router-dom'
import FeedbackPopup from './FeedbackPopup'
import FunctionsService from '../../services/FunctionsService'

const AIFeedbacks = () => {
    const { id, uid } = useParams()
    const dispatch = useDispatch()
    const feedbacks = useSelector(state => state.aiFeedbacks.data)

    useEffect(() => {
        dispatch(fetchAIFeedbacks({ more: false }))
    }, [dispatch])

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchAIFeedbacks({ more: true }))
        }
    }
    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <FeedbackPopup id={id} uid={uid} />
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.thumbs}></th>
                    <th className={styles.email}>Email</th>
                    <th className={styles.context}>Context</th>
                    <th className={styles.time}>Time</th>
                </tr>
                {
                    feedbacks.map((d, index) =>
                        <RowView
                            key={`${d.id}-${index}`}
                            {...d} />
                    )
                }
            </table>
        </Layout>
    )
}

const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR


const RowView = ({ id, timestamp, context, uid, thumbs }) => {
    const navigate = useNavigate()
    let lastActivity = ''
    if (timestamp !== undefined) {
        const now = Date.now()
        const diff = now - timestamp;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/ai_feedbacks/user/${uid}/${id}`) }}>
            <td className={styles.thumbs}>{thumbs === 'up' ? <ThumbsUp /> : <ThumbsDown />}</td>
            <td className={styles.email}><Email uid={uid} /></td>
            <th className={styles.context}>{`${context.cid}/${context.lid}`}</th>
            <td className={styles.time}>{lastActivity}</td>
        </tr>
    )
}

const Email = ({ uid }) => {
    const [data, setData] = useState({})
    useEffect(() => {
        FunctionsService.getCoddyUser(uid).then(d => setData(d))
    }, [uid])
    return <>{data.email}</>
}

export default AIFeedbacks