import React from 'react'

const Login = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="32" cy="32" r="32" fill="#057EFB" />
            <path d="M28.205 26.2667C28.0799 26.3887 27.9806 26.534 27.9129 26.6942C27.8451 26.8545 27.8102 27.0264 27.8102 27.2C27.8102 27.3736 27.8451 27.5455 27.9129 27.7058C27.9806 27.866 28.0799 28.0113 28.205 28.1333L30.77 30.6667H18.35C17.6075 30.6667 17 31.2667 17 32C17 32.7333 17.6075 33.3333 18.35 33.3333H30.77L28.205 35.8667C28.0799 35.9887 27.9806 36.134 27.9129 36.2942C27.8451 36.4545 27.8102 36.6264 27.8102 36.8C27.8102 36.9736 27.8451 37.1455 27.9129 37.3058C27.9806 37.466 28.0799 37.6113 28.205 37.7333C28.7315 38.2533 29.5685 38.2533 30.095 37.7333L34.9415 32.9467C35.0667 32.8233 35.1659 32.6768 35.2337 32.5155C35.3014 32.3542 35.3363 32.1813 35.3363 32.0067C35.3363 31.832 35.3014 31.6591 35.2337 31.4978C35.1659 31.3365 35.0667 31.19 34.9415 31.0667L30.095 26.2667C29.9714 26.1431 29.8243 26.0451 29.6621 25.9781C29.4999 25.9112 29.3258 25.8767 29.15 25.8767C28.9742 25.8767 28.8001 25.9112 28.6379 25.9781C28.4757 26.0451 28.3286 26.1431 28.205 26.2667ZM41.3 41.3333H31.85C31.1075 41.3333 30.5 41.9333 30.5 42.6667C30.5 43.4 31.1075 44 31.85 44H41.3C42.785 44 44 42.8 44 41.3333V22.6667C44 21.2 42.785 20 41.3 20H31.85C31.1075 20 30.5 20.6 30.5 21.3333C30.5 22.0667 31.1075 22.6667 31.85 22.6667H41.3V41.3333Z" fill="white" />
        </svg>

    )
}

export default Login