import { toast } from "react-toastify"
import Status from "../status"

export const fetchFunnelsOptionsLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchFunnelsOptionsFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.data = action.payload
}

export const fetchFunnelsOptionsRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}

export const saveFunnelOptionsLoading = (state, _) => {
    state.saveStatus = Status.Loading
}

export const saveFunnelOptionsFulfilled = (state, action) => {
    state.saveStatus = Status.Success
    state.data = state.data.map(d => d.id === action.meta.arg.id ? action.meta.arg.data : d)
    toast.success('Successfully updated')
}

export const saveFunnelOptionsRejected = (state, action) => {
    state.saveStatus = Status.Fail
    toast.error(action.error.message)
}