import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Status from '../status'
import {
    fetchFeedbacksFulfilled,
    fetchFeedbacksLoading,
    fetchFeedbacksRejected,
    fetchMoreFeedbacksFulfilled,
    fetchMoreFeedbacksRejected,
    fetchNumbersFulfilled,
} from './feedbacks.actions'
import FunctionsService from '../../services/FunctionsService'

export const fetchFeedbacks = createAsyncThunk('feedbacks/fetchFeedbacks', async (_) => {
    return FunctionsService.getFeedbacks(undefined)
})

export const fetchMoreFeedbacks = createAsyncThunk('feedbacks/fetchMoreFeedbacks', async (_, { getState }) => {
    const data = getState().feedbacks.data
    return FunctionsService.getFeedbacks(data[data.length - 1]?.lastUpdated)
})

export const fetchNumbers = createAsyncThunk('feedbacks/fetchNumbers', async (_, { getState }) => {
    const numbers = getState().feedbacks.numbers
    if (Object.keys(numbers).length === 0)
        return FunctionsService.getFeedbacksNumbers()
    else
        return numbers
})


export const feedbacksSlice = createSlice({
    name: 'feedbacks',
    initialState: {
        data: [],
        numbers: {},
        fetchStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFeedbacks.pending, fetchFeedbacksLoading)
            .addCase(fetchFeedbacks.fulfilled, fetchFeedbacksFulfilled)
            .addCase(fetchFeedbacks.rejected, fetchFeedbacksRejected)
            .addCase(fetchMoreFeedbacks.fulfilled, fetchMoreFeedbacksFulfilled)
            .addCase(fetchMoreFeedbacks.rejected, fetchMoreFeedbacksRejected)
            .addCase(fetchNumbers.fulfilled, fetchNumbersFulfilled)
    },
})

export default feedbacksSlice.reducer