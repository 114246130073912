import React from 'react'

const Blog = ({ size = 24, color = "white" }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.5 2 2 6.08333 2 11.125C2 16.1667 6.5 20.25 12 20.25C13.1667 20.25 14.375 20.0417 15.5 19.6667C15.625 19.625 15.7917 19.6667 15.875 19.75C16.7083 20.875 18 21.6667 19.4167 22L19.625 21.9167V21.6667C19.0793 20.9545 18.7977 20.0751 18.828 19.1785C18.8584 18.2819 19.199 17.4235 19.7917 16.75C21.0833 15.2083 22 13.25 22 11.125C22 6.08333 17.4583 2 12 2ZM11.9583 6.33333C12.0833 6.33333 12.2083 6.41667 12.25 6.54167L13.2917 9.5L16.5 9.58333C16.625 9.58333 16.75 9.66667 16.7917 9.75C16.8333 9.875 16.7917 10 16.6667 10.0833L14.125 12L15.0417 15C15.0833 15.125 15.0417 15.25 14.9167 15.3333H14.5833L11.9583 13.5417L9.33333 15.3333H9C8.875 15.25 8.83333 15.125 8.875 15L9.79167 12L7.25 10.0833C7.125 10 7.08333 9.875 7.125 9.75C7.16667 9.66667 7.29167 9.58333 7.41667 9.58333L10.625 9.5L11.6667 6.54167C11.7083 6.41667 11.8333 6.33333 11.9583 6.33333Z" fill={color} />
        </svg>
    )
}

export default Blog