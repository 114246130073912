import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import FunctionsService from '../../services/FunctionsService'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Button from '../../components/Button/Button';
import { useDispatch } from 'react-redux';
import { setShowEditBilling } from '../../store/analytics/analytics.slice';
import BillingEditPopup from './BillingEditPopup';
import DotLoader from "react-spinners/DotLoader";

const ChargesData = ({ data }) => {
    const [currPoint, setCurrPoint] = useState(data[0] ?? null)

    const handleMouseMove = (e) => {
        const curr = data.find(d => d.created === e?.activeLabel)
        setCurrPoint(curr ?? null)
    }

    const handleMouseLeave = () => {
        setCurrPoint(null)
    }

    return (
        <Layout row alignItems='flex-start'>
            <Layout alignItems='flex-start' style={{ width: '200px' }} gap={8}>
                <Layout alignItems='flex-start'>
                    <Text text={currPoint ? 'Selected' : 'Total'} type='small' color='darkGray' />
                    <Text text={`$${currPoint ? currPoint.amount : data.map(d => d.amount).reduce((a, b) => a + b, 0).toFixed(2)}`} type='subTitle' />
                    <Text text={currPoint?.promo_code ? `(${currPoint.promo_code})` : ''} type='small' color='darkGray' />
                </Layout>
                <Layout alignItems='flex-start'>
                    <Text text={currPoint ? 'Date' : 'Last payment'} type='small' color='darkGray' />
                    <Layout row gap={4}>
                        <Text
                            text={currPoint ?
                                new Date(currPoint?.created * 1000).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' }) :
                                (data[0]?.created ?
                                    new Date(data[0]?.created * 1000).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' }) :
                                    null)}
                            type='medium' />
                        {
                            currPoint ?
                                null :
                                <Text text={`($${data[0]?.amount})`} type='medium' />
                        }
                    </Layout>
                </Layout>
                <Layout alignItems='flex-start'>
                    <Text text={'Description'} type='small' color='darkGray' />
                    <Text text={currPoint ? currPoint?.description : (data[0]?.description ?? '')} type='medium' />
                </Layout>
            </Layout>
            <div style={{ width: '400px', height: '160px' }}>
                <ResponsiveContainer
                    width='100%'
                    height={'100%'}>
                    <LineChart
                        data={data}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="created" reversed tick={false} />
                        <YAxis dataKey={'amount'} yAxisId={`left`} />
                        {/* <YAxis domain={[0, 100]} dataKey={'ret'} yAxisId={`right`} orientation='right' unit={'%'} /> */}
                        <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                        <Line type="monotone" dataKey="amount" stroke="#8884d8" activeDot={{ r: 8 }} yAxisId={`left`} />
                        {/* <Line type="monotone" dataKey="ret" stroke="#82ca9d" strokeDasharray="3 4 5 2" yAxisId={`right`} /> */}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </Layout>
    )
}

const BillingData = ({ user }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (user?.billingData?.sid) {
            setIsLoading(true)
            FunctionsService.getCustomerData(user.billingData.sid)
                .then(d => {
                    setData(d.data)
                    setIsLoading(false)
                })
                .catch(e => {
                    console.error(e)
                    setIsLoading(false)
                })
        }
    }, [user])

    const countryNames = new Intl.DisplayNames(['en'], { type: 'region' });

    const handleEdit = () => {
        dispatch(setShowEditBilling(true))
    }

    return (
        <Layout fullWidth alignItems='flex-start' gap={16} padding={[32]}>
            {
                isLoading ? <Layout fullWidth fullHeight><DotLoader loading={true} size={24} /></Layout> : <>
                    <Layout row gap={8}>
                        <Text text='Billing data' type='small' color='darkGray' />
                        <Text text={`(${data?.id ?? '-'})`} type='small' />
                    </Layout>
                    <Layout row alignItems='flex-start'>
                        <Layout alignItems='flex-start' gap={8} style={{ width: '320px' }}>
                            {
                                user?.billingData?.sid ?
                                    <Layout row gap={8}>
                                        <Text text='Name:' type='small' color='darkGray' />
                                        <Text text={data?.name} />
                                    </Layout> :
                                    null
                            }
                            {
                                user?.billingData?.sid ?
                                    <Layout row gap={8}>
                                        <Text text='Created date:' type='small' color='darkGray' />
                                        <Text text={new Date(data.created * 1000).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })} type='medium' />
                                    </Layout> :
                                    null
                            }
                            {
                                user?.billingData?.sid ?
                                    <Layout row gap={8}>
                                        <Text text='Country:' type='small' color='darkGray' />
                                        <Text
                                            text={
                                                data?.country ?
                                                    <><span className={`fi fi-${data?.country?.toLowerCase()}`}></span> {countryNames.of(data.country)}</> :
                                                    null
                                            }
                                            type='medium' />
                                    </Layout> :
                                    null
                            }
                            {
                                user?.billingData?.reason ?
                                    <Layout row gap={8}>
                                        <Text text='Reason:' type='small' color='darkGray' />
                                        <Text text={user?.billingData?.reason} type='medium' />
                                    </Layout> :
                                    null
                            }
                            {
                                user?.billingData?.expiresAt ?
                                    <Layout row gap={8}>
                                        <Text text='Expires at:' type='small' color='darkGray' />
                                        <Text text={new Date(user?.billingData?.expiresAt).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })} type='medium' />
                                    </Layout> :
                                    null
                            }
                            <Button text={'Edit'} onClick={handleEdit} />
                        </Layout>
                    </Layout>
                    {
                        user?.billingData?.sid ?
                            <ChargesData data={(data.charges ?? []).map(c => {
                                c.amount = c.amount / 100
                                return c
                            })} /> : null}
                    <BillingEditPopup data={user?.billingData ?? {}} uid={user?.uid} />
                </>
            }


        </Layout>
    )
}

export default BillingData