import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../components/Button'
import Divider from '../../components/Divider/Divider'
import FloatBox from '../../components/FloatBox/FloatBox'
import IconButton from '../../components/IconButton'
import Layout from '../../components/Layout'
import InputBar from '../../components/InputBar'
import TextCategory from './TextCategory'
import Text from '../../components/Text/Text'
import Cross from '../../assets/icons/Cross'
import Email from '../../assets/icons/Email'
import { isValidEmail } from '../../services/EmailValidator'
import { MARGIN } from '../../Constants'
import { setNewPermissions } from '../../store/permissions/permissions.slice'


const FlexBoxAddUser = ({ peoplePage, isAddUserOpen, closeAddUsers }) => {
    const dispatch = useDispatch()

    const [currentEmailToAdd, setCurrentEmailToAdd] = useState('')
    const [currentEmailToAddError, setCurrentEmailToAddError] = useState(false)
    const [usersToAdd, setUsersToAdd] = useState([])


    const handleAddUser = () => {
        if (isValidEmail(currentEmailToAdd)) {
            setCurrentEmailToAddError(false)
            setUsersToAdd([...usersToAdd, currentEmailToAdd])
            setCurrentEmailToAdd('')
        } else {
            setCurrentEmailToAddError(true)
        }
    }

    const handleDeleteNewItem = (email) => {
        setUsersToAdd(usersToAdd.filter(e => e !== email))
    }

    const handleCloseAddUsers = () => {
        closeAddUsers()
        setCurrentEmailToAddError(false)
        setUsersToAdd([])

    }

    const handleAddUsers = () => {
        dispatch(setNewPermissions({ emails: usersToAdd, type: peoplePage }))
        handleCloseAddUsers()
    }


    return (
        <FloatBox title={`Add ${peoplePage}`} isActive={isAddUserOpen} onExit={handleCloseAddUsers} style={{maxWidth: '600px'}}>
            <Layout row fullWidth justifyContent='flex-start'>
                <InputBar style={{width: '100%'}} Icon={Email} hint='Email' error={currentEmailToAddError} errorMsg='Email is invalid' value={currentEmailToAdd} onValueChange={setCurrentEmailToAdd} onEnterPress={handleAddUser} />
                <Button text="Add" onClick={handleAddUser} />
            </Layout>
            <div style={{ "height": MARGIN }}></div>
            <Layout fullWidth row justifyContent='start'>
                <TextCategory text='Emails to Add' width={"25%"} color='darkGray' type='medium' />
            </Layout>
            <Divider margin={[0]} color='#D4D4D4' />
            <div style={{ "height": MARGIN/2 }}></div>
            <Layout fullWidth scroll justifyContent='flex-start' alignItems='flex-start' margin={[MARGIN/2, 0]}>
                {usersToAdd.map((email) => {
                    return (
                        <Layout fullWidth row justifyContent='start' key={email}>
                            <IconButton Icon={Cross} size={'small'} type='white' onClick={() => handleDeleteNewItem(email)} />
                            <div style={{ "width": MARGIN }}></div>
                            <Text text={email} />

                        </Layout>

                    )
                })}


            </Layout>
            <Layout row margin={[MARGIN]}>
                <Button text="Cancel" type='secondary' onClick={handleCloseAddUsers} />
                <Button text="Done" onClick={handleAddUsers} />
            </Layout>
        </FloatBox>
    )
}

export default FlexBoxAddUser