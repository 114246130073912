import React from 'react'

const Location = ({ size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.657 5.30377C14.533 2.23077 9.46802 2.23077 6.34402 5.30377C5.6023 6.02841 5.01293 6.894 4.61053 7.84968C4.20814 8.80536 4.00085 9.83184 4.00085 10.8688C4.00085 11.9057 4.20814 12.9322 4.61053 13.8879C5.01293 14.8435 5.6023 15.7091 6.34402 16.4338L12 21.9988L17.657 16.4338C18.3987 15.7091 18.9881 14.8435 19.3905 13.8879C19.7929 12.9322 20.0002 11.9057 20.0002 10.8688C20.0002 9.83184 19.7929 8.80536 19.3905 7.84968C18.9881 6.894 18.3987 6.02841 17.657 5.30377ZM12 13.4988C11.332 13.4988 10.705 13.2388 10.232 12.7668C9.76377 12.2975 9.50079 11.6617 9.50079 10.9988C9.50079 10.3359 9.76377 9.70002 10.232 9.23077C10.704 8.75877 11.332 8.49877 12 8.49877C12.668 8.49877 13.296 8.75877 13.768 9.23077C14.2363 9.70002 14.4992 10.3359 14.4992 10.9988C14.4992 11.6617 14.2363 12.2975 13.768 12.7668C13.296 13.2388 12.668 13.4988 12 13.4988Z" fill="black" />
        </svg>
    )
}

export default Location