import React from 'react'
import styles from './Marketing.module.css'
import Layout from '../../components/Layout'
import Button from '../../components/Button'
import TicketPopup from './TicketPopup'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import Divider from '../../components/Divider/Divider'
import TicketsView from './TicketsView'
import DeliverablesView from './DeliverablesView'
import PerformanceView from './PerformanceView'
import FunnelsView from './FunnelsView'
import FunnelsPopup from './FunnelPopup'
import PerformancePopup from './PerformancePopup'
import FunnelsAnalyticsPopup from './FunnelAnalyticsPopup'

const Marketing = ({ view = 'performance' }) => {
    const navigate = useNavigate()
    const [searchParams,] = useSearchParams()

    const fid = searchParams.get('fid')

    let currView = null
    if (view === 'tickets') {
        currView = <TicketsView />
    } else if (view === 'deliverables') {
        currView = <DeliverablesView />
    } else if (view === 'performance') {
        currView = <PerformanceView />
    } else if (view === 'funnels') {
        currView = <FunnelsView />
    }

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <Layout row fullWidth justifyContent='space-between'>
                <Layout row gap={16}>
                    <Button
                        text={'Dashboard'}
                        selected={view === 'performance'}
                        type={'nav'}
                        onClick={() => {
                            navigate('/marketing/performance')
                        }} />
                    <Button
                        text={'Funnels'}
                        selected={view === 'funnels'}
                        type={'nav'}
                        onClick={() => {
                            navigate('/marketing/funnels')
                        }} />
                    <Button
                        text={'Tickets'}
                        selected={view === 'tickets'}
                        type={'nav'}
                        onClick={() => {
                            navigate('/marketing/tickets')
                        }} />
                    <Button
                        text={'Deliverables'}
                        selected={view === 'deliverables'}
                        type={'nav'}
                        onClick={() => {
                            navigate('/marketing/deliverables')
                        }} />
                </Layout>
                <Layout row gap={16}>
                    <Button text={'Add Funnel'} onClick={() => navigate({ search: createSearchParams({ fid: 'add' }).toString() })} />
                    <Button text={'Add Ticket'} onClick={() => navigate({ search: createSearchParams({ tid: 'add' }).toString() })} />
                </Layout>
            </Layout>
            <Divider color={'#ccc'} />
            {currView}
            <TicketPopup />
            <FunnelsPopup fid={fid} />
            <FunnelsAnalyticsPopup />
            <PerformancePopup />
        </Layout>
    )
}

export default Marketing