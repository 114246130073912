import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Status from '../status'
import {
    fetchLastInsightsFulfilled,
    fetchLastInsightsLoading,
    fetchLastInsightsRejected,
    fetchInsightsCountFulfilled,
    fetchInsightsByContextFulfilled,
    fetchInsightsByContextLoading,
    fetchInsightsByContextRejected,
    fetchInsightFulfilled,
} from './insights.actions'
import FunctionsService from '../../services/FunctionsService'


export const fetchLastInsights = createAsyncThunk('insights/fetchLastInsights', async (_, { getState }) => {
    const data = getState().insights.lastInsights
    if (data.length === 0) {
        return FunctionsService.getLastInsights()
    } else {
        return data
    }
})

export const fetchInsightsByContext = createAsyncThunk('insights/fetchInsightsByContext', async ({ courseId, lessonId }) => {
    return FunctionsService.getInsightsByContext(courseId, lessonId)

})

export const fetchInsightsCount = createAsyncThunk('insights/fetchInsightsCount', async (_, { getState }) => {
    const data = getState().insights.insightsCount
    if (data === 0) {
        return FunctionsService.getInsightsCount()
    } else {
        return data
    }
})

export const fetchInsight = createAsyncThunk('insights/fetchInsight', async ({ id }, { getState }) => {
    let data = getState().insights.insight
    if (data.id === id) {
        return data
    } else {
        data = getState().insights.lastInsights.find(i => i.id === id)
        if (data !== undefined) {
            return data
        }
        data = getState().insights.insightsByContext.find(i => i.id === id)
        if (data !== undefined) {
            return data
        } else {
            return FunctionsService.getInsight(id)
        }
    }
})

export const insightsSlice = createSlice({
    name: 'insights',
    initialState: {
        lastInsights: [],
        insightsByContext: [],
        insight: {},
        insightsCount: 0,
        fetchLastStatus: Status.None,
        fetchByContextStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLastInsights.pending, fetchLastInsightsLoading)
            .addCase(fetchLastInsights.fulfilled, fetchLastInsightsFulfilled)
            .addCase(fetchLastInsights.rejected, fetchLastInsightsRejected)
            .addCase(fetchInsightsCount.fulfilled, fetchInsightsCountFulfilled)
            .addCase(fetchInsightsByContext.pending, fetchInsightsByContextLoading)
            .addCase(fetchInsightsByContext.fulfilled, fetchInsightsByContextFulfilled)
            .addCase(fetchInsightsByContext.rejected, fetchInsightsByContextRejected)
            .addCase(fetchInsight.fulfilled, fetchInsightFulfilled)
    },
})

export default insightsSlice.reducer