import React from 'react'

const Search = ({size, color}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.36">
            <path d="M8.33332 15C9.81247 14.9997 11.249 14.5045 12.4142 13.5933L16.0775 17.2567L17.2558 16.0783L13.5925 12.415C14.5042 11.2497 14.9996 9.81285 15 8.33332C15 4.65749 12.0092 1.66666 8.33332 1.66666C4.65749 1.66666 1.66666 4.65749 1.66666 8.33332C1.66666 12.0092 4.65749 15 8.33332 15ZM8.33332 3.33332C11.0908 3.33332 13.3333 5.57582 13.3333 8.33332C13.3333 11.0908 11.0908 13.3333 8.33332 13.3333C5.57582 13.3333 3.33332 11.0908 3.33332 8.33332C3.33332 5.57582 5.57582 3.33332 8.33332 3.33332Z" fill={color}/>
            </g>
        </svg>
    )
}

export default Search

