import React, { useState } from 'react'
import styles from './Customers.module.css'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import Button from '../../components/Button'
import FunctionsService from '../../services/FunctionsService'
import Divider from '../../components/Divider/Divider'
import IconButton from '../../components/IconButton/IconButton'
import Delete from '../../assets/icons/Delete'
import { CSVLink } from "react-csv";
import Filter from '../../assets/icons/Filter'
import { getOnboardString } from '../../util/helpers'


const CreateCustomersReport = ({ setCustomersData, data }) => {
    const tommorow = new Date()
    tommorow.setDate(tommorow.getDate() + 1)
    const tommorowString = `${tommorow.getFullYear()}-${String(tommorow.getMonth() + 1).padStart(2, '0')}-${String(tommorow.getDate()).padStart(2, '0')}`

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const yesterdayString = `${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`

    const [startDateString, setStartDateString] = useState(yesterdayString)
    const [endDateString, setEndDateString] = useState(tommorowString)
    const [loading, setLoading] = useState(false)


    const handleClick = () => {
        if (startDateString) {
            const startTime = new Date(startDateString).getTime() / 1000;
            const endTime = new Date(endDateString).getTime() / 1000;
            setLoading(true)
            FunctionsService.getNewCustomersData(startTime, endTime)
                .then((d) => {
                    d = d.map(c => {
                        return {
                            ...c,
                            exposureWay: `${c.exposureWay?.how ?? ''}/${c.exposureWay?.more ?? ''}/${c.exposureWay?.info ?? ''}`,
                            registerAt: new Date(c.userCreated * 1000).toLocaleString(),
                            premiumAt: new Date(c.customerCreated * 1000).toLocaleString(),
                        }
                    })
                    setCustomersData(d)
                    setLoading(false)
                })
        }
    }

    return (
        <Layout fullWidth alignItems='flex-start' gap={8}>
            <Layout justifyContent='flex-start' row style={{ flexWrap: 'wrap', gap: '16px 32px' }} margin={[0, 0, 16, 0]} >
                <Layout row gap={16}>
                    <Text text='Start date:' />
                    <input
                        type='date'
                        onChange={e => setStartDateString(e.target.value)}
                        value={yesterdayString}
                        min={'2022-01-01'} />
                </Layout>
                <Layout row gap={16}>
                    <Text text='End date:' />
                    <input
                        type='date'
                        onChange={e => setEndDateString(e.target.value)}
                        value={tommorowString}
                        min={'2022-01-01'} />
                </Layout>
            </Layout>
            <Layout row spacing={32}>
                <Button text={'Generate'} onClick={handleClick} disabled={loading} />
                <CSVLink data={data}>Download me</CSVLink>
            </Layout>
        </Layout>
    )
}

const Customers = () => {
    const [data, setData] = useState([])
    const [showFilter, setShowFilter] = useState()
    const [exposureFilter, setExposureFilter] = useState([])
    const [countryFilter, setCountryFilter] = useState([])

    const deleteCustomerFromData = (uid) => {
        setData(d => d.filter(c => c.uid !== uid))
    }

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <CreateCustomersReport setCustomersData={(d) => {
                setData(d)
                setExposureFilter([])
                setCountryFilter([])
                setShowFilter(null)
            }} data={data} />
            <Divider color={'rgba(0, 0, 0, 0.3)'} />
            <Layout fullWidth style={{ height: '100%', overflow: 'auto' }} justifyContent='flex-start'>
                <table className={styles.table}>
                    <thead>
                        <tr className={styles.tableRow}>
                            <th>#</th>
                            <th>Actions</th>
                            <th>UID</th>
                            <th>Customer ID</th>
                            <th>Register At</th>
                            <th>Premium At</th>
                            <th>
                                <Layout row justifyContent='space-between' style={{ position: 'relative' }}>
                                    Country
                                    <IconButton Icon={Filter} size={'small'} onClick={() => setShowFilter(o => o === 'country' ? null : 'country')} />
                                    {
                                        showFilter === 'country' ?
                                            <Layout classes={[styles.filterMenu]}>
                                                {
                                                    data.map(d => d.country)
                                                        .filter((value, index, arr) => arr.indexOf(value) === index)
                                                        .sort()
                                                        .map(d =>
                                                            <Layout row>
                                                                <input defaultChecked={countryFilter.includes(d)} type='checkbox' onClick={e => setCountryFilter(o => e.target.checked ? o.concat([d]) : o.filter(dd => dd !== d))} />
                                                                <Text text={d} type='medium' />
                                                            </Layout>)
                                                }
                                            </Layout> :
                                            null}
                                </Layout>
                            </th>
                            <th>
                                <Layout row justifyContent='space-between' style={{ position: 'relative' }}>
                                    Exposure Way
                                    <IconButton Icon={Filter} size={'small'} onClick={() => setShowFilter(o => o === 'exposure' ? null : 'exposure')} />
                                    {
                                        showFilter === 'exposure' ?
                                            <Layout classes={[styles.filterMenu]}>
                                                {
                                                    data.map(d => d.exposureWay)
                                                        .filter((value, index, arr) => arr.indexOf(value) === index)
                                                        .sort()
                                                        .map(d =>
                                                            <Layout row>
                                                                <input defaultChecked={exposureFilter.includes(d)} type='checkbox' onClick={e => setExposureFilter(o => e.target.checked ? o.concat([d]) : o.filter(dd => dd !== d))} />
                                                                <Text text={d} type='medium' />
                                                            </Layout>)
                                                }
                                            </Layout> :
                                            null}
                                </Layout>
                            </th>
                            <th>Promo Code</th>
                            <th>From</th>
                            <th>Ref</th>
                            <th>Amount Paid</th>
                            <th>Onboard</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            data
                                .filter(d => exposureFilter.length > 0 ? exposureFilter.includes(d.exposureWay) : true)
                                .filter(d => countryFilter.length > 0 ? countryFilter.includes(d.country) : true)
                                .map((d, i) => <tr key={d.uid + i.toString()} className={styles.tableRow}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <IconButton Icon={Delete} size={'small'} onClick={() => deleteCustomerFromData(d.uid)} />
                                    </td>
                                    <td onClick={() => window.open('/users/' + d.uid, '_blank')}>{d.uid}</td>
                                    <td>{d.sid}</td>
                                    <td>{d.registerAt}</td>
                                    <td>{d.premiumAt}</td>
                                    <td>{d.country}</td>
                                    <td>{d.exposureWay}</td>
                                    <td>{d.promoCode}</td>
                                    <td>{d.from}</td>
                                    <td>{d.ref}</td>
                                    <td>{d.amountPaid}</td>
                                    <td>{getOnboardString(d.onboard)}</td>
                                </tr>)
                        }
                    </tbody>
                </table>
            </Layout>
        </Layout>
    )
}

export default Customers