import React from 'react'

const Feedbacks = ({ size = 24, color = "black" }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.77 12.4C14.92 12.47 15.09 12.5 15.25 12.5C15.58 12.5 15.89 12.37 16.13 12.14L18.31 10H19.25C20.77 10 22 8.77 22 7.25V4.75C22 3.23 20.77 2 19.25 2H14.75C13.23 2 12 3.23 12 4.75V7.25C12 8.51 12.85 9.57 14 9.9V11.25C14 11.75 14.31 12.2 14.77 12.4ZM13.5 4.75C13.5 4.06 14.06 3.5 14.75 3.5H19.25C19.94 3.5 20.5 4.06 20.5 4.75V7.25C20.5 7.94 19.94 8.5 19.25 8.5H17.69L15.5 10.65V8.5H14.75C14.06 8.5 13.5 7.94 13.5 7.25V4.75ZM8 13.5C6.07 13.5 4.5 11.93 4.5 10C4.5 8.07 6.07 6.5 8 6.5C9.93 6.5 11.5 8.07 11.5 10C11.5 11.93 9.93 13.5 8 13.5ZM8 8C6.9 8 6 8.9 6 10C6 11.1 6.9 12 8 12C9.1 12 10 11.1 10 10C10 8.9 9.1 8 8 8ZM8 22C5.94 22 4.36 21.44 3.3 20.33C1.964 18.926 1.997 17.156 2 16.973V16.96C2 15.89 2.9 15 4 15H12C13.1 15 14 15.9 14 17L14.001 17.006C14.004 17.133 14.046 18.916 12.701 20.33C11.64 21.44 10.06 22 8 22ZM4 16.5C3.72 16.5 3.5 16.72 3.5 17V17.005C3.5 17.1 3.483 18.353 4.4 19.31C5.16 20.1 6.37 20.5 8 20.5C9.63 20.5 10.85 20.09 11.61 19.29C12.523 18.338 12.502 17.098 12.5 17.032V17.03C12.5 16.72 12.27 16.49 12 16.49H4V16.5Z" fill={color} />
        </svg>
    )
}

export default Feedbacks