import React from 'react'

const CodeSubmission = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="32" cy="32" r="32" fill="#057EFB" />
            <path d="M25.8011 38.9736L18.4753 31.9999L25.8011 25.0263C25.9751 24.8626 26.1133 24.6677 26.2076 24.4529C26.3019 24.238 26.3505 24.0075 26.3505 23.7746C26.3505 23.5417 26.3019 23.3112 26.2076 23.0963C26.1133 22.8815 25.9751 22.6866 25.8011 22.5229C25.6292 22.3573 25.4245 22.2257 25.1987 22.136C24.973 22.0462 24.7308 22 24.4862 22C24.2416 22 23.9994 22.0462 23.7737 22.136C23.548 22.2257 23.3432 22.3573 23.1713 22.5229L14.5493 30.7304C14.3752 30.8958 14.237 31.0923 14.1428 31.3086C14.0485 31.5249 14 31.7568 14 31.991C14 32.2252 14.0485 32.4571 14.1428 32.6734C14.237 32.8897 14.3752 33.0862 14.5493 33.2516L23.1713 41.477C23.9039 42.1743 25.0685 42.1743 25.8011 41.477C25.9751 41.3133 26.1133 41.1184 26.2076 40.9036C26.3019 40.6887 26.3505 40.4582 26.3505 40.2253C26.3505 39.9924 26.3019 39.7619 26.2076 39.547C26.1133 39.3322 25.9751 39.1373 25.8011 38.9736ZM38.1988 38.9736L45.5246 31.9999L38.1988 25.0263C38.0247 24.8626 37.8866 24.6677 37.7923 24.4529C37.698 24.238 37.6494 24.0075 37.6494 23.7746C37.6494 23.5417 37.698 23.3112 37.7923 23.0963C37.8866 22.8815 38.0247 22.6866 38.1988 22.5229C38.3707 22.3573 38.5754 22.2257 38.8011 22.136C39.0269 22.0462 39.269 22 39.5137 22C39.7583 22 40.0005 22.0462 40.2262 22.136C40.4519 22.2257 40.6566 22.3573 40.8286 22.5229L49.4506 30.7304C50.1831 31.4277 50.1831 32.5543 49.4506 33.2516L40.8286 41.477C40.6566 41.6426 40.4519 41.7741 40.2262 41.8639C40.0005 41.9537 39.7583 41.9999 39.5137 41.9999C39.269 41.9999 39.0269 41.9537 38.8011 41.8639C38.5754 41.7741 38.3707 41.6426 38.1988 41.477C38.0247 41.3133 37.8866 41.1184 37.7923 40.9036C37.698 40.6887 37.6494 40.4582 37.6494 40.2253C37.6494 39.9924 37.698 39.7619 37.7923 39.547C37.8866 39.3322 38.0247 39.1373 38.1988 38.9736Z" fill="white" />
        </svg>

    )
}

export default CodeSubmission