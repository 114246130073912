import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import styles from './PerformancePopup.module.css'
import Text from '../../components/Text'
import Button from '../../components/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { saveMarketingPerformance } from '../../store/marketing/marketing.slice'
import Status from '../../store/status'
import Divider from '../../components/Divider/Divider'

const PerformancePopup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams,] = useSearchParams()
    const performance = useSelector(state => state.marketing.performance)
    const saveStatus = useSelector(state => state.marketing.saveStatus)
    const [estimated, setEstimated] = useState({})

    const pid = searchParams.get('pid')

    const selectedPerformance = performance.find(p => p.key === pid)
    const [targetPrice, setTargetPrice] = useState(0)

    const handleEditTargetPrice = (s) => {
        setTargetPrice(s)
    }

    const handleClose = () => {
        searchParams.delete('pid');
        navigate({ search: searchParams.toString() })
    }

    const handleSave = () => {
        dispatch(saveMarketingPerformance({ pid, targetPrice: Number(targetPrice) }))
    }

    const handleCalculateEstimated = () => {
        if (selectedPerformance?.revenueInfo?.funnels === undefined) return
        let undefinedAmount = selectedPerformance.revenueInfo.funnels['undefined']?.amount ?? 0
        let totalAmount = selectedPerformance.revenueInfo?.total?.amount ?? 0
        const res = {}

        // calculate facebook ads
        const undefinedAmountUS = (selectedPerformance.revenueInfo.funnels['undefined']?.details['undefined_us']?.amount ?? 0) +
            (selectedPerformance.revenueInfo.funnels['undefined']?.details['instagram_us']?.amount ?? 0)

        const undefinedPart = undefinedAmountUS / undefinedAmount
        const fbPart = selectedPerformance.revenueInfo.funnels['facebook_ads']?.amount / (totalAmount - undefinedAmount)
        const assumedPart = Math.min(1, fbPart / undefinedPart)

        res['facebook_ads'] = (selectedPerformance.revenueInfo.funnels['facebook_ads']?.amount / 100 + ((assumedPart * undefinedAmountUS) / 100)).toFixed(2)
        undefinedAmount -= assumedPart * undefinedAmountUS;
        totalAmount -= (selectedPerformance.revenueInfo.funnels['facebook_ads']?.amount + assumedPart * undefinedAmountUS)

        // calculate other
        Object.keys(selectedPerformance?.revenueInfo?.funnels ?? {}).forEach(fid => {
            if (fid === 'facebook_ads') return;

            const a = (selectedPerformance.revenueInfo.funnels[fid]?.amount ?? 0)
            const part = a / (totalAmount - undefinedAmount)
            res[fid] = (((undefinedAmount * part) / 100) + (a / 100)).toFixed(2)
        })

        res['undefined'] = 0

        setEstimated(res)
    }

    useEffect(() => {
        setTargetPrice(selectedPerformance?.targetPrice ?? 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pid])

    useEffect(() => {
        handleCalculateEstimated()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pid])

    const orderedFunnels = Object.keys(selectedPerformance?.revenueInfo?.funnels ?? {})
    orderedFunnels.sort((a, b) => (estimated[b] ?? 0) - (estimated[a] ?? 0))

    return (
        <Layout classes={[styles.backdrop, !pid ? null : styles.display]} alignItems='flex-end' onClick={handleClose}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[32, 32, 0, 32]} justifyContent='flex-start' gap={32}>
                <Layout fullWidth gap={16}>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text='Month:' />
                        <input className={styles.input} value={pid} disabled />
                    </Layout>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text='Target budget (USD):' />
                        <input className={styles.input} value={targetPrice} type='number' onChange={(e) => handleEditTargetPrice(e.target.value)} />
                    </Layout>
                    <Layout row classes={[styles.bottomContainer]} fullWidth justifyContent='space-between' gap={32}>
                        <Button
                            text={'Save'}
                            disabled={saveStatus === Status.Loading}
                            onClick={handleSave} />
                    </Layout>
                </Layout>
                <Divider />
                <Layout fullWidth gap={16}>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text='Budget:' color='darkGray' />
                        <Text text={`$${selectedPerformance?.price ?? 0}`} />
                    </Layout>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text='Deliverables:' color='darkGray' />
                        <Text text={selectedPerformance?.deliverables?.length ?? 0} />
                    </Layout>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text='Average p/Deliverable:' color='darkGray' />
                        <Text text={`$${((selectedPerformance?.price ?? 0) / (selectedPerformance?.deliverables?.length ?? 0)).toFixed(2)}`} />
                    </Layout>
                </Layout>
                <Divider />
                <Layout fullWidth gap={16}>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text='Revenue:' color='darkGray' />
                        <Text text={`$${selectedPerformance?.revenue ?? 0}`} />
                    </Layout>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text='Customers:' color='darkGray' />
                        <Text text={selectedPerformance?.revenueInfo?.total?.customers ?? 0} />
                    </Layout>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text='Average p/Customer:' color='darkGray' />
                        <Text text={`$${((selectedPerformance?.revenue ?? 0) / (selectedPerformance?.revenueInfo?.total?.customers ?? 0)).toFixed(2)}`} />
                    </Layout>
                </Layout>
                <Divider />
                <Layout fullWidth gap={16} alignItems='flex-start'>
                    <Layout row fullWidth justifyContent='space-between'>
                        <Text text='Funnels' type='title' />
                    </Layout>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th className={styles.fid}>FID</th>
                                <th className={styles.revenue}>Revenue (Est.)</th>
                                <th className={styles.revenue}>Revenue</th>
                                <th className={styles.customers}>Customers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderedFunnels.map(fid => <tr>
                                    <td className={styles.fid}>{fid}</td>
                                    <td className={styles.revenue}>{`$${estimated[fid] ?? 0}`}</td>
                                    <td className={styles.revenue}>{`$${selectedPerformance?.revenueInfo?.funnels[fid].amount / 100}`}</td>
                                    <td className={styles.customers}>{selectedPerformance?.revenueInfo?.funnels[fid].customers}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default PerformancePopup