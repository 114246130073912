import React, { useEffect, useState } from 'react'
import styles from './Challenges.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { fetchLastGeneratedChallenges, fetchChallengesData } from '../../store/challenges/challenges.slice'
import Status from '../../store/status'
import Text from '../../components/Text/Text'

const Challenges = () => {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState()
    const lastGenerated = useSelector(state => state.challenges.lastGenerated)
    const data = useSelector(state => state.challenges.data)
    const fetchLastGeneratedStatus = useSelector(state => state.challenges.fetchLastGeneratedStatus)

    console.log(data);
    useEffect(() => {
        dispatch(fetchLastGeneratedChallenges())
        dispatch(fetchChallengesData())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <Layout row justifyContent='flex-start' gap={32}>
                <Layout
                    alignItems='flex-start'
                    classes={[styles.card, selected === 'challenges' ? styles.selected : null]}
                    onClick={() => setSelected(o => o === 'challenges' ? null : 'challenges')}>
                    <Text text={Object.values(data).reduce((p, c) => p + c.num, 0)} type='title' bold />
                    <Text text='Challenges' />
                </Layout>
                <Layout
                    alignItems='flex-start'
                    classes={[styles.card, selected === 'generated' ? styles.selected : null]}
                    onClick={() => setSelected(o => o === 'generated' ? null : 'generated')}>
                    <Text text={Object.values(data).reduce((p, c) => p + c.generated, 0)} type='title' bold />
                    <Text text='Generated' />
                </Layout>
            </Layout>
            {
                selected ?
                    <Layout fullWidth row alignItems='flex-start' justifyContent='flex-start' gap={32}>
                        {
                            ['python', 'js', 'cpp', 'java', 'csharp'].map(l =>
                                <Layout key={l} alignItems='flex-start' style={{ width: '160px' }}>
                                    <Text text={LANGS[l]} />
                                    <Text
                                        text={
                                            selected === 'generated' ?
                                                Object.values(data.filter(d => d.lang === l)).reduce((p, c) => p + c.generated, 0) :
                                                Object.values(data.filter(d => d.lang === l)).reduce((p, c) => p + c.num, 0)
                                        }
                                        type='title'
                                        bold />
                                    <Text text='Diff' type='small' color='darkGray' />
                                    <Text
                                        text={`${
                                            selected === 'generated' ?
                                                (data.find(d => d.lang === l && d.diff === 0)?.generated ?? 0) :
                                                (data.find(d => d.lang === l && d.diff === 0)?.num ?? 0)
                                        }/${
                                            selected === 'generated' ?
                                                (data.find(d => d.lang === l && d.diff === 1)?.generated ?? 0) :
                                                (data.find(d => d.lang === l && d.diff === 1)?.num ?? 0)
                                        }/${
                                            selected === 'generated' ?
                                                (data.find(d => d.lang === l && d.diff === 2)?.generated ?? 0) :
                                                (data.find(d => d.lang === l && d.diff === 2)?.num ?? 0)
                                        }/${
                                            selected === 'generated' ?
                                                (data.find(d => d.lang === l && d.diff === 3)?.generated ?? 0) :
                                                (data.find(d => d.lang === l && d.diff === 3)?.num ?? 0)
                                        }`}
                                        type='normal'
                                        bold />
                                </Layout>)
                        }
                    </Layout> :
                    null
            }
            <table className={styles.table} >
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.uid}>UID</th>
                    <th className={styles.totalGenerated}>#</th>
                    <th className={styles.cid}>Challenge ID</th>
                    <th className={styles.lang}>Language</th>
                    <th className={styles.diff}>Diff</th>
                    <th className={styles.diff}>Feedback</th>
                    <th className={styles.diff}>Done</th>
                    <th className={styles.time}>Time</th>
                </tr>
                {
                    fetchLastGeneratedStatus === Status.Success ?
                        lastGenerated.map((d, i) =>
                            <RowView
                                key={`d${i}`}
                                data={d} />
                        ) :
                        <Text text='Loading...' />
                }
            </table>
        </Layout>
    )
}

const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR

const DIFF = ['-', 'Easy', 'Medium', 'Hard', 'Expert']
const LANGS = {
    'python': 'Python',
    'js': 'JavaScript',
    'cpp': 'C++',
    'java': 'Java',
    'csharp': 'C#'
}

const RowView = ({ data }) => {
    let lastActivity = ''
    if (data?.timestamp !== undefined) {
        const time = Date.parse(data.timestamp.value + '+00:00')
        const now = Date.now()
        const diff = now - time;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    return (
        <tr className={styles.tableRow} onClick={() => { window.open(`/users/${data.user_id}`, '_blank').focus() }}>
            <td className={styles.uid}>{data.user_id}</td>
            <td className={styles.totalGenerated}>{data.total_generated}</td>
            <td className={styles.cid}>{data.challenge_id}</td>
            <td className={styles.lang}>{LANGS[data.lang]}</td>
            <td className={styles.diff}>{DIFF[data.diff]}</td>
            <td className={styles.diff}>{data.feedback}</td>
            <td className={styles.diff}>{data.done ? '✔️' : '❌'}</td>
            <td className={styles.time}>{lastActivity}</td>
        </tr>
    )
}

export default Challenges