import React from 'react'

const LearningGoals = ({ size = 24, intensity = 0 }) => {

    const color = 'black'
    let text = ''
    switch (intensity) {
        case 1:
            text = '🟢'
            break;
        case 2:
            text = '🟡'
            break;
        case 3:
            text = '🟠'
            break;
        case 4:
            text = '🔴'
            break;
        default:
            break;
    }

    return (
        <div style={{ position: 'relative' }}>
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 13V2L20 6L12 10" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.5499 10.2299C21.0821 11.8701 21.1319 13.6286 20.6934 15.2962C20.2549 16.9638 19.3466 18.4704 18.0765 19.6366C16.8064 20.8029 15.2281 21.5797 13.5292 21.8748C11.8303 22.1698 10.0824 21.9706 8.4935 21.3008C6.90457 20.6311 5.54149 19.519 4.56643 18.0968C3.59136 16.6747 3.04534 15.0024 2.99335 13.2789C2.94136 11.5553 3.38557 9.85316 4.27314 8.37483C5.16071 6.8965 6.45428 5.70425 7.99994 4.93994" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.99994 10C7.49885 10.667 7.17262 11.4488 7.05099 12.2742C6.92935 13.0996 7.01617 13.9422 7.30354 14.7254C7.59092 15.5087 8.0697 16.2075 8.69627 16.7584C9.32284 17.3092 10.0773 17.6945 10.8909 17.8792C11.7045 18.0638 12.5513 18.042 13.3543 17.8156C14.1573 17.5892 14.8908 17.1655 15.4882 16.5831C16.0855 16.0007 16.5276 15.278 16.7742 14.481C17.0208 13.684 17.064 12.838 16.8999 12.02" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p style={{ position: 'absolute', margin: 0, padding: 0, fontSize: '8px', bottom: 0, left: 0 }}>{text}</p>
        </div>
    )
}

export default LearningGoals