import React from 'react'

const Referral = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3771_567)">
                <path d="M9.6924 0.144043C6.90748 0.144043 4.67271 2.05666 4.67271 5.71204C4.67271 8.09912 5.62348 10.5305 7.06717 12.0287C7.63025 13.5268 6.61486 14.0843 6.4044 14.1637C3.49025 15.2327 0.0859375 17.1711 0.0859375 19.0967V19.8167C0.0859375 22.441 5.08348 23.0474 9.72009 23.0474C10.7307 23.0457 11.7409 23.0076 12.7487 22.933C11.4867 21.6009 10.7848 19.8349 10.7881 18C10.7881 16.3468 11.3512 14.832 12.2872 13.6154C12.1487 13.2905 12.0933 12.7782 12.3453 12C13.7807 10.5 14.7112 8.08989 14.7112 5.71112C14.7112 2.05666 12.4736 0.144966 9.69148 0.144966L9.6924 0.144043ZM18.0001 12.1727C16.4547 12.1727 14.9726 12.7866 13.8798 13.8793C12.7871 14.9721 12.1732 16.4542 12.1732 17.9996C12.1732 19.545 12.7871 21.0271 13.8798 22.1198C14.9726 23.2126 16.4547 23.8265 18.0001 23.8265C19.5455 23.8265 21.0276 23.2126 22.1203 22.1198C23.2131 21.0271 23.827 19.545 23.827 17.9996C23.827 16.4542 23.2131 14.9721 22.1203 13.8793C21.0276 12.7866 19.5455 12.1727 18.0001 12.1727ZM17.1924 14.7693H18.8078V17.1637H21.2022V18.8363H18.8078V21.2308H17.1924V18.8363H14.7693V17.1637H17.1924V14.7693Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_3771_567">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Referral