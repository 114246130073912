import React from 'react'

const Reload = ({ className, size = 24, color = "#262F56" }) => {
    return (
        <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.07178 17C7.28092 20.8263 12.1736 22.1373 16 19.9282C19.8263 17.7191 21.1373 12.8263 18.9282 9C16.719 5.17366 11.8263 3.86266 7.99998 6.0718L5.4019 7.5718M5.4019 7.5718L6.49998 3.47372M5.4019 7.5718L9.49998 8.66988" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Reload