import React from 'react'

const ThumbsUp = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.3 10.08C21.019 9.74269 20.6674 9.47113 20.27 9.28452C19.8726 9.0979 19.439 9.0008 19 9.00004H14.44L15 7.57004C15.2329 6.94393 15.3105 6.27065 15.2261 5.60797C15.1416 4.94528 14.8977 4.31297 14.5152 3.76527C14.1327 3.21758 13.623 2.77084 13.0299 2.46339C12.4369 2.15593 11.778 1.99694 11.11 2.00004C10.9176 2.00045 10.7295 2.05632 10.5681 2.16097C10.4067 2.26561 10.2789 2.41459 10.2 2.59004L7.35 9.00004H5C4.20435 9.00004 3.44129 9.31611 2.87868 9.87872C2.31607 10.4413 2 11.2044 2 12V19C2 19.7957 2.31607 20.5588 2.87868 21.1214C3.44129 21.684 4.20435 22 5 22H17.73C18.4318 21.9998 19.1113 21.7535 19.6503 21.3041C20.1893 20.8546 20.5537 20.2304 20.68 19.54L21.95 12.54C22.0286 12.1074 22.011 11.6628 21.8987 11.2377C21.7864 10.8126 21.582 10.4174 21.3 10.08ZM7 20H5C4.73478 20 4.48043 19.8947 4.29289 19.7072C4.10536 19.5196 4 19.2653 4 19V12C4 11.7348 4.10536 11.4805 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11H7V20ZM20 12.18L18.73 19.18C18.6874 19.413 18.5635 19.6233 18.3804 19.7734C18.1973 19.9236 17.9668 20.0039 17.73 20H9V10.21L11.72 4.09004C12 4.17167 12.26 4.31045 12.4837 4.49763C12.7073 4.6848 12.8897 4.91631 13.0194 5.17754C13.1491 5.43876 13.2232 5.72404 13.2371 6.01535C13.2509 6.30666 13.2043 6.59768 13.1 6.87004L12.57 8.30005C12.4571 8.6023 12.4189 8.92739 12.4589 9.24758C12.4988 9.56776 12.6156 9.87353 12.7993 10.1388C12.983 10.404 13.2282 10.6209 13.5139 10.7709C13.7996 10.9209 14.1173 10.9995 14.44 11H19C19.1469 10.9998 19.2921 11.0319 19.4252 11.0942C19.5582 11.1564 19.676 11.2472 19.77 11.36C19.8663 11.4714 19.9369 11.6026 19.9767 11.7443C20.0164 11.8861 20.0244 12.0349 20 12.18Z" fill="black" />
        </svg>
    )
}

export default ThumbsUp