import React, { useState } from 'react'
import styles from './Coupons.module.css'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import FunctionsService from '../../services/FunctionsService'
import Button from '../../components/Button'
import Divider from '../../components/Divider/Divider'


const CreateReport = ({ addReport }) => {
    const [loading, setLoading] = useState(false)
    const [startDateString, setStartDateString] = useState()
    const [endDateString, setEndDateString] = useState()
    const [promoCode, setPromoCode] = useState()

    const handleClick = () => {
        if (startDateString && endDateString) {
            const startTime = new Date(startDateString).getTime() / 1000;
            const endTime = new Date(endDateString).getTime() / 1000;

            setLoading(true)
            FunctionsService.getCouponRevenue(promoCode, startTime, endTime)
                .then((d) => {
                    console.log(d);
                    const res = d.data;
                    res.promoCode = promoCode;
                    res.start = startTime * 1000
                    res.end = endTime * 1000
                    addReport(res)
                    setLoading(false)
                })
        }
    }

    return (
        <Layout fullWidth alignItems='flex-start' gap={8}>
            <Layout row gap={32} >
                <Layout row gap={16}>
                    <Text text='Promo code:' />
                    <input
                        onChange={e => setPromoCode(e.target.value)} />
                </Layout>
                <Layout row gap={16}>
                    <Text text='Start date:' />
                    <input
                        type='date'
                        onChange={e => setStartDateString(e.target.value)}
                        min={'2022-01-01'} />
                </Layout>
                <Layout row gap={16}>
                    <Text text='End date:' />
                    <input
                        type='date'
                        onChange={e => setEndDateString(e.target.value)}
                        min={'2022-01-01'} />
                </Layout>
            </Layout>
            <Button text={'Generate'} onClick={handleClick} disabled={loading} />
        </Layout>
    )
}


const Coupons = () => {
    const [data, setData] = useState([])

    const handleAddReport = (d) => {
        setData(o => ([d]).concat(o))
    }

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <CreateReport addReport={handleAddReport} />
            <Divider color={'rgba(0, 0, 0, 0.3)'} />
            <Layout fullWidth style={{ height: '100%', overflow: 'auto' }} justifyContent='flex-start' gap={32}>
                    {
                        data.map((d, i) => {
                            return (
                                <Layout classes={[styles.activeCard]} padding={[24]} gap={16} key={`_${i}`} alignItems='normal' justifyContent='normal'>
                                    <Layout row gap={16} alignItems='baseline' justifyContent='space-between'>
                                        <Text text={d.promoCode.toUpperCase()} bold type='subTitle'/>
                                        <Text text={`${new Date(d.start).toLocaleDateString()} - ${new Date(d.end).toLocaleDateString()}`}/>
                                    </Layout>
                                    <Layout row gap={32} justifyContent='flex-start'>
                                        <Layout>
                                            <Text text={`$${d.revenue / 100}`} type='title' bold />
                                            <Text text={`${d.payments} payments`} color='darkGray' />
                                        </Layout>
                                        <Text text='=' type='title'/>
                                        <Layout>
                                            <Text text={`$${d.create.revenue / 100}`} type='subTitle' bold />
                                            <Text text={`${d.create.payments} payments (New)`} color='darkGray' type='small' />
                                        </Layout>
                                        <Text text='+' type='title'/>
                                        <Layout>
                                            <Text text={`$${d.update.revenue / 100}`} type='subTitle' bold />
                                            <Text text={`${d.update.payments} payments (Old)`} color='darkGray' type='small' />
                                        </Layout>
                                    </Layout>
                                </Layout>
                            )
                        })
                    }
                </Layout>
        </Layout>
    )
}

export default Coupons