import React from 'react'
import styles from './Tag.module.css'

const Tag = ({ text, className, colorType = 'main' }) => {
    let colorStyle = styles.main
    if (colorType === 'success') colorStyle = styles.success
    if (colorType === 'secondary') colorStyle = styles.secondary
    if (colorType === 'error') colorStyle = styles.error
    return (
        <span className={[styles.tag, colorStyle, className].join(' ')}>
            {text}
        </span>
    )
}

export default Tag