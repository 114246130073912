import React from 'react'

const New = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4132_704)">
            <path d="M34.11 24.49L30.19 17.87L34.07 11.52C34.1621 11.3687 34.2124 11.1956 34.2157 11.0185C34.2189 10.8414 34.1751 10.6665 34.0886 10.5119C34.0022 10.3573 33.8762 10.2284 33.7236 10.1385C33.5709 10.0485 33.3972 10.0007 33.22 10H2C1.46957 10 0.960859 10.2107 0.585786 10.5858C0.210714 10.9609 0 11.4696 0 12L0 24C0 24.5304 0.210714 25.0391 0.585786 25.4142C0.960859 25.7893 1.46957 26 2 26H33.25C33.4265 26 33.5999 25.9532 33.7524 25.8645C33.905 25.7758 34.0314 25.6483 34.1188 25.4949C34.2061 25.3415 34.2513 25.1678 34.2498 24.9913C34.2482 24.8148 34.2 24.6418 34.11 24.49ZM10.51 21.18H9.39L6.13 16.84V21.19H5V15H6.13L9.4 19.35V15H10.52L10.51 21.18ZM16.84 16H13.31V17.49H16.51V18.49H13.31V20.1H16.84V21.1H12.18V15H16.83L16.84 16ZM25.13 21.16H24L22.45 16.57L20.9 21.18H19.78L17.78 15H19L20.32 19.43L21.84 15H23.06L24.52 19.43L25.85 15H27.08L25.13 21.16Z" fill="#F05C1C"/>
        </g>
        <defs>
            <clipPath id="clip0_4132_704">
                <rect width="36" height="36" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default New