import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
  addReviewersAction,
  addCreatorsAction,
  addStudentsAction,
  fetchPermissionsDataFulfilled,
  fetchPermissionsDataRejected,
  setNewPermissionsFulfilled,
  setNewPermissionsRejected,
  setResetPermissionLoading,
  setResetPermissionFulfilled,
  setResetPermissionRejected,
} from './permissions.actions'

export const setNewPermissions = createAsyncThunk('permissions/setNewPermissions', async ({ type, emails }, { _ }) => {
  let uids = [];
  for (var i  = 0; i < emails.length; i++) {
    const res = await FunctionsService.setPermission({type: type, email: emails[i]});
    if (res.data) {
      uids.push(res.data.uid)  
    }
  }
  return DatabaseService.getPermissionsData({type: type, uids: uids})

})

export const setResetPermission = createAsyncThunk('permissions/setResetPermission', async ({type, email }, { _ }) => {
  await FunctionsService.resetPermission({email: email});
  return email

})



export const fetchPermissionsData = createAsyncThunk('permissions/fetchPermissionsData', async ({type, uids=[]}, { getState }) => {
  let data;
  switch (type) {
    case 'creators':
      data = getState().permissions.creators
      break
    case 'reviewers':
      data = getState().permissions.reviewers
      break
    default:
      return
  }
 
  if (data.length === 0) {
    return DatabaseService.getPermissionsData({type: type})
  } else if (data.length > 0 && uids.length > 0){
    return DatabaseService.getPermissionsData({type: type, uids: uids})
  } else {
    return []
  }
})

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    fetchStatus: Status.None,
    loading: {},
    creators: [],
    reviewers: [],
    affiliators: []
  },
  reducers: {
    addReviewers: addReviewersAction,
    addCreators: addCreatorsAction,
    addStudents: addStudentsAction
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPermissionsData.fulfilled, fetchPermissionsDataFulfilled)
      .addCase(fetchPermissionsData.rejected, fetchPermissionsDataRejected)
      .addCase(setNewPermissions.fulfilled, setNewPermissionsFulfilled)
      .addCase(setNewPermissions.rejected, setNewPermissionsRejected)
      .addCase(setResetPermission.pending, setResetPermissionLoading)
      .addCase(setResetPermission.fulfilled, setResetPermissionFulfilled)
      .addCase(setResetPermission.rejected, setResetPermissionRejected)
  },
})

// export const {

// } = peopleSlice.actions

export default permissionsSlice.reducer


