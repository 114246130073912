import React, { useEffect, useState } from 'react'
import styles from './Royalties.module.css'
import Layout from '../../components/Layout'
import DatabaseService from '../../services/DatabaseService'
import { BounceLoader } from 'react-spinners'
import Text from '../../components/Text'
import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import Down from '../../assets/icons/Down'
import Up from '../../assets/icons/Up'
import FunctionsService from '../../services/FunctionsService'

const Report = ({ data }) => {
    const [expended, setExpended] = useState(false)

    const realTotal = Object.keys(data.payments).reduce((pv, cv) => pv + data.payments[cv].total, 0)
    const realTotalNoTeam = Object.keys(data.payments).reduce((pv, cv) => pv + (data.payments[cv].creatorInfo.creatorVisibility === 'coddy' ? 0 : data.payments[cv].total), 0)
    const sortedPayments = Object.values(data.payments).sort((a, b) => b.total - a.total)

    const handleAccept = () => {
        const d = Object.values(data.payments).map(d => {
            return {
                total: d.total,
                uid: d.creatorInfo.uid,
            }
        })
        FunctionsService.updateCreatorsPayments(d, data.id)
    }

    return (
        <Layout fullWidth style={{ background: 'white', borderRadius: '8px' }}>
            <Layout row padding={[16]} justifyContent='space-between' alignItems='flex-start' fullWidth>
                <Layout gap={8} alignItems='flex-start'>
                    <Layout row alignItems='baseline' gap={8}>
                        <IconButton Icon={expended ? Up : Down} onClick={() => setExpended(o => !o)} />
                        <Text text={`$${data.total / 100}`} type='title' bold />
                        <Text text={`/ $${realTotal / 100} (${Math.floor((realTotal / data.total) * 100)}%) / $${realTotalNoTeam / 100} (${Math.floor((realTotalNoTeam / data.total) * 100)}%)`} type='normal' color='darkGray' />
                    </Layout>
                    <Text
                        type='medium'
                        text={`${data.percentage * 100}% of the revenue between ${new Date(data.startDate).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' })} and ${new Date(data.endDate).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' })}`} />
                </Layout>
                <Button text={'ACCEPT'} onClick={handleAccept} />
            </Layout>
            {
                expended ?
                    sortedPayments.map(d =>
                        <Layout
                            row
                            fullWidth
                            justifyContent='space-between'
                            padding={[16]}
                            key={d.creatorInfo.uid}
                            style={{
                                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                                background: d.creatorInfo.creatorVisibility === 'coddy' ? 'rgba(255, 0, 0, 0.25)' : null
                            }}>
                            <Layout alignItems='flex-start'>
                                <Text text={d.creatorInfo.displayName} />
                                <Text text={d.creatorInfo.email} type='small' color='darkGray' />
                            </Layout>
                            <Text text={`$${d.total / 100}`} type='subTitle' bold />
                        </Layout>) :
                    null
            }
        </Layout>
    )
}

const Royalties = () => {
    const [data, setData] = useState()
    const [isHistory, setIsHistory] = useState(false)

    useEffect(() => {
        if (isHistory) {
            DatabaseService.getRoyaltiesHistory()
                .then(d => setData(d))
        } else {
            DatabaseService.getRoyalties()
                .then(d => setData(d))
        }
    }, [isHistory])

    const handleToggleView = (v) => {
        if (isHistory !== v) {
            setIsHistory(v)
        }
    }

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal'>
            <Layout row padding={[32]} fullWidth gap={16} justifyContent='flex-start'>
                <button
                    className={[styles.button, isHistory === false ? styles.selected : null].join(' ')}
                    onClick={() => handleToggleView(false)}>Current</button>
                <button
                    className={[styles.button, isHistory === true ? styles.selected : null].join(' ')}
                    onClick={() => handleToggleView(true)}>History</button>
            </Layout>
            <Layout style={{ height: '100%', overflow: 'auto' }} justifyContent='flex-start' padding={[32]} fullWidth gap={16}>

                {
                    data === undefined ?
                        <BounceLoader /> :
                        data.map(d => <Report key={d.id} data={d} />)
                }
            </Layout>

        </Layout>
    )
}

export default Royalties