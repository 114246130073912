import React from 'react'

const Triangles = () => {
    return (
        <svg width="225" height="375" viewBox="0 0 225 375" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.499954 0L233.894 366H-232.894L0.499954 0Z" fill="url(#paint0_linear_262_29)" fillOpacity="0.05"/>
            <path d="M222 165L461.023 375H-17.0229L222 165Z" fill="url(#paint1_linear_262_29)" fillOpacity="0.05"/>
            <defs>
            <linearGradient id="paint0_linear_262_29" x1="-79.3987" y1="49.4822" x2="298.838" y2="615.258" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_262_29" x1="471.606" y1="185.467" x2="429.522" y2="547.875" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>

    )
}
export default Triangles

