import React from 'react'
import styles from './UserInfo.module.css'
import CoddyPremium from '../../assets/icons/CoddyPremium'
import Layout from '../../components/Layout'
import Text from '../../components/Text/Text'
import { useLocation, useNavigate } from 'react-router-dom'
import ProfilePlaceholder from '../../assets/icons/ProfilePlaceholder'


const isEmpty = (s) => {
    return s === undefined || s === null || s?.length === 0
}

const UserInfo = ({ uid, finalUser }) => {
    const navigate = useNavigate()
    const currView = new URLSearchParams(useLocation().search).get('view') ?? 'main'

    const handleOpenUserProfile = () => {
        window.open(`https://coddy.tech/user/${uid}`, 'blank')
    }

    const noBio = isEmpty(finalUser?.bio)

    return (
        <Layout row justifyContent='space-between' fullWidth gap={8}>
            <Layout row gap={16} >
                <Layout style={{ width: '48px', height: '48px', borderRadius: '24px', overflow: 'hidden' }}>
                    {
                        finalUser.imagePath ?
                            <img src={finalUser.imagePath} width={48} height={48} alt='' style={{ objectFit: 'cover' }} /> :
                            <ProfilePlaceholder size={48} />}
                </Layout>
                <Layout alignItems='start'>
                    <Layout row gap={8}>
                        <Text text={finalUser?.displayName} color='darkGray' bold type='subTitle' onClick={handleOpenUserProfile} />
                        {finalUser.billingData !== undefined ? <CoddyPremium size={16} billingData={finalUser.billingData} disabled={!finalUser.isPremium} /> : null}
                    </Layout>
                    <Text text={finalUser?.email} color='darkGray' />
                    <Text text={noBio ? '-- No bio --' : finalUser?.bio} overflow color={noBio ? 'darkGray' : 'black'} />

                </Layout>

            </Layout>
            <Layout row justifyContent='flex-start' style={{ height: '100%' }} gap={8}>
                <div
                    className={[styles.button, currView === 'main' ? styles.selected : null].join(' ')}
                    onClick={() => navigate(`/users/${uid}?view=main`)}>
                    <p>Main</p>
                </div>
                <div
                    className={[styles.button, currView === 'courses' ? styles.selected : null].join(' ')}
                    onClick={() => navigate(`/users/${uid}?view=courses`)}>
                    <p>Courses</p>
                </div>
                <div
                    className={[styles.button, currView === 'challenges' ? styles.selected : null].join(' ')}
                    onClick={() => navigate(`/users/${uid}?view=challenges`)}>
                    <p>Challenges</p>
                </div>
                <div
                    className={[styles.button, currView === 'events' ? styles.selected : null].join(' ')}
                    onClick={() => navigate(`/users/${uid}?view=events`)}>
                    <p>Events</p>
                </div>
                <div
                    className={[styles.button, currView === 'billing' ? styles.selected : null].join(' ')}
                    onClick={() => navigate(`/users/${uid}?view=billing`)}>
                    <p>Billing</p>
                </div>
                <div
                    className={[styles.button, currView === 'goals' ? styles.selected : null].join(' ')}
                    onClick={() => navigate(`/users/${uid}?view=goals`)}>
                    <p>Goals</p>
                </div>
            </Layout>
        </Layout>
    )
}

export default UserInfo