import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
    fetchFunnelsOptionsFulfilled,
    fetchFunnelsOptionsLoading,
    fetchFunnelsOptionsRejected,
    saveFunnelOptionsFulfilled,
    saveFunnelOptionsLoading,
    saveFunnelOptionsRejected,
} from './funnelOptions.actions'

export const fetchFunnelsOptions = createAsyncThunk('funnelOptions/fetchFunnelsOptions', async (_) => {
    return DatabaseService.getFunnelOptions()
})

export const saveFunnelOptions = createAsyncThunk('funnelOptions/saveFunnelOptions', async ({ id, data }) => {
    return FunctionsService.updateFunnelOptions(id, data);
})

export const funnelOptionsSlice = createSlice({
    name: 'funnelOptions',
    initialState: {
        data: [],
        fetchStatus: Status.None,
        saveStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFunnelsOptions.pending, fetchFunnelsOptionsLoading)
            .addCase(fetchFunnelsOptions.fulfilled, fetchFunnelsOptionsFulfilled)
            .addCase(fetchFunnelsOptions.rejected, fetchFunnelsOptionsRejected)
            .addCase(saveFunnelOptions.pending, saveFunnelOptionsLoading)
            .addCase(saveFunnelOptions.fulfilled, saveFunnelOptionsFulfilled)
            .addCase(saveFunnelOptions.rejected, saveFunnelOptionsRejected)
    },
})

export default funnelOptionsSlice.reducer