
export const BORDER_RADIUS = 10;
export const MARGIN = 16;

export const MAIN_COLOR = "#5677FC"
export const PAGE_BACKGROUND = "EAEEFF"


export const PERMISSIONS_LEVELS = (type) => {
    switch (type) {
        case 'creators':
            return 1
        case 'reviewers':
            return 2;
        default:
            return 0;
    }
}