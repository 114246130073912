import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import styles from './InsightPopup.module.css'
import AceEditor from "react-ace";
import Base64 from '../../util/Base64'
import ReactMarkdown from 'react-markdown'
import { fetchInsight } from '../../store/insights/insights.slice'


const InsightPopup = ({ id }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const insight = useSelector(state => state.insights.insight)

    useEffect(() => {
        dispatch(fetchInsight({ id }))
    }, [dispatch, id])

    const handleOpenContext = () => {
        if (insight.context.courseId === 'challenge') {
            window.open(`https://coddy.tech/challenges/${insight.context.lessonId}`)
        } else {
            window.open(`https://coddy.tech/courses/${insight.context.courseId}/${insight.context.lessonId}`)
        }
    }

    return (
        <Layout classes={[styles.backdrop, id === undefined ? null : styles.display]} alignItems='flex-end' onClick={() => { navigate(`/insights`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16]} justifyContent='flex-start' gap={32}>
                <Layout row justifyContent='space-between' fullWidth alignItems='flex-start'>
                    <Layout alignItems='flex-start' fullWidth>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Course' type='small' color='darkGray' />
                            <Text text={`${insight?.context?.courseId}/${insight?.context?.lessonId}`} onClick={handleOpenContext} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Last updated' type='small' color='darkGray' />
                            <Text text={`${new Date(insight.lastUpdated).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' })}`} />
                        </Layout>
                    </Layout>
                </Layout>
                <div style={{ width: '100%' }}>
                    <AceEditor
                        width="100%"
                        theme="monokai"
                        fontSize={16}
                        showPrintMargin={false}
                        showGutter={true}
                        highlightActiveLine={true}
                        maxLines={Infinity}
                        value={Base64.decode(insight?.payloads ? insight?.payloads[0] : '')}
                        setOptions={{
                            showLineNumbers: true,
                            readOnly: true
                        }} />
                </div>
                <Layout fullWidth alignItems='flex-start' gap={16}>
                    <Text text='Insights' type='normal' color='darkGray' />
                    <Layout fullWidth alignItems='flex-start' gap={16}>
                        {
                            Object.keys(insight?.insights ?? {}).map(helpLevel =>
                                <Layout key={insight.insights[helpLevel].id} fullWidth alignItems='flex-start' color={'#eeeeee'} padding={[16]}>
                                    <Layout row alignItems='baseline' gap={8}>
                                        <Text text='Response ID' type='small' color='darkGray' />
                                        <Text text={insight.insights[helpLevel].id} />
                                    </Layout>
                                    <Layout row alignItems='baseline' gap={8}>
                                        <Text text='Help level' type='small' color='darkGray' />
                                        <Text text={helpLevel} />
                                    </Layout>
                                    <Layout row alignItems='baseline' gap={8}>
                                        <Text text='Model' type='small' color='darkGray' />
                                        <Text text={insight.insights[helpLevel].model} />
                                    </Layout>
                                    <div className={styles.askAiContainer}>
                                        <ReactMarkdown className={styles.content}>{insight.insights[helpLevel].response}</ReactMarkdown>
                                    </div>
                                </Layout>
                            )
                        }
                    </Layout>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default InsightPopup