import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import styles from './FunnelAnalyticsPopup.module.css'
import Text from '../../components/Text'
import Divider from '../../components/Divider/Divider'
import DatabaseService from '../../services/DatabaseService'
import Tag from '../../components/Tag/Tag'

const FunnelsAnalyticsPopup = () => {
    const navigate = useNavigate()
    const [searchParams,] = useSearchParams()
    const [ticketsCount, setTicketsCount] = useState()
    const [deliverablesCount, setDeliverablesCount] = useState()

    const fid = searchParams.get('analytics')

    useEffect(() => {
        if (fid) {
            setTicketsCount(null)
            setDeliverablesCount(null)
            DatabaseService.getDeliverablesCount(fid)
                .then(r => setDeliverablesCount(r))
            DatabaseService.getTicketsCount(fid)
                .then(r => setTicketsCount(r))
        }
    }, [fid])

    const handleClose = () => {
        searchParams.delete('analytics');
        navigate({ search: searchParams.toString() })
    }

    return (
        <Layout classes={[styles.backdrop, !fid ? null : styles.display]} alignItems='flex-end' onClick={handleClose}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[32, 32, 0, 32]} justifyContent='flex-start' gap={32}>
                <Layout fullWidth gap={16}>
                    <Layout row fullWidth gap={16} justifyContent='flex-start'>
                        <Text text={fid} color='darkGray' />
                    </Layout>
                    <Layout row fullWidth gap={8} justifyContent='flex-start'>
                        <Tag text={`${ticketsCount ?? '-'} Tickets`} />
                        <Tag text={`${deliverablesCount ?? '-'} Deliverables`} />
                    </Layout>
                </Layout>
                <Divider />
            </Layout>
        </Layout>
    )
}

export default FunnelsAnalyticsPopup