import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import IconButton from '../../components/IconButton/IconButton'
import Left from '../../assets/icons/Left'
import Right from '../../assets/icons/Right'
import FunctionsService from '../../services/FunctionsService'

const getCurrentWeek = () => {
    const currentDate = new Date()
    const startDate = new Date(currentDate.getFullYear(), 0, 0);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    return Math.ceil(days / 7);
}

const WeeklyGoals = ({ uid }) => {
    const [week, setWeek] = useState(getCurrentWeek())
    const [year, setYear] = useState(new Date().getFullYear())
    const [data, setData] = useState(null)

    useEffect(() => {
        FunctionsService.getUserWeeklyGoals(uid, week, year)
            .then(d => setData(d))
    }, [uid, week, year])

    const handleBack = () => {
        if (week === 1) {
            setWeek(52)
            setYear(y => y - 1)
        } else {
            setWeek(w => w - 1)
        }
    }

    const handleNext = () => {
        if (week === 52) {
            setWeek(1)
            setYear(y => y + 1)
        } else {
            setWeek(w => w + 1)
        }
    }


    return (
        <Layout alignItems='flex-start' justifyContent='flex-start' gap={16} style={{ height: '280px' }} padding={[32]}>
            <Layout gap={16} row>
                <Text text='Weekly Goals' />
                <Layout row gap={16}>
                    <IconButton Icon={Left} onClick={handleBack} />
                    <Text text={`${year}W${String(week).padStart(2, '0')}`} />
                    <IconButton Icon={Right} onClick={handleNext} />
                </Layout>
            </Layout>
            {
                data === null ?
                    <Text text='Loading...' /> :
                    Object.keys(data).length === 0 ?
                        <Text text='No goals set' /> :
                        <Layout alignItems='flex-start' gap={4}>
                            <Text text={`Intensity: ${data.intensity}`} />
                            <Text text={`Generated: ${data?.generated ? new Date(data.generated).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' }) : '-'}`} />
                            <Text text={`Last updated: ${data?.lastUpdated ? new Date(data.lastUpdated).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' }) : '-'}`} />
                            <Text text={`Rewarded: ${data?.rewarded ?? 0}`} />
                            <ul>
                                {
                                    Object.keys(data.goals).map((g, i) => <li>
                                        <Text key={i} text={`${g} - ${data.goals[g].current}/${data.goals[g].target}`} />
                                    </li>)
                                }
                            </ul>
                        </Layout>
            }
        </Layout>
    )
}

export default WeeklyGoals