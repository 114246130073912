import React, { useState } from 'react'
import styles from './Tagging.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { deleteTerm } from '../../store/tagging/tagging.slice'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import IconButton from '../../components/IconButton'
import Cross from '../../assets/icons/Cross'
import { toast } from 'react-toastify'
import SearchBar from '../../components/SearchBar/SearchBar'

const AreYouSure = ({ id }) => {
    const dispatch = useDispatch()
    return (
        <Layout row>
            <Button text={`Delete ${id} term`} onClick={() => dispatch(deleteTerm({ id }))} />
        </Layout>
    )
}

const Tag = ({ title, id }) => {
    const navigate = useNavigate()

    const handleDelete = () => {
        toast(<AreYouSure id={id} />)
    }

    return (
        <Layout classes={[styles.tagContainer]} row gap={8} padding={[4, 8, 4, 16]}>
            <Text text={title} className={styles.tag} onClick={() => navigate(`/tagging/term/${id}`)} />
            <IconButton Icon={Cross} size='tiny' type={'white'} onClick={handleDelete} />
        </Layout>
    )
}

const Terms = () => {
    const navigate = useNavigate()
    const terms = useSelector(state => state.tagging.terms)
    const [filterValue, setFilterValue] = useState('')

    return (
        <Layout alignItems='normal' justifyContent='normal' gap={16}>
            <Layout row justifyContent='flex-start'>
                <SearchBar onChange={s => setFilterValue(s)} />
                <Button text={'Add'} onClick={() => navigate('/tagging/term/add')} />
            </Layout>
            <Layout fullWidth alignItems='flex-start' justifyContent='flex-start' classes={[styles.tagsContainer]}>
                {
                    terms.filter(term =>
                        term.id.toLowerCase().includes(filterValue.toLowerCase()) ||
                        term?.title?.toLowerCase()?.includes(filterValue.toLowerCase()))
                        .map(d => <Tag key={d.id} title={d.title} id={d.id} />)
                }
            </Layout>
        </Layout>
    )
}

export default Terms