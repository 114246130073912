import { toast } from "react-toastify"
import Status from "../status"

export const fetchAIFeedbacksLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchAIFeedbacksFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    if (action.meta.arg.more) {
        state.data = state.data.concat(action.payload)
    } else {
        state.data = action.payload
    }
}

export const fetchAIFeedbacksRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchAIResponsesFulfilled = (state, action) => {
    state.responses = action.payload
}

export const fetchAIResponsesRejected = (state, action) => {
    toast.error(action.error.message)
}

