import React from 'react'

const CoddyLogo = ({ className = {} }) => {
    return (
        <svg className={className} width="224.17" height="256" viewBox="0 0 56 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5587_808)">
                <path d="M51.947 43.8642C51.947 60.4858 51.2444 63.9993 26.3795 63.9993C1.51457 63.9993 0.757812 60.4858 0.757812 43.8642C0.757812 27.2426 1.51456 24.1074 26.3795 24.1074C51.2444 24.1074 51.947 27.2426 51.947 43.8642Z" fill="#29A9E2" />
                <mask id="mask0_5587_808" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="24" width="52" height="40">
                    <path d="M51.947 43.8642C51.947 60.4858 51.2443 63.9993 26.3794 63.9993C1.51457 63.9993 0.757812 60.4858 0.757812 43.8642C0.757812 27.2426 1.51456 24.1074 26.3794 24.1074C51.2443 24.1074 51.947 27.2426 51.947 43.8642Z" fill="#29AAE3" />
                </mask>
                <g mask="url(#mask0_5587_808)">
                    <path d="M52.5142 36.0258L50.5413 29.8096L48.4602 26.7826L44.9467 25.7285C44.9467 25.7285 43.0277 25.8907 40.9737 26.1069C40.1266 26.1961 39.5369 26.3082 39.1363 26.4092C38.8532 26.4806 38.8808 26.7751 39.1663 26.8363C40.2662 27.0722 42.2566 27.5359 43.4061 28.0258C45.0548 28.7285 45.325 28.9988 46.271 30.0528C47.2169 31.1069 47.6764 32.7826 47.8385 33.8096C47.9184 34.3156 48.5413 37.8096 48.9196 42.6204C49.298 47.4312 49.4602 48.5123 48.6223 53.5394C47.7845 58.5664 45.0548 62.1069 45.0548 62.1069L48.8115 60.7285L51.2169 57.0529L52.271 49.7556L52.5142 36.0258Z" fill="#118BD4" />
                    <path d="M2.48776 55.7828C2.51479 56.7287 2.75803 58.3774 2.75803 58.3774L0.109375 56.6206L0.785052 40.1611C0.785052 40.1611 1.08235 44.6206 1.92019 47.026C2.75803 49.4314 6.10938 54.3233 6.10938 54.3233L4.731 53.7287C4.731 53.7287 3.75803 53.3774 3.27154 53.7287C2.78506 54.0801 2.46073 54.8368 2.48776 55.7828Z" fill="#118BD4" />
                    <path d="M15.8134 26.4043C16.6583 26.5733 16.6695 26.426 16.9447 26.3669C16.9891 26.3574 17.0188 26.4057 16.9925 26.4428C16.8368 26.6625 16.4263 27.2115 16.0837 27.4043C15.6513 27.6475 14.9215 27.8367 14.1918 27.5394C13.4621 27.2421 11.9756 25.8975 10.9756 25.6475C9.97561 25.3975 7.89453 25.6475 7.89453 25.6475L12.8675 24.4854C13.3089 24.8908 14.8675 26.2151 15.8134 26.4043Z" fill="#118BD4" />
                </g>
                <path d="M9.92051 55.2706C7.37997 55.1496 6.05565 54.3247 5.56916 54.0544C5.08267 53.7841 3.1097 52.8652 3.1097 52.8652L1.35294 50.0544L1.13672 45.9463L3.29889 49.8652L6.19078 52.676L11.38 53.8922L16.0286 52.8652L19.9205 50.9733C19.9205 50.9733 19.5151 51.8652 18.4341 52.8112C17.353 53.7571 15.5151 54.489 14.6503 54.8112C13.7854 55.1334 12.4611 55.3916 9.92051 55.2706Z" fill="#118BD4" />
                <path d="M32.6234 51.8379C32.1098 50.8919 31.8125 49.4054 31.8125 49.4054C33.1549 50.1352 36.4342 52.1946 36.9747 52.3244C37.5153 52.4541 40.281 52.955 41.5963 53.1892C43.3351 52.7388 46.8504 51.8379 47.0018 51.8379C47.1531 51.8379 49.4072 48.5586 50.5153 46.9189L50.3531 51.3514C50.3531 51.3514 49.5693 52.8108 49.0017 53.2703C48.4342 53.7298 46.5152 54.8108 45.1098 55.1081C43.7044 55.4054 42.9477 55.5135 41.4071 55.4865C39.8666 55.4595 39.1909 55.3514 37.7315 55.0541C36.272 54.7568 35.0287 54 34.5963 53.7838C34.1639 53.5676 33.1369 52.7838 32.6234 51.8379Z" fill="#118BD4" />
                <path fillRule="evenodd" clipRule="evenodd" d="M30.324 42.8304C27.7634 41.6823 24.8344 41.6823 22.2738 42.8304L21.168 40.3642C24.4321 38.9007 28.1657 38.9007 31.4298 40.3642L30.324 42.8304Z" fill="#003F7D" />
                <path d="M51.0389 42.4611C49.6021 50.2451 46.5107 52.6766 41.1391 52.6766C34.6181 52.4556 31.1919 49.3767 30.7656 42.9505C30.7656 37.5789 33.6076 33.5244 41.8812 33.9191C47.2467 34.1751 51.9161 37.7085 51.0389 42.4611Z" fill="#FFFAE8" />
                <path fillRule="evenodd" clipRule="evenodd" d="M32.1168 42.9057C32.3213 45.8758 33.2002 47.8638 34.5859 49.1496C35.9849 50.4478 38.1018 51.2178 41.1617 51.325C43.6299 51.3216 45.3426 50.7645 46.6217 49.5193C47.9504 48.2258 49.0113 45.9997 49.7098 42.2154C50.0464 40.3919 49.3499 38.7772 47.912 37.5214C46.4469 36.2419 44.2429 35.3844 41.8166 35.2686C37.8869 35.0811 35.526 35.9631 34.1449 37.258C32.7752 38.5421 32.1246 40.4621 32.1168 42.9057ZM32.2964 35.2863C34.4047 33.3097 37.6016 32.3618 41.9454 32.569C44.8845 32.7092 47.6981 33.7464 49.6898 35.4857C51.7086 37.2488 52.9083 39.7771 52.3676 42.706C51.6293 46.7058 50.4261 49.5876 48.507 51.4558C46.5422 53.3686 44.0301 54.0277 41.1389 54.0277H41.116L41.0931 54.0269C37.6445 53.91 34.7949 53.0306 32.7475 51.1308C30.6902 49.2218 29.6447 46.4708 29.417 43.0397L29.4141 42.995V42.9502C29.4141 40.0434 30.185 37.2659 32.2964 35.2863Z" fill="#003F7D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M37.6089 43.5841C37.609 43.584 37.6091 43.584 37.3826 43.0284C37.156 42.4728 37.1562 42.4728 37.1563 42.4727L37.1573 42.4723L37.1592 42.4715L37.1648 42.4693C37.1692 42.4675 37.1753 42.4651 37.1828 42.4622C37.1979 42.4563 37.2191 42.4481 37.2459 42.4382C37.2993 42.4182 37.3754 42.3909 37.4704 42.3595C37.66 42.2966 37.9274 42.2164 38.2434 42.1451C38.8643 42.005 39.722 41.8881 40.556 42.0439C41.3944 42.2005 42.1469 42.6288 42.6691 42.9916C42.9348 43.1762 43.1519 43.3517 43.3035 43.4818C43.3794 43.547 43.4394 43.6012 43.4814 43.6401C43.5024 43.6595 43.5189 43.6752 43.5307 43.6864C43.5365 43.6921 43.5412 43.6966 43.5447 43.7L43.5491 43.7043L43.5505 43.7057L43.5513 43.7065C43.5515 43.7066 43.5516 43.7067 43.13 44.1337C42.7085 44.5606 42.7086 44.5607 42.7087 44.5608C42.7074 44.5595 42.7043 44.5565 42.7007 44.5531C42.6935 44.5462 42.6818 44.5351 42.6658 44.5204C42.634 44.4908 42.5853 44.4468 42.5219 44.3923C42.3946 44.2831 42.21 44.1339 41.9844 43.9771C41.5236 43.6569 40.934 43.3353 40.3356 43.2235C39.7328 43.1109 39.0589 43.1913 38.5074 43.3157C38.2372 43.3766 38.0082 43.4454 37.8479 43.4985C37.768 43.525 37.7058 43.5474 37.6647 43.5627C37.6442 43.5704 37.629 43.5762 37.6195 43.5799C37.6148 43.5817 37.6107 43.5834 37.6089 43.5841Z" fill="black" />
                <path d="M43.7313 44.1342C43.7313 44.4655 43.4627 44.7342 43.1313 44.7342C42.8 44.7342 42.5312 44.4655 42.5312 44.1342C42.5312 43.8028 42.8 43.5342 43.1313 43.5342C43.4627 43.5342 43.7313 43.8028 43.7313 44.1342Z" fill="black" />
                <path d="M37.95 43.0601C37.95 43.3915 37.6656 43.6443 37.3342 43.6443C37.0029 43.6443 36.75 43.3915 36.75 43.0601C36.75 42.7287 37.0029 42.4443 37.3342 42.4443C37.6656 42.4443 37.95 42.7287 37.95 43.0601Z" fill="black" />
                <path d="M1.45815 42.4611C2.89497 50.2451 5.98637 52.6766 11.358 52.6766C17.8789 52.4556 21.3051 49.3767 21.7314 42.9505C21.7314 37.5789 18.8894 33.5244 10.6159 33.9191C5.25038 34.1751 0.580909 37.7085 1.45815 42.4611Z" fill="#FFFAE8" />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.3804 42.9057C20.1759 45.8758 19.297 47.8638 17.9114 49.1496C16.5124 50.4478 14.3954 51.2178 11.3355 51.325C8.86741 51.3216 7.15466 50.7645 5.87556 49.5193C4.54689 48.2258 3.48598 45.9997 2.78746 42.2154C2.45086 40.3919 3.14733 38.7772 4.58529 37.5214C6.0504 36.2419 8.25433 35.3844 10.6806 35.2686C14.6103 35.0811 16.9712 35.9631 18.3523 37.258C19.722 38.5421 20.3726 40.4621 20.3804 42.9057ZM20.2009 35.2863C18.0926 33.3097 14.8956 32.3618 10.5518 32.569C7.61273 32.7092 4.79919 33.7464 2.8075 35.4857C0.788651 37.2488 -0.410991 39.7771 0.129654 42.706C0.86795 46.7058 2.07116 49.5876 3.99028 51.4558C5.95505 53.3686 8.4672 54.0277 11.3583 54.0277H11.3812L11.4041 54.0269C14.8528 53.91 17.7023 53.0306 19.7497 51.1308C21.807 49.2218 22.8526 46.4708 23.0802 43.0397L23.0832 42.995V42.9502C23.0832 40.0434 22.3123 37.2659 20.2009 35.2863Z" fill="#003F7D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.8917 43.5841C14.8916 43.584 14.8915 43.584 15.118 43.0284C15.3445 42.4728 15.3444 42.4728 15.3443 42.4727L15.3433 42.4723L15.3414 42.4715L15.3358 42.4693C15.3313 42.4675 15.3253 42.4651 15.3177 42.4622C15.3026 42.4563 15.2815 42.4481 15.2547 42.4382C15.2012 42.4182 15.1252 42.3909 15.0302 42.3595C14.8406 42.2966 14.5732 42.2164 14.2572 42.1451C13.6363 42.005 12.7786 41.8881 11.9446 42.0439C11.1062 42.2005 10.3536 42.6288 9.83152 42.9916C9.56575 43.1762 9.34867 43.3517 9.19712 43.4818C9.12115 43.547 9.06115 43.6012 9.01919 43.6401C8.9982 43.6595 8.98169 43.6752 8.96992 43.6864C8.96403 43.6921 8.95932 43.6966 8.95583 43.7L8.9515 43.7043L8.95002 43.7057L8.94922 43.7065C8.94912 43.7066 8.94901 43.7067 9.37055 44.1337C9.7921 44.5606 9.792 44.5607 9.79192 44.5608C9.79323 44.5595 9.79631 44.5565 9.79991 44.5531C9.80711 44.5462 9.8188 44.5351 9.83473 44.5204C9.8666 44.4908 9.91528 44.4468 9.97871 44.3923C10.106 44.2831 10.2905 44.1339 10.5162 43.9771C10.977 43.6569 11.5665 43.3353 12.165 43.2235C12.7678 43.1109 13.4417 43.1913 13.9932 43.3157C14.2634 43.3766 14.4924 43.4454 14.6527 43.4985C14.7326 43.525 14.7948 43.5474 14.8359 43.5627C14.8564 43.5704 14.8716 43.5762 14.881 43.5799C14.8857 43.5817 14.8899 43.5834 14.8917 43.5841Z" fill="black" />
                <path d="M8.76953 44.1342C8.76953 44.4655 9.03816 44.7342 9.36952 44.7342C9.70089 44.7342 9.96951 44.4655 9.96951 44.1342C9.96951 43.8028 9.70089 43.5342 9.36952 43.5342C9.03816 43.5342 8.76953 43.8028 8.76953 44.1342Z" fill="black" />
                <path d="M14.5469 43.0601C14.5469 43.3915 14.8313 43.6443 15.1627 43.6443C15.494 43.6443 15.7469 43.3915 15.7469 43.0601C15.7469 42.7287 15.494 42.4443 15.1627 42.4443C14.8313 42.4443 14.5469 42.7287 14.5469 43.0601Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M29.5298 57.2975C29.2849 57.5613 28.9257 57.904 28.5269 58.1179C27.8357 58.4889 27.2984 58.7059 26.4553 58.8113C25.6558 58.9113 25.0064 58.9474 24.0247 58.7407C23.5348 58.6376 23.1524 58.4976 22.8491 58.308C22.5378 58.1135 22.3304 57.8796 22.1758 57.6219L22.999 57.128C23.0845 57.2704 23.1891 57.3885 23.3579 57.4939C23.5346 57.6044 23.8002 57.7124 24.2225 57.8013C25.0649 57.9787 25.5997 57.9508 26.3363 57.8587C27.0294 57.7721 27.4522 57.6052 28.073 57.272C28.3304 57.1339 28.6032 56.8846 28.8263 56.6443C28.9341 56.5282 29.0232 56.4221 29.085 56.3453C29.1158 56.307 29.1396 56.2764 29.1553 56.2559C29.1631 56.2456 29.1689 56.238 29.1725 56.2331C29.1743 56.2307 29.1755 56.229 29.1762 56.2281C29.1762 56.2282 29.1766 56.2276 29.5641 56.5109C29.9517 56.7941 29.9516 56.7941 29.9516 56.7942L29.9505 56.7956L29.9485 56.7984C29.9468 56.8006 29.9446 56.8037 29.9418 56.8074C29.9361 56.815 29.9282 56.8255 29.9181 56.8387C29.898 56.8651 29.8691 56.9022 29.8327 56.9474C29.76 57.0377 29.6561 57.1615 29.5298 57.2975Z" fill="black" />
                <path d="M23.0695 57.3756C23.0695 57.6407 22.8546 57.8556 22.5894 57.8556C22.3243 57.8556 22.1094 57.6407 22.1094 57.3756C22.1094 57.1104 22.3243 56.8955 22.5894 56.8955C22.8546 56.8955 23.0695 57.1104 23.0695 57.3756Z" fill="black" />
                <path d="M30.9757 57.0552C30.9757 57.3204 30.7607 57.5353 30.4956 57.5353C30.2305 57.5353 30.0156 57.3204 30.0156 57.0552C30.0156 56.7901 30.2305 56.5752 30.4956 56.5752C30.7607 56.5752 30.9757 56.7901 30.9757 57.0552Z" fill="black" />
                <path d="M29.8545 55.1832C29.8545 55.4483 29.6396 55.6632 29.3745 55.6632C29.1094 55.6632 28.8945 55.4163 28.8945 55.1512C28.8945 54.886 29.1094 54.7031 29.3745 54.7031C29.6396 54.7031 29.8545 54.918 29.8545 55.1832Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M28.9021 55.0684C28.9021 55.0683 28.9022 55.0681 29.3749 55.1514C29.8476 55.2347 29.8476 55.2346 29.8476 55.2345L29.8478 55.2335C29.8478 55.2334 29.8479 55.2332 29.8478 55.2335L29.8474 55.2361C29.8469 55.2394 29.846 55.2453 29.8448 55.2536C29.8424 55.2704 29.839 55.2965 29.8356 55.3302C29.8287 55.3982 29.8221 55.4937 29.8234 55.6017C29.8262 55.8326 29.8644 56.056 29.9516 56.2042C30.0299 56.3374 30.1695 56.4353 30.3262 56.5023C30.4008 56.5342 30.4693 56.5548 30.5182 56.567C30.5423 56.5731 30.5607 56.5769 30.5716 56.579C30.5771 56.58 30.5806 56.5806 30.5819 56.5809L30.5809 56.5807L30.5792 56.5805C30.579 56.5804 30.5789 56.5804 30.511 57.0556C30.4431 57.5308 30.4429 57.5307 30.4428 57.5307L30.4417 57.5306L30.44 57.5303L30.436 57.5297C30.4329 57.5292 30.4292 57.5287 30.4249 57.5279C30.4162 57.5265 30.4049 57.5245 30.3914 57.5219C30.3644 57.5168 30.328 57.5091 30.2844 57.4981C30.1978 57.4764 30.0795 57.4409 29.9485 57.3849C29.6938 57.2759 29.3469 57.0697 29.1241 56.6908C28.9102 56.3271 28.8669 55.9024 28.8635 55.6132C28.8617 55.4612 28.8708 55.3287 28.8804 55.2336C28.8853 55.1858 28.8903 55.1467 28.8943 55.1186C28.8963 55.1045 28.8981 55.093 28.8994 55.0846C28.9001 55.0803 28.9007 55.0768 28.9011 55.0741L28.9018 55.0705L28.9021 55.0684Z" fill="black" />
                <path d="M42.4626 25.9718C41.5437 25.8367 39.4626 25.0529 39.5167 24.6205C39.5707 24.1881 41.1383 18.8097 41.6518 17.4854C42.1653 16.161 43.7059 12.4313 44.2735 11.2962C44.841 10.161 45.76 9.24211 45.8681 8.91778C45.9762 8.59345 45.5167 7.99886 45.3816 7.64751C45.2465 7.29616 45.1924 6.94481 45.1654 6.40427C45.1383 5.86372 45.1383 5.40426 45.4627 4.5394C45.787 3.67453 46.0573 3.2421 46.3816 2.80967C46.7059 2.37723 47.1924 1.75561 47.9492 1.21507C48.7059 0.67453 49.1924 0.377232 50.2194 0.296151C51.2465 0.21507 51.8411 0.323177 52.5708 0.566422C53.3005 0.809667 53.7793 1.21507 54.2465 1.80967C54.7136 2.40426 55.1384 3.16102 55.3275 3.8367C55.5167 4.51237 55.4897 5.16102 55.4086 5.91778C55.3275 6.67454 54.9762 7.37724 54.6789 7.94481C54.3816 8.51237 54.0302 8.97183 53.1113 9.67454C52.1924 10.3772 50.8681 10.4043 50.5708 10.6205C50.2735 10.8367 48.3275 13.7286 47.5708 15.5124C46.814 17.2962 46.4356 18.6475 45.787 20.4043C45.1383 22.161 44.7329 25.8908 44.7329 25.8908C44.7329 25.8908 43.3816 26.107 42.4626 25.9718Z" fill="#29A9E2" />
                <mask id="mask1_5587_808" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="39" y="0" width="17" height="27">
                    <path d="M42.4626 25.9718C41.5437 25.8367 39.4626 25.0529 39.5167 24.6205C39.5707 24.1881 41.1383 18.8097 41.6518 17.4854C42.1653 16.161 43.7059 12.4313 44.2735 11.2962C44.841 10.161 45.76 9.24211 45.8681 8.91778C45.9762 8.59345 45.5167 7.99886 45.3816 7.64751C45.2465 7.29616 45.1924 6.94481 45.1654 6.40427C45.1383 5.86372 45.1383 5.40426 45.4627 4.5394C45.787 3.67453 46.0573 3.2421 46.3816 2.80967C46.7059 2.37723 47.1924 1.75561 47.9492 1.21507C48.7059 0.67453 49.1924 0.377232 50.2194 0.296151C51.2465 0.21507 51.8411 0.323177 52.5708 0.566422C53.3005 0.809667 53.7793 1.21507 54.2465 1.80967C54.7136 2.40426 55.1384 3.16102 55.3275 3.8367C55.5167 4.51237 55.4897 5.16102 55.4086 5.91778C55.3275 6.67454 54.9762 7.37724 54.6789 7.94481C54.3816 8.51237 54.0302 8.97183 53.1113 9.67454C52.1924 10.3772 50.8681 10.4043 50.5708 10.6205C50.2735 10.8367 48.3275 13.7286 47.5708 15.5124C46.814 17.2962 46.4356 18.6475 45.787 20.4043C45.1383 22.161 44.7329 25.8908 44.7329 25.8908C44.7329 25.8908 43.3816 26.107 42.4626 25.9718Z" fill="#29AAE3" />
                </mask>
                <g mask="url(#mask1_5587_808)">
                    <path d="M48.4101 5.48476C48.1855 5.26017 48.0516 4.89301 47.9906 4.68844C47.9759 4.63938 48.0343 4.59979 48.0776 4.62711C48.2999 4.76735 48.8024 5.0253 49.4912 5.0253C50.4371 5.0253 50.5723 4.67395 50.6804 4.3226C50.7638 4.0515 50.7185 3.41029 50.6934 3.13154C50.6891 3.08305 50.7449 3.05439 50.7806 3.08749C50.9333 3.2291 51.2207 3.51909 51.3561 3.80909C51.5452 4.21449 51.4371 5.07936 50.8696 5.53882C50.302 5.99828 49.8696 5.99828 49.4912 5.97125C49.1128 5.94422 48.7344 5.80909 48.4101 5.48476Z" fill="#118BD4" />
                    <path d="M55.7328 3.51264L54.1653 1.6748C54.1653 1.6748 54.3815 3.21535 54.3545 3.59373C54.3274 3.9721 54.1382 4.67481 53.9761 5.18832C53.8139 5.70184 53.3815 6.75589 52.6518 7.37751C51.922 7.99914 51.1653 8.48562 50.7869 8.62076C50.4085 8.75589 50.0842 8.86401 49.1112 8.86401C48.313 8.86401 47.0782 8.60933 46.6604 8.51785C46.6112 8.50707 46.5719 8.56569 46.5987 8.60835C46.7224 8.80474 46.9633 9.23287 47.1653 9.86401C47.4507 10.7559 45.4896 16.0262 45.4896 16.0262C45.4896 16.0262 42.9761 22.3775 42.7058 24.0802C42.4355 25.7829 42.4896 25.891 42.4896 25.891L42.2734 26.5397C43.0301 26.5577 44.5653 26.5829 44.6518 26.5397C44.7382 26.4965 45.1743 26.0892 45.3815 25.891L45.5707 24.1883C45.922 22.6928 46.6355 19.691 46.6788 19.6478C46.722 19.6046 47.8139 16.9991 48.3545 15.7018L50.895 11.2964C51.3184 11.1793 52.2139 10.9451 52.4085 10.9451C52.6518 10.9451 55.1112 9.21535 55.2734 9.13427C55.4031 9.06941 55.9401 7.19733 56.1923 6.2694L55.7328 3.51264Z" fill="#118BD4" />
                </g>
                <path d="M15.0371 26.0811C13.902 26.3243 11.6317 25.6757 11.6317 25.6757C11.6317 25.6757 11.4425 22.1081 10.8209 18.946C10.2611 16.0982 8.60523 11.3434 8.28152 10.426C8.24871 10.3331 8.15808 10.2762 8.0596 10.2803C7.7696 10.2924 7.15565 10.2942 6.65867 10.1351C5.98297 9.91893 5.22624 9.2973 5.03705 9.13514C4.84786 8.97298 4.19924 8.32433 3.92897 6.75676C3.6587 5.18919 4.0641 4.16217 4.33437 3.35135C4.60464 2.54054 5.25329 1.56757 6.3614 0.837838C7.46951 0.108107 8.65872 0 9.87492 0C11.0912 0 11.3885 0.162162 12.0641 0.621622C12.7398 1.08108 13.3074 1.54054 13.7128 2.32433C14.1182 3.10811 14.3074 3.75676 14.2263 4.67568C14.1452 5.5946 13.9831 6.45946 13.4696 7.16217C13.1541 7.59388 12.8488 7.84197 12.6603 7.96912C12.5595 8.03705 12.5117 8.17043 12.5629 8.28063C12.9664 9.14971 14.5709 12.6747 15.2533 15.2162C16.0371 18.1351 17.1993 24.4325 17.1993 24.4325C17.1993 24.4325 16.1722 25.8379 15.0371 26.0811Z" fill="#29A9E2" />
                <mask id="mask2_5587_808" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="0" width="15" height="27">
                    <path d="M15.0371 26.0811C13.902 26.3243 11.6317 25.6757 11.6317 25.6757C11.6317 25.6757 11.4425 22.1081 10.8209 18.946C10.2611 16.0982 8.60523 11.3434 8.28152 10.426C8.24871 10.3331 8.15808 10.2762 8.0596 10.2803C7.7696 10.2924 7.15565 10.2942 6.65867 10.1351C5.98297 9.91893 5.22624 9.2973 5.03705 9.13514C4.84786 8.97298 4.19924 8.32433 3.92897 6.75676C3.6587 5.18919 4.0641 4.16217 4.33437 3.35135C4.60464 2.54054 5.25329 1.56757 6.3614 0.837838C7.46951 0.108107 8.65872 0 9.87492 0C11.0912 0 11.3885 0.162162 12.0641 0.621622C12.7398 1.08108 13.3074 1.54054 13.7128 2.32433C14.1182 3.10811 14.3074 3.75676 14.2263 4.67568C14.1452 5.5946 13.9831 6.45946 13.4696 7.16217C13.1541 7.59388 12.8488 7.84197 12.6603 7.96912C12.5595 8.03705 12.5117 8.17043 12.5629 8.28063C12.9664 9.14971 14.5709 12.6747 15.2533 15.2162C16.0371 18.1351 17.1993 24.4325 17.1993 24.4325C17.1993 24.4325 16.1722 25.8379 15.0371 26.0811Z" fill="#29AAE3" />
                </mask>
                <g mask="url(#mask2_5587_808)">
                    <path d="M7.76629 3.67547C7.4882 3.56851 7.15934 3.15678 7.03429 2.98974C7.0101 2.95743 6.95911 2.96054 6.93522 2.99308C6.78886 3.19245 6.55574 3.31114 6.60416 3.89169C6.65827 4.54034 7.25281 5.02682 7.76629 5.16196C8.31761 5.30705 8.95556 5.21601 9.44205 5.05385C9.92853 4.89169 10.6583 4.24304 10.7934 3.72952C10.896 3.33951 10.8583 2.87155 10.8343 2.66901C10.8291 2.62577 10.778 2.60883 10.7476 2.64002C10.4993 2.89484 10.2402 3.22364 9.98256 3.37817C9.71224 3.54033 9.22575 3.72952 8.90148 3.75655C8.5772 3.78358 8.11764 3.8106 7.76629 3.67547Z" fill="#118BD4" />
                    <path d="M4.4149 10.0813L3.82031 6.35156C4.2167 6.94616 4.14463 7.43264 5.1176 8.1894C6.09058 8.94616 7.38788 8.72994 8.14463 8.62184C8.50694 8.57008 9.51855 8.2848 10.3339 7.81102C10.9209 7.4512 11.4549 7.05156 11.722 6.84458C11.7671 6.80963 11.8333 6.85998 11.8114 6.91263C11.6263 7.35594 11.1979 8.44677 10.9285 9.64886C10.5771 11.2164 11.9825 14.3245 12.8203 17.784C13.6582 21.2435 13.9555 23.5948 14.3609 24.5408C14.7663 25.4867 15.1987 26.4597 15.1987 26.4597L11.5501 26.2705L11.0636 25.2164L7.60409 13.1083L4.4149 10.0813Z" fill="#118BD4" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_5587_808">
                    <rect width="224.17" height="256" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default CoddyLogo