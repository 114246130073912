import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
    fetchCarouselsFulfilled,
    fetchCarouselsLoading,
    fetchCarouselsRejected,
    saveCarouselsFulfilled,
    saveCarouselsRejected,
} from './carousels.actions'

export const fetchCarousels = createAsyncThunk('contact/fetchCarousels', async (_) => {
    return DatabaseService.getCarousels()
})

export const saveCarousel = createAsyncThunk('contact/saveCarousel', async ({ id, newData }, { getState }) => {
    const data = getState().carousels.data
    const dataCopy = data.filter(d => d.id !== id)
    dataCopy.push(newData)
    
    const toSet = {[id]: newData.courses};
    return FunctionsService.updateCarousels(toSet)
        .then(() => dataCopy)
})

export const carouselsSlice = createSlice({
    name: 'carousels',
    initialState: {
        data: [],
        fetchStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCarousels.pending, fetchCarouselsLoading)
            .addCase(fetchCarousels.fulfilled, fetchCarouselsFulfilled)
            .addCase(fetchCarousels.rejected, fetchCarouselsRejected)
            .addCase(saveCarousel.fulfilled, saveCarouselsFulfilled)
            .addCase(saveCarousel.rejected, saveCarouselsRejected)
    },
})

export default carouselsSlice.reducer