import React from 'react'

const Challenge = ({ size = 24, color = 'black' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 18.75C8.25 18.3358 8.58579 18 9 18H15C15.4142 18 15.75 18.3358 15.75 18.75V18.75C15.75 19.1642 15.4142 19.5 15 19.5H9C8.58579 19.5 8.25 19.1642 8.25 18.75V18.75ZM9.75 21.75C9.75 21.3358 10.0858 21 10.5 21H13.5C13.9142 21 14.25 21.3358 14.25 21.75V21.75C14.25 22.1642 13.9142 22.5 13.5 22.5H10.5C10.0858 22.5 9.75 22.1642 9.75 21.75V21.75ZM12 1.5C10.0109 1.5 8.10323 2.29018 6.6967 3.6967C5.29018 5.10322 4.5 7.01088 4.5 9C4.44928 10.09 4.65808 11.1765 5.10923 12.17C5.56037 13.1636 6.24096 14.0358 7.095 14.715C7.54864 15.1369 7.87606 15.449 8.06399 15.7825C8.26734 16.1433 8.58579 16.5 9 16.5V16.5C9.41422 16.5 9.76205 16.1587 9.66119 15.757C9.42583 14.8195 8.75938 14.2026 8.10751 13.605C7.40652 13.0682 6.84786 12.3677 6.48044 11.5649C6.11302 10.7621 5.94806 9.88138 6 9C6 7.4087 6.63215 5.88258 7.75736 4.75736C8.88258 3.63214 10.4087 3 12 3C13.5913 3 15.1174 3.63214 16.2426 4.75736C17.3679 5.88258 18 7.4087 18 9C18.0511 9.88203 17.885 10.7631 17.5162 11.566C17.1475 12.3689 16.5874 13.069 15.885 13.605C15.2381 14.2096 14.5716 14.8142 14.3376 15.7568C14.2377 16.1588 14.5858 16.5 15 16.5V16.5C15.4142 16.5 15.7321 16.1435 15.934 15.7818C16.1205 15.4477 16.4469 15.1338 16.905 14.7075C17.7585 14.0295 18.4387 13.1585 18.8899 12.1663C19.341 11.174 19.5501 10.0888 19.5 9C19.5 8.01509 19.306 7.03982 18.9291 6.12987C18.5522 5.21993 17.9997 4.39314 17.3033 3.6967C16.6069 3.00026 15.7801 2.44781 14.8701 2.0709C13.9602 1.69399 12.9849 1.5 12 1.5Z" fill={color} />
        </svg>

    )
}

export default Challenge