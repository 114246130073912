import React from 'react'

const Failure = ({ size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#E23F33" />
            <path d="M8.53033 7.46967C8.23743 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76257 7.17678 8.23744 7.46967 8.53033L8.53033 7.46967ZM16.5303 8.53033C16.8232 8.23743 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L16.5303 8.53033ZM7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23743 16.8232 8.53033 16.5303L7.46967 15.4697ZM15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L15.4697 16.5303ZM7.46967 8.53033L11.4697 12.5303L12.5303 11.4697L8.53033 7.46967L7.46967 8.53033ZM12.5304 12.5303L16.5303 8.53033L15.4697 7.46967L11.4697 11.4697L12.5304 12.5303ZM11.4697 11.4697L7.46967 15.4697L8.53033 16.5303L12.5303 12.5303L11.4697 11.4697ZM11.4697 12.5303L15.4697 16.5303L16.5303 15.4697L12.5304 11.4697L11.4697 12.5303Z" fill="white" />
        </svg>
    )
}

export default Failure