import React from 'react'

const ProfilePlaceholder = ({ size = 64 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="32" cy="32" r="32" fill="#007ACC" />
            <circle cx="32.0002" cy="25.3334" r="6.66667" fill="#F1F5F9" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.1358 44.4136C20.4865 43.6151 19.8124 41.6158 20.8107 40.0791C23.1879 36.42 27.3112 34 31.9998 34C36.6884 34 40.8118 36.42 43.1889 40.0791C44.1872 41.6158 43.5132 43.6151 41.8638 44.4136C38.8817 45.8572 35.5353 46.6667 31.9998 46.6667C28.4644 46.6667 25.118 45.8572 22.1358 44.4136Z" fill="#F1F5F9" />
        </svg>
    )
}

export default ProfilePlaceholder