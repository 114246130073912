import { toast } from "react-toastify"
import Status from "../status"

export const fetchTagsFulfilled = (state, action) => {
    state.tags = action.payload
}

export const fetchTagsRejected = (state, action) => {
    toast.error(action.error.message)
}

export const updateTagLoading = (state, action) => {
    state.updateTagStatus = Status.Loading
}

export const updateTagFulfilled = (state, action) => {
    if (state.tags.some(tag => tag.id === action.meta.arg.id)) {
        state.tags = state.tags.map(tag => {
            if (tag.id === action.meta.arg.id) {
                return { id: action.meta.arg.id, title: action.meta.arg.data.title }
            } else {
                return tag
            }
        })
    } else {
        state.tags.push({ id: action.meta.arg.id, title: action.meta.arg.data.title })
    }
    state.updateTagStatus = Status.Success
    toast.success('Updated!')
}

export const updateTagRejected = (state, action) => {
    toast.error(action.error.message)
    state.updateTagStatus = Status.Fail
}

export const deleteTagFulfilled = (state, action) => {
    state.tags = state.tags.filter(tag => tag.id !== action.meta.arg.id)
}

export const deleteTagRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchTermsFulfilled = (state, action) => {
    state.terms = action.payload
}

export const fetchTermsRejected = (state, action) => {
    toast.error(action.error.message)
}

export const updateTermLoading = (state, action) => {
    state.updateTermStatus = Status.Loading
}

export const updateTermFulfilled = (state, action) => {
    if (state.terms.some(tag => tag.id === action.meta.arg.id)) {
        state.terms = state.terms.map(tag => {
            if (tag.id === action.meta.arg.id) {
                return { id: action.meta.arg.id, title: action.meta.arg.data.title }
            } else {
                return tag
            }
        })
    } else {
        state.terms.push({ id: action.meta.arg.id, title: action.meta.arg.data.title })
    }
    state.updateTermStatus = Status.Success
    toast.success('Updated!')
}

export const updateTermRejected = (state, action) => {
    toast.error(action.error.message)
    state.updateTermStatus = Status.Fail
}

export const deleteTermFulfilled = (state, action) => {
    state.terms = state.terms.filter(term => term.id !== action.meta.arg.id)
}

export const deleteTermRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchCoursesFulfilled = (state, action) => {
    state.courses = action.payload
}

export const fetchCoursesRejected = (state, action) => {
    toast.error(action.error.message)
}

export const updateCourseLoading = (state, action) => {
    state.updateCourseStatus = Status.Loading
}

export const updateCourseFulfilled = (state, action) => {
    state.updateCourseStatus = Status.Success
    toast.success('Updated!')
}

export const updateCourseRejected = (state, action) => {
    if (action.payload) {
        toast.error(action.payload)
    } else {
        toast.error(action.error.message)
    }
    state.updateCourseStatus = Status.Fail
}

export const updateLessonLoading = (state, action) => {
    state.updateLessonStatus = Status.Loading
}


export const updateLessonFulfilled = (state, action) => {
    state.updateLessonStatus = Status.Success
    toast.success('Updated!')
}

export const updateLessonRejected = (state, action) => {
    if (action.payload) {
        toast.error(action.payload)
    } else {
        toast.error(action.error.message)
    }
    state.updateLessonStatus = Status.Fail
}
