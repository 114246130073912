import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { MARGIN } from '../../Constants'
import CourseView from './CourseView'
import FunctionsService from '../../services/FunctionsService'

const CoursesProgress = ({ uid, onCourseClick, isChallenges = false }) => {
    const [progress, setProgress] = useState({})
    const [activeCourseId, setActiveCourseId] = useState('')

    useEffect(() => {
        FunctionsService.getCoddyUserProgress(uid, isChallenges)
            .then(d => setProgress(d))
    }, [uid, isChallenges])

    const handleCourseClick = (courseId) => {
        setActiveCourseId(courseId)
        onCourseClick(courseId)
    }

    return (
        <Layout scroll alignItems='start' justifyContent='start' gap={MARGIN / 2} fullWidth>
            {
                Object.keys(progress).map(courseId =>
                    <CourseView
                        key={courseId}
                        courseId={courseId}
                        uid={uid}
                        isActive={activeCourseId === courseId}
                        progress={progress[courseId] ?? {}}
                        onCourseClick={handleCourseClick}
                        isChallenge={isChallenges} />)
            }
        </Layout>

    )
}

export default CoursesProgress