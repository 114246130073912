import React, { useEffect, useState } from 'react'
import styles from './PerformanceView.module.css'
import Layout from '../../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMarketingPerformance } from '../../store/marketing/marketing.slice'
import { CartesianGrid, Line, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, Bar } from 'recharts'
import { createSearchParams, useNavigate } from 'react-router-dom'
import FilterTag from '../../components/FilterTag/FilterTag'

const PreformanceView = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const performance = useSelector(state => state.marketing.performance)
    const funnelsInfo = useSelector(state => state.marketing.funnelsInfo)
    const [filterFunnel, setFilterFunnel] = useState()

    useEffect(() => {
        dispatch(fetchMarketingPerformance())
    }, [dispatch])

    const handleChartClick = (e) => {
        if (e?.activeLabel) {
            navigate({ search: createSearchParams({ 'pid': e.activeLabel }).toString() })
        }
    }

    const funnelsOrder = Object.keys(funnelsInfo).sort()
    const filteredPerformance = filterFunnel ? performance.map(p => {
        return {
            ...p,
            revenue: p?.revenueInfo?.funnels?.hasOwnProperty(filterFunnel) ? Number(((p?.revenueInfo?.funnels[filterFunnel]?.amountEst ?? 0) / 100).toFixed(2)) : 0,
            customers: p?.revenueInfo?.funnels?.hasOwnProperty(filterFunnel) ? Number((p?.revenueInfo?.funnels[filterFunnel]?.customersEst ?? 0).toFixed(2)) : 0
        }
    }) : [];


    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' gap={16}>
            <Layout row fullWidth justifyContent='flex-start'>
                <FilterTag initialSelected={filterFunnel} placeholder='Funnel' values={funnelsOrder} onApply={setFilterFunnel} />
            </Layout>
            <Layout style={{ width: '100%', height: '320px' }}>
                {
                    filterFunnel ?
                        <ResponsiveContainer
                            width='100%'
                            height={'100%'}>
                            <ComposedChart
                                data={filteredPerformance}
                                onClick={handleChartClick}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="key" />
                                <YAxis dataKey={'revenue'} orientation='left' yAxisId={`left`} unit={'$'}  />
                                <YAxis dataKey={'customers'} orientation='right' yAxisId={`right`} />
                                <Tooltip />
                                <Legend />
                                {/* <Bar dataKey="price" fill="#413ea0" yAxisId={`left`} name='Budget' stackId={'main'} unit={'$'} /> */}
                                <Bar dataKey="revenue" fill="green" yAxisId={`left`} name='Revenue' stackId={'main2'} unit={'$'} />
                                <Line type="monotone" dataKey={'customers'} stroke="#8884d8" activeDot={{ r: 8 }} yAxisId={`right`} name='Customers' />
                            </ComposedChart>
                        </ResponsiveContainer> :
                        <ResponsiveContainer
                            width='100%'
                            height={'100%'}>
                            <ComposedChart
                                data={performance}
                                onClick={handleChartClick}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="key" />
                                <YAxis dataKey={'price'} orientation='left' yAxisId={`left`} unit={'$'} />
                                <YAxis dataKey={'deliverablesCount'} orientation='right' yAxisId={`right`} />
                                <Tooltip formatter={(value, name, props) => [name === "Target budget" ? props.payload.targetPrice : value, name]} />
                                <Legend />
                                <Bar dataKey={"targetPrice"} fill="#aaa" yAxisId={`left`} name='Target budget' stackId={'main3'} unit={'$'} />
                                <Bar dataKey="price" fill="#413ea0" yAxisId={`left`} name='Budget' stackId={'main'} unit={'$'} />
                                <Bar dataKey="revenue" fill="green" yAxisId={`left`} name='Revenue' stackId={'main2'} unit={'$'} />
                                <Line type="monotone" dataKey={'deliverablesCount'} stroke="#8884d8" activeDot={{ r: 8 }} yAxisId={`right`} name='Deliverables' />
                            </ComposedChart>
                        </ResponsiveContainer>
                }
            </Layout>
        </Layout>
    )
}


export default PreformanceView