import { toast } from "react-toastify"


export const fetchCreatorsFulfilled = (state, action) => {
        state.creators = action.payload

}

export const fetchCreatorsRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchPaymentsFulfilled = (state, action) => {
    state.payments = action.payload

}

export const fetchPaymentsRejected = (state, action) => {
toast.error(action.error.message)
}

export const setPaymentLoading = (state, action) => {
    state.loading[action.meta.arg.uid] = true

}

export const setPaymentFulfilled = (state, action) => {
    state.payments[action.meta.arg.uid] = {
        currentMonth: action.payload.data.currentMonth, 
        lastUpdated: new Date(Date.parse(Date(action.payload.data.lastUpdated))),
    }
    state.loading[action.meta.arg.uid] = false

}

export const setPaymentRejected = (state, action) => {
    toast.error(action.error.message)
    state.loading[action.meta.arg.uid] = false
}
