import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchDailySupportData, fetchMonthlySupportData, fetchWeeklySupportData } from '../../store/home/home.slice'
import Status from '../../store/status'
import { NUM_TO_MONTH } from './Main'
import styles from './Main.module.css'


const SupportView = ({ cohortType }) => {
    const dispatch = useDispatch()
    const {
        fetchDailySupportDataStatus,
        fetchWeeklySupportDataStatus,
        fetchMonthlySupportDataStatus,
        dailySupportData,
        weeklySupportData,
        monthlySupportData,
    } = useSelector(state => state.home)

    let data = dailySupportData
    let status = fetchDailySupportDataStatus

    if (cohortType === 'weekly') {
        data = weeklySupportData
        status = fetchWeeklySupportDataStatus
    } else if (cohortType === 'monthly') {
        data = monthlySupportData
        status = fetchMonthlySupportDataStatus
    }

    useEffect(() => {
        if (cohortType === 'daily') {
            dispatch(fetchDailySupportData())
        } else if (cohortType === 'weekly') {
            dispatch(fetchWeeklySupportData())
        } else if (cohortType === 'monthly') {
            dispatch(fetchMonthlySupportData())
        }
    }, [cohortType, dispatch])

    const [currPoint, setCurrPoint] = useState(data[0] ?? {})
    const [currIndex, setCurrIndex] = useState(0)

    useEffect(() => {
        setCurrPoint(data[0] ?? {})
    }, [data])

    if (status !== Status.Success) {
        return (
            <Layout classes={[styles.activeCard]}>
                <Text text='Loading...' />
            </Layout>
        )
    }

    const handleMouseMove = (e) => {
        const currIndex = data.findIndex(d => d.date === e?.activeLabel)
        const curr = data.find(d => d.date === e?.activeLabel)
        setCurrPoint(curr ?? (data[0] ?? {}))
        setCurrIndex(currIndex ?? 0)
    }

    const handleMouseLeave = () => {
        setCurrPoint(data[0] ?? {})
        setCurrIndex(0)
    }

    let diff = NaN
    let isZero = false
    let isPositive = false
    let percentage = 0.0
    if (data && currIndex + 1 < data?.length) {
        const lastPoint = data[currIndex + 1]
        diff = currPoint?.num - lastPoint?.num
        isZero = diff === 0
        isPositive = diff >= 0
        percentage = isPositive ?
            (((currPoint?.num / lastPoint?.num) - 1) * 100).toFixed(2) :
            ((1 - (currPoint?.num / lastPoint?.num)) * 100).toFixed(2)
    }

    let dateExtension = ''
    if (currPoint?.dateTime && cohortType === 'weekly') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setDate(d.getDate() + 7)
        dateExtension += d.toUTCString().slice(5, 11)
    }

    let currDate = ''
    if (currPoint?.dateTime) {
        currDate = `${currPoint.date.split('-')[1]} ${NUM_TO_MONTH[currPoint.date.split('-')[0]]}`
        if (cohortType === 'monthly') {
            const d = new Date(currPoint.dateTime)
            d.setDate(d.getDate() + 1)
            currDate = d.toUTCString().slice(8, 11) + ' ' + d.toUTCString().slice(14, 16)
        }
    }

    return (
        <Layout classes={[styles.activeCard]} row alignItems='flex-start' padding={[24, 24, 0, 24]}>
            <Layout alignItems='flex-start' gap={24} style={{ width: '160px', height: '100%' }} padding={[0, 0, 28, 0]}>
                <Layout alignItems='flex-start' justifyContent='flex-start' gap={8} style={{ flex: 1 }}>
                    <Layout alignItems='flex-start'>
                        <Text text={currPoint.num} type='title' bold />
                        <Text
                            text={`${isZero ? '' : isPositive ? '+' : '-'}${Math.abs(diff)} (${isZero ? '' : isPositive ? '+' : '-'}${percentage}%)`}
                            type='small'
                            color={isZero ? 'mainColor' : isPositive ? 'green' : 'red'} />
                        <Text text={'Users'} type='medium' color='darkGray' />
                    </Layout>
                    {
                        currPoint.premium !== null ?
                            <Layout alignItems='baseline' row gap={8} margin={[8, 0, 0, 0]}>
                                <Text text={currPoint.premium} type='medium' />
                                <Text text={'Premium'} type='small' color='darkGray' />
                            </Layout> :
                            null
                    }
                </Layout>
                {
                    currPoint.date ?
                        <Text text={`${currDate}${dateExtension}`} type='medium' />
                        : null
                }
            </Layout>
            <ResponsiveContainer
                width='100%'
                height={'100%'}>
                <LineChart
                    data={data}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" reversed tick={false} />
                    <YAxis dataKey={'num'} />
                    <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                    <Line type="monotone" dataKey="num" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </Layout>
    )
}

export default SupportView