import React, { useState } from 'react'
import styles from './Main.module.css'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import SupportView from './SupportView'

const SupportViewWrapper = () => {
    const [cohortType, setCohortType] = useState('daily')

    return (
        <Layout classes={[styles.cardContainer]}>
            <Layout row fullWidth justifyContent='space-between' padding={[12, 24]}>
                <Layout row gap={16}>
                    <Text text={'Support Tickets'} type='subTitle' />
                </Layout>
                <Layout row gap={16}>
                    <Text
                        text={'Daily'}
                        type='normal'
                        onClick={() => setCohortType('daily')}
                        className={[styles.textButton, cohortType === 'daily' ? styles.textButtonSelected : null].join(' ')} />
                    <Text
                        text={'Weekly'}
                        type='normal'
                        onClick={() => setCohortType('weekly')}
                        className={[styles.textButton, cohortType === 'weekly' ? styles.textButtonSelected : null].join(' ')} />
                    <Text
                        text={'Monthly'}
                        type='normal'
                        onClick={() => setCohortType('monthly')}
                        className={[styles.textButton, cohortType === 'monthly' ? styles.textButtonSelected : null].join(' ')} />
                </Layout>
            </Layout>
            <SupportView cohortType={cohortType} />
        </Layout>
    )
}

export default SupportViewWrapper