import React from 'react'

const Submissions = ({ size = 24, color = 'black' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47714 17.5228 1.99999 12 1.99999C9.23859 1.99999 6.7386 3.11927 4.92896 4.92889L3.85786 5.99999M3.85786 5.99999V1.5M3.85786 5.99999H8.36006" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 6.5V12L15 15" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Submissions