import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
    fetchTagsFulfilled,
    fetchTagsRejected,
    updateTagLoading,
    updateTagFulfilled,
    updateTagRejected,
    deleteTagFulfilled,
    deleteTagRejected,
    fetchTermsFulfilled,
    fetchTermsRejected,
    updateTermLoading,
    updateTermFulfilled,
    updateTermRejected,
    deleteTermFulfilled,
    deleteTermRejected,
    fetchCoursesFulfilled,
    fetchCoursesRejected,
    updateCourseLoading,
    updateCourseFulfilled,
    updateCourseRejected,
    updateLessonLoading,
    updateLessonFulfilled,
    updateLessonRejected,
} from './tagging.actions'

export const fetchTags = createAsyncThunk('tagging/fetchTags', async (_, { getState }) => {
    const curr = getState().tagging.tags
    if (curr.length === 0) {
        return DatabaseService.getTags()
    } else {
        return curr
    }
})

export const updateTag = createAsyncThunk('tagging/updateTag', async ({ id, data }) => {
    return FunctionsService.updateTag(id, data)
})

export const deleteTag = createAsyncThunk('tagging/deleteTag', async ({ id }) => {
    return FunctionsService.deleteTag(id)
})

export const fetchTerms = createAsyncThunk('tagging/fetchTerms', async (_, { getState }) => {
    const curr = getState().tagging.terms
    if (curr.length === 0) {
        return DatabaseService.getTerms()
    } else {
        return curr
    }
})

export const fetchCourses = createAsyncThunk('tagging/fetchCourses', async (_, { getState }) => {
    const curr = getState().tagging.courses
    if (curr.length === 0) {
        return DatabaseService.getCourses()
    } else {
        return curr
    }
})

export const updateTerm = createAsyncThunk('tagging/updateTerm', async ({ id, data }) => {
    return FunctionsService.updateTerm(id, data)
})

export const deleteTerm = createAsyncThunk('tagging/deleteTerm', async ({ id }) => {
    return FunctionsService.deleteTerm(id)
})

export const updateCourse = createAsyncThunk('tagging/updateCourse', async ({ id, data }) => {
    return FunctionsService.updateCourse(id, data)
})

export const updateLesson = createAsyncThunk('tagging/updateLesson', async ({ courseId, lessonId, terms }, { rejectWithValue }) => {
    let mappedTerms = []
    for (let i = 0; i < terms.length; i++) {
        const floatWeight = parseFloat(terms[i].weight)
        if (isNaN(floatWeight)) {
            return rejectWithValue('weight must me a float!')
        } else if (floatWeight < 0 || floatWeight > 1) {
            return rejectWithValue('weight must me between 0 and 1!')
        } else {
            const newTerm = terms[i]
            newTerm.weight = floatWeight
            mappedTerms.push(newTerm)
        }
    }
    return FunctionsService.updateTerms(courseId, lessonId, mappedTerms)
})


export const taggingSlice = createSlice({
    name: 'tagging',
    initialState: {
        tags: [],
        terms: [],
        courses: [],
        updateTagStatus: Status.None,
        updateTermStatus: Status.None,
        updateCourseStatus: Status.None,
        updateLessonStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTags.fulfilled, fetchTagsFulfilled)
            .addCase(fetchTags.rejected, fetchTagsRejected)
            .addCase(updateTag.pending, updateTagLoading)
            .addCase(updateTag.fulfilled, updateTagFulfilled)
            .addCase(updateTag.rejected, updateTagRejected)
            .addCase(deleteTag.fulfilled, deleteTagFulfilled)
            .addCase(deleteTag.rejected, deleteTagRejected)
            .addCase(fetchTerms.fulfilled, fetchTermsFulfilled)
            .addCase(fetchTerms.rejected, fetchTermsRejected)
            .addCase(updateTerm.pending, updateTermLoading)
            .addCase(updateTerm.fulfilled, updateTermFulfilled)
            .addCase(updateTerm.rejected, updateTermRejected)
            .addCase(deleteTerm.fulfilled, deleteTermFulfilled)
            .addCase(deleteTerm.rejected, deleteTermRejected)
            .addCase(fetchCourses.fulfilled, fetchCoursesFulfilled)
            .addCase(fetchCourses.rejected, fetchCoursesRejected)
            .addCase(updateCourse.pending, updateCourseLoading)
            .addCase(updateCourse.fulfilled, updateCourseFulfilled)
            .addCase(updateCourse.rejected, updateCourseRejected)
            .addCase(updateLesson.pending, updateLessonLoading)
            .addCase(updateLesson.fulfilled, updateLessonFulfilled)
            .addCase(updateLesson.rejected, updateLessonRejected)
    },
})

export default taggingSlice.reducer