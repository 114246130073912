import React from 'react'

const Loading = ({children, loading}) => {

    return (
        <div style={{"position": "relative", "width": "100%", "height": "100%"}}>
            {
                loading ?
                <div style={{
                    "backgroundColor": "rgba(0, 0, 0, 0.3)", 
                    "position": "absolute", 
                    "width": "100%", 
                    "height": "100%", 
                    "zIndex": "100",
                    "borderRadius": "10px",
                
                }}/>

                : null
            }
            {children}
        </div>
    )
}

export default Loading