import React from 'react'

const SessionIcon = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="32" cy="32" r="32" fill="#E5E5E5" />
            <path opacity="0.25" d="M32 35C31.7184 35 31.4432 34.9295 31.2 34.796L16.7999 26.8248C16.5567 26.6902 16.3548 26.4966 16.2143 26.2635C16.0739 26.0304 16 25.7659 16 25.4967C16 25.2276 16.0739 24.9631 16.2143 24.73C16.3548 24.4969 16.5567 24.3033 16.7999 24.1687L31.2 16.2036C31.696 15.9321 32.304 15.9321 32.8 16.2036L47.2001 24.1687C47.4433 24.3033 47.6452 24.4969 47.7857 24.73C47.9261 24.9631 48 25.2276 48 25.4967C48 25.7659 47.9261 26.0304 47.7857 26.2635C47.6452 26.4966 47.4433 26.6902 47.2001 26.8248L32.8 34.796C32.5566 34.93 32.2807 35.0003 32 35Z" fill="black" />
            <path opacity="0.5" d="M47.2001 30.7984L44.0577 29L32.8 35.4449C32.304 35.7272 31.696 35.7272 31.2 35.4449L19.9423 29L16.7999 30.7984C16.5567 30.9375 16.3548 31.1377 16.2143 31.3788C16.0739 31.6199 16 31.8933 16 32.1717C16 32.4501 16.0739 32.7235 16.2143 32.9646C16.3548 33.2057 16.5567 33.4059 16.7999 33.545L31.2 41.7883C31.696 42.0706 32.304 42.0706 32.8 41.7883L47.2001 33.545C47.4433 33.4059 47.6452 33.2057 47.7857 32.9646C47.9261 32.7235 48 32.4501 48 32.1717C48 31.8933 47.9261 31.6199 47.7857 31.3788C47.6452 31.1377 47.4433 30.9375 47.2001 30.7984Z" fill="black" />
            <path d="M47.2001 36.7984L44.0577 35L32.8 41.4449C32.304 41.7272 31.696 41.7272 31.2 41.4449L19.9423 35L16.7999 36.7984C16.5567 36.9375 16.3548 37.1377 16.2143 37.3788C16.0739 37.6199 16 37.8933 16 38.1717C16 38.4501 16.0739 38.7235 16.2143 38.9646C16.3548 39.2057 16.5567 39.4059 16.7999 39.545L31.2 47.7883C31.696 48.0706 32.304 48.0706 32.8 47.7883L47.2001 39.545C47.4433 39.4059 47.6452 39.2057 47.7857 38.9646C47.9261 38.7235 48 38.4501 48 38.1717C48 37.8933 47.9261 37.6199 47.7857 37.3788C47.6452 37.1377 47.4433 36.9375 47.2001 36.7984Z" fill="black" />
        </svg>
    )
}

export default SessionIcon