import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip'
import Text from '../../../components/Text';
import { toast } from 'react-toastify';
import FunctionsService from '../../../services/FunctionsService';

const shiftDate = (date, numDays) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
}

const getRange = (count) => {
    return Array.from({ length: count }, (_, i) => i);
}

const ActivityCalendar = ({ uid }) => {
    const [activityData, setActivityData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (uid) {
            setLoading(true)
            FunctionsService.getUserActivityUsage(uid)
                .then(d => {
                    setActivityData(d)
                    setLoading(false)
                })
                .catch(e => {
                    console.error(e)
                    toast.error("Error loading activity calendar")
                    setLoading(false)
                })
        }
    }, [uid])

    const today = new Date();
    // let totalSubmissions = 0;
    const values = getRange(365).map(i => {
        const date = shiftDate(today, -i)
        let submissions = 0
        let challengesDone = 0
        const year = date.getFullYear().toString()
        if (activityData.hasOwnProperty(year)) {
            const month = (date.getMonth() + 1).toString()
            if (activityData[year].hasOwnProperty(month)) {
                const day = date.getDate().toString()
                if (activityData[year][month].hasOwnProperty(day)) {
                    submissions = activityData[year][month][day]?.submissions ?? 0
                    challengesDone = activityData[year][month][day]?.challengesDone ?? 0
                    // totalSubmissions += submissions
                }
            }
        }
        return {
            date,
            submissions,
            challengesDone,
        }
    })

    return (
        <Layout style={{ width: '840px' }} alignItems='flex-start' gap={16}>
            <Layout row gap={16}>
                <Text text='Activity Heatmap' />
                {
                    loading ?
                        <Text text='Loading...' color='darkGray' /> :
                        null
                }
            </Layout>
            <CalendarHeatmap
                startDate={shiftDate(today, -365)}
                endDate={today}
                classForValue={(value) => {
                    if (!value?.submissions) {
                        return "color-white";
                    }
                    if (value.submissions <= 2 && value.challengesDone < 2)
                        return `color-github-1`;
                    if (value.submissions <= 4 && value.challengesDone < 3)
                        return `color-github-2`;
                    if (value.submissions <= 7 && value.challengesDone < 4)
                        return `color-github-3`;
                    else
                        return `color-github-4`;
                }}
                values={values}
                showWeekdayLabels={true}
                tooltipDataAttrs={(value) => {
                    return {
                        "data-tip": `<b>${value.submissions ?? 0}</b>&nbsp&nbspSubmissions<br/><b>${value.challengesDone ?? 0}</b>&nbsp&nbspChallenges done</br></br>${value?.date?.toUTCString()?.slice(5, 16)}`
                    };
                }}
            />
            <ReactTooltip html />
        </Layout>
    )
}

export default ActivityCalendar