import React from 'react'
import styles from './Button.module.css'

const Button = ({ text, onClick, type = 'main', disabled = false, selected = false }) => {
  let buttonStyle = styles.mainButton
  let textStyle = styles.mainText
  switch (type) {
    case 'main':
      buttonStyle = styles.mainButton
      textStyle = styles.mainText
      break
    case 'secondary':
      buttonStyle = styles.secondaryButton
      textStyle = styles.secondaryText
      break
    case 'nav':
      buttonStyle = styles.navButton
      textStyle = styles.navText
      break
    default:
      break
  }

  return (
    <div className={[styles.container, buttonStyle, disabled ? styles.disabled : null, selected ? styles.selected : null].join(' ')} onClick={disabled ? () => { } : onClick}>
      <span className={[styles.text, textStyle].join(' ')}>{text}</span>
    </div>
  )
}

export default Button