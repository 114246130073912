import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import Status from '../status'
import {
  fetchUserLoading,
  fetchUserFulfilled,
  fetchUserRejected,
  updateMarkedUserLoading,
  updateMarkedUserRejected,
  addUsersLabelFulfilled,
  addUsersLabelRejected,
  deleteUsersLabelLoading,
  deleteUsersLabelRejected,
  updateUserLabelLoading,
  updateUserLabelRejected,
  toggleSidebarActionFulfilled,
} from './user.actions'
import {
  arrayUnion,
  arrayRemove,
} from "@firebase/firestore";


export const fetchUser = createAsyncThunk('user/fetchUser', async ({ user }, { dispatch }) => {
  if (user != null) {
    return DatabaseService.getUser(user.uid, user.auth.currentUser.email)
  }
  return null
})

export const updateMarkedUser = createAsyncThunk('user/updateMarkedUser', async ({ uidToMark, isMarked }, { getState }) => {
  const uid = getState().user.data.uid
  return DatabaseService.updateUser(uid, { [`markedUsers.${uidToMark}`]: isMarked })
})

export const addUsersLabel = createAsyncThunk('user/addUsersLabel', async (newLabel, { getState, rejectWithValue }) => {
  const uid = getState().user.data.uid
  const labels = getState().user.data?.usersLabels ?? []
  if (labels.includes(newLabel)) {
    return rejectWithValue('Label already exists!')
  }
  return DatabaseService.updateUser(uid, { usersLabels: arrayUnion(newLabel) })
})

export const deleteUsersLabel = createAsyncThunk('user/deleteUsersLabel', async (label, { getState }) => {
  const uid = getState().user.data.uid
  return DatabaseService.updateUser(uid, { usersLabels: arrayRemove(label) })
})

export const updateUserLabel = createAsyncThunk('user/updateUserLabel', async ({ uidToLabel, label, isAddLabel }, { getState }) => {
  const uid = getState().user.data.uid
  return DatabaseService.updateUser(
    uid,
    {
      [`usersLabelsMap.${uidToLabel}`]:
        isAddLabel ?
          arrayUnion(label) :
          arrayRemove(label)
    },
  )
})

export const toggleSidebarAction = createAsyncThunk('user/toggleSidebarAction', async ({ action }, { getState }) => {
  const uid = getState().user.data.uid
  const isExists = (getState().user.data.sidebar ?? []).includes(action)
  return DatabaseService.updateUser(
    uid,
    {
      sidebar:
        isExists ?
          arrayRemove(action) :
          arrayUnion(action)
    },
  )
})


export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: {},
    fetchStatus: Status.None,
    isLogged: false,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, fetchUserLoading)
      .addCase(fetchUser.fulfilled, fetchUserFulfilled)
      .addCase(fetchUser.rejected, fetchUserRejected)
      .addCase(updateMarkedUser.pending, updateMarkedUserLoading)
      .addCase(updateMarkedUser.rejected, updateMarkedUserRejected)
      .addCase(addUsersLabel.fulfilled, addUsersLabelFulfilled)
      .addCase(addUsersLabel.rejected, addUsersLabelRejected)
      .addCase(deleteUsersLabel.pending, deleteUsersLabelLoading)
      .addCase(deleteUsersLabel.rejected, deleteUsersLabelRejected)
      .addCase(updateUserLabel.pending, updateUserLabelLoading)
      .addCase(updateUserLabel.rejected, updateUserLabelRejected)
      .addCase(toggleSidebarAction.fulfilled, toggleSidebarActionFulfilled)
  },
})

export const {
  setStatus,
} = userSlice.actions

export default userSlice.reducer