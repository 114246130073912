import React from 'react'
import styles from './Input.module.css'

const Input = ({ Icon, isPass, onValueChange, value, hint, size=24, color="black" }) => {
  return (
    <div className={styles.container}>
      <Icon size={size} color={color}/>
      <input
        className={styles.input}
        type={isPass ? 'password' : null}
        value={value}
        placeholder={hint}
        onChange={e => onValueChange(e.target.value)} />
    </div>
  )
}

export default Input