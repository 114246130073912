import { toast } from "react-toastify"
import Status from "../status"

export const fetchFeedbacksLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchFeedbacksFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.data = action.payload
}

export const fetchFeedbacksRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMoreFeedbacksFulfilled = (state, action) => {
    state.data = state.data.concat(action.payload)
}

export const fetchMoreFeedbacksRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchNumbersFulfilled = (state, action) => {
    state.numbers = action.payload
}
