import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text/Text'
import { MARGIN } from '../../Constants'
import DatabaseService from '../../services/DatabaseService'
import styles from './CourseView.module.css'
import Success from '../../assets/icons/Success'
import { useDispatch } from 'react-redux'
import { toggleLessonFlow } from '../../store/analytics/analytics.slice'
import { DIFFICULTIES, LANGUAGES } from '../../util/types'
import Tag from '../../components/Tag/Tag'


const CourseView = ({ courseId, progress, isActive, onCourseClick, isChallenge, uid }) => {
    const dispatch = useDispatch()
    const [course, setCourse] = useState({})
    // const [showLessons, setShowLessons] = useState(false)

    useEffect(() => {
        if (isChallenge) {
            DatabaseService.getChallenge(courseId)
                .then(d => setCourse(d))
        } else {
            DatabaseService.getCourse(courseId)
                .then(d => setCourse(d))
        }
    }, [courseId, isChallenge])

    const lessonsDone = Object.keys(progress).filter(k => progress[k]?.done === true).length
    // const lessons = course?.chapters?.flatMap(c => c.lessons) ?? []

    const handleClick = (courseId) => {
        onCourseClick(courseId)
        if (isChallenge) {
            const hintsSeen = {}
            if (progress !== undefined) {
                Object.keys(progress.hints ?? {})
                    .forEach(k => hintsSeen[k] = progress.hints[k].toDate().getTime())
            }
            const solutionDate = progress?.solution?.toDate()?.getTime() ?? undefined
            const askedAi = progress?.askAiNum > 0

            dispatch(toggleLessonFlow({ uid, courseId: 'challenge', lessonId: courseId, lessonName: '', chapterName: '', hintsSeen, solutionDate, askedAi }))
        }
    }

    return (
        <Layout
            row fullWidth
            padding={[MARGIN / 2]} alignItems='start' justifyContent='start'
            border
            // color={isActive ? 'rgb(240, 240, 240)' : 'white'}
            classes={[styles.itemContainer, isActive ? styles.itemActive : null]}
            onClick={() => onCourseClick(courseId)}
        >
            {
                isChallenge ?
                    <Layout justifyContent='space-between' row fullWidth gap={MARGIN} onClick={() => handleClick(courseId)}>
                        <Layout row gap={8}>
                            {
                                progress?.done ?
                                    <Success size={20} /> :
                                    <div
                                        style={{
                                            background: progress?.started ? '#ccc' : 'transparent',
                                            border: '2px solid #ccc',
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '10px',
                                            boxSizing: 'border-box'
                                        }} />
                            }
                            <Text text={course?.title} overflow />

                        </Layout>
                        <Layout row gap={8}>
                            <Tag text={LANGUAGES[course?.task?.languages[0]]} colorType='secondary' />
                            <Tag text={DIFFICULTIES[course?.task?.diff]} colorType='secondary' />
                            {
                                progress?.date ? <Tag colorType='secondary' text={`${String(progress.date.day).padStart(2, '0')}/${String(progress.date.month).padStart(2, '0')}/${progress.date.year}`} /> : null
                            }
                        </Layout>

                    </Layout> :
                    <Layout alignItems='start' justifyContent='space-between' row fullWidth gap={MARGIN} onClick={() => onCourseClick(courseId)}>
                        <Text text={course?.title} overflow />
                        <Text text={`${lessonsDone}/${course?.lessonsNum}`} />
                    </Layout>
            }
        </Layout>
    )
}

export default CourseView