import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
    fetchJourneysFulfilled,
    fetchJourneysLoading,
    fetchJourneysRejected,
    saveJourneyFulfilled,
    saveJourneyRejected,
    publishJourneyFulfilled,
    publishJourneyRejected,
} from './journeys.actions'

export const fetchJourneys = createAsyncThunk('journeys/fetchJourneys', async (_) => {
    return DatabaseService.getJourneys()
})

export const saveJourney = createAsyncThunk('journeys/saveJourney', async ({ data }) => {
    return FunctionsService.updateJourney(data).then(d => d.data);
})

export const publishJourney = createAsyncThunk('journeys/publishJourney', async ({ data }) => {
    return FunctionsService.publishJourney(data).then(d => d.data);
})

export const journeysSlice = createSlice({
    name: 'journeys',
    initialState: {
        data: [],
        fetchStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchJourneys.pending, fetchJourneysLoading)
            .addCase(fetchJourneys.fulfilled, fetchJourneysFulfilled)
            .addCase(fetchJourneys.rejected, fetchJourneysRejected)
            .addCase(saveJourney.fulfilled, saveJourneyFulfilled)
            .addCase(saveJourney.rejected, saveJourneyRejected)
            .addCase(publishJourney.fulfilled, publishJourneyFulfilled)
            .addCase(publishJourney.rejected, publishJourneyRejected)
    },
})

export default journeysSlice.reducer