import Layout from "../../components/Layout"
import DateButton from "../../components/DateButton"
import styles from './CourseAnalytics.module.css'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { fetchCourses } from '../../store/tagging/tagging.slice'
import FunctionsService from "../../services/FunctionsService"
import { addDays, format } from 'date-fns';
import Button from '../../components/Button'
import Text from '../../components/Text'
import Divider from '../../components/Divider/Divider'
import DatabaseService from "../../services/DatabaseService"

const CourseAnalytics = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(addDays(new Date(), -6))
    const [endDate, setEndDate] = useState(new Date())
    const [courseId, setCourseId] = useState()
    const [course, setCourse] = useState()
    const [data, setData] = useState([])
    const courses = useSelector(state => state.tagging.courses)
    useEffect(() => {
        dispatch(fetchCourses())
    }, [dispatch])
    useEffect(() => {
        if (courses !== null && courses !== undefined && courses.length > 0) {
            setCourseId(courses[0])
        }
    }, [courses])

    const handleClick = async () => {
        setLoading(true)
        const formatDate = "yyyy-MM-dd"
        const data = {
            startDate: format(startDate, formatDate),
            endDate: format(endDate, formatDate),
            courseId: courseId
        }
        console.log(data)
        let res = await FunctionsService.getLessonsOverview(data).then(data => data.data)
        res = Object.values(JSON.parse(res))
        const convertedObject = {};
        res.forEach(obj => {
            convertedObject[obj.lesson_id] = obj;
        });

        const courseRes = await DatabaseService.getCourse(courseId)
        setCourse(courseRes)
        console.log(courseRes)
        console.log(convertedObject)
        setData(convertedObject)
        setLoading(false)
    }

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <Layout row justifyContent="space-between">
                <select onChange={e => setCourseId(e.target.value)} style={{ "padding": "4px", "fontSize": "16px" }}>
                    {
                        Object.values(courses).sort((a, b) => a.id.localeCompare(b.id)).map(c => <option key={c.id} value={c.id} style={{ "fontSize": "16px" }}>{c.id}</option>)
                    }
                </select>
                <Layout padding={[0, 32]}>
                    <DateButton isActive={!loading} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                </Layout>
            </Layout>
            <Layout row justifyContent="flex-start">
                <Button text={'Generate'} onClick={handleClick} disabled={loading} />
            </Layout>
            <Layout scroll fullWidth alignItems='normal' justifyContent='normal' gap={8} padding={[8]}>
                <Layout row justifyContent="space-between" padding={[2]}>
                    <Text text={"Lesson Id"} className={styles.cellId} bold />
                    <Layout row gap={16}>
                        <Text text={"Total Pass"} className={styles.cellData} bold />
                        <Text text={"Total Fail"} className={styles.cellData} bold />
                        <Text text={"Ratio"} className={styles.cellData} bold />
                        <Text text={"Avg First Pass"} className={styles.cellData} bold />
                    </Layout>
                </Layout>
                <Layout fullWidth alignItems='normal' justifyContent='normal'>
                    <Divider color={'rgba(0, 0, 0, 0.1)'} />
                    {

                        course !== undefined && course !== null ? course.chapters.map(chapter => {
                            return chapter.lessons.map(lesson => {
                                if (lesson.id in data) {
                                    return (
                                        <>
                                            <Layout row justifyContent="space-between" padding={[2]}>
                                                <Text text={data[lesson.id].lesson_id} className={styles.cellId} />
                                                <Layout row gap={16}>
                                                    <Text text={data[lesson.id].total_pass} className={styles.cellData} />
                                                    <Text text={data[lesson.id].total_fail} className={styles.cellData} />
                                                    <Text text={data[lesson.id].pass_fail_ratio} className={styles.cellData} />
                                                    <Text text={data[lesson.id].avg_first_pass} className={styles.cellData} />
                                                </Layout>
                                            </Layout>
                                            <Divider color={'rgba(0, 0, 0, 0.1)'} />
                                        </>
                                    )

                                } else {
                                    return null
                                }
                            })
                        }) : null
                    }
                </Layout>

            </Layout>
        </Layout>
    )
}

export default CourseAnalytics