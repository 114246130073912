import React, { useEffect } from 'react'
import styles from './Feedbacks.module.css'
import Layout from '../../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFeedbacks, fetchMoreFeedbacks, fetchNumbers } from '../../store/feedbacks/feedbacks.slice'
import Sad from '../../assets/icons/Sad'
import Meh from '../../assets/icons/Meh'
import Happy from '../../assets/icons/Happy'
import Text from '../../components/Text'
import Feedback from '../../assets/icons/Feedback'

const Feedbacks = () => {
    const dispatch = useDispatch()
    const data = useSelector(state => state.feedbacks.data)
    const numbers = useSelector(state => state.feedbacks.numbers)

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchMoreFeedbacks())
        }
    }

    useEffect(() => {
        dispatch(fetchFeedbacks())
        dispatch(fetchNumbers())
    }, [dispatch])


    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <Layout row fullWidth gap={32}>
                <Layout row gap={8} classes={[styles.card]} padding={[8, 16]} >
                    <Text bold text={((numbers.rating3 * 3 + numbers.rating2 * 2 + numbers.rating1) / (numbers.rating3 + numbers.rating2 + numbers.rating1)).toFixed(3) + '/' + 3} type='title' />
                </Layout>
                <Layout row fullWidth gap={8} justifyContent='normal'>
                    <Layout row gap={8} classes={[styles.card]} padding={[8, 16]} >
                        <Happy />
                        <Text text={numbers.rating3} type='subTitle' />
                    </Layout>
                    <Layout row gap={8} classes={[styles.card]} padding={[8, 16]} >
                        <Meh />
                        <Text text={numbers.rating2} type='subTitle' />
                    </Layout>
                    <Layout row gap={8} classes={[styles.card]} padding={[8, 16]} >
                        <Sad />
                        <Text text={numbers.rating1} type='subTitle' />
                    </Layout>
                    <Layout row gap={8} classes={[styles.card]} padding={[8, 16]} >
                        <Feedback />
                        <Text text={numbers.withFeedback} type='subTitle' />
                    </Layout>
                </Layout>
            </Layout>
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.type}>Rating</th>
                    <th className={styles.email}>Feedback</th>
                    <th className={styles.time}>Time from creation</th>
                    <th className={styles.time}>Last updated</th>
                </tr>
                {
                    data.map(d =>
                        <FormView
                            key={d.uid}
                            data={d} />
                    )
                }
            </table>
        </Layout>
    )
}

const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR

const FormView = ({ data }) => {
    let lastActivity = ''
    if (data?.lastUpdated !== undefined) {
        const time = data.lastUpdated
        const now = Date.now()
        const diff = now - time;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    let timeFromCreation = ''
    if (data?.createdTime !== undefined && data?.lastUpdated !== undefined) {
        const a = data.lastUpdated
        const b = data.createdTime
        const diff = a - b;

        if (diff < 0) timeFromCreation = ''
        else if (diff < MIN) timeFromCreation = `${Math.floor(diff / SECOND)}s`
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            timeFromCreation = `${minutes}m`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            const minutes = Math.floor(diff / MIN) % 60
            timeFromCreation = `${hours}h${minutes}m`
        }
        else {
            const days = Math.floor(diff / DAY)
            const hours = Math.floor(diff / HOUR) % 24
            timeFromCreation = `${days}d${hours}h`
        }
    }

    let Smiley = null
    if (data.rating === 1) Smiley = Sad
    if (data.rating === 2) Smiley = Meh
    if (data.rating === 3) Smiley = Happy

    return (
        <tr className={styles.tableRow} onClick={() => { window.open(`/users/${data.uid}`) }}>
            <td className={styles.type}>{Smiley ? <Smiley /> : null}</td>
            <td className={styles.email}>{data.feedback}</td>
            <td className={styles.time}>{timeFromCreation}</td>
            <td className={styles.time}>{lastActivity}</td>
        </tr>
    )
}

export default Feedbacks