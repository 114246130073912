import { toast } from "react-toastify"
import Status from "../status"

export const fetchCarouselsLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchCarouselsFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.data = action.payload
}

export const fetchCarouselsRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}

export const saveCarouselsFulfilled = (state, action) => {
    state.data = action.payload
    toast.success('Successfully updated')
}

export const saveCarouselsRejected = (state, action) => {
    toast.error(action.error.message)
}

