import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import styles from './UserMetrics.module.css'
import FunctionsService from '../../services/FunctionsService'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { parseISO, format } from 'date-fns';
import Button from '../../components/Button/Button'

const UserMetrics = () => {
    const [loading, setLoading] = useState(false)
    const [experimentId, setExperimentId] = useState()
    const [group1, setGroup1] = useState()
    const [groupRet1, setGroupRet1] = useState()
    const [group2, setGroup2] = useState()
    const [groupRet2, setGroupRet2] = useState()
    const [startDateString, setStartDateString] = useState()
    const [endDateString, setEndDateString] = useState()
    const [isOnlyUs, setIsOnlyUs] = useState(false)
    const [data, setData] = useState(null)
    const [currenTimeType, setCurrenTimeType] = useState('daily')
    const [submCombinedData, setSubmCombinedData] = useState(null)
    const [retCombinedData, setRetCombineData] = useState(null)
    const [lsnDoneCombinedData, setLsnDoneCombineData] = useState(null)
    const [retLessonDoneCombinedData, setRetLessonDoneCombineData] = useState(null)
    const [cohortRetList, setCohortRetList] = useState(null)
    const [cohortRetLessonDoneList, setCohortRetLessonDoneList] = useState(null)

    const calRetSpecific = (lst) => {
        let res = {}
        for (let i = 0; i < lst.length; i++) {
            if (!(lst[i].cohort_date.value in res)) {
                res[lst[i].cohort_date.value] = []
            }
            res[lst[i].cohort_date.value].push(lst[i].active_users)

        }
        let retention1 = 0
        let len1 = 0
        let retention2 = 0
        let len2 = 0
        let retention7 = 0
        let len7 = 0
        let retention30 = 0
        let len30 = 0
        for (let i = 0; i < Object.keys(res).length; i++) {
            if (1 < Object.values(res)[i].length) {
                retention1 += Object.values(res)[i][1]
                len1 += 1
            }
            if (2 < Object.values(res)[i].length) {
                retention2 += Object.values(res)[i][2]
                len2 += 1
            }
            if (7 < Object.values(res)[i].length) {
                retention7 += Object.values(res)[i][7]
                len7 += 1
            }
            if (30 < Object.values(res)[i].length) {
                retention30 += Object.values(res)[i][30]
                len30 += 1
            }
        }
        retention1 /= len1
        retention2 /= len2
        retention7 /= len7
        retention30 /= len30

        return {
            ret1: retention1 * 100,
            ret2: retention2 * 100,
            ret7: retention7 * 100,
            ret30: retention30 * 100
        }
    }

    const combinedRetData = (retDt1, retDt2, cohort) => {
        // Combine the two lists
        const dt1 = retDt1.filter((item) => item.cohort_date.value === cohort)
        const dt2 = retDt2.filter((item) => item.cohort_date.value === cohort)
        return dt1.map((item, index) => {
            return {
                date: parseISO(item.activity_date.value),
                group1: item.active_users,
                group2: dt2[index]?.active_users
            }
        });
    }

    const fetchData = async () => {
        setLoading(true)
        const res = await FunctionsService.getUserMetricsData(experimentId, group1, group2, startDateString, endDateString, isOnlyUs)
        console.log(res)
        console.log()
        console.log()
        console.log()
        console.log()
        setGroupRet1(calRetSpecific(res.group1.retentionDaily))
        setGroupRet2(calRetSpecific(res.group2.retentionDaily))
        setData(res)
        setLoading(false)
    }

    useEffect(() => {
        const combinedSumbData = (submDt1, submDt2) => {
            return submDt1.map((item, index) => ({
                date: parseISO(item.date),
                group1: item.submissions / item.usersNum,
                group2: submDt2[index]?.submissions / submDt2[index]?.usersNum
            }));
        }
        const combinedLsnDoneData = (submDt1, submDt2) => {
            return submDt1.map((item, index) => ({
                date: parseISO(item.date),
                group1: item.lessons_done / item.usersNum,
                group2: submDt2[index]?.lessons_done / submDt2[index]?.usersNum
            }));
        }

        const combineCohortDates = (retDt1, retDt2) => {
            const allValues = [...retDt1.map((item) => item.date), ...retDt2.map((item) => item.date)];
            const distinctValues = [...new Set(allValues)];
            return distinctValues
        }

        if (data != null) {
            if (currenTimeType === 'daily') {
                setSubmCombinedData(combinedSumbData(data.group1.submDay, data.group2.submDay))
                setLsnDoneCombineData(combinedLsnDoneData(data.group1.lsnDoneDay, data.group2.lsnDoneDay))
                const distinct = combineCohortDates(data.group1.retentionDaily, data.group2.retentionDaily)
                setCohortRetList(distinct)
                setRetCombineData(combinedRetData(data.group1.retentionDaily, data.group2.retentionDaily, distinct[0]))

                const distinctLessonDone = combineCohortDates(data.group1.retentionLessonDoneDaily, data.group2.retentionLessonDoneDaily)
                setCohortRetLessonDoneList(distinctLessonDone)
                setRetLessonDoneCombineData(combinedRetData(data.group1.retentionLessonDoneDaily, data.group2.retentionLessonDoneDaily, distinctLessonDone[0]))
            } else if (currenTimeType === 'weekly') {
                setSubmCombinedData(combinedSumbData(data.group1.submWeek, data.group2.submWeek))
                setLsnDoneCombineData(combinedLsnDoneData(data.group1.lsnDoneWeek, data.group2.lsnDoneWeek))

                const distinct = combineCohortDates(data.group1.retentionWeekly, data.group2.retentionWeekly)
                setCohortRetList(distinct)
                setRetCombineData(combinedRetData(data.group1.retentionWeekly, data.group2.retentionWeekly, distinct[0]))

                const distinctLessonDone = combineCohortDates(data.group1.retentionLessonDoneWeekly, data.group2.retentionLessonDoneWeekly)
                setCohortRetLessonDoneList(distinctLessonDone)

                setRetLessonDoneCombineData(combinedRetData(data.group1.retentionLessonDoneWeekly, data.group2.retentionLessonDoneWeekly, distinctLessonDone[0]))

            } else if (currenTimeType === 'monthly') {
                setSubmCombinedData(combinedSumbData(data.group1.submMonth, data.group2.submMonth))
                setLsnDoneCombineData(combinedLsnDoneData(data.group1.lsnDoneMonth, data.group2.lsnDoneMonth))

                const distinct = combineCohortDates(data.group1.retentionMonthly, data.group2.retentionMonthly)
                setCohortRetList(distinct)
                setRetCombineData(combinedRetData(data.group1.retentionMonthly, data.group2.retentionMonthly, distinct[0]))

                const distinctLessonDone = combineCohortDates(data.group1.retentionLessonDoneMonthly, data.group2.retentionLessonDoneMonthly)
                setCohortRetLessonDoneList(distinctLessonDone)
                setRetLessonDoneCombineData(combinedRetData(data.group1.retentionLessonDoneMonthly, data.group2.retentionLessonDoneMonthly, distinctLessonDone[0]))

            }


        }

    }, [currenTimeType, data])
    const onRetentionCohortChange = (val) => {
        if (currenTimeType === 'daily') {
            setRetCombineData(combinedRetData(data.group1.retentionDaily, data.group2.retentionDaily, val))
        } else if (currenTimeType === 'weekly') {
            setRetCombineData(combinedRetData(data.group1.retentionWeekly, data.group2.retentionWeekly, val))

        } else if (currenTimeType === 'monthly') {
            setRetCombineData(combinedRetData(data.group1.retentionMonthly, data.group2.retentionMonthly, val))

        }
    }
    const onRetentionLessonDoneCohortChange = (val) => {
        if (currenTimeType === 'daily') {
            setRetLessonDoneCombineData(combinedRetData(data.group1.retentionLessonDoneDaily, data.group2.retentionLessonDoneDaily, val))
        } else if (currenTimeType === 'weekly') {
            setRetLessonDoneCombineData(combinedRetData(data.group1.retentionLessonDoneWeekly, data.group2.retentionLessonDoneWeekly, val))
        } else if (currenTimeType === 'monthly') {
            setRetLessonDoneCombineData(combinedRetData(data.group1.retentionLessonDoneMonthly, data.group2.retentionLessonDoneMonthly, val))


        }
    }

    const percentage1 = data?.group1?.overallStats != null && data?.group1?.overallStats.length > 1 ? ((data.group1.overallStats[1].total_users / (data.group1.overallStats[0].total_users + data.group1.overallStats[1].total_users)) * 100).toFixed(3) : null
    const percentage2 = data?.group2?.overallStats != null && data?.group2?.overallStats.length > 1 ? ((data.group2.overallStats[1].total_users / (data.group2.overallStats[0].total_users + data.group2.overallStats[1].total_users)) * 100).toFixed(3) : null

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={32}>
            <Layout fullWidth justifyContent='space-between' row alignItems='flex-end'>
                <Layout row justifyContent='flex-start' gap={8}>
                    <Layout alignItems='flex-start'>
                        <Text text='Experiment Id:' />
                        <input onChange={e => { setExperimentId(e.target.value) }} value={experimentId} className={styles.options} disabled={loading} />
                    </Layout>
                    <Layout alignItems='flex-start'>
                        <Text text='Group Id 1:' />
                        <input onChange={e => { setGroup1(e.target.value) }} value={group1} className={styles.options} disabled={loading} />

                    </Layout>
                    <Layout alignItems='flex-start'>
                        <Text text='Group Id 2:' />
                        <input onChange={e => { setGroup2(e.target.value) }} value={group2} className={styles.options} disabled={loading} />

                    </Layout>
                    <Layout alignItems='flex-start'>
                        <Text text='Start Date:' />
                        <input
                            disabled={loading}
                            className={styles.options}
                            type='date'
                            onChange={e => setStartDateString(e.target.value)}
                            min={'2022-01-01'} />
                    </Layout>
                    <Layout alignItems='flex-start'>
                        <Text text='End Date:' />
                        <input
                            disabled={loading}
                            className={styles.options}
                            type='date'
                            onChange={e => setEndDateString(e.target.value)}
                            min={'2022-01-01'} />
                    </Layout>
                    <Layout alignItems='flex-start'>
                        <Text text='Only US:' />
                        <input
                            disabled={loading}
                            className={styles.options}
                            type="checkbox"
                            id="scales"
                            name="Only US"
                            checked={isOnlyUs}
                            onChange={(e) => setIsOnlyUs(e.target.checked)} />
                    </Layout>
                </Layout>
                <Button text={"Fetch"} onClick={() => fetchData()} disabled={loading} />
            </Layout>
            {
                data != null ?
                    <Layout gap={32}>
                        {
                            data?.group1?.overallStats != null ?
                                <Layout fullWidth classes={[styles.boxContainer]} row style={{ "gap": "96px" }}>
                                    <Layout row gap={16}>
                                        <Layout alignItems='flex-start'>
                                            <Layout row gap={16}>
                                                <Layout style={{ "width": "80px" }} alignItems='flex-start'>
                                                    <Text text="Group 1" type="subTitle" bold />
                                                </Layout>
                                                <Text text={`(${data.group1.percentUs[0].us_perc.toFixed(3)}% US)`} />
                                            </Layout>
                                            <Layout row gap={16}>
                                                <Layout style={{ "width": "80px" }} alignItems='flex-start'>
                                                    <Text text="Users:" />
                                                </Layout>
                                                <Text text={data?.group1?.overallStats[0]?.total_users + (data?.group1?.overallStats[1]?.total_users != null ? data?.group1?.overallStats[1]?.total_users : 0)} />
                                            </Layout>
                                            <Layout row gap={16}>
                                                <Layout style={{ "width": "80px" }} alignItems='flex-start'>
                                                    <Text text="Premium:" />
                                                </Layout>
                                                <Text text={data?.group1?.overallStats[1]?.total_users} />
                                            </Layout>
                                            <Layout row gap={16}>
                                                <Layout style={{ "width": "80px" }} alignItems='flex-start'>
                                                    <Text text="Ratio:" />
                                                </Layout>
                                                <Text text={`${percentage1}%`} />
                                            </Layout>
                                        </Layout>
                                        <Layout alignItems='flex-start'>
                                            {
                                                groupRet1 != null ?
                                                    <>
                                                        <Layout row gap={16}>
                                                            <Layout style={{ "width": "100px" }} alignItems='flex-start'>
                                                                <Text text="Retention 1:" />
                                                            </Layout>
                                                            {
                                                                !isNaN(groupRet1.ret1) ?
                                                                    <Text text={`${groupRet1.ret1?.toFixed(2)}%`} /> : null
                                                            }
                                                        </Layout>
                                                        <Layout row gap={16}>
                                                            <Layout style={{ "width": "100px" }} alignItems='flex-start'>
                                                                <Text text="Retention 2:" />
                                                            </Layout>
                                                            {
                                                                !isNaN(groupRet1.ret2) ?
                                                                    <Text text={`${groupRet1.ret2?.toFixed(2)}%`} /> : null
                                                            }
                                                        </Layout>
                                                        <Layout row gap={16}>
                                                            <Layout style={{ "width": "100px" }} alignItems='flex-start'>
                                                                <Text text="Retention 7:" />
                                                            </Layout>
                                                            {
                                                                !isNaN(groupRet1.ret7) ?
                                                                    <Text text={`${groupRet1.ret7?.toFixed(2)}%`} /> : null
                                                            }

                                                        </Layout>
                                                        <Layout row gap={16}>
                                                            <Layout style={{ "width": "100px" }} alignItems='flex-start'>
                                                                <Text text="Retention 30:" />
                                                            </Layout>
                                                            {
                                                                !isNaN(groupRet1.ret30) ?
                                                                    <Text text={`${groupRet1.ret30?.toFixed(2)}%`} /> : null
                                                            }
                                                        </Layout>
                                                    </>
                                                    : null
                                            }
                                        </Layout>
                                    </Layout>
                                    <Layout row gap={16}>
                                        <Layout alignItems='flex-start'>
                                            <Layout row gap={16}>
                                                <Layout style={{ "width": "80px" }} alignItems='flex-start'>
                                                    <Text text="Group 2" type="subTitle" bold />
                                                </Layout>
                                                <Text text={`(${data.group2.percentUs[0].us_perc.toFixed(3)}% US)`} />
                                            </Layout>
                                            <Layout row gap={16}>
                                                <Layout style={{ "width": "80px" }} alignItems='flex-start'>
                                                    <Text text="Users:" />
                                                </Layout>
                                                <Text text={data?.group2?.overallStats[0]?.total_users + (data?.group2?.overallStats[1]?.total_users != null ? data?.group2?.overallStats[1]?.total_users : 0)} />
                                            </Layout>
                                            <Layout row gap={16}>
                                                <Layout style={{ "width": "80px" }} alignItems='flex-start'>
                                                    <Text text="Premium:" />
                                                </Layout>
                                                <Text text={data?.group2?.overallStats[1]?.total_users ?? 0} />
                                            </Layout>
                                            <Layout row gap={16}>
                                                <Layout style={{ "width": "80px" }} alignItems='flex-start'>
                                                    <Text text="Ratio:" />
                                                </Layout>
                                                <Text text={`${percentage2 ?? 0}% (${percentage2 - percentage1 > 0 ? "+" : ''}${((percentage2 / percentage1)*100 - 100).toFixed(2)}%)`}
                                                    color={percentage2 > percentage1 ? "green" : percentage2 < percentage1 ? "red" : null}

                                                />
                                            </Layout>
                                        </Layout>
                                        <Layout alignItems='flex-start'>
                                            {
                                                groupRet2 != null ?
                                                    <>
                                                        <Layout row gap={16}>
                                                            <Layout style={{ "width": "100px" }} alignItems='flex-start'>
                                                                <Text text="Retention 1:" />
                                                            </Layout>
                                                            {
                                                                !isNaN(groupRet2.ret1) ?
                                                                    <>
                                                                        <Text text={`${groupRet2.ret1?.toFixed(2)}% (${groupRet2.ret1 - groupRet1.ret1 > 0 ? "+" : ''}${((groupRet2.ret1 / groupRet1.ret1) * 100 - 100).toFixed(2)}%)`} color={groupRet2.ret1 > groupRet1.ret1 ? "green" : groupRet2.ret1 < groupRet1.ret1 ? "red" : null} />
                                                                    </> : null
                                                            }

                                                        </Layout>
                                                        <Layout row gap={16}>
                                                            <Layout style={{ "width": "100px" }} alignItems='flex-start'>
                                                                <Text text="Retention 2:" />
                                                            </Layout>
                                                            {
                                                                !isNaN(groupRet2.ret2) ?
                                                                    <>
                                                                        <Text text={`${groupRet2.ret2?.toFixed(2)}% (${groupRet2.ret2 - groupRet1.ret2 > 0 ? "+" : ''}${((groupRet2.ret2 / groupRet1.ret2) * 100 - 100).toFixed(2)}%)`} color={groupRet2.ret2 > groupRet1.ret2 ? "green" : groupRet2.ret2 < groupRet1.ret2 ? "red" : null} />
                                                                    </> : null
                                                            }
                                                        </Layout>
                                                        <Layout row gap={16}>
                                                            <Layout style={{ "width": "100px" }} alignItems='flex-start'>
                                                                <Text text="Retention 7:" />
                                                            </Layout>
                                                            {
                                                                !isNaN(groupRet2.ret7) ?
                                                                    <>
                                                                        <Text text={`${groupRet2.ret7?.toFixed(2)}% (${groupRet2.ret7 - groupRet1.ret7 > 0 ? "+" : ''}${((groupRet2.ret7 / groupRet1.ret7) * 100 - 100).toFixed(2)}%)`} color={groupRet2.ret7 > groupRet1.ret7 ? "green" : groupRet2.ret7 < groupRet1.ret7 ? "red" : null} />
                                                                    </> : null
                                                            }

                                                        </Layout>
                                                        <Layout row gap={16}>
                                                            <Layout style={{ "width": "100px" }} alignItems='flex-start'>
                                                                <Text text="Retention 30:" />
                                                            </Layout>
                                                            {
                                                                !isNaN(groupRet2.ret30) ?
                                                                    <>
                                                                        <Text text={`${groupRet2.ret30?.toFixed(2)}% (${groupRet2.ret30 - groupRet1.ret30 > 0 ? "+" : ''}${((groupRet2.ret30 / groupRet1.ret30) * 100 - 100).toFixed(2)}%)`} color={groupRet2.ret30 > groupRet1.ret30 ? "green" : groupRet2.ret30 < groupRet1.ret30 ? "red" : null} />
                                                                    </> : null
                                                            }
                                                        </Layout>
                                                    </>
                                                    : null
                                            }
                                        </Layout>
                                    </Layout>
                                </Layout> : null
                        }
                        <Layout row gap={16}>
                            <Text
                                text={'Daily'}
                                type='normal'
                                onClick={() => setCurrenTimeType('daily')}
                                className={[styles.textButton, currenTimeType === 'daily' ? styles.textButtonSelected : null].join(' ')} />
                            <Text
                                text={'Weekly'}
                                type='normal'
                                onClick={() => setCurrenTimeType('weekly')}
                                className={[styles.textButton, currenTimeType === 'weekly' ? styles.textButtonSelected : null].join(' ')} />
                            <Text
                                text={'Monthly'}
                                type='normal'
                                onClick={() => setCurrenTimeType('monthly')}
                                className={[styles.textButton, currenTimeType === 'monthly' ? styles.textButtonSelected : null].join(' ')} />
                        </Layout>

                        {
                            submCombinedData != null ?
                                <Layout fullWidth classes={[styles.boxContainer]}>
                                    <Layout row gap={16}>
                                        <Text text={"Average Submissions Per User"} />
                                    </Layout>

                                    <ResponsiveContainer
                                        width='100%'
                                        height={200}>
                                        <LineChart data={submCombinedData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date"
                                                type="category"
                                                tickFormatter={(tick) => format(tick, 'MM/dd')}
                                            />
                                            <YAxis />
                                            <Tooltip />
                                            <Line type="monotone" dataKey="group1" name="Group 1" stroke="#8884d8" activeDot={{ r: 8 }} />
                                            <Line type="monotone" dataKey="group2" name="Group 2" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Layout> : null
                        }
                        {
                            lsnDoneCombinedData != null ?
                                <Layout fullWidth classes={[styles.boxContainer]}>
                                    <Layout row gap={16}>
                                        <Text text={"Average Lesson done per User"} />
                                    </Layout>

                                    <ResponsiveContainer
                                        width='100%'
                                        height={200}>
                                        <LineChart data={lsnDoneCombinedData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date"
                                                type="category"
                                                tickFormatter={(tick) => format(tick, 'MM/dd')}
                                            />
                                            <YAxis />
                                            <Tooltip />
                                            <Line type="monotone" dataKey="group1" name="Group 1" stroke="#8884d8" activeDot={{ r: 8 }} />
                                            <Line type="monotone" dataKey="group2" name="Group 2" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Layout> : null
                        }
                        {
                            retCombinedData != null ?
                                <Layout fullWidth classes={[styles.boxContainer]}>
                                    <Layout row gap={16}>
                                        <Text text={"Retention via Submissions"} />
                                        <select onChange={e => onRetentionCohortChange(e.target.value)} className={styles.options}>
                                            {
                                                cohortRetList.map((item, index) => {
                                                    return <option value={item} key={index}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </Layout>
                                    <ResponsiveContainer
                                        width='100%'
                                        height={200}>
                                        <LineChart data={retCombinedData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date"
                                                type="category"
                                                tickFormatter={(tick) => format(tick, 'MM/dd')}

                                            />
                                            <YAxis />
                                            <Tooltip />
                                            <Line type="monotone" dataKey="group1" name="Group 1" stroke="#8884d8" activeDot={{ r: 8 }} />
                                            <Line type="monotone" dataKey="group2" name="Group 2" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Layout> : null

                        }
                        {
                            retLessonDoneCombinedData != null ?
                                <Layout fullWidth classes={[styles.boxContainer]}>
                                    <Layout row gap={16}>
                                        <Text text={"Retention via Lesson Done"} />
                                        <select onChange={e => onRetentionLessonDoneCohortChange(e.target.value)} className={styles.options}>
                                            {
                                                cohortRetLessonDoneList.map((item) => {
                                                    return <option value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </Layout>
                                    <ResponsiveContainer
                                        width='100%'
                                        height={200}>
                                        <LineChart data={retLessonDoneCombinedData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date"
                                                type="category"
                                                tickFormatter={(tick) => format(tick, 'MM/dd')}

                                            />
                                            <YAxis />
                                            <Tooltip />
                                            <Line type="monotone" dataKey="group1" name="Group 1" stroke="#8884d8" activeDot={{ r: 8 }} />
                                            <Line type="monotone" dataKey="group2" name="Group 2" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Layout> : null

                        }

                    </Layout> : null
            }
        </Layout>
    )
}

export default UserMetrics