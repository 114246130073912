import { toast } from "react-toastify"
import Status from "../status"


export const fetchLastGeneratedChallengesLoading = (state, _) => {
    state.fetchLastGeneratedStatus = Status.Loading
}

export const fetchLastGeneratedChallengesFulfilled = (state, action) => {
    state.fetchLastGeneratedStatus = Status.Success
    state.lastGenerated = action.payload
}

export const fetchLastGeneratedChallengesRejected = (state, action) => {
    state.fetchLastGeneratedStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchChallengesDataLoading = (state, _) => {
    state.fetchDataStatus = Status.Loading
}

export const fetchChallengesDataFulfilled = (state, action) => {
    state.fetchDataStatus = Status.Success
    state.data = action.payload
}

export const fetchChallengesDataRejected = (state, action) => {
    state.fetchDataStatus = Status.Fail
    toast.error(action.error.message)
}
