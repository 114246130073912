import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import Status from '../status'
import {
    fetchBlogsFulfilled,
    fetchBlogsLoading,
    fetchBlogsRejected,
    fetchMoreBlogsFulfilled,
    fetchMoreBlogsRejected,
} from './blogs.actions'

export const fetchBlogs = createAsyncThunk('blogs/fetchBlogs', async (_) => {
    return DatabaseService.getBlogs(undefined)
})

export const fetchMoreBlogs = createAsyncThunk('blogs/fetchMoreContact', async (_, { getState }) => {
    const blogs = getState().blogs.data
    return DatabaseService.getBlogs(blogs[blogs.length - 1]?.date)
})

export const blogsSlice = createSlice({
    name: 'blogs',
    initialState: {
        data: [],
        fetchStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBlogs.pending, fetchBlogsLoading)
            .addCase(fetchBlogs.fulfilled, fetchBlogsFulfilled)
            .addCase(fetchBlogs.rejected, fetchBlogsRejected)
            .addCase(fetchMoreBlogs.fulfilled, fetchMoreBlogsFulfilled)
            .addCase(fetchMoreBlogs.rejected, fetchMoreBlogsRejected)
    },
})

export default blogsSlice.reducer