import React from 'react'

const Linkedin = ({ size, onClick }) => {
    return (
        <svg onClick={onClick} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={onClick ? { cursor: 'pointer' } : {}}>
            <g clipPath="url(#clip0_3769_556)">
                <path d="M20.4491 20.4492H16.8931V14.8802C16.8931 13.5522 16.8694 11.8427 15.0436 11.8427C13.1915 11.8427 12.9081 13.2896 12.9081 14.7835V20.4488H9.35213V8.99672H12.7658V10.5618H12.8137C13.1553 9.97763 13.649 9.49707 14.2421 9.17129C14.8353 8.84552 15.5057 8.68672 16.1819 8.71181C19.7861 8.71181 20.4506 11.0826 20.4506 14.1667L20.4491 20.4492ZM5.33963 7.43137C4.19991 7.43156 3.27581 6.50775 3.27562 5.36803C3.27544 4.22831 4.19916 3.30422 5.33887 3.30403C6.47859 3.30375 7.40269 4.22756 7.40287 5.36728C7.40297 5.9146 7.18566 6.43954 6.79872 6.82663C6.41179 7.21372 5.88694 7.43125 5.33963 7.43137ZM7.11769 20.4493H3.55791V8.99672H7.11759L7.11769 20.4493ZM22.2219 0.0015026H1.77103C0.804469 -0.0093724 0.0118125 0.764909 0 1.73147V22.2677C0.0114375 23.2347 0.804 24.0097 1.77094 23.9996H22.2219C23.1909 24.0116 23.9866 23.2366 24 22.2677V1.72988C23.9862 0.76144 23.1905 -0.0127474 22.2219 -9.11508e-05" fill="#0A66C2" />
            </g>
            <defs>
                <clipPath id="clip0_3769_556">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Linkedin