import React, { useEffect, useState } from 'react'
import styles from './DeliverablesView.module.css'
import Layout from '../../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLastMarketingDeliverables } from '../../store/marketing/marketing.slice'
import IconButton from '../../components/IconButton'
import Open from '../../assets/icons/Open'
import { createSearchParams, useNavigate } from 'react-router-dom'
import FilterTag from '../../components/FilterTag/FilterTag'
import Tag from '../../components/Tag/Tag'

const DeliverablesView = () => {
    const dispatch = useDispatch()
    const deliverables = useSelector(state => state.marketing.deliverables)
    const funnelsInfo = useSelector(state => state.marketing.funnelsInfo)
    const [filterFunnel, setFilterFunnel] = useState()

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchLastMarketingDeliverables({ more: true, fid: filterFunnel }))
        }
    }

    useEffect(() => {
        if (filterFunnel) {
            dispatch(fetchLastMarketingDeliverables({ more: false, fid: filterFunnel, force: true }))
        } else {
            dispatch(fetchLastMarketingDeliverables({ more: false, force: true }))
        }
    }, [filterFunnel, dispatch])

    const funnelsOrder = Object.keys(funnelsInfo).sort()

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' gap={16}>
            <Layout row justifyContent='flex-start'>
                <FilterTag initialSelected={filterFunnel} placeholder='Funnel' values={funnelsOrder} onApply={setFilterFunnel} />
            </Layout>
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.actions}></th>
                    <th className={styles.handleId}>Handle ID</th>
                    <th className={styles.platform}>Platform</th>
                    <th className={styles.status}></th>
                    <th className={styles.publishTime}>Publish time</th>
                </tr>
                {
                    deliverables.map(deliverable =>
                        <DeliverableView
                            key={deliverable.id}
                            deliverable={deliverable} />
                    )
                }
            </table>
        </Layout>
    )
}

const PLATFORM_MAPPER = {
    'instagram': 'Instagram',
    'tiktok': 'TikTok',
    'facebook': 'Facebook',
    'linkedin': 'LinkedIn',
    'youtube': 'YouTube',
    'twitter': 'Twitter',
}

const DeliverableView = ({ deliverable }) => {
    const navigate = useNavigate()

    const handleOpenDeliverableUrl = (e) => {
        e.stopPropagation()
        window.open(deliverable.url, '_blank')
    }

    return (
        <tr className={styles.tableRow} onClick={() => { navigate({ search: createSearchParams({ 'tid': deliverable.tid }).toString() }) }}>
            <td className={styles.actions}>
                {
                    deliverable.url ?
                        <IconButton Icon={Open} size={'small'} onClick={handleOpenDeliverableUrl} /> :
                        null
                }
            </td>
            <td className={styles.handleId}>{deliverable.handleId ? deliverable.handleId : '-'}</td>
            <td className={styles.platform}>{PLATFORM_MAPPER[deliverable.platform]}</td>
            <td className={styles.status}>
                {
                    deliverable.publishTime < Date.now() && !deliverable.url ?
                        <Tag text="Require attention" colorType='error' /> :
                        null
                }
            </td>
            <td className={styles.publishTime}>{new Date(deliverable.publishTime).toLocaleString(undefined, { timeStyle: 'short', dateStyle: 'short' })}</td>
        </tr>
    )
}

export default DeliverablesView