import React, { useEffect } from 'react'
import styles from './Support.module.css'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMoreSupport, fetchSupport, setShowClose } from '../../store/support/support.slice'
import { useNavigate, useParams } from 'react-router-dom'
import TicketPopup from './TicketPopup'
import CoddyPremium from '../../assets/icons/CoddyPremium'

const Support = () => {
    const { tid } = useParams()
    const dispatch = useDispatch()
    const showClose = useSelector(state => state.support.showClose)
    const tickets = useSelector(state => state.support.tickets)

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchMoreSupport())
        }
    }

    const handleSetShowClose = (e) => {
        dispatch(setShowClose(e.target.checked))
    }

    useEffect(() => {
        dispatch(fetchSupport())
    }, [dispatch, showClose])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <Layout row justifyContent='space-between' fullWidth>
                <Text text='Support tickets' type='subTitle' />
                <Layout row gap={8}>
                    <Text text='Show close' />
                    <input type={'checkbox'} checked={showClose} onChange={handleSetShowClose} />
                </Layout>
            </Layout>
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.status}>Status</th>
                    <th className={styles.email}>Email</th>
                    <th className={styles.lastUpdated}>Last Updated</th>
                </tr>
                {
                    tickets.map(ticket =>
                        <TicketView
                            key={ticket.id}
                            ticket={ticket} />
                    )
                }
            </table>
            <TicketPopup tid={tid} />
        </Layout>
    )
}


const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR

const TicketView = ({ ticket }) => {

    const navigate = useNavigate()

    let lastActivity = ''
    if (ticket?.lastUpdated !== undefined) {
        const time = ticket.lastUpdated
        const now = Date.now()
        const diff = now - time;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/support/${ticket.id}`) }}>
            <td className={styles.status}>{ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}</td>
            <td className={styles.email}>
                {
                    ticket.isPremium ?
                        <CoddyPremium billingData={{}} size={16} /> :
                        null
                }
                {ticket.email}
            </td>
            <td className={styles.lastUpdated}>{lastActivity}</td>
        </tr>
    )
}

export default Support