import React from 'react'

const Right = ({ size = '24', color = '#262F56', className, onClick }) => {
    return (
        <svg onClick={onClick} className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00008 4.99998L16.0001 12L9.00008 19" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Right