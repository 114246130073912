import React, { useState } from 'react'
import Email from '../../assets/icons/Email'
import Lock from '../../assets/icons/Lock'
import Button from '../../components/Button'
import Input from '../../components/Input'
import styles from './Form.module.css'
import AuthService from '../../services/AuthService'
import { toast } from "react-toastify"

const Form = ({ children }) => {
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')

  const handleLogin = () => {
    AuthService.login(email, pass)
      .catch(e => toast.error(e))
  }

  return (
    <div className={styles.container}>
      {children}
      <Input
        Icon={Email}
        value={email}
        onValueChange={setEmail}
        hint='Email' />
      <div style={{ height: '16px' }} />
      <Input
        Icon={Lock}
        isPass
        value={pass}
        onValueChange={setPass}
        hint='Password' />
      <div style={{ height: '24px' }} />
      <Button text='LOGIN' onClick={handleLogin} />
    </div>
  )
}

export default Form