import React from 'react'

const Filter = ({ color = '#262F56', width = '1.8', size = '24' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 7C16.9706 7 21 6.10457 21 5C21 3.89543 16.9706 3 12 3C7.02944 3 3 3.89543 3 5C3 6.10457 7.02944 7 12 7Z" stroke={color} strokeWidth={width} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 5C3 7.23 6.871 11.674 8.856 13.805C9.58416 14.5769 9.99291 15.5959 10 16.657V22L14 20V16.657C14 15.596 14.421 14.582 15.144 13.805C17.13 11.674 21 7.231 21 5" stroke={color} strokeWidth={width} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Filter