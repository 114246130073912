import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import styles from './FormPopup.module.css'
import FunctionsService from '../../services/FunctionsService'


const FormPopup = ({ id }) => {
    const [user, setUser] = useState(null)
    const navigate = useNavigate()
    const ticketData = useSelector(state => state.contact.forms.find(f => f.id === id))

    useEffect(() => {
        if (ticketData?.email) {
            FunctionsService.getCoddyUserByEmail(ticketData.email)
                .then(u => setUser(u))
        }
    }, [ticketData])

    const handleOpenUser = () => {
        window.open(`/users/${user.uid}`)
    }

    return (
        <Layout classes={[styles.backdrop, id === undefined ? null : styles.display]} alignItems='flex-end' onClick={() => { navigate(`/contact`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16]} justifyContent='flex-start' gap={32}>
                <Layout row justifyContent='space-between' fullWidth alignItems='flex-start'>
                    <Layout alignItems='flex-start' fullWidth>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Time' type='small' color='darkGray' />
                            <Text text={ticketData?.time ? new Date(ticketData.time).toLocaleString() : null} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Email' type='small' color='darkGray' />
                            <Text text={ticketData?.email} onClick={user === null ? null : handleOpenUser} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Name' type='small' color='darkGray' />
                            <Text text={ticketData?.name} />
                        </Layout>
                    </Layout>
                </Layout>
                <Layout classes={[styles.messageContainer]} alignItems='flex-start'>
                    <pre className={styles.message}>
                        {ticketData?.message}
                    </pre>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default FormPopup