import React from 'react'
import { useDispatch } from 'react-redux';
import styles from './UserActionsMenu.module.css'
import Dots from '../../assets/icons/Dots'
import Delete from '../../assets/icons/Delete'
import { setResetPermission } from '../../store/permissions/permissions.slice'
// import { updateMarkedUser, updateUserLabel } from '../../store/user/user.slice';

const UserActionsMenu = ({ email, type }) => {
    const dispatch = useDispatch()

    const handleDelete = () => {
        dispatch(setResetPermission({email: email, type: type}))
    }

    return (
        <div className={styles.container} onClick={e => e.stopPropagation()}>
            <Dots size={24} />
            <div className={styles.menuContainer} onClick={e => e.preventDefault()}>
                <div className={styles.row} onClick={handleDelete}>
                    { <Delete size={16} />}
                    <span>{'Delete'}</span>
                </div>
            </div>
        </div>
    )
}

export default UserActionsMenu