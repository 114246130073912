import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip'
import Text from '../../../components/Text';
import FunctionsService from '../../../services/FunctionsService';
import { toast } from 'react-toastify';

const shiftDate = (date, numDays) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
}

const getRange = (count) => {
    return Array.from({ length: count }, (_, i) => i);
}

const ChallengesCalendar = ({ uid }) => {
    const [activityData, setActivityData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (uid) {
            setLoading(true)
            FunctionsService.getUserChallengesActivity(uid)
                .then(d => {
                    setActivityData(d.data)
                    setLoading(false)
                })
                .catch(e => {
                    console.error(e)
                    toast.error("Error loading challenges calendar")
                    setLoading(false)
                })
        }
    }, [uid])

    const today = new Date();
    const values = getRange(365).map(i => {
        const date = shiftDate(today, -i)
        let generated = false;
        let started = false;
        let done = false;
        let isRandom = false;
        const year = date.getFullYear().toString()
        if (activityData.hasOwnProperty(year)) {
            const month = (date.getMonth() + 1).toString()
            if (activityData[year].hasOwnProperty(month)) {
                const day = date.getDate().toString()
                if (activityData[year][month].hasOwnProperty(day)) {
                    generated = true
                    started = activityData[year][month][day]?.started ?? 0
                    done = activityData[year][month][day]?.done ?? 0
                    isRandom = activityData[year][month][day]?.isRandom ?? 0
                }
            }
        }
        return {
            date,
            generated,
            started,
            done,
            isRandom,
        }
    })

    return (
        <Layout style={{ width: '840px' }} alignItems='flex-start' gap={16}>
            <Layout row gap={16}>
                <Text text='Daily Challenges Heatmap' />
                {
                    loading ?
                        <Text text='Loading...' color='darkGray' /> :
                        null
                }
            </Layout>
            <CalendarHeatmap
                startDate={shiftDate(today, -365)}
                endDate={today}
                classForValue={(value) => {
                    if (!value?.generated) {
                        return "color-white";
                    }
                    if (value.done) {
                        return `color-github-4`;
                    }
                    if (value.started) {
                        return `color-github-3`;
                    }
                    else
                        return `color-github-2`;
                }}
                values={values}
                showWeekdayLabels={true}
                tooltipDataAttrs={(value) => {
                    return {
                        "data-tip": `<b>${value.generated ? value.started ? value.done ? 'Done' : 'Started' : 'Not Started' : 'Not Generated'}</b><br/>${value.generated ? value.isRandom ? '(Random)' : '(Not Random)' : ''}</br></br>${value?.date?.toUTCString()?.slice(5, 16)}`
                    };
                }}
            />
            <ReactTooltip html />
        </Layout>
    )
}

export default ChallengesCalendar