import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchTicket, sendTicketMessage, setTicketStatus } from '../../store/support/support.slice'
import styles from './TicketPopup.module.css'
import Base64 from '../../util/Base64'
import Button from '../../components/Button'
import Status from '../../store/status'
import { toast } from 'react-toastify'
import FunctionsService from '../../services/FunctionsService'
import { SubmissionView } from '../UserAnalytics/LessonFlow/LessonFlow'
import DatabaseService from '../../services/DatabaseService'

const Message = ({ body, time, sender, name }) => {
    return (
        <Layout fullWidth alignItems='flex-start' gap={2}>
            <Layout row fullWidth justifyContent='space-between'>
                <Text text={sender === 'user' ? `${name} (User)` : sender} type='small' color='darkGray' />
                <Text text={new Date(time).toLocaleString()} type='small' color='darkGray' />
            </Layout>
            <Layout classes={[styles.messageContainer]} alignItems='flex-start'>
                <pre className={styles.message}>
                    {Base64.decode(body)}
                </pre>
            </Layout>
        </Layout>
    )
}

const TicketPopup = ({ tid }) => {
    const [newMessage, setNewMessage] = useState('')
    const [user, setUser] = useState()
    const [noMail, setNoMail] = useState(false)
    const [done, setDone] = useState(null)
    const [loadingAttempt, setLoadingAttempt] = useState(false)
    const [lastAttempt, setLastAttempt] = useState(null)
    const [solution, setSolution] = useState(null)
    const [tests, setTests] = useState(null)
    const [previewIds, setPreviewIds] = useState(null)
    const [loadingSolution, setLoadingSolution] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const connectedUid = useSelector(state => state.user.data.connectedUid)
    const ticketData = useSelector(state => state.support.currTicket)
    const sendMessageStatus = useSelector(state => state.support.sendMessageStatus)

    useEffect(() => {
        if (tid !== undefined) {
            dispatch(fetchTicket({ tid }))
            setLastAttempt(null)
            setSolution(null)
            setPreviewIds(null)
        }
    }, [dispatch, tid])

    useEffect(() => {
        if (ticketData?.uid) {
            FunctionsService.getCoddyUser(ticketData.uid)
                .then(u => setUser(u))
        }
    }, [ticketData])

    useEffect(() => {
        if (previewIds === null && ticketData.courseId && ticketData.lessonId) {
            FunctionsService.getLessonPreviewIds(ticketData.courseId, ticketData.lessonId)
                .then(d => setPreviewIds(d))
        }
    }, [previewIds, ticketData.courseId, ticketData.lessonId])

    const handleSend = () => {
        if (connectedUid === undefined) {
            toast.error("Connected Coddy UID missing")
            return
        }
        dispatch(sendTicketMessage({ tid, message: Base64.encode(newMessage), noMail }))
    }

    const handleOpenContext = () => {
        if (ticketData.courseId === 'challenge') {
            window.open(`https://coddy.tech/challenges/${ticketData.lessonId}`)
        } else {
            window.open(`https://coddy.tech/courses/${ticketData.courseId}/${ticketData.lessonId}`)
        }
    }

    const handleOpenUser = () => {
        window.open(`/users/${user.uid}`)
    }

    const handleOpenCoddyPreview = () => {
        if (previewIds.cid === 'challenge') {
            window.open(`https://coddy.tech/preview/challenges/${previewIds.lid}`)
        } else {
            window.open(`https://coddy.tech/preview/courses/${previewIds.cid}/${previewIds.lid}`)
        }
    }

    const handleOpenCFCPreview = () => {
        window.open(`https://admin.coddy.tech/courses/${previewIds.cid}/lesson/${previewIds.lid}`)
    }

    const handleOpenClose = () => {
        dispatch(setTicketStatus({ tid, status: ticketData.status === 'open' ? 'close' : 'open' }))
    }

    const handleGetLastAttempt = () => {
        setLoadingAttempt(true)
        FunctionsService.getSubmissionsByUserFromContext(user?.uid, `${ticketData.courseId}-${ticketData.lessonId}`)
            .then((d) => {
                setLoadingAttempt(false)
                if (d.length > 0) {
                    setLastAttempt({
                        submission: d[0],
                        count: d.length,
                    })
                }
            })
    }

    const handleCopyLastAttempt = () => {
        navigator.clipboard.writeText(Base64.decode(lastAttempt.submission.payload));
    }

    const handleGetSolution = async () => {
        setLoadingSolution(true)
        let d = null;
        if (ticketData.courseId === 'challenge') {
            d = await DatabaseService.getChallenge(ticketData.lessonId)
        } else {
            d = await DatabaseService.getLesson(ticketData.courseId, ticketData.lessonId)
        }
        setTests(d.task.tests)
        if (d.isMultiFile) {
            setSolution({
                lang: Object.keys(d.task.solutionCodes)[0],
                payloads: d.task.solutionCodes,
            })
        } else if (lastAttempt) {
            setSolution({
                lang: lastAttempt.submission.lang,
                payload: d.task.solutionCodes[lastAttempt.submission.lang],
            })
        } else {
            setSolution({
                lang: Object.keys(d.task.solutionCodes)[0],
                payload: d.task.solutionCodes[Object.keys(d.task.solutionCodes)[0]],
            })
        }
        setLoadingSolution(false)
    }

    const handleCopySolution = () => {
        navigator.clipboard.writeText(Base64.decode(solution.payloads ? solution.payloads[solution.payloads.length - 1].payload : solution.payload));
    }

    useEffect(() => {
        if (sendMessageStatus === Status.Success) {
            setNewMessage('')
        }
    }, [sendMessageStatus])

    useEffect(() => {
        setDone(null)
        if (ticketData?.courseId) {
            FunctionsService.getCoddyUserCourseProgress(
                ticketData.uid,
                ticketData.courseId === 'challenge',
                ticketData.courseId === 'challenge' ? ticketData.lessonId : ticketData.courseId)
                .then(d => {
                    if (ticketData.courseId === 'challenge') {
                        setDone(d?.done ?? false)
                    } else {
                        if (d[ticketData.lessonId] !== undefined) {
                            setDone(d[ticketData.lessonId]?.done ?? false)
                        } else {
                            setDone(false)
                        }
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketData])

    return (
        <Layout classes={[styles.backdrop, tid === undefined ? null : styles.display]} alignItems='flex-end' onClick={() => { navigate(`/support`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16]} justifyContent='flex-start' gap={32}>
                <Layout row justifyContent='space-between' fullWidth alignItems='flex-start'>
                    <Layout alignItems='flex-start' fullWidth>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Context' type='small' color='darkGray' />
                            <Text text={`${ticketData.courseId}/${ticketData.lessonId}`} onClick={handleOpenContext} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='UID' type='small' color='darkGray' />
                            <Text text={user?.uid ?? ''} onClick={handleOpenUser} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Email' type='small' color='darkGray' />
                            <Text text={ticketData.email} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Country' type='small' color='darkGray' />
                            <Text text={user?.geo?.startCountry ? user?.geo?.startCountry : (user?.geo?.country ?? '-')} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Challenge done' type='small' color='darkGray' />
                            <Text text={done === null ? 'loading...' : done.toString()} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={8}>
                            <Text text='Preview' type='small' color='darkGray' />
                            <Text text={previewIds === null ? 'Loading...' : 'Coddy'} onClick={handleOpenCoddyPreview} />
                            <Text text={'/'} />
                            <Text text={previewIds === null ? 'Loading...' : 'CFC'} onClick={handleOpenCFCPreview} />
                        </Layout>
                    </Layout>
                    <Button text={ticketData.status === 'open' ? 'CLOSE' : 'OPEN'} onClick={handleOpenClose} />
                </Layout>
                <Layout fullWidth gap={16}>
                    <Layout fullWidth gap={16}>
                        {
                            ticketData?.messages?.map((m, i) => {
                                return (
                                    <Message key={i.toString()} {...m} name={user?.displayName ?? ''} />
                                )
                            })
                        }
                    </Layout>
                    <Layout fullWidth classes={[styles.textareaContainer]} alignItems='flex-start' gap={16}>
                        <textarea className={styles.textarea} value={newMessage} onChange={e => setNewMessage(e.target.value)} />
                        <Layout row fullWidth gap={8} justifyContent='flex-start' style={{ flexWrap: 'wrap' }}>
                            <Text text='Templates:' color='darkGray' />
                            <Text text='Solution available' onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

You have a solution available on this challenge, you should reveal it and learn from it!
If you have any specific questions let me know`)
                            }} />
                            <Text text='|' />
                            <Text text='Figured out' onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

It seems like you figured it out. Well done!
Let me know if you need any further assistance `)
                            }} />
                            <Text text='|' />
                            <Text text="Creator's solution" onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

Here is the creator's solution for your reference:

${solution?.payloads ?
                                        (solution?.payloads.map((p) => `${p.path.split('.')[1].toUpperCase()}:\n\n${Base64.decode(p.payload)}`).join('\n\n\n')) :
                                        (solution?.payload ? Base64.decode(solution.payload) : '')
                                    }`)
                            }} />
                            <Text text='|' />
                            <Text text="Beginner" onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

If you are a beginner you should not start from this course, go here:

coddy.tech/beginner

If not, let me know how exactly I can help :)`)
                            }} />
                            <Text text='|' />
                            <Text text="Only function" onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

Your code seems to be correct!

But, when you are asked to write a function in Coddy, you should write only the function implementation, don't call or execute the function - we do that for you behind the scenes with the test cases.

Let me know if you need any further assistance`)
                            }} />
                            <Text text='|' />
                            <Text text="Empty Hi" onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

`)
                            }} />
                            <Text text='|' />
                            <Text text="No Attemps" onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

I'm here to help! Can you elaborate a bit more on what you are struggling with?

I've been checking and you did not make any attempts in this challenge, so please be more specific or make an attempt so I'll be able to help accordingly.`)
                            }} />
                            <Text text='|' />
                            <Text text="Part of Solution" onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

Here is a part of the solution as a hint, complete the missing:

${solution?.payload ? Base64.decode(solution.payload) : ''}`)
                            }} />
                            <Text text='|' />
                            <Text text="Premium feedback" onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

We are still trying to figure out the right way to tackle the free plan.

I'm not sure you have seen, but you can buy premium for free using Coddy score, in our store.
You can get a score by completing challenges and referring friends.`)
                            }} />
                            <Text text='|' />
                            <Text text="Reveal tests" onClick={() => {
                                setNewMessage(`Hi${user?.displayName ? ' ' + user.displayName.split(' ')[0] : ''},

Here is the shadowed test case(s) for your reference:

${tests ? tests
    .map((t, i) => {return {...t, index: i}})
    .filter((t) => t.secret)
    .map((t) => `Test case #${t.index + 1}:\nInput: ${t.input.map(i => i.value)}\nExpected output: ${t.expectedOutput.value}`).join('\n\n') : ''}`)
                            }} />
                        </Layout>
                        <Layout row fullWidth justifyContent='space-between'>
                            <Button text={'SEND'} onClick={handleSend} />
                            <Layout row gap={8}>
                                <input checked={noMail} type='checkbox' onClick={e => setNoMail(e.target.checked)} />
                                <Text text='No email' />
                            </Layout>
                        </Layout>
                    </Layout>
                    <Layout fullWidth alignItems='flex-start'>
                        <Layout row justifyContent='space-between' fullWidth>
                            <Button text={loadingAttempt ? 'Loading...' : 'Get Last Attempt'} onClick={handleGetLastAttempt} />
                            <Button type='secondary' text={'copy'} onClick={handleCopyLastAttempt} disabled={!lastAttempt} />
                        </Layout>
                        {
                            lastAttempt ?
                                <SubmissionView submission={lastAttempt.submission} index={lastAttempt.count} />
                                : null
                        }
                    </Layout>
                    <Layout fullWidth alignItems='flex-start'>
                        <Layout row justifyContent='space-between' fullWidth>
                            <Button text={loadingSolution ? 'Loading...' : 'Get Solution'} onClick={handleGetSolution} />
                            <Button type='secondary' text={'copy'} onClick={handleCopySolution} disabled={!solution} />
                        </Layout>
                        {
                            solution ?
                                <SubmissionView submission={solution} />
                                : null
                        }
                    </Layout>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default TicketPopup