import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Status from '../status'
import {
    fetchContactFulfilled,
    fetchContactLoading,
    fetchContactRejected,
    fetchMoreContactFulfilled,
    fetchMoreContactRejected,
} from './contact.actions'
import FunctionsService from '../../services/FunctionsService'

export const fetchContact = createAsyncThunk('contact/fetchContact', async (_) => {
    return FunctionsService.getContactForms(undefined)
})

export const fetchMoreContact = createAsyncThunk('contact/fetchMoreContact', async (_, { getState }) => {
    const forms = getState().contact.forms
    return FunctionsService.getContactForms(forms[forms.length - 1]?.time)
})

export const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        forms: [],
        fetchStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchContact.pending, fetchContactLoading)
            .addCase(fetchContact.fulfilled, fetchContactFulfilled)
            .addCase(fetchContact.rejected, fetchContactRejected)
            .addCase(fetchMoreContact.fulfilled, fetchMoreContactFulfilled)
            .addCase(fetchMoreContact.rejected, fetchMoreContactRejected)
    },
})

export default contactSlice.reducer