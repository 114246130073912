import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cross from '../../assets/icons/Cross'
import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchCourses } from '../../store/tagging/tagging.slice'
import styles from './Popup.module.css'
import { publishJourney, saveJourney } from '../../store/journeys/journeys.slice'


const Popup = ({ id, add = false }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const savedData = useSelector(state => id ? state.journeys.data?.find(d => d.id === id) : undefined)
    const courses = useSelector(state => state.tagging.courses)
    const [data, setData] = useState({})
    const [searchedValue, setSearchedValue] = useState('')

    useEffect(() => {
        if (add) {
            setData({
                shadowed: true,
                title: '',
                description: '',
                coursesIds: [],
                bgImagePath: '',
                mainColor: '#000000',
                secondaryColor: '#000000',
                titlePath: ''
            })
        } else {
            setData(savedData)
        }
    }, [savedData, add])

    useEffect(() => {
        dispatch(fetchCourses())
    }, [dispatch])

    const handleSave = () => {
        dispatch(add ? publishJourney({ data }) : saveJourney({ data }))
    }

    const handleDeleteItem = (id) => {
        setData(o => {
            const newCourses = o.coursesIds.filter(t => t !== id)
            return { ...o, coursesIds: newCourses }
        })
    }

    const handleAddItem = (id) => {
        setData(o => {
            const newCourses = o.coursesIds.concat([id])
            return { ...o, coursesIds: newCourses }
        })
        setSearchedValue('')
    }

    return (
        <Layout classes={[styles.backdrop, id === undefined && add === false ? null : styles.display]} alignItems='flex-end' onClick={() => { navigate(`/journeys`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16, 16, 0, 16]} justifyContent='flex-start' alignItems='flex-start' gap={32}>
                {
                    add ?
                        null :
                        <Layout gap={4} alignItems='flex-start' fullWidth>
                            <Text text='Id:' />
                            <input
                                value={data?.id}
                                className={styles.input}
                                disabled />
                        </Layout>
                }
                <Layout gap={8} justifyContent='flex-start' fullWidth row>
                    <Text text='Shadowed:' />
                    <input
                        checked={data?.shadowed}
                        type='checkbox'
                        onChange={e => setData(o => { return { ...o, shadowed: e.target.checked } })} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Title:' />
                    <input
                        value={add ? (data?.title ?? '') : data?.title}
                        className={styles.input}
                        onChange={e => setData(o => { return { ...o, title: e.target.value } })} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Description:' />
                    <textarea
                        value={add ? (data?.description ?? '') : data?.description}
                        className={styles.input}
                        onChange={e => setData(o => { return { ...o, description: e.target.value } })} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Title image url:' />
                    <input
                        value={add ? (data?.titlePath ?? '') : data?.titlePath}
                        className={styles.input}
                        onChange={e => setData(o => { return { ...o, titlePath: e.target.value } })} />
                    <img src={add ? (data?.titlePath ?? '') : data?.titlePath} alt='' style={{ maxHeight: '120px', backgroundColor: data?.mainColor ?? '' }} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Main color:' />
                    <input
                        type='color'
                        value={add ? (data?.mainColor ?? '') : data?.mainColor}
                        className={styles.input}
                        onChange={e => setData(o => { return { ...o, mainColor: e.target.value } })} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Secondary color:' />
                    <input
                        type='color'
                        value={add ? (data?.secondaryColor ?? '') : data?.secondaryColor}
                        className={styles.input}
                        onChange={e => setData(o => { return { ...o, secondaryColor: e.target.value } })} />
                </Layout>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Background image url:' />
                    <input
                        value={add ? (data?.bgImagePath ?? '') : data?.bgImagePath}
                        className={styles.input}
                        onChange={e => setData(o => { return { ...o, bgImagePath: e.target.value } })} />
                    <img src={add ? (data?.bgImagePath ?? '') : data?.bgImagePath} alt='' style={{ maxHeight: '120px', backgroundColor: data?.mainColor ?? '' }} />
                </Layout>
                <Layout fullWidth gap={16} alignItems='flex-start'>
                    <Text text='Courses:' />
                    <Layout fullWidth classes={[styles.selectTagContainer]} alignItems='flex-start'>
                        <input
                            value={searchedValue}
                            className={styles.input}
                            onChange={e => setSearchedValue(e.target.value)} />
                        <Layout classes={[styles.tagsSelectionContainer]} alignItems='flex-start' justifyContent='flex-start'>
                            {
                                courses.filter(c =>
                                    !data?.coursesIds?.includes(c.id) &&
                                    (
                                        c.id.toLowerCase().includes(searchedValue.toLowerCase()) ||
                                        c.title.toLowerCase().includes(searchedValue.toLowerCase())
                                    ))
                                    .map(c =>
                                        <Text
                                            key={c.id}
                                            text={c.title}
                                            className={styles.tagSelection}
                                            onClick={() => handleAddItem(c.id)} />)
                            }
                        </Layout>
                    </Layout>
                    <Layout alignItems='flex-start' fullWidth gap={8}>
                        {
                            data?.coursesIds?.map(courseId => {
                                return (
                                    <Layout key={courseId} classes={[styles.tag]} padding={[4, 12]} row gap={8}>
                                        <Text text={courses.find(c => c.id === courseId)?.title} />
                                        <IconButton Icon={Cross} size='tiny' type={'white'} onClick={() => handleDeleteItem(courseId)} />
                                    </Layout>
                                )
                            })
                        }
                    </Layout>
                </Layout>
                <Layout row classes={[styles.bottomContainer]} fullWidth justifyContent='flex-start' padding={[8, 0]}>
                    <Button
                        text={add ? 'Publish' : 'Save'}
                        onClick={handleSave}
                        disabled={false} />
                </Layout>
            </Layout>
        </Layout>
    )
}

export default Popup