import { toast } from "react-toastify"
import Status from "../status"


export const fetchLastInsightsLoading = (state, _) => {
    state.fetchLastStatus = Status.Loading
}

export const fetchLastInsightsFulfilled = (state, action) => {
    state.fetchLastStatus = Status.Success
    state.lastInsights = action.payload
}

export const fetchLastInsightsRejected = (state, action) => {
    state.fetchLastStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchInsightsByContextLoading = (state, _) => {
    state.fetchByContextStatus = Status.Loading
}

export const fetchInsightsByContextFulfilled = (state, action) => {
    state.fetchByContextStatus = Status.Success
    state.insightsByContext = action.payload
}

export const fetchInsightsByContextRejected = (state, action) => {
    state.fetchByContextStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchInsightsCountFulfilled = (state, action) => {
    state.insightsCount = action.payload
}

export const fetchInsightFulfilled = (state, action) => {
    state.insight = action.payload
}
