import React from 'react'
import Form from './Form'
import styles from './Login.module.css'
import CoddyText from '../../assets/icons/CoddyText'
import Layout from '../../components/Layout'

const Login = () => {
  return (
    <div className={styles.container}>
      <Layout row fullWidth padding={[8, 12]} gap={16}>
        <Layout margin={[4, 0, 0, 0]}>
          <CoddyText size={196} white />
        </Layout>
      </Layout>

      <Form />
    </div>
  )
}

export default Login