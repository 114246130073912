import React from 'react'

const Youtube = ({ size, onClick }) => {
    return (
        <svg onClick={onClick} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={onClick ? { cursor: 'pointer' } : {}}>
            <g clipPath="url(#clip0_3772_576)">
                <path d="M23.47 5.65153C23.3323 5.13903 23.0638 4.6718 22.6911 4.29637C22.3185 3.92094 21.8547 3.6504 21.346 3.5117C19.4835 3 11.9878 3 11.9878 3C11.9878 3 4.49177 3.01549 2.62933 3.52719C2.1206 3.6659 1.65681 3.93645 1.28415 4.3119C0.911497 4.68735 0.642976 5.1546 0.505331 5.66711C-0.0580132 9.00081 -0.276545 14.0806 0.520799 17.2809C0.658459 17.7934 0.926986 18.2607 1.29964 18.6361C1.6723 19.0115 2.13608 19.2821 2.6448 19.4208C4.50724 19.9325 12.0031 19.9325 12.0031 19.9325C12.0031 19.9325 19.4989 19.9325 21.3612 19.4208C21.87 19.2821 22.3338 19.0116 22.7064 18.6361C23.0791 18.2607 23.3477 17.7935 23.4853 17.2809C24.0795 13.9425 24.2626 8.86585 23.47 5.65153Z" fill="#FF0000" />
                <path d="M9.60205 15.0946L15.8203 11.4663L9.60205 7.83789V15.0946Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_3772_576">
                    <rect width="24" height="17" fill="white" transform="translate(0 3)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Youtube