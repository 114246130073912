import React from 'react'

const Google = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3782_548)">
                <g clipPath="url(#clip1_3782_548)">
                    <path d="M23.9888 12.2244C23.9888 11.2412 23.9071 10.5236 23.7304 9.77954H12.2393V14.2175H18.9843C18.8484 15.3204 18.114 16.9814 16.4821 18.0975L16.4592 18.246L20.0925 20.9962L20.3442 21.0208C22.656 18.9346 23.9888 15.8652 23.9888 12.2244Z" fill="#4285F4" />
                    <path d="M12.2393 23.9176C15.5438 23.9176 18.3179 22.8545 20.3442 21.0209L16.4821 18.0976C15.4486 18.8018 14.0615 19.2934 12.2393 19.2934C9.00273 19.2934 6.25576 17.2074 5.27654 14.324L5.13301 14.3359L1.35507 17.1927L1.30566 17.3269C3.31829 21.2334 7.45238 23.9176 12.2393 23.9176Z" fill="#34A853" />
                    <path d="M5.27634 14.324C5.01797 13.58 4.86844 12.7826 4.86844 11.9589C4.86844 11.135 5.01797 10.3378 5.26275 9.59366L5.25591 9.43519L1.43062 6.53247L1.30547 6.59064C0.475969 8.21174 0 10.0322 0 11.9589C0 13.8855 0.475969 15.7059 1.30547 17.327L5.27634 14.324Z" fill="#FBBC05" />
                    <path d="M12.2393 4.62403C14.5374 4.62403 16.0877 5.59401 16.9717 6.40461L20.4258 3.10928C18.3044 1.1826 15.5438 0 12.2393 0C7.45238 0 3.31829 2.68406 1.30566 6.59056L5.26295 9.59359C6.25576 6.7102 9.00273 4.62403 12.2393 4.62403Z" fill="#EB4335" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_3782_548">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_3782_548">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Google