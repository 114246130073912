import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import styles from './FunnelPopup.module.css'
import Text from '../../components/Text'
import Button from '../../components/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFunnelsInfo, fetchMarketingFunnel, saveMarketingFunnel } from '../../store/marketing/marketing.slice'
import Status from '../../store/status'
import { toast } from 'react-toastify'
import Divider from '../../components/Divider/Divider'
import IconButton from '../../components/IconButton'
import Plus from '../../assets/icons/Plus'
import Delete from '../../assets/icons/Delete'

const DEFAULT_SETTINGS = {
    handleId: {}
}

const FunnelsPopup = ({ fid }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams,] = useSearchParams()
    const funnelsInfo = useSelector(state => state.marketing.funnelsInfo)
    const currFunnel = useSelector(state => state.marketing.currFunnel)
    const saveStatus = useSelector(state => state.marketing.saveStatus)

    const [newFid, setNewFid] = useState("")
    const [from, setFrom] = useState([])
    const [info, setInfo] = useState([])
    const [promo, setPromo] = useState([])
    const [defaultSettings, setDefaultSettings] = useState(DEFAULT_SETTINGS)
    const [selectedDefaultHandlePlatform, setSelectedDefaultHandlePlatform] = useState('instagram')
    const [performanceMarketing, setPerformanceMarketing] = useState(false)

    useEffect(() => {
        if (Object.keys(funnelsInfo).length === 0) {
            dispatch(fetchFunnelsInfo())
        }
    }, [dispatch, funnelsInfo])

    useEffect(() => {
        if (fid && fid !== 'add') {
            dispatch(fetchMarketingFunnel({ fid }))
        }
    }, [fid, dispatch])

    useEffect(() => {
        if (fid !== 'add' && currFunnel) {
            setFrom(currFunnel.from ?? [])
            setInfo(currFunnel.info ?? [])
            setPromo(currFunnel.promo ?? [])
            setDefaultSettings(currFunnel.defaultSettings ?? DEFAULT_SETTINGS)
            setPerformanceMarketing(currFunnel.performanceMarketing ?? false)
        } else {
            setFrom([])
            setInfo([])
            setPromo([])
            setDefaultSettings(DEFAULT_SETTINGS)
            setPerformanceMarketing(false)
        }
    }, [currFunnel, fid])

    const handleEdifFid = (s) => {
        setNewFid(s)
    }

    const handeAddProperty = (currValue, setter) => {
        setter((o) => o.concat(""))
    }

    const handeDeleteProperty = (index, setter) => {
        setter((o) => o.filter((_, i) => i !== index))
    }

    const handeEditProperty = (index, value, setter) => {
        setter((o) => o.map((d, i) => index === i ? value : d))
    }

    const handeTogglePerformanceMarketing = (value) => {
        setPerformanceMarketing(value)
    }

    const handleClose = () => {
        searchParams.delete('fid');
        navigate({ search: searchParams.toString() })
    }

    const handleSave = () => {
        if (fid === 'add' && newFid.length < 3) {
            toast.error("Funnel ID must be at least 3 characters long")
            return;
        }

        if (from.some(f => f.length < 3) || info.some(f => f.length < 3) || promo.some(f => f.length < 3)) {
            toast.error("Property ID must be at least 3 characters long")
            return;
        }

        const data = {
            lastUpdated: Date.now(),
            from: from,
            info: info,
            promo: promo,
            defaultSettings: defaultSettings,
            performanceMarketing: performanceMarketing,
        }

        if (fid === 'add') {
            data.created = Date.now()
        }

        dispatch(saveMarketingFunnel({ fid: fid === 'add' ? newFid : fid, data, isNew: fid === 'add' }))
    }

    const handleEditDefaultHandle = (val) => {
        setDefaultSettings(o => {
            return {
                ...o,
                handleId: {
                    ...o.handleId,
                    [selectedDefaultHandlePlatform]: val,
                }
            }
        })
    }

    if (currFunnel === null && fid !== 'add') {
        <Layout classes={[styles.backdrop, !fid ? null : styles.display]} alignItems='flex-end' onClick={handleClose}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[32, 32, 0, 32]} justifyContent='flex-start' gap={32}>
                <Text text='Loading...' />
            </Layout>
        </Layout>
    }

    return (
        <Layout classes={[styles.backdrop, !fid ? null : styles.display]} alignItems='flex-end' onClick={handleClose}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[32, 32, 0, 32]} justifyContent='flex-start' gap={32}>
                <Layout row fullWidth gap={16} justifyContent='flex-start'>
                    <Text text='Funnel ID:' />
                    <input className={styles.input} value={fid === 'add' ? newFid : fid} onChange={(e) => handleEdifFid(e.target.value)} disabled={fid !== 'add'} />
                </Layout>
                <Layout fullWidth gap={16} alignItems='flex-start'>
                    <Text text='Properties' color='darkGray' />
                    <Divider />
                    <Layout fullWidth gap={16} alignItems='flex-start'>
                        <Layout row fullWidth gap={16} justifyContent='flex-start'>
                            <Text text='Performance marketing:' />
                            <input className={styles.input} checked={performanceMarketing} type='checkbox' value={performanceMarketing} onChange={e => handeTogglePerformanceMarketing(e.target.checked)} />
                        </Layout>
                        <Layout row justifyContent='space-between' fullWidth>
                            <Text text='From IDs:' />
                            <IconButton Icon={Plus} label={'Add'} onClick={() => handeAddProperty(from, setFrom)} />
                        </Layout>
                        {
                            from?.map((f, i) =>
                                <Layout row gap={16} key={`${i}`}>
                                    <input className={styles.input} value={f} onChange={(e) => handeEditProperty(i, e.target.value, setFrom)} />
                                    <IconButton Icon={Delete} label={'Delete'} onClick={() => handeDeleteProperty(i, setFrom)} />
                                </Layout>
                            )
                        }
                    </Layout>
                    <Layout fullWidth gap={16} alignItems='flex-start'>
                        <Layout row justifyContent='space-between' fullWidth>
                            <Text text='Info/Poll IDs:' />
                            <IconButton Icon={Plus} label={'Add'} onClick={() => handeAddProperty(info, setInfo)} />
                        </Layout>
                        {
                            info?.map((f, i) =>
                                <Layout row gap={16}>
                                    <input className={styles.input} value={f} onChange={(e) => handeEditProperty(i, e.target.value, setInfo)} />
                                    <IconButton Icon={Delete} label={'Delete'} onClick={() => handeDeleteProperty(i, setInfo)} />
                                </Layout>
                            )
                        }
                    </Layout>
                    <Layout fullWidth gap={16} alignItems='flex-start'>
                        <Layout row justifyContent='space-between' fullWidth>
                            <Text text='Promotion Codes:' />
                            <IconButton Icon={Plus} label={'Add'} onClick={() => handeAddProperty(promo, setPromo)} />
                        </Layout>
                        {
                            promo?.map((f, i) =>
                                <Layout row gap={16}>
                                    <input className={styles.input} value={f} onChange={(e) => handeEditProperty(i, e.target.value, setPromo)} />
                                    <IconButton Icon={Delete} label={'Delete'} onClick={() => handeDeleteProperty(i, setPromo)} />
                                </Layout>
                            )
                        }
                    </Layout>
                </Layout>
                <Layout fullWidth gap={16} alignItems='flex-start'>
                    <Text text='Default Settings' color='darkGray' />
                    <Divider />
                    {
                        performanceMarketing ?
                            null :
                            <Layout row fullWidth gap={16} justifyContent='flex-start'>
                                <Text text='Handle ID:' />
                                <select value={selectedDefaultHandlePlatform} name="platforms" id="platforms" className={styles.input} onChange={(e) => setSelectedDefaultHandlePlatform(e.target.value)}>
                                    <option value="instagram">Instagram</option>
                                    <option value="tiktok">TikTok</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="twitter">Twitter</option>
                                    <option value="youtube">Youtube</option>
                                    <option value="linkedin">LinkedIn</option>
                                </select>
                                <input
                                    className={styles.input}
                                    value={defaultSettings?.handleId[selectedDefaultHandlePlatform] ?? ''}
                                    onChange={e => handleEditDefaultHandle(e.target.value)} />
                            </Layout>
                    }
                </Layout>
                <Layout row classes={[styles.bottomContainer]} fullWidth justifyContent='space-between' padding={[32, 0, 32, 0]} gap={32}>
                    <Button
                        text={fid === 'add' ? 'Add' : 'Save'}
                        disabled={saveStatus === Status.Loading}
                        onClick={handleSave} />
                </Layout>
                <Divider />
                <Layout >

                </Layout>
            </Layout>
        </Layout>
    )
}

export default FunnelsPopup