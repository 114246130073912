import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Cross from '../../assets/icons/Cross'
import Button from '../../components/Button/Button'
import IconButton from '../../components/IconButton'
import Layout from '../../components/Layout'
import Text from '../../components/Text/Text'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../../store/status'
import { updateCourse, updateLesson, updateTag, updateTerm } from '../../store/tagging/tagging.slice'
import styles from './Popup.module.css'



const Popup = ({ add, type }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id, courseId } = useParams()
    const [data, setData] = useState({})
    const [searchedTag, setSearchedTag] = useState('')
    const [searchedTerm, setSearchedTerm] = useState('')
    const updateTagStatus = useSelector(state => state.tagging.updateTagStatus)
    const updateTermStatus = useSelector(state => state.tagging.updateTermStatus)
    const updateCourseStatus = useSelector(state => state.tagging.updateCourseStatus)
    const updateLessonStatus = useSelector(state => state.tagging.updateLessonStatus)
    const tags = useSelector(state => state.tagging.tags)
    const terms = useSelector(state => state.tagging.terms)

    useEffect(() => {
        setData({})
        if (!add) {
            if (type === 'tag') {
                DatabaseService.getTag(id)
                    .then(d => {
                        setData({
                            title: d.title,
                            desc: d.desc,
                            alias: d.alias.join(','),
                            id,
                        })
                    })
            } else if (type === 'term') {
                DatabaseService.getTerm(id)
                    .then(d => {
                        setData({
                            title: d.title,
                            id
                        })
                    })
            } else if (type === 'course') {
                DatabaseService.getCourse(id)
                    .then(d => {
                        setData({
                            title: d.title,
                            shadowed: d.shadowed,
                            tags: d.tags,
                            id
                        })
                    })
            } else if (type === 'lesson') {
                FunctionsService.getLessonTerms(courseId, id)
                    .then(d => {
                        setData({
                            terms: d.data,
                            id,
                        })
                    })
            }
        } else {
            setData({})
        }
    }, [id, add, type, courseId])

    const handleShow = () => {
        if (type === 'tag') {
            window.open(`https://coddy.tech/explore/tag/${id}`, '_blank').focus();
        } else if (type === 'course') {
            window.open(`https://coddy.tech/courses/${id}`, '_blank').focus();
        } else if (type === 'lesson') {
            window.open(`https://coddy.tech/courses/${courseId}/${id}`, '_blank').focus();
        }
    }

    const handleSave = () => {
        if (type === 'tag') {
            dispatch(updateTag({ id: data.id, data: { title: data.title, desc: data.desc, alias: data.alias.split(',') } }))
        } else if (type === 'term') {
            dispatch(updateTerm({ id: data.id, data: { title: data.title } }))
        } else if (type === 'course') {
            dispatch(updateCourse({ id: data.id, data: { shadowed: data.shadowed, tags: data.tags } }))
        } else if (type === 'lesson') {
            dispatch(updateLesson({ courseId, lessonId: data.id, terms: data.terms }))
        }
    }

    const handleDeleteTag = (id) => {
        setData(o => {
            const newTags = o.tags.filter(t => t !== id)
            return { ...o, tags: newTags }
        })
    }

    const handleAddTag = (id) => {
        setData(o => {
            const newTags = o.tags.concat([id])
            return { ...o, tags: newTags }
        })
        setSearchedTag('')
    }

    const handleDeleteTerm = (id) => {
        setData(o => {
            const newTerms = o.terms.filter(t => t.id !== id)
            return { ...o, terms: newTerms }
        })
    }

    const handleAddTerm = (id) => {
        setData(o => {
            const newTerms = o.terms.concat([{ id, weight: 0.0 }])
            return { ...o, terms: newTerms }
        })
        setSearchedTerm('')
    }

    const handleSetWeightTerm = (id, weight) => {
        setData(o => {
            const newTerms = o.terms.map(term => {
                if (term.id === id) {
                    term.weight = weight
                }
                return term
            })
            return { ...o, terms: newTerms }
        })
        setSearchedTerm('')
    }

    return (
        <Layout classes={[styles.backdrop, type !== undefined ? styles.display : null]} alignItems='flex-end' onClick={() => { navigate(`/tagging`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16]} justifyContent='space-between' alignItems='flex-start'>
                <Layout fullWidth gap={16}>
                    <Layout gap={4} alignItems='flex-start' fullWidth>
                        <Text text='Id:' />
                        <input
                            value={data.id}
                            className={[styles.input, add ? null : styles.disabled].join(' ')}
                            readOnly={!add}
                            onChange={e => setData(o => { return { ...o, id: e.target.value } })} />
                    </Layout>
                    {
                        type === 'tag' || type === 'term' ?
                            <Layout gap={4} alignItems='flex-start' fullWidth>
                                <Text text='Title:' />
                                <input
                                    value={data.title}
                                    className={styles.input}
                                    onChange={e => setData(o => { return { ...o, title: e.target.value } })} />
                            </Layout> :
                            null
                    }
                    {
                        type === 'course' ?
                            <Layout gap={8} justifyContent='flex-start' fullWidth row>
                                <Text text='Shadowed:' />
                                <input
                                    checked={data.shadowed}
                                    type='checkbox'
                                    onChange={e => setData(o => { return { ...o, shadowed: e.target.checked } })} />
                            </Layout> :
                            null
                    }
                    {
                        type === 'course' ?
                            <Layout gap={4} alignItems='flex-start' fullWidth>
                                <Text text='Tags:' />
                                <Layout fullWidth classes={[styles.selectTagContainer]} alignItems='flex-start'>
                                    <input
                                        value={searchedTag}
                                        className={styles.input}
                                        onChange={e => setSearchedTag(e.target.value)} />
                                    <Layout classes={[styles.tagsSelectionContainer]} alignItems='flex-start' justifyContent='flex-start'>
                                        {
                                            tags.filter(t =>
                                                !data.tags?.includes(t.id) &&
                                                (
                                                    t.id.toLowerCase().includes(searchedTag.toLowerCase()) ||
                                                    t.title.toLowerCase().includes(searchedTag.toLowerCase())
                                                ))
                                                .map(tag =>
                                                    <Text
                                                        key={tag.id}
                                                        text={tag.title}
                                                        className={styles.tagSelection}
                                                        onClick={() => handleAddTag(tag.id)} />)
                                        }
                                    </Layout>
                                </Layout>
                                <Layout fullWidth alignItems='flex-start' justifyContent='flex-start' classes={[styles.tagsContainer]}>
                                    {
                                        data.tags?.map(tag =>
                                            <Layout classes={[styles.tagContainer]} row gap={8} padding={[4, 8, 4, 16]} key={tag}>
                                                <Text text={tags.find(t => t.id === tag).title} className={styles.tag} />
                                                <IconButton Icon={Cross} size='tiny' type={'white'} onClick={() => handleDeleteTag(tag)} />
                                            </Layout>
                                        )
                                    }
                                </Layout>
                            </Layout> :
                            null
                    }
                    {
                        type === 'lesson' ?
                            <Layout gap={4} alignItems='flex-start' fullWidth>
                                <Text text='Terms:' />
                                <Layout fullWidth classes={[styles.selectTagContainer]} alignItems='flex-start'>
                                    <input
                                        value={searchedTerm}
                                        className={styles.input}
                                        onChange={e => setSearchedTerm(e.target.value)} />
                                    <Layout classes={[styles.tagsSelectionContainer]} alignItems='flex-start' justifyContent='flex-start'>
                                        {
                                            terms.filter(t =>
                                                !data.terms?.some(tt => tt.id === t.id) &&
                                                (
                                                    t.id.toLowerCase().includes(searchedTerm.toLowerCase()) ||
                                                    t.title.toLowerCase().includes(searchedTerm.toLowerCase())
                                                ))
                                                .map(term =>
                                                    <Text
                                                        key={term.id}
                                                        text={term.title}
                                                        className={styles.tagSelection}
                                                        onClick={() => handleAddTerm(term.id)} />)
                                        }
                                    </Layout>
                                </Layout>
                                <Layout fullWidth alignItems='flex-start' justifyContent='flex-start' classes={[styles.tagsContainer]}>
                                    {
                                        data.terms?.map(term =>
                                            <Layout classes={[styles.tagContainer]} row gap={8} padding={[4, 8, 4, 16]} key={term.id}>
                                                <Text text={terms.find(t => t.id === term.id).title} className={styles.tag} />
                                                <input value={term.weight} className={styles.termWeight} onChange={(e) => handleSetWeightTerm(term.id, e.target.value)} />
                                                <IconButton Icon={Cross} size='tiny' type={'white'} onClick={() => handleDeleteTerm(term.id)} />
                                            </Layout>
                                        )
                                    }
                                </Layout>
                            </Layout> :
                            null
                    }
                    {
                        type === 'tag' ?
                            < Layout gap={4} alignItems='flex-start' fullWidth>
                                <Text text='Description:' />
                                <textarea
                                    value={data.desc}
                                    className={styles.input}
                                    onChange={e => setData(o => { return { ...o, desc: e.target.value } })} />
                            </Layout> :
                            null
                    }
                    {
                        type === 'tag' ?
                            <Layout gap={4} alignItems='flex-start' fullWidth>
                                <Text text='Aliases:' />
                                <textarea
                                    value={data.alias}
                                    className={styles.input}
                                    onChange={e => setData(o => { return { ...o, alias: e.target.value } })} />
                            </Layout> :
                            null
                    }
                </Layout>
                <Layout row>
                    <Button
                        text={add ? 'Add' : 'Save'}
                        onClick={handleSave}
                        disabled={
                            type === 'tag' ?
                                updateTagStatus === Status.Loading :
                                type === 'course' ?
                                    updateCourseStatus === Status.Loading :
                                    type === 'lesson' ?
                                        updateLessonStatus === Status.Loading :
                                        updateTermStatus === Status.Loading
                        } />
                    {
                        add || type === 'term' ?
                            null :
                            <Button text={'Show'} type='secondary' onClick={handleShow} />
                    }
                </Layout>
            </Layout>
        </Layout >
    )
}

export default Popup