import { toast } from "react-toastify"
import Status from "../status"

export const setShowCloseAction = (state, action) => {
    state.showClose = action.payload
}

export const fetchContactLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchContactFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.forms = action.payload
}

export const fetchContactRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchMoreContactFulfilled = (state, action) => {
    state.forms = state.forms.concat(action.payload)
}

export const fetchMoreContactRejected = (state, action) => {
    toast.error(action.error.message)
}
