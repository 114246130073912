import React from 'react'
import { MARGIN } from '../../Constants'
import Layout from '../Layout'
import Text from '../Text/Text'

const FloatBox = ({ children, title = '', style = {}, isActive = false, onExit = () => { } }) => {

    return (
        <>
            {isActive ?
                <>
                    <div style={{
                        "width": "100%",
                        "height": "100%",
                        "backgroundColor": "#0000005C",
                        "zIndex": "10",
                        "position": "absolute",
                        "top": "0",
                        "left": "0",
                    }}
                        onClick={onExit}
                    ></div>
                    <Layout padding={[MARGIN]} justifyContent="" content color="white" border style={{
                        "zIndex": "11",
                        "position": "absolute",
                        "top": "40%",
                        "right": "50%",
                        "transform": "translate(50%, -50%)",
                        ...style
                    }} >
                        <Layout fullWidth alignItems='start' padding={[0, 0, MARGIN, 0]}>
                            <Text text={title} type='subTitle' />
                        </Layout>


                        {children}
                    </Layout>

                </> : <></>
            }

        </>



    )
}
export default FloatBox