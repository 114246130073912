import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
    fetchAIFeedbacksFulfilled,
    fetchAIFeedbacksLoading,
    fetchAIFeedbacksRejected,
    fetchAIResponsesFulfilled,
    fetchAIResponsesRejected,
} from './aiFeedbacks.actions'

export const fetchAIFeedbacks = createAsyncThunk('aiFeedbacks/fetchAIFeedbacks', async ({ more }, { getState }) => {
    const currData = getState().aiFeedbacks.data
    if (more && currData.length > 0) {
        return FunctionsService.getAIFeedbacks(currData[currData.length - 1].timestamp).then(d => d.data)
    } else {
        let res = await FunctionsService.getAIFeedbacks().then(d => d.data)
        const more = await FunctionsService.getAIFeedbacks(res[res.length - 1].timestamp).then(d => d.data)
        res = [...res, ...more]
        return res
    }
})

export const fetchAIResponses = createAsyncThunk('aiFeedbacks/fetchAIResponses', async (_, { getState }) => {
    const currData = getState().aiFeedbacks.responses
    if (currData.length > 0) {
        return currData
    } else {
        return FunctionsService.getLastAIResponses()
    }
})

export const aiFeedbacksSlice = createSlice({
    name: 'aiFeedbacks',
    initialState: {
        data: [],
        responses: [],
        fetchStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAIFeedbacks.pending, fetchAIFeedbacksLoading)
            .addCase(fetchAIFeedbacks.fulfilled, fetchAIFeedbacksFulfilled)
            .addCase(fetchAIFeedbacks.rejected, fetchAIFeedbacksRejected)
            .addCase(fetchAIResponses.fulfilled, fetchAIResponsesFulfilled)
            .addCase(fetchAIResponses.rejected, fetchAIResponsesRejected)
    },
})

export default aiFeedbacksSlice.reducer