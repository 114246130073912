import React from 'react'
import styles from './SearchBar.module.css'
import Search from '../../assets/icons/Search'
import Layout from '../Layout'

const SearchBar = ({ onChange }) => {
    const handleChange = (e) => {
        onChange(e.target.value)
    }

    return (
        <Layout row gap={8} classes={[styles.container]} padding={[8, 8]}>
            <Search size={24} color='black' />
            <input className={styles.input} onChange={handleChange} />
        </Layout>
    )
}

export default SearchBar