import React, { useEffect } from 'react'
import styles from './FunnelsView.module.css'
import Layout from '../../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLastMarketingFunnels } from '../../store/marketing/marketing.slice'
import { createSearchParams, useNavigate } from 'react-router-dom'
import IconButton from '../../components/IconButton'
import Cohort from '../../assets/icons/Cohort'

const FunnelsView = () => {
    const dispatch = useDispatch()
    const funnels = useSelector(state => state.marketing.funnels)

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchLastMarketingFunnels({ more: true }))
        }
    }

    useEffect(() => {
        dispatch(fetchLastMarketingFunnels({ more: false }))
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' gap={16}>
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.actions}></th>
                    <th className={styles.fid}>FID</th>
                    <th className={styles.dueDate}></th>
                    <th className={styles.lastUpdated}>Last updated</th>
                </tr>
                {
                    funnels.map(funnel =>
                        <FunnelView
                            key={funnel.fid}
                            funnel={funnel} />
                    )
                }
            </table>
        </Layout>
    )
}


const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR

const FunnelView = ({ funnel }) => {
    const navigate = useNavigate()

    let lastActivity = ''
    if (funnel?.lastUpdated !== undefined) {
        const time = funnel.lastUpdated
        const now = Date.now()
        const diff = now - time;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    const handleOpenAnalytics = (e) => {
        e.stopPropagation();
        navigate({ search: createSearchParams({ 'analytics': funnel.fid }).toString() })
    }

    const isWithAnalytics = funnel?.from?.length > 0 || funnel?.info?.length > 0 || funnel?.promo?.length > 0

    return (
        <tr className={styles.tableRow} onClick={() => { navigate({ search: createSearchParams({ 'fid': funnel.fid }).toString() }) }}>
            <td className={styles.actions}>
                {
                    isWithAnalytics ?
                        <IconButton Icon={Cohort} size={'small'} onClick={handleOpenAnalytics} /> :
                        null
                }
            </td>
            <td className={styles.fid}>{funnel.fid}</td>
            <td className={styles.dueDate}>{ }</td>
            <td className={styles.lastUpdated}>{lastActivity}</td>
        </tr>
    )
}


export default FunnelsView