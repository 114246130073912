import React, { useEffect } from 'react'
import styles from './Carousels.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import Popup from './Popup'
import { fetchCarousels } from '../../store/carousels/carousels.slice'

const Carousels = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const data = useSelector(state => state.carousels.data)

    useEffect(() => {
        dispatch(fetchCarousels())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <table className={styles.table}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.id}>Id</th>
                    <th className={styles.num}>Number of courses</th>
                </tr>
                {
                    data.map(d =>
                        <RowView
                            key={d.id}
                            id={d.id}
                            courses={d.courses} />
                    )
                }
            </table>
            <Popup id={id} />
        </Layout>
    )
}

const RowView = ({ id, courses }) => {

    const navigate = useNavigate()

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/carousels/${id}`) }}>
            <td className={styles.id}>{id}</td>
            <td className={styles.num}>{courses.length}</td>
        </tr>
    )
}

export default Carousels