import React from 'react'

const Mark = ({ color = '#262F56', width = '1.8' }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 6L9 18L3 12" stroke={color} strokeWidth={width} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Mark