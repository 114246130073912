import React, { useState } from 'react'
import Layout from '../Layout'
import { DateRange } from 'react-date-range';
import Button, { TYPE_NO_DECORATION } from './Button/Button';

const Range = ({ onSubmit, initRange }) => {

    const [tempState, setTempState] = useState([
        {
            startDate: initRange.startDate,
            endDate: initRange.endDate,
            key: 'selection'
        }]
    )

    const dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
    return (
        <Layout>
            <DateRange
                editableDateInputs={true}
                onChange={item => setTempState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={tempState} />

            <Layout>
                <Button text="Apply" type={TYPE_NO_DECORATION} onClick={() => onSubmit(tempState, dateDiffInDays(tempState[0].startDate, tempState[0].endDate))} />
            </Layout>


        </Layout>
    )
}

export default Range