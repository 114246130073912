import React from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text/Text'
import { MAIN_COLOR } from '../../Constants'

const PeopleTypeButton = ({
    text='',
    selected=false,
    onClick,
}) => {
    return (
        <Layout margin={[8, 8, 0, 8]} onClick={onClick}>
            <Text text={text} color={selected ? 'mainColor' : "darkGray"} bold={selected ? true : false}/>
            <div style={{"height": "4px"}}></div>
            <div style={{"width": "48px", 
            "height": "3px", 
            "opacity": selected ? "1" : "0", 
            "backgroundColor": MAIN_COLOR, 
            "borderRadius": "10px",
            "transition": "0.3s ease-in-out"
            
            }}></div>
        </Layout>
    )
}

export default PeopleTypeButton