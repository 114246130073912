import React from 'react'
import styles from './Settings.module.css'
import SidebarActions from './SidebarActions'
import Profile from './Profile'

const Settings = () => {
    return (
        <div className={styles.container}>
            <SidebarActions />
            <Profile />
        </div>
    )
}

export default Settings