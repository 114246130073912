import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import styles from './TicketPopup.module.css'
import Text from '../../components/Text'
import Divider from '../../components/Divider/Divider'
import Button from '../../components/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { deleteMarketingTicket, fetchFunnelsInfo, fetchMarketingFunnel, fetchMarketingTicket, saveMarketingTicket, setCurrTicketId } from '../../store/marketing/marketing.slice'
import IconButton from '../../components/IconButton/IconButton'
import Delete from '../../assets/icons/Delete'
import DatabaseService from '../../services/DatabaseService'
import Status from '../../store/status'

const EMPTY_DELIVERABLE = {
    platform: "instagram",
    url: "",
    handleId: "",
    publishTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, -8),
}

const EMPTY_DEAL = {
    price: 0,
    revshare: false,
    percentage: 0,
}

const EMPTY_ADDITIONAL = {
    repost: false,
    usageRights: false,
    memo: '',
}

const TicketPopup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams,] = useSearchParams()
    const funnelsInfo = useSelector(state => state.marketing.funnelsInfo)
    const currTicket = useSelector(state => state.marketing.currTicket)
    const currFunnel = useSelector(state => state.marketing.currFunnel)
    const currTicketId = useSelector(state => state.marketing.currTicketId)
    const saveStatus = useSelector(state => state.marketing.saveStatus)

    const tid = searchParams.get('tid')

    const [fid, setFid] = useState()
    const [deliverables, setDeliverables] = useState([])
    const [deal, setDeal] = useState(EMPTY_DEAL)
    const [additional, setAdditional] = useState(EMPTY_ADDITIONAL)

    useEffect(() => {
        if (Object.keys(funnelsInfo).length === 0) {
            dispatch(fetchFunnelsInfo())
        }
    }, [dispatch, funnelsInfo])

    useEffect(() => {
        dispatch(setCurrTicketId(tid))
    }, [tid, dispatch])

    useEffect(() => {
        if (fid) {
            dispatch(fetchMarketingFunnel({ fid }))
        }
    }, [fid, dispatch])

    useEffect(() => {
        if (funnelsInfo) {
            const ordered = Object.keys(funnelsInfo).sort()
            setFid(ordered[0])
        }
    }, [funnelsInfo])

    const handleAddDeliverable = () => {
        const d = EMPTY_DELIVERABLE
        const id = DatabaseService.getDeliverableId();
        d.id = id;
        if (currFunnel) {
            d.handleId = currFunnel?.defaultSettings?.handleId['instagram'] ?? ''
        }
        setDeliverables((o) => o.concat([{ ...d }]))
    }

    const handleDeleteDeliverable = (id) => {
        setDeliverables((o) => o.filter(d => d.id !== id))
    }

    const handleEditDeliverable = (id, field, value) => {
        setDeliverables((o) => {
            const index = o.findIndex(d => d.id === id);
            const newData = { ...o[index] }
            newData[field] = value;
            return o.slice(0, index).concat([newData]).concat(o.slice(index + 1))
        })
    }

    const handleEditDeal = (field, value) => {
        if (field === 'price' || field === 'percentage') {
            value = Number(value)
        }
        if (field === 'revshare' && value === false) {
            handleEditDeal('percentage', 0)
        }
        setDeal(o => {
            return {
                ...o,
                [field]: value,
            }
        })
    }

    const handleEditAdditional = (field, value) => {
        setAdditional(o => {
            return {
                ...o,
                [field]: value,
            }
        })
    }

    const handleSave = () => {
        const data = {
            fid,
            deal,
            deliverables: deliverables.map((d) => {
                const res = {
                    ...d,
                    publishTime: new Date(d.publishTime).getTime()
                }
                return res
            }),
            additional,
            lastUpdated: Date.now(),
        }

        if (currTicketId === 'add') {
            data.created = Date.now()
        }

        dispatch(saveMarketingTicket({ tid: currTicketId === 'add' ? null : currTicketId, data }))
    }

    const handleDelete = () => {
        dispatch(deleteMarketingTicket({ tid: currTicketId }))
    }

    const handleClose = () => {
        searchParams.delete('tid');
        navigate({ search: searchParams.toString() })
    }

    const handleOpenManageFunnels = () => {
        searchParams.append('fid', 'add');
        navigate({ search: searchParams.toString() })
    }

    useEffect(() => {
        if (currTicketId && currTicketId !== 'add') {
            dispatch(fetchMarketingTicket({ tid: currTicketId }))
        }
    }, [currTicketId, dispatch])

    useEffect(() => {
        if (currTicketId !== 'add' && currTicket) {
            setFid(currTicket.fid)
            setDeal(currTicket.deal)
            setDeliverables(currTicket.deliverables.map((d) => {
                const res = {
                    ...d,
                    publishTime: d.publishTime === 0 ? null : new Date(d.publishTime - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, -1)
                }
                return res
            }))
            setAdditional(currTicket.additional)
        }
    }, [currTicket, currTicketId])

    useEffect(() => {
        if (currTicketId === 'add') {
            setDeal(EMPTY_DEAL)
            setDeliverables([])
            setAdditional(EMPTY_ADDITIONAL)
        }
    }, [currTicketId])

    const funnelsOrder = Object.keys(funnelsInfo).sort()

    if (currTicket === null && currTicketId !== 'add') {
        <Layout classes={[styles.backdrop, !currTicketId ? null : styles.display]} alignItems='flex-end' onClick={handleClose}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[32, 32, 0, 32]} justifyContent='flex-start' gap={32}>
                <Text text='Loading...' />
            </Layout>
        </Layout>
    }

    return (
        <Layout classes={[styles.backdrop, !currTicketId ? null : styles.display]} alignItems='flex-end' onClick={handleClose}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[32, 32, 0, 32]} justifyContent='flex-start' gap={32}>
                <Layout row fullWidth justifyContent='space-between'>
                    <Layout row gap={16} justifyContent='flex-start'>
                        <Text text='Funnel ID:' />
                        <select value={fid} name="funnels" id="funnels" className={styles.input} onChange={(e) => setFid(e.target.value)}>
                            {
                                funnelsOrder.map((key) => <option key={key} value={key}>{key}</option>)
                            }
                        </select>
                    </Layout>
                    <Button text={'Add Funnel'} type='secondary' onClick={handleOpenManageFunnels} />
                </Layout>
                <Layout gap={16} fullWidth alignItems='flex-start'>
                    <Text text='Deliverable(s)' color='darkGray' />
                    <Divider />
                    <Layout gap={16} fullWidth alignItems='flex-start'>
                        {
                            deliverables.map((d, i) =>
                                <Layout key={d.id} row fullWidth padding={[16]} color={'#f1f1f1'} alignItems='flex-start'>
                                    <Layout gap={8} fullWidth alignItems='flex-start'>
                                        <Layout row gap={16} fullWidth justifyContent='flex-start'>
                                            <Text text='Platform:' />
                                            <select value={d.platform} name="platforms" id="platforms" className={styles.input} onChange={(e) => handleEditDeliverable(d.id, 'platform', e.target.value)}>
                                                <option value="instagram">Instagram</option>
                                                <option value="tiktok">TikTok</option>
                                                <option value="facebook">Facebook</option>
                                                <option value="twitter">Twitter</option>
                                                <option value="youtube">Youtube</option>
                                                <option value="linkedin">LinkedIn</option>
                                            </select>
                                        </Layout>
                                        <Layout row gap={16} fullWidth justifyContent='flex-start'>
                                            <Text text='URL:' />
                                            <input className={styles.input} value={d.url} onChange={(e) => handleEditDeliverable(d.id, 'url', e.target.value)} />
                                        </Layout>
                                        <Layout row gap={16} fullWidth justifyContent='flex-start'>
                                            <Text text='Handle ID:' />
                                            <input className={styles.input} value={d.handleId} onChange={(e) => handleEditDeliverable(d.id, 'handleId', e.target.value)} />
                                        </Layout>
                                        <Layout row gap={16} fullWidth justifyContent='flex-start'>
                                            <Text text='Time of publish:' />
                                            <input type='datetime-local' className={styles.input} value={d.publishTime} onChange={(e) => handleEditDeliverable(d.id, 'publishTime', e.target.value)} />
                                        </Layout>
                                    </Layout>
                                    <IconButton Icon={Delete} onClick={() => handleDeleteDeliverable(d.id)} />
                                </Layout>)
                        }
                        <Button text={'Add deliverable'} type='secondary' onClick={handleAddDeliverable} />
                    </Layout>
                </Layout>
                <Layout gap={16} fullWidth alignItems='flex-start'>
                    <Text text='Deal terms' color='darkGray' />
                    <Divider />
                    <Layout row gap={16} fullWidth justifyContent='flex-start'>
                        <Text text='Price (USD):' />
                        <input className={styles.input} type='number' value={deal.price === 0 ? '' : deal.price} onChange={(e) => handleEditDeal('price', e.target.value)} />
                    </Layout>
                    <Layout row gap={32} fullWidth justifyContent='flex-start'>
                        <Layout row gap={16}>
                            <Text text='Revshare:' />
                            <input
                                type='checkbox'
                                checked={deal.revshare}
                                onChange={(e) => handleEditDeal('revshare', e.target.checked)} />
                        </Layout>
                        <Layout row gap={16}>
                            <Text text='Percentage (%):' />
                            <input className={styles.input} type='number' value={deal.percentage === 0 ? '' : deal.percentage} onChange={(e) => handleEditDeal('percentage', e.target.value)} disabled={!deal.revshare} />
                        </Layout>
                    </Layout>
                </Layout>
                <Layout gap={16} fullWidth alignItems='flex-start'>
                    <Text text='Additional' color='darkGray' />
                    <Divider />
                    <Layout row gap={16} fullWidth justifyContent='flex-start'>
                        <Text text='Repost:' />
                        <input
                            type='checkbox'
                            checked={additional.repost}
                            onChange={(e) => handleEditAdditional('repost', e.target.checked)} />
                    </Layout>
                    <Layout row gap={16} fullWidth justifyContent='flex-start'>
                        <Text text='Usage rights:' />
                        <input
                            type='checkbox'
                            checked={additional.usageRights}
                            onChange={(e) => handleEditAdditional('usageRights', e.target.checked)} />
                    </Layout>
                    <Layout gap={4} fullWidth alignItems='flex-start'>
                        <Text text='Memo:' />
                        <textarea
                            style={{ width: '100%', height: '120px' }}
                            className={styles.input}
                            value={additional.memo}
                            onChange={(e) => handleEditAdditional('memo', e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout row classes={[styles.bottomContainer]} fullWidth justifyContent='space-between' padding={[32, 0, 32, 0]} gap={32}>
                    <Button
                        text={currTicketId === 'add' ? 'Add' : 'Save'}
                        disabled={saveStatus === Status.Loading}
                        onClick={handleSave} />
                    {
                        currTicketId === 'add' ?
                            null :
                            <Button
                                text={'Delete'}
                                type='secondary'
                                disabled={saveStatus === Status.Loading}
                                onClick={handleDelete} />
                    }
                </Layout>
            </Layout>
        </Layout>
    )
}

export default TicketPopup