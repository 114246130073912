import React from 'react'

const Cohort = ({ size = 24, color = 'white' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0936 6C20.7585 6.00011 20.4279 6.07836 20.1283 6.22853C19.8286 6.37871 19.5681 6.59667 19.3674 6.86512C19.1667 7.13358 19.0313 7.44512 18.9721 7.77503C18.9128 8.10493 18.9313 8.4441 19.026 8.76562L15.6805 12.1106C15.2393 11.9637 14.7623 11.9637 14.3211 12.1106L11.841 9.63047C11.9226 9.31201 11.9303 8.9791 11.8635 8.6572C11.7968 8.3353 11.6573 8.03293 11.4557 7.77321C11.2542 7.51349 10.9959 7.3033 10.7006 7.1587C10.4054 7.0141 10.081 6.93893 9.75224 6.93893C9.42348 6.93893 9.09908 7.0141 8.80384 7.1587C8.50859 7.3033 8.25032 7.51349 8.04876 7.77321C7.84721 8.03293 7.70771 8.3353 7.64094 8.6572C7.57416 8.9791 7.58187 9.31201 7.66349 9.63047L3.51552 13.7756C3.055 13.6393 2.56219 13.6604 2.11497 13.8354C1.66776 14.0105 1.29165 14.3297 1.0461 14.7424C0.80055 15.1552 0.69956 15.638 0.759095 16.1145C0.81863 16.5911 1.03529 17.0342 1.37484 17.3739C1.71438 17.7136 2.15744 17.9304 2.63399 17.99C3.11053 18.0497 3.59338 17.9489 4.00621 17.7035C4.41903 17.4581 4.73829 17.0821 4.91353 16.6349C5.08876 16.1877 5.10997 15.6949 4.9738 15.2344L9.06927 11.1394C9.51046 11.2864 9.98745 11.2864 10.4286 11.1394L12.9093 13.62C12.8277 13.9385 12.8199 14.2714 12.8867 14.5933C12.9535 14.9152 13.093 15.2175 13.2945 15.4773C13.4961 15.737 13.7544 15.9472 14.0496 16.0918C14.3449 16.2364 14.6693 16.3115 14.998 16.3115C15.3268 16.3115 15.6512 16.2364 15.9464 16.0918C16.2417 15.9472 16.4999 15.737 16.7015 15.4773C16.903 15.2175 17.0425 14.9152 17.1093 14.5933C17.1761 14.2714 17.1684 13.9385 17.0868 13.62L20.4843 10.2244C20.7809 10.3119 21.0928 10.3346 21.399 10.2909C21.7051 10.2472 21.9983 10.1382 22.2586 9.97122C22.5189 9.80426 22.7402 9.58326 22.9076 9.32322C23.0749 9.06318 23.1844 8.77018 23.2286 8.46411C23.2727 8.15803 23.2505 7.84604 23.1635 7.5493C23.0764 7.25256 22.9266 6.97802 22.7241 6.7443C22.5216 6.51057 22.2712 6.32314 21.9899 6.19472C21.7085 6.0663 21.4029 5.99989 21.0936 6Z" fill={color} />
        </svg>
    )
}

export default Cohort