import React from 'react'

const Cross = ({ color = "#262F56", size = 24, onClick }) => {
    return (
        <svg onClick={onClick} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5L12 12M12 12L19 19M12 12L19 5M12 12L5 19" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Cross