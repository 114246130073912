import React, { useEffect } from 'react'
import styles from './Leaderboards.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { fetchGroups, fetchLeaderboards, fetchMoreGroups, fetchUsers } from '../../store/leaderboards/leaderboards.slice'
import Text from '../../components/Text'

const Leaderboards = () => {
    const dispatch = useDispatch()
    const data = useSelector(state => state.leaderboards.data)
    const usersData = useSelector(state => state.leaderboards.usersData)
    const groupsData = useSelector(state => state.leaderboards.groupsData)
    const selectedLID = useSelector(state => state.leaderboards.selectedLID)
    const selectedGID = useSelector(state => state.leaderboards.selectedGID)

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchMoreGroups())
        }
    }

    useEffect(() => {
        dispatch(fetchLeaderboards())
    }, [dispatch])

    return (
        <Layout row classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <table className={styles.leaderboardTable}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.title}>Leaderboards</th>
                </tr>
                {
                    data.map(d =>
                        <LeaderboardView
                            selected={selectedLID === d.id}
                            key={d.id}
                            data={d}
                            id={d.id} />
                    )
                }
            </table>
            <table className={styles.groupTable} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.title}>{'Groups'}</th>
                </tr>
                {
                    groupsData.map(d =>
                        <GroupView
                            selected={selectedGID === d.id}
                            key={d.id}
                            data={d}
                            id={d.id} />
                    )
                }
            </table>
            <table className={styles.userTable}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.title}>Users</th>
                </tr>
                {
                    usersData.map(d =>
                        <RowView
                            key={d.id}
                            id={d.id}
                            data={d} />
                    )
                }
            </table>
        </Layout>
    )
}

const LeaderboardView = ({ id, data, selected }) => {
    const dispatch = useDispatch()

    return (
        <tr className={[styles.tableRow, styles.leaderboard, selected ? styles.selected : null].join(' ')} onClick={() => { dispatch(fetchGroups({ lid: id })) }}>
            <td className={styles.title}>
                <Layout fullWidth alignItems='flex-start' gap={16}>
                    <Text text={id} />
                    <Layout fullWidth alignItems='flex-start' gap={8}>
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={'Groups:'} color='darkGray' type='small' />
                            <Text text={data.groupsNum} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={'Users:'} color='darkGray' type='small' />
                            <Text text={data.usersNum} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={'Non bots:'} color='darkGray' type='small' />
                            <Text text={data.usersNum - data.botsNum} />
                        </Layout>
                    </Layout>
                </Layout>
            </td>
        </tr>
    )
}

const GroupView = ({ id, data, selected }) => {
    const dispatch = useDispatch()

    return (
        <tr className={[styles.tableRow, styles.leaderboard, selected ? styles.selected : null].join(' ')} onClick={() => { dispatch(fetchUsers({ gid: id })) }}>
            <td className={styles.title}>
                <Layout fullWidth alignItems='flex-start' gap={16}>
                    <Layout fullWidth alignItems='flex-start' gap={4}>
                        <Text text={id} color='darkGray' type='small' />
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={new Date(data.created).toLocaleString()} type='small' />
                        </Layout>
                    </Layout>
                    <Layout fullWidth alignItems='flex-start' gap={8}>
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={'Members:'} color='darkGray' type='small' />
                            <Text text={data.members} />
                        </Layout>
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={'Non bots:'} color='darkGray' type='small' />
                            <Text text={data.members - data.botMembers} />
                        </Layout>
                    </Layout>
                </Layout>
            </td>
        </tr>
    )
}

const RowView = ({ id, data }) => {
    return (
        <tr className={styles.tableRow} onClick={() => { window.open('/users/' + id, '_blank') }}>
            <td className={styles.title}>
                <Layout row justifyContent='flex-start' gap={16}>
                    <Text text={id.substring(0, 6) + '...'} color='darkGray' type='small' className={styles.idWidth} />
                    <Text text={data.xp} className={styles.valueWidth} />
                    <Text text={data.isBot ? '' : 'Not bot'} className={styles.valueWidth} />
                    <div style={{ flex: 1 }} />
                    <Text text={new Date(data.lastUpdated).toLocaleString()} type='small' />
                </Layout>
            </td>
        </tr>
    )
}

export default Leaderboards