import React from 'react'
import Layout from '../../components/Layout'
import { useNavigate } from "react-router-dom";
import Text from '../../components/Text/Text'
import styles from './MenuButton.module.css'

const MenuButton = ({
    onClick = () => { },
    href = '/',
    text = '',
    selected = false,
    hide = false
}) => {
    let navigate = useNavigate();

    const handleOnClick = () => {
        onClick();
        navigate(href, { replace: true });
    }

    if (hide) return null;

    return (

        <Layout row fullwidth border fitParent justifyContent='flex-start' padding={[4, 8]} classes={[styles.MenuButton, selected ? styles.Selected : styles.NotSelected]} onClick={handleOnClick}>
            <div style={{ "width": "8px", "height": "0px" }} />
            <Text text={text} color={selected ? "mainColor" : "darkGray"} bold={selected} />
        </Layout>

    )
}

export default MenuButton