import React, { useEffect } from 'react'
import styles from './TicketsView.module.css'
import Layout from '../../components/Layout'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLastMarketingTickets } from '../../store/marketing/marketing.slice'

const TicketsView = () => {
    const dispatch = useDispatch()
    const tickets = useSelector(state => state.marketing.tickets)

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchLastMarketingTickets({ more: true }))
        }
    }

    useEffect(() => {
        dispatch(fetchLastMarketingTickets({ more: false }))
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' gap={16}>
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.fid}>FID</th>
                    <th className={styles.deliverables}>Deliverables</th>
                    <th className={styles.deal}>Deal</th>
                    <th className={styles.repost}>Repost</th>
                    <th className={styles.usageRights}>Usage rights</th>
                    <th className={styles.lastUpdated}>Last updated</th>
                </tr>
                {
                    tickets.map(ticket =>
                        <TicketView
                            key={ticket.tid}
                            ticket={ticket} />
                    )
                }
            </table>
        </Layout>
    )
}


const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR

const TicketView = ({ ticket }) => {

    const navigate = useNavigate()

    let lastActivity = ''
    if (ticket?.lastUpdated !== undefined) {
        const time = ticket.lastUpdated
        const now = Date.now()
        const diff = now - time;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    let dealString = '-'
    if (ticket.deal.price > 0) {
        dealString = `$${ticket.deal.price}`
    }
    if (ticket.deal.revshare) {
        if (dealString !== '-') {
            dealString += ' + '
        } else {
            dealString = ''
        }
        dealString += `${ticket.deal.percentage}%`
    }

    let deliverablesString = '-'
    if (ticket.deliverables.length > 0) {
        const tmpArr = []
        const platforms = ticket.deliverables.map(d => d.platform)
        let count = platforms.filter(p => p === 'instagram').length;
        if (count > 0) tmpArr.push(`${count} x IG`)
        count = platforms.filter(p => p === 'tiktok').length;
        if (count > 0) tmpArr.push(`${count} x TT`)
        count = platforms.filter(p => p === 'facebook').length;
        if (count > 0) tmpArr.push(`${count} x FB`)
        count = platforms.filter(p => p === 'youtube').length;
        if (count > 0) tmpArr.push(`${count} x YT`)
        count = platforms.filter(p => p === 'linkedin').length;
        if (count > 0) tmpArr.push(`${count} x LI`)
        count = platforms.filter(p => p === 'twitter').length;
        if (count > 0) tmpArr.push(`${count} x TW`)
        deliverablesString = tmpArr.join(', ')
    }

    return (
        <tr className={styles.tableRow} onClick={() => { navigate({ search: createSearchParams({ 'tid': ticket.tid }).toString() }) }}>
            <td className={styles.fid}>{ticket.fid}</td>
            <td className={styles.deliverables}>{deliverablesString}</td>
            <td className={styles.deal}>{dealString}</td>
            <td className={styles.repost}>{ticket.additional.repost ? 'Yes' : '-'}</td>
            <td className={styles.usageRights}>{ticket.additional.usageRights ? 'Yes' : '-'}</td>
            <td className={styles.lastUpdated}>{lastActivity}</td>
        </tr>
    )
}

export default TicketsView