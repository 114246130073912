import React from 'react'

const TikTok = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3769_560)">
                <path d="M16.8219 8.64166C18.2811 9.74607 20.0687 10.3959 21.9994 10.3959V6.46237C21.634 6.46246 21.2696 6.42207 20.9121 6.34188V9.43813C18.9815 9.43813 17.1941 8.78839 15.7346 7.68406V15.7113C15.7346 19.727 12.6599 22.9821 8.8674 22.9821C7.45232 22.9821 6.13701 22.5291 5.04443 21.7523C6.29146 23.1023 8.03053 23.9398 9.95443 23.9398C13.7472 23.9398 16.822 20.6847 16.822 16.6688V8.64166H16.8219ZM18.1633 4.67322C17.4175 3.81062 16.9278 2.69586 16.8219 1.46342V0.95752H15.7915C16.0508 2.52389 16.9356 3.8621 18.1633 4.67322ZM7.44318 18.6708C7.02653 18.0925 6.80131 17.3849 6.80232 16.6573C6.80232 14.8207 8.20865 13.3316 9.94373 13.3316C10.267 13.3314 10.5884 13.384 10.8966 13.4874V9.46593C10.5365 9.41371 10.173 9.39145 9.80975 9.39964V12.5297C9.50141 12.4262 9.17984 12.3737 8.85639 12.374C7.12139 12.374 5.71514 13.863 5.71514 15.6998C5.71514 16.9986 6.41803 18.1231 7.44318 18.6708Z" fill="#FF004F" />
                <path d="M15.7347 7.68397C17.1944 8.78831 18.9816 9.43804 20.9122 9.43804V6.3418C19.8346 6.09873 18.8806 5.50254 18.1633 4.67322C16.9356 3.86202 16.051 2.52381 15.7916 0.95752H13.0851V16.6687C13.079 18.5003 11.6751 19.9833 9.94373 19.9833C8.92357 19.9833 8.01717 19.4685 7.44318 18.6708C6.41818 18.1231 5.71521 16.9985 5.71521 15.6999C5.71521 13.8632 7.12146 12.3741 8.85646 12.3741C9.18889 12.3741 9.50928 12.4289 9.80982 12.5298V9.39973C6.08389 9.48124 3.0874 12.7045 3.0874 16.6687C3.0874 18.6477 3.83357 20.4416 5.04467 21.7524C6.13725 22.5291 7.45248 22.9822 8.86764 22.9822C12.6602 22.9822 15.7348 19.7269 15.7348 15.7113V7.68406L15.7347 7.68397Z" fill="black" />
                <path d="M20.9121 6.34179V5.50477C19.9403 5.50633 18.9877 5.21819 18.1632 4.6733C18.893 5.51926 19.8541 6.10264 20.9121 6.34196V6.34179ZM15.7914 0.957517C15.7667 0.807857 15.7477 0.657195 15.7345 0.505903V0H11.9975V15.7113C11.9916 17.5428 10.5877 19.0258 8.85625 19.0258C8.36541 19.0266 7.88128 18.905 7.44297 18.671C8.01695 19.4686 8.92336 19.9833 9.94352 19.9833C11.6748 19.9833 13.0788 18.5004 13.0849 16.6688V0.9576H15.7914V0.957517ZM9.80984 9.39972V8.5085C9.49757 8.46328 9.18275 8.44066 8.86758 8.4408C5.07461 8.4408 2 11.696 2 15.7113C2 18.2287 3.20836 20.4473 5.04461 21.7524C3.83352 20.4416 3.08734 18.6476 3.08734 16.6687C3.08734 12.7046 6.08375 9.48124 9.80984 9.39972Z" fill="#00F2EA" />
            </g>
            <defs>
                <clipPath id="clip0_3769_560">
                    <rect width="20" height="24" fill="white" transform="translate(2)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TikTok