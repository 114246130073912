import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../assets/icons/Search';
import InputBar from '../../components/InputBar';
import Layout from '../../components/Layout';
import Text from '../../components/Text';
import { MARGIN } from '../../Constants';
import { fetchPayments, setPayment } from '../../store/payments/payments.slice';
import Item from './Item';

const Payments = () => {
    const dispatch = useDispatch()
    const { payments } = useSelector(state => state.payments)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        dispatch(fetchPayments())
    }, [dispatch])

    const handleUpdatePayment = ({ newMoney, uid }) => {
        dispatch(setPayment({ uid: uid, currentMonth: newMoney }))
    }

    return (
        <Layout fullWidth justifyContent='flex-start' padding={[32]} style={{ 'overflow': 'auto', 'height': '100vh' }} gap={MARGIN * 2}>
            <Layout row justifyContent='space-between' fullWidth>
                <Text text='Payments' type='title' />
            </Layout>
            <Layout color="white" padding={[MARGIN / 2]} border content>
                <Layout fullWidth row padding={[MARGIN]}>
                    <InputBar Icon={Search} hint='Search Email or Name' value={searchText} onValueChange={(e) => setSearchText(e)} style={{ "width": "100%" }} />

                </Layout>
            </Layout>
            <Layout padding={[MARGIN / 2]} fullWidth gap={MARGIN} content>
                {
                    payments.filter(p => p.creatorInfo.creatorVisibility !== 'coddy' && (searchText.length === 0 || p.creatorInfo.email.toLowerCase().includes(searchText.toLowerCase()) || p.creatorInfo.displayName.toLowerCase().includes(searchText.toLowerCase()))).map(d => {
                        return (
                            <Item
                                key={d.id}
                                creator={d.creatorInfo}
                                money={`Total: $${(d.total / 100)}`}
                                lastUpdated={d.lastUpdated}
                                onUpdate={(newMoney) => {
                                    handleUpdatePayment({ newMoney: newMoney, uid: d.id })
                                }}
                                loading={false}
                            />
                        )
                    })
                }
            </Layout>
        </Layout>
    )
}

export default Payments;