import React from 'react'

const Home = ({ size, color })  => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_228_120)">
            <path d="M14.7894 7.89063L8.75186 1.85626L8.34717 1.45157C8.25496 1.35997 8.13027 1.30856 8.0003 1.30856C7.87033 1.30856 7.74563 1.35997 7.65342 1.45157L1.21123 7.89063C1.11675 7.98475 1.04208 8.09684 0.991628 8.22028C0.941176 8.34373 0.915965 8.47603 0.917484 8.60938C0.923734 9.15938 1.38155 9.59845 1.93155 9.59845H2.59561V14.6875H13.405V9.59845H14.0831C14.3503 9.59845 14.6019 9.49376 14.7909 9.3047C14.884 9.2119 14.9578 9.10156 15.0079 8.98006C15.0581 8.85857 15.0836 8.72833 15.0831 8.59688C15.0831 8.33126 14.9784 8.0797 14.7894 7.89063ZM8.8753 13.5625H7.1253V10.375H8.8753V13.5625ZM12.28 8.47345V13.5625H9.8753V10C9.8753 9.6547 9.59561 9.37501 9.2503 9.37501H6.7503C6.40498 9.37501 6.1253 9.6547 6.1253 10V13.5625H3.72061V8.47345H2.22061L8.00186 2.69688L8.3628 3.05782L13.7815 8.47345H12.28Z" fill={color}/>
            </g>
            <defs>
            <clipPath id="clip0_228_120">
            <rect width={size} height={size} fill={color} transform="translate(0.000305176)"/>
            </clipPath>
            </defs>
        </svg>
    )

}

export default Home

