import { getApp } from '@firebase/app'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "@firebase/auth";

export default class AuthService {
    static setAuthChangeListener = (onAuthChange) => {
        const auth = getAuth(getApp())
        onAuthStateChanged(auth, (user) => {
            onAuthChange(user)
        });
    }

    static login = async (email, pass) => {
        const auth = getAuth(getApp())
        return new Promise((resolve, reject) => signInWithEmailAndPassword(auth, email, pass)
            .then(async user => resolve())
            .catch(e => {
                if (e.code === 'auth/user-not-found')
                    reject('User not found')
                else if (e.code === 'auth/wrong-password')
                    reject('Wrong password')
                else
                    reject(e.message)
            }))
    }

    static logout = async () => {
        const auth = getAuth(getApp())
        return auth.signOut()
    }
}