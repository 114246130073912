import React from 'react'
import { BORDER_RADIUS, PAGE_BACKGROUND } from '../../Constants'
import styles from './Layout.module.css'

const Layout = ({
    children,
    padding = [0],
    margin = [0],
    spacing = 0,
    alignItems = 'center',
    justifyContent = 'center',
    fullWidth = false,
    fullHeight = false,
    row = false,
    fitParent = false,
    border = false,
    hide = false,
    classes = [],
    color,
    page=false,
    content=false,
    style = {},
    scroll=false,
    maxHeightScroll=null,
    gap=0,
    onScroll,
    onClick,
    onMouseEnter,
}) => {
    const containerStyle = {
        'alignItems': alignItems,
        'justifyContent': justifyContent,
        'padding': page ? '16px' : padding.map(p => p + 'px').join(' '),
        'margin': margin.map(p => p + 'px').join(' '),
        'position': page ? 'relative' : null,
        'gap': gap ? `${gap}px` : null,
        'borderRadius': border ? `${BORDER_RADIUS}px` : null,
        'width': content ? '95%' : null,
        'cursor': onClick !== undefined ? 'pointer' : null,
        'overflowY': scroll ? 'scroll' : null,
        'overflowX': scroll ? 'hidden' : null,
        'height': page ? '100vh' : scroll ? maxHeightScroll : null,

        ...style,
    }
    if (color) {
        containerStyle.background = color
    } else if (page) {
        containerStyle.background = PAGE_BACKGROUND
    }


    if (hide) return null
    return (
        <div
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            className={[
                styles.base,
                fullWidth ? styles.fullWidth : null,
                fullHeight ? styles.fullHeight : null,
                row ? styles.row : null,
                fitParent ? styles.fitParent : null,
                ...classes
            ].join(' ')}
            onScroll={onScroll !== undefined ? onScroll : null}
            style={containerStyle}>
            {React.Children.map(children, (child, i) => {
                if (child === null) return null
                if (spacing === 0 || i === children.length - 1)
                    return child
                return (
                    <>
                        {child}
                        <div style={
                            row ?
                                { width: spacing + 'px' } :
                                { height: spacing + 'px' }
                        } />
                    </>
                );
            })}
        </div>
    )
}

export default Layout
