import React, { useEffect } from 'react'
import styles from './Cancellations.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { fetchCanceledSubscriptions } from '../../store/cancellations/cancellations.slice'
import Status from '../../store/status'
import Text from '../../components/Text/Text'

const Cancellations = () => {
    const dispatch = useDispatch()
    const data = useSelector(state => state.cancellations.data)
    const fetchStatus = useSelector(state => state.cancellations.fetchStatus)

    useEffect(() => {
        dispatch(fetchCanceledSubscriptions())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <table className={styles.table} >
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.type}></th>
                    <th className={styles.email}>Email</th>
                    <th className={styles.feedback}>Feedback</th>
                    <th className={styles.comment}>Comment</th>
                    <th className={styles.time}>Duration</th>
                    <th className={styles.time}>Canceled at</th>
                </tr>
                {
                    fetchStatus === Status.Success ?
                        data.map((d, i) =>
                            <RowView
                                key={`d${i}`}
                                data={d} />
                        ) :
                        <Text text='Loading...' />
                }
            </table>
        </Layout>
    )
}

const RowView = ({ data }) => {
    let interval = data.plan.interval === 'month' ?
        data.plan.interval_count === 6 ?
            'SY' :
            'M' :
        'Y'

    const duration = data.canceled_at - data.started_at

    let durationString = ''
    if (duration >= 60 * 60 * 24) {
        durationString += `${Math.floor(duration / (60 * 60 * 24))}d`
    } if (duration >= 60 * 60) {
        durationString += `${Math.floor((duration % (60 * 60 * 24)) / (60 * 60))}h`
    } if (duration >= 60) {
        durationString += `${Math.floor((duration % (60 * 60)) / 60)}m`
    }

    return (
        <tr className={styles.tableRow} onClick={() => { window.open(`/users/${data.customer.email}`, '_blank').focus() }}>
            <td className={styles.type}>{interval}</td>
            <td className={styles.email}>{data.customer.email}</td>
            <th className={styles.feedback}>{data.cancellation_details.feedback}</th>
            <th className={styles.comment}>{data.cancellation_details.comment}</th>
            <td className={styles.time}>{durationString}</td>
            <td className={styles.time}>{new Date(data.canceled_at * 1000).toLocaleString('en-GB', { dateStyle: 'short', timeStyle: 'short' })}</td>
        </tr>
    )
}

export default Cancellations