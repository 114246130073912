import { toast } from "react-toastify"
import Status from "../status"

export const fetchJourneysLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchJourneysFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.data = action.payload
}

export const fetchJourneysRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}

export const saveJourneyFulfilled = (state, action) => {
    state.data = state.data.map(d => d.id === action.payload.id ? action.payload : d)
    toast.success('Successfully updated')
}

export const saveJourneyRejected = (state, action) => {
    toast.error(action.error.message)
}

export const publishJourneyFulfilled = (state, action) => {
    state.data.push(action.payload)
    toast.success('Successfully updated')
}

export const publishJourneyRejected = (state, action) => {
    toast.error(action.error.message)
}
