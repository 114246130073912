import React from 'react'
import styles from './SidebarActions.module.css'
import Layout from '../../components/Layout'
import Divider from '../../components/Divider'
import Text from '../../components/Text/Text'
import Submissions from '../../assets/icons/Submissions'
import Cohort from '../../assets/icons/Cohort'
import Plus from '../../assets/icons/Plus'
import IconButton from '../../components/IconButton/IconButton'
import Cross from '../../assets/icons/Cross'
import { useNavigate } from 'react-router-dom'
import People from '../../assets/icons/People'
import Payments from '../../assets/icons/Payments'
import Tag from '../../assets/icons/Tag'
import Support from '../../assets/icons/Support'
import Contact from '../../assets/icons/Contact'
import Carousel from '../../assets/icons/Carousel'
import AI from '../../assets/icons/AI'
import Blog from '../../assets/icons/Blog'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebarAction } from '../../store/user/user.slice'
import Coupon from '../../assets/icons/Coupon'
import Feedbacks from '../../assets/icons/Feedbacks'
import Journeys from '../../assets/icons/Journeys'
import Cancellation from '../../assets/icons/Cancellation'
import Challenge from '../../assets/icons/Challenge'
import Course from '../../assets/icons/Course'
import Customer from '../../assets/icons/Customer'
import Royalty from '../../assets/icons/Royalty'

const ACTIONS = [
    {
        id: 'submissions',
        title: 'Submissions',
        url: '/submissions',
        Icon: Submissions,
    },
    {
        id: 'cohorts',
        title: 'Cohorts',
        url: '/cohorts',
        Icon: Cohort,
    },
    {
        id: 'permissions',
        title: 'Permissions',
        url: '/permissions',
        Icon: People,
    },
    {
        id: 'payments',
        title: 'Payments',
        url: '/payments',
        Icon: Payments,
    },
    {
        id: 'tagging',
        title: 'Tagging',
        url: '/tagging',
        Icon: Tag,
    },
    {
        id: 'support',
        title: 'Support',
        url: '/support',
        Icon: Support,
    },
    {
        id: 'contact',
        title: 'Contact',
        url: '/contact',
        Icon: Contact,
    },
    {
        id: 'carousels',
        title: 'Carousels',
        url: '/carousels',
        Icon: Carousel,
    },
    {
        id: 'ai_feedbacks',
        title: 'AI Feedbacks',
        url: '/ai_feedbacks',
        Icon: AI,
    },
    {
        id: 'blogs',
        title: 'Blogs',
        url: '/blogs',
        Icon: Blog,
    },
    {
        id: 'coupons',
        title: 'Coupons',
        url: '/coupons',
        Icon: Coupon,
    },
    {
        id: 'feedbacks',
        title: 'Feedbacks',
        url: '/feedbacks',
        Icon: Feedbacks,
    },
    {
        id: 'journeys',
        title: 'Journeys',
        url: '/journeys',
        Icon: Journeys,
    },
    {
        id: 'cancellations',
        title: 'Cancellations',
        url: '/cancellations',
        Icon: Cancellation,
    },
    {
        id: 'challenges',
        title: 'Challenges',
        url: '/challenges',
        Icon: Challenge,
    },
    {
        id: 'courses',
        title: 'Courses',
        url: '/courses',
        Icon: Course,
    },
    {
        id: 'courseAnalytics',
        title: 'Course Analytics',
        url: '/courseAnalytics',
    },
    {
        id: 'customers',
        title: 'Customer',
        url: '/customers',
        Icon: Customer,
    },
    {
        id: 'royalties',
        title: 'Royalties',
        url: '/royalties',
        Icon: Royalty,
    },
    {
        id: 'ai_responses',
        title: 'AI Responses',
        url: '/ai_responses',
        Icon: AI,
    },
    {
        id: 'marketing_funnels',
        title: 'Marketing Funnels',
        url: '/marketing_funnels',
        Icon: AI,
    },
    {
        id: 'insights',
        title: 'Insights',
        url: '/insights',
        Icon: AI,
    },
    {
        id: 'funnel_options',
        title: 'Funnel Options',
        url: '/funnel_options',
        Icon: AI,
    },
    {
        id: 'leaderboards',
        title: 'Leaderboards',
        url: '/leaderboards',
        Icon: AI,
    },
    {
        id: 'marketing',
        title: 'Marketing',
        url: '/marketing',
        Icon: AI,
    },
    {
        id: 'user_metrics',
        title: 'User Metrics',
        url: '/user_metrics',
        Icon: AI,
    },
]

const Action = ({ id, title, url, Icon }) => {
    const sidebarActions = useSelector(state => state.user.data?.sidebar ?? [])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleToggleAction = (e) => {
        e.stopPropagation()
        dispatch(toggleSidebarAction({ action: id }))
    }

    const selected = sidebarActions.includes(id)

    return (
        <Layout padding={[4, 12]} row gap={8} classes={[styles.actionContainer, selected ? styles.selected : null]} onClick={() => navigate(url)}>
            <Text text={title} color={selected ? 'white' : 'black'} />
            <IconButton Icon={selected ? Cross : Plus} size={'small'} label={selected ? 'Remove' : 'Add'} type={selected ? 'dark' : 'white'} onClick={handleToggleAction} />
        </Layout>
    )
}

const SidebarActions = () => {
    const sidebarActions = useSelector(state => state.user.data?.sidebar ?? [])
    function compare(a, b) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }
    return (
        <Layout gap={16} style={{ background: 'white', borderRadius: '8px' }} alignItems='flex-start' padding={[16]}>
            <Text text='Sidebar actions' type='title' />
            <Layout row gap={16} style={{ flexWrap: 'wrap' }} justifyContent='flex-start'>
                {
                    ACTIONS.map((a) => {
                        if (sidebarActions.includes(a.id)) {
                            return <Action key={a.title} {...a} />
                        }
                        return null
                    })
                }
            </Layout>
            <Divider />
            <Layout row gap={16} style={{ flexWrap: 'wrap' }} justifyContent='flex-start'>
                {
                    ACTIONS.sort(compare).map((a) => {
                        if (!(sidebarActions.includes(a.id))) {
                            return <Action key={a.title} {...a} />
                        }
                        return null
                    })
                }
            </Layout>
        </Layout>
    )
}

export default SidebarActions