import React from 'react'

const Royalty = ({ size = 24, color = "black" }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 21C19 21.5523 18.5523 22 18 22H6.00004C5.44775 22 5.00004 21.5523 5.00004 21V21C5.00004 20.4477 5.44775 20 6.00004 20H18C18.5523 20 19 20.4477 19 21V21ZM17 10C15.58 10 14.26 10.77 13.55 12H13V7H15C15.5523 7 16 6.55228 16 6V6C16 5.44772 15.5523 5 15 5H13V3C13 2.44772 12.5523 2 12 2V2C11.4478 2 11 2.44772 11 3V5H9.00004C8.44775 5 8.00004 5.44772 8.00004 6V6C8.00004 6.55228 8.44775 7 9.00004 7H11V12H10.45C9.35004 10.09 6.90004 9.43 5.00004 10.54C4.07892 11.0669 3.40398 11.9371 3.12287 12.9603C2.84177 13.9836 2.97737 15.0765 3.50004 16C4.24004 17.24 5.57004 18 7.00004 18H17C18.0609 18 19.0783 17.5786 19.8285 16.8284C20.5786 16.0783 21 15.0609 21 14C21 12.9391 20.5786 11.9217 19.8285 11.1716C19.0783 10.4214 18.0609 10 17 10Z" fill={color} />
        </svg>
    )
}

export default Royalty