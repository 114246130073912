import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FunctionsService from '../../services/FunctionsService'
import {
    fetchLeaderboardsFulfilled,
    fetchLeaderboardsRejected,
    fetchLeaderboardGroupsLoading,
    fetchLeaderboardGroupsFulfilled,
    fetchLeaderboardGroupsRejected,
    fetchLeaderboardMoreGroupsFulfilled,
    fetchLeaderboardGroupDataLoading,
    fetchLeaderboardGroupDataFulfilled,
    fetchLeaderboardGroupDataRejected,
} from './leaderboards.actions'

export const fetchLeaderboards = createAsyncThunk('leaderboards/fetchLeaderboards', async (_) => {
    return FunctionsService.getLeaderboards()
})

export const fetchGroups = createAsyncThunk('leaderboards/fetchGroups', async ({ lid }) => {
    return FunctionsService.getLeaderboardGroups(lid)
})

export const fetchMoreGroups = createAsyncThunk('leaderboards/fetchMoreGroups', async (_, { getState }) => {
    const groupsData = getState().leaderboards.groupsData
    const lid = getState().leaderboards.selectedLID
    const lastTime = groupsData[groupsData.length - 1].created
    return FunctionsService.getLeaderboardGroups(lid, lastTime)
})

export const fetchUsers = createAsyncThunk('leaderboards/fetchUsers', async ({ gid }, { getState, rejectWithValue }) => {
    const lid = getState().leaderboards.selectedLID;
    if (lid === undefined) return rejectWithValue("no leaderboard")
    return FunctionsService.getLeaderboardGroupData(lid, gid)
})

export const leaderboardsSlice = createSlice({
    name: 'leaderboards',
    initialState: {
        data: [],
        groupsData: [],
        usersData: [],
        selectedLID: undefined,
        selectedGID: undefined,
        fetchGroupStatus: undefined,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLeaderboards.fulfilled, fetchLeaderboardsFulfilled)
            .addCase(fetchLeaderboards.rejected, fetchLeaderboardsRejected)
            .addCase(fetchGroups.pending, fetchLeaderboardGroupsLoading)
            .addCase(fetchGroups.fulfilled, fetchLeaderboardGroupsFulfilled)
            .addCase(fetchGroups.rejected, fetchLeaderboardGroupsRejected)
            .addCase(fetchMoreGroups.fulfilled, fetchLeaderboardMoreGroupsFulfilled)
            .addCase(fetchUsers.pending, fetchLeaderboardGroupDataLoading)
            .addCase(fetchUsers.fulfilled, fetchLeaderboardGroupDataFulfilled)
            .addCase(fetchUsers.rejected, fetchLeaderboardGroupDataRejected)
    },
})

export default leaderboardsSlice.reducer