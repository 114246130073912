import { toast } from "react-toastify"
import Status from "../status"

export const setCurrTicketIdAction = (state, action) => {
    state.currTicketId = action.payload
}

export const fetchFunnelsInfoFulfilled = (state, action) => {
    state.funnelsInfo = action.payload
}

export const fetchFunnelsInfoRejected = (state, action) => {
    toast.error(action.error.message)
}

export const saveMarketingTicketLoading = (state, action) => {
    state.saveStatus = Status.Loading
}

export const saveMarketingTicketFulfilled = (state, action) => {
    state.saveStatus = Status.Success
    toast.success('Saved!')
    const tid = action.meta.arg.tid;
    if (tid) {
        const currIndex = state.tickets.findIndex(t => t.tid === tid)
        if (currIndex !== -1) {
            const d = action.meta.arg.data
            d.tid = tid;
            state.tickets[currIndex] = d
            state.currTicket = d
            state.currTicketId = tid
        }
    } else {
        const d = action.meta.arg.data
        d.tid = action.payload;
        state.tickets.splice(0, 0, d)
        state.currTicket = d
        state.currTicketId = action.payload
    }

}

export const saveMarketingTicketRejected = (state, action) => {
    state.saveStatus = Status.Fail
    toast.error(action.error.message)
}

export const saveMarketingFunnelLoading = (state, action) => {
    state.saveStatus = Status.Loading
}

export const saveMarketingFunnelFulfilled = (state, action) => {
    state.saveStatus = Status.Success
    toast.success('Saved!')
    const fid = action.meta.arg.fid;
    if (!action.meta.arg.isNew) {
        const currIndex = state.funnels.findIndex(t => t.fid === fid)
        if (currIndex !== -1) {
            const d = action.meta.arg.data
            d.fid = fid;
            state.funnels[currIndex] = d
        }
    } else {
        const d = action.meta.arg.data
        d.fid = fid;
        state.funnels.splice(0, 0, d)
        state.funnelsInfo[fid] = {}
    }
}

export const saveMarketingFunnelRejected = (state, action) => {
    state.saveStatus = Status.Fail
    if (action.payload) {
        toast.error(action.payload)
    } else {
        toast.error(action.error.message)
    }
}

export const saveMarketingPerformanceLoading = (state, action) => {
    state.saveStatus = Status.Loading
}

export const saveMarketingPerformanceFulfilled = (state, action) => {
    state.saveStatus = Status.Success
    toast.success('Saved!')
    const pid = action.meta.arg.pid;
    const targetPrice = action.meta.arg.targetPrice;
    const currIndex = state.performance.findIndex(t => t.key === pid)
    state.performance[currIndex].targetPrice = targetPrice;
}

export const saveMarketingPerformanceRejected = (state, action) => {
    state.saveStatus = Status.Fail
    toast.error(action.error.message)
}


export const deleteMarketingTicketLoading = (state, action) => {
    state.saveStatus = Status.Loading
}

export const deleteMarketingTicketFulfilled = (state, action) => {
    state.saveStatus = Status.Success
    toast.success('Deleted!')
    const tid = action.meta.arg.tid;
    if (tid) {
        const currIndex = state.tickets.findIndex(t => t.tid === tid)
        if (currIndex !== -1) {
            state.tickets = state.tickets.splice(0, currIndex).concat(state.tickets.splice(currIndex + 1))
        }
    }
}

export const deleteMarketingTicketRejected = (state, action) => {
    state.saveStatus = Status.Fail
    toast.error(action.error.message)
}

export const fetchLastMarketingTicketsFulfilled = (state, action) => {
    if (action.meta.arg.more) {
        state.tickets = state.tickets.concat(action.payload)
    } else {
        state.tickets = action.payload
    }
}

export const fetchLastMarketingTicketsRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchLastMarketingDeliverablesFulfilled = (state, action) => {
    if (action.meta.arg.more) {
        state.deliverables = state.deliverables.concat(action.payload)
    } else {
        state.deliverables = action.payload
    }
}

export const fetchLastMarketingDeliverablesRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchLastMarketingFunnelsFulfilled = (state, action) => {
    if (action.meta.arg.more) {
        state.funnels = state.funnels.concat(action.payload)
    } else {
        state.funnels = action.payload
    }
}

export const fetchLastMarketingFunnelsRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchMarketingPerformanceFulfilled = (state, action) => {
    state.performance = action.payload
}

export const fetchMarketingPerformanceRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchMarketingTicketLoading = (state, action) => {
    state.currTicket = null
}

export const fetchMarketingTicketFulfilled = (state, action) => {
    state.currTicket = action.payload
}

export const fetchMarketingTicketRejected = (state, action) => {
    toast.error(action.error.message)
}

export const fetchMarketingFunnelLoading = (state, action) => {
    state.currFunnel = null
}

export const fetchMarketingFunnelFulfilled = (state, action) => {
    state.currFunnel = action.payload
}

export const fetchMarketingFunnelRejected = (state, action) => {
    toast.error(action.error.message)
}
