import React from 'react'

const Email = ({size, color}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0553 4.5H3.94466C2.87065 4.5 2 5.37065 2 6.44466C2 7.10288 2.33296 7.71643 2.88484 8.07515L10.256 12.8664C10.8873 13.2767 11.2029 13.4819 11.543 13.5617C11.8436 13.6323 12.1564 13.6323 12.457 13.5617C12.7971 13.4819 13.1127 13.2767 13.744 12.8664L13.744 12.8664L21.1152 8.07515C21.667 7.71643 22 7.10288 22 6.44466C22 5.37065 21.1293 4.5 20.0553 4.5Z" stroke={color} strokeWidth="1.8" />
      <path d="M21.5 8.00002V16.3C21.5 17.4201 21.5 17.9802 21.282 18.408C21.0903 18.7843 20.7843 19.0903 20.408 19.282C19.9802 19.5 19.4201 19.5 18.3 19.5H5.7C4.57989 19.5 4.01984 19.5 3.59202 19.282C3.21569 19.0903 2.90973 18.7843 2.71799 18.408C2.5 17.9802 2.5 17.4201 2.5 16.3V8.00002" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default Email