import { useEffect } from 'react';
import styles from './App.module.css';
import Login from './pages/Login';
import { initializeApp } from "firebase/app";
import AuthService from './services/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from './store/user/user.slice';
// import Main from './pages/Main';
import Status from './store/status';
import Splash from './pages/Splash';
import UserAnalytics from './pages/UserAnalytics';
import SideBar from './components/SideBar';
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Settings from './pages/Settings';
import Permissions from './pages/Permissions';
import UserMetrics from './pages/UserMetrics/UserMetrics';
import Submissions from './pages/Submissions/Submissions';
import Main from './pages/Main/Main';
import Support from './pages/Support/Support';
import Payments from './pages/Payments/Payments';
import Tagging from './pages/Tagging/Tagging';
import Contact from './pages/Contact/Contact';
import Carousels from './pages/Carousels/Carousels';
import AIFeedbacks from './pages/AIFeedbacks/AIFeedbacks';
import Blogs from './pages/Blogs/Blogs';
import Cohorts from './pages/Cohorts/Cohorts';
import Coupons from './pages/Coupons/Coupons';
import Feedbacks from './pages/Feedbacks/Feedbacks';
import Journeys from './pages/Journeys/Journeys';
import Cancellations from './pages/Cancellations/Cancellations';
import Challenges from './pages/Challenges/Challenges';
import Courses from './pages/Courses/Courses';
import Customers from './pages/Customers/Customers';
import Royalties from './pages/Royalties/Royalties';
import AIResponses from './pages/AIResponses/AIResponses';
import CourseAnalytics from './pages/CourseAnalytics/CourseAnalytics';
import MarketingFunnels from './pages/MarketingFunnels/MarketingFunnels';
import Insights from './pages/Insights/Insights';
import FunnelOptions from './pages/FunnelOptions/FunnelOptions';
import Leaderboards from './pages/Leaderboards/Leaderboards';
import Marketing from './pages/Marketing/Marketing';

const firebaseConfig = {
  apiKey: "AIzaSyAlGQGqFeBIwXer5lQObIgo6slOavXUM3s",
  authDomain: "coddy-rapidsight.firebaseapp.com",
  projectId: "coddy-rapidsight",
  storageBucket: "coddy-rapidsight.appspot.com",
  messagingSenderId: "185529559720",
  appId: "1:185529559720:web:24b5d8834bf19c7306309d",
  measurementId: "G-EKMYJJKP68"
};

const firebaseConfigCoddy = {
  apiKey: "AIzaSyAwpKz7TwTGkm-mIUDeCVwOP4TfpxBqvyU",
  authDomain: "coddy-15505.firebaseapp.com",
  databaseURL: "https://coddy-15505-default-rtdb.firebaseio.com",
  projectId: "coddy-15505",
  storageBucket: "coddy-15505.appspot.com",
  messagingSenderId: "733978470830",
  appId: "1:733978470830:web:e1055550dc24f1408fc03f",
  measurementId: "G-TZLB4EWQE3"
};

const firebaseConfigCompileApi = {
  apiKey: "AIzaSyBeq8fv6Zxm4Sj4PCLBJmCK0jFPeewmv28",
  authDomain: "compile-api.firebaseapp.com",
  projectId: "compile-api",
  storageBucket: "compile-api.appspot.com",
  messagingSenderId: "1047344475714",
  appId: "1:1047344475714:web:c22af1ae64222422dc07db"
};

const firebaseConfigCoddyForCreators = {
  apiKey: "AIzaSyAEoSmdHhBZyERidDBT5_JtR13bLxJzRc4",
  authDomain: "coddy-for-creators.firebaseapp.com",
  projectId: "coddy-for-creators",
  storageBucket: "coddy-for-creators.appspot.com",
  messagingSenderId: "251824326892",
  appId: "1:251824326892:web:4ab15f8e2a253652cefdc9",
  measurementId: "G-PYMYZLFWCY"
};

const Wrapper = () => {
  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  )
}

const HomeWrapper = () => {
  return (
    <div className={styles.mainContainer}>
      {/* <LessonFlow /> */}
      <SideBar />
      <Outlet />
    </div>
  )
}

function App() {
  const dispatch = useDispatch()
  const userFetchStatus = useSelector(state => state.user.fetchStatus)
  const isLogged = useSelector(state => state.user.isLogged)

  useEffect(() => {
    initializeApp(firebaseConfig)
    initializeApp(firebaseConfigCoddy, 'coddy')
    initializeApp(firebaseConfigCoddyForCreators, 'cfc')
    initializeApp(firebaseConfigCompileApi, 'compileApi')
  }, [])

  useEffect(() => {
    AuthService.setAuthChangeListener(async user => {
      dispatch(fetchUser({ user: user }))
    })
  }, [dispatch])

  if (userFetchStatus === Status.Loading || userFetchStatus === Status.None) {
    return <Splash />
  }
  if (!isLogged) {
    return <Login />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Wrapper />}>
          <Route path='/' element={<HomeWrapper />}>
            <Route index element={<Main />} />
            <Route path='submissions' element={<Submissions />} />
            {/* <Route path='users' element={<Users />} /> */}
            <Route path='permissions' element={<Permissions />} />
            <Route path='settings' element={<Settings />} />
            <Route path='user/:uid' element={<UserAnalytics />} />
            <Route path='users/:uid' element={<UserAnalytics />} />
            <Route path='support' element={<Support />} />
            <Route path='contact' element={<Contact />} />
            <Route path='contact/:id' element={<Contact />} />
            <Route path='payments' element={<Payments />} />
            <Route path='support/:tid' element={<Support />} />
            <Route path='tagging' element={<Tagging />} />
            <Route path='tagging/tag/add' element={<Tagging popup='tag' add/>} />
            <Route path='tagging/tag/:id' element={<Tagging popup='tag' />} />
            <Route path='tagging/term/add' element={<Tagging popup='term' add/>} />
            <Route path='tagging/term/:id' element={<Tagging popup='term' />} />
            <Route path='tagging/course/:id' element={<Tagging popup='course' />} />
            <Route path='tagging/lesson/:courseId/:id' element={<Tagging popup='lesson' />} />
            <Route path='carousels' element={<Carousels />} />
            <Route path='carousels/:id' element={<Carousels />} />
            <Route path='ai_feedbacks' element={<AIFeedbacks />} />
            <Route path='ai_feedbacks/user/:uid/:id' element={<AIFeedbacks />} />
            <Route path='ai_responses' element={<AIResponses />} />
            <Route path='ai_responses/:id' element={<AIResponses />} />
            <Route path='blogs' element={<Blogs />} />
            <Route path='blogs/add' element={<Blogs add />} />
            <Route path='blogs/:id' element={<Blogs />} />
            <Route path='cohorts' element={<Cohorts />} />
            <Route path='coupons' element={<Coupons />} />
            <Route path='feedbacks' element={<Feedbacks />} />
            <Route path='journeys' element={<Journeys />} />
            <Route path='journeys/add' element={<Journeys add />} />
            <Route path='journeys/:id' element={<Journeys />} />
            <Route path='cancellations' element={<Cancellations />} />
            <Route path='challenges' element={<Challenges />} />
            <Route path='courses' element={<Courses />} />
            <Route path='courseAnalytics' element={<CourseAnalytics />} />
            <Route path='marketing_funnels' element={<MarketingFunnels />} />
            <Route path='customers' element={<Customers />} />
            <Route path='royalties' element={<Royalties />} />
            <Route path='insights' element={<Insights />} />
            <Route path='insights/:id' element={<Insights />} />
            <Route path='funnel_options' element={<FunnelOptions />} />
            <Route path='funnel_options/:id' element={<FunnelOptions />} />
            <Route path='leaderboards' element={<Leaderboards />} />
            <Route path='marketing' element={<Marketing />} />
            <Route path='marketing/performance' element={<Marketing view='performance' />} />
            <Route path='marketing/tickets' element={<Marketing view='tickets' />} />
            <Route path='marketing/deliverables' element={<Marketing view='deliverables' />} />
            <Route path='marketing/funnels' element={<Marketing view='funnels' />} />
            <Route path='user_metrics' element={<UserMetrics view='funnels' />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
