import Status from "../status"
import { toast } from "react-toastify"

export const changeOrderFieldAction = (state, action) => {
    state.fetchStatus = Status.None
    if (state.order === action.payload) {
        state.orderDirection = state.orderDirection === 'desc' ? 'asc' : 'desc'
    } else {
        state.order = action.payload
        state.orderDirection = 'desc'
    }
}

export const setFromAction = (state, action) => {
    state.from = action.payload
}

export const addFilterLabelAction = (state, action) => {
    state.filterLabels.push(action.payload)
}

export const removeFilterLabelAction = (state, action) => {
    state.filterLabels = state.filterLabels.filter(l => l !== action.payload)
}

export const setFilterMarkedAction = (state, action) => {
    state.filterMarked = action.payload
}

export const fetchUsersLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchUsersFulfilled = (state, action) => {
    state.fetchStatus = Status.Success

    if (action.payload === null) {
        state.data = []
    } else {
        state.data = action.payload
    }
}

export const fetchUsersRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
    state.data = []
}

export const fetchMoreUsersLoading = (state, _) => {
    state.fetchMoreStatus = Status.Loading
}

export const fetchMoreUsersFulfilled = (state, action) => {
    state.fetchMoreStatus = Status.Success

    if (action.payload !== null) {
        state.data = state.data.concat(action.payload)
    }
}

export const fetchMoreUsersRejected = (state, action) => {
    state.fetchMoreStatus = Status.Fail
    toast.error(action.error.message)
}