import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import Divider from '../../components/Divider/Divider'
import CoursesProgress from './CoursesProgress'
import LessonsProgress from './LessonsProgress'
import { MARGIN } from '../../Constants'
import DatabaseService from '../../services/DatabaseService'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetLessonFlow } from '../../store/analytics/analytics.slice'
import LessonFlow from './LessonFlow/LessonFlow'

const Courses = () => {
    const dispatch = useDispatch()
    let { uid } = useParams();
    const [course, setCourse] = useState({})
    const [currentCourseId, setCurrentCourseId] = useState(uid)

    useEffect(() => {
        dispatch(resetLessonFlow())
        return () => {
            dispatch(resetLessonFlow())
        }
    }, [dispatch])


    useEffect(() => {
        DatabaseService.getCourse(currentCourseId)
            .then(d => setCourse(d))
    }, [currentCourseId])

    const handleCourseClick = (courseId) => {
        setCurrentCourseId(courseId)
    }

    return (
        <>
            <Layout fullWidth color='white' border alignItems='start' justifyContent='start' padding={[8]} margin={[16, 0, 0, 0]}>
                <Layout row fullWidth alignItems='start' justifyContent='start' padding={[0]} >
                    <Layout style={{ "width": "40%" }} alignItems='start' justifyContent='start' padding={[MARGIN / 4]}>
                        <Text text='Courses' />
                    </Layout>
                    <Divider color='#D4D4D4' horizontal />
                    <Layout style={{ "width": "60%" }} alignItems='start' justifyContent='start' padding={[MARGIN / 4]}>
                        <Text text='Lessons' />
                    </Layout>
                </Layout>
                <Layout fullWidth>
                    <Divider margin={[0]} color='#D4D4D4' />
                </Layout>
                <Layout row fullWidth alignItems='start' justifyContent='start' padding={[0]} style={{ height: '25vh', minHeight: '200px' }}>
                    <Layout style={{ "width": "40%", height: '100%' }} alignItems='start' justifyContent='start' padding={[MARGIN / 2]}>
                        <CoursesProgress uid={uid} onCourseClick={handleCourseClick} />
                    </Layout>
                    <Divider margin={[0]} color='#D4D4D4' horizontal />
                    <Layout style={{ "width": "60%", height: '100%' }} alignItems='start' justifyContent='start' padding={[MARGIN / 2]}>
                        <LessonsProgress uid={uid} course={course} courseId={currentCourseId} />
                    </Layout >
                </Layout>
            </Layout>
            <Layout color='white' fullWidth border scroll style={{ height: "60vh", minHeight: '400px' }} margin={[MARGIN, 0, MARGIN, 0]} padding={[MARGIN]} alignItems='start' justifyContent='start'>
                <LessonFlow />
            </Layout>
        </>
    )
}

export default Courses