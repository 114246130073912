import React, { useEffect } from 'react'
import styles from './Contact.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { fetchContact, fetchMoreContact } from '../../store/contact/contact.slice'
import { useNavigate, useParams } from 'react-router-dom'
import FormPopup from './FormPopup'

const Contact = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const forms = useSelector(state => state.contact.forms)

    const handleScroll = (event) => {
        if (event.scrollHeight - event.scrollTop === event.clientHeight) {
            dispatch(fetchMoreContact())
        }
    }

    useEffect(() => {
        dispatch(fetchContact())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <table className={styles.table} onScroll={e => handleScroll(e.target)}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.type}>Type</th>
                    <th className={styles.email}>Email</th>
                    <th className={styles.time}>Time</th>
                </tr>
                {
                    forms.map(form =>
                        <FormView
                            key={form.id}
                            form={form} />
                    )
                }
            </table>
            <FormPopup id={id} />
        </Layout>
    )
}

const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR

const FormView = ({ form }) => {

    const navigate = useNavigate()

    let lastActivity = ''
    if (form?.time !== undefined) {
        const time = form.time
        const now = Date.now()
        const diff = now - time;

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/contact/${form.id}`) }}>
            <td className={styles.type}>{form.message.startsWith('I want to be a creator!') ? 'Creator' : ''}</td>
            <td className={styles.email}>{form.email}</td>
            <td className={styles.time}>{lastActivity}</td>
        </tr>
    )
}

export default Contact