import React from 'react'

const AI = ({ size = 24, color = 'white' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 18.7501C11.1337 18.7508 10.2807 18.5369 9.51724 18.1274C8.75381 17.7178 8.10378 17.1255 7.62525 16.4033L8.87475 15.5731C9.2168 16.0889 9.68119 16.512 10.2265 16.8047C10.7718 17.0974 11.3811 17.2506 12 17.2506C12.6189 17.2506 13.2282 17.0974 13.7735 16.8047C14.3188 16.512 14.7832 16.0889 15.1253 15.5731L16.3748 16.4033C15.8962 17.1255 15.2462 17.7178 14.4828 18.1274C13.7193 18.5369 12.8663 18.7508 12 18.7501ZM15 10.5001C14.7033 10.5001 14.4133 10.5881 14.1666 10.7529C13.92 10.9177 13.7277 11.152 13.6142 11.4261C13.5006 11.7001 13.4709 12.0017 13.5288 12.2927C13.5867 12.5837 13.7296 12.851 13.9393 13.0607C14.1491 13.2705 14.4164 13.4134 14.7074 13.4713C14.9983 13.5291 15.2999 13.4994 15.574 13.3859C15.8481 13.2724 16.0824 13.0801 16.2472 12.8334C16.412 12.5868 16.5 12.2967 16.5 12.0001C16.502 11.8025 16.4646 11.6066 16.3899 11.4237C16.3152 11.2408 16.2048 11.0746 16.0651 10.9349C15.9254 10.7953 15.7593 10.6848 15.5764 10.6102C15.3935 10.5355 15.1975 10.4981 15 10.5001ZM9 10.5001C8.70333 10.5001 8.41332 10.5881 8.16665 10.7529C7.91997 10.9177 7.72771 11.152 7.61418 11.4261C7.50065 11.7001 7.47094 12.0017 7.52882 12.2927C7.5867 12.5837 7.72956 12.851 7.93934 13.0607C8.14912 13.2705 8.41639 13.4134 8.70737 13.4713C8.99834 13.5291 9.29994 13.4994 9.57403 13.3859C9.84811 13.2724 10.0824 13.0801 10.2472 12.8334C10.412 12.5868 10.5 12.2967 10.5 12.0001C10.502 11.8025 10.4646 11.6066 10.3899 11.4237C10.3152 11.2408 10.2048 11.0746 10.0651 10.9349C9.92544 10.7953 9.75928 10.6848 9.57639 10.6102C9.3935 10.5355 9.19754 10.4981 9 10.5001Z" fill={color} />
            <path d="M22.5 12V10.5H21V7.5C20.999 6.70466 20.6826 5.94217 20.1202 5.37978C19.5578 4.81738 18.7953 4.50099 18 4.5H16.5V1.5H15V4.5H9V1.5H7.5V4.5H6C5.20466 4.50099 4.44217 4.81738 3.87978 5.37978C3.31738 5.94217 3.00099 6.70466 3 7.5V10.5H1.5V12H3V15.75H1.5V17.25H3V19.5C3.00099 20.2953 3.31738 21.0578 3.87978 21.6202C4.44217 22.1826 5.20466 22.499 6 22.5H18C18.7953 22.499 19.5578 22.1826 20.1202 21.6202C20.6826 21.0578 20.999 20.2953 21 19.5V17.25H22.5V15.75H21V12H22.5ZM19.5 19.5C19.4996 19.8977 19.3414 20.279 19.0602 20.5602C18.779 20.8414 18.3977 20.9996 18 21H6C5.6023 20.9996 5.221 20.8414 4.93978 20.5602C4.65856 20.279 4.5004 19.8977 4.5 19.5V7.5C4.5004 7.1023 4.65856 6.721 4.93978 6.43978C5.221 6.15856 5.6023 6.0004 6 6H18C18.3977 6.0004 18.779 6.15856 19.0602 6.43978C19.3414 6.721 19.4996 7.1023 19.5 7.5V19.5Z" fill={color} />
        </svg>
    )
}

export default AI