import React, { useState } from 'react'
import styles from './Cohorts.module.css'
import FunctionsService from '../../services/FunctionsService'
import Layout from '../../components/Layout/Layout'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Divider from '../../components/Divider/Divider'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { NUM_TO_MONTH } from '../Main/Main'
import Instagram from '../../assets/icons/Instagram'
import TikTok from '../../assets/icons/TikTok'
import Facebook from '../../assets/icons/Facebook'
import Twitter from '../../assets/icons/Twitter'
import Linkedin from '../../assets/icons/Linkedin'
import Youtube from '../../assets/icons/Youtube'
import Google from '../../assets/icons/Google'
import Bing from '../../assets/icons/Bing'
import Podcast from '../../assets/icons/Podcast'

const DAY_MIL = 1000 * 60 * 60 * 24
const WEEK_MIL = DAY_MIL * 7

function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

const CreateCohort = ({ addCohort }) => {
    const [loading, setLoading] = useState(false)
    const [cohort, setCohort] = useState('DAY')
    const [dateString, setDateString] = useState()
    const [steps, setSteps] = useState()
    const [customActive, setCustomActive] = useState(null)

    const handleClick = () => {
        if (dateString && new Date(dateString).getTime() > new Date('2021-12-1').getTime()) {
            setLoading(true)
            const now = new Date();
            const startDate = new Date(dateString);
            const times = dateString.split('-')
            let end_range = 1
            if (steps && !Number.isNaN(steps) && steps > 0) {
                end_range = steps
            } else {
                switch (cohort) {
                    case 'DAY':
                        end_range = Math.ceil((now.getTime() - startDate.getTime()) / DAY_MIL) - 1
                        break;
                    case 'WEEK':
                        end_range = Math.ceil((now.getTime() - startDate.getTime()) / WEEK_MIL) - 1
                        break;
                    case 'MONTH':
                        end_range = monthDiff(startDate, now) + 1
                        break;
                    case 'QUARTER':
                        const quarters = Math.floor(monthDiff(startDate, now) / 3)
                        end_range = quarters === 0 ? 1 : quarters
                        break;
                    default:
                        end_range = 1;
                        break;
                }
            }
            FunctionsService.getCohortData(Number(times[0]), Number(times[1]), Number(times[2]), cohort)
                .then((d) => {
                    const res = {
                        data: {
                            info: d,
                            cohort,
                            isCustomActive: customActive !== null & customActive > 0,
                            customActive: customActive,
                        }
                    }
                    FunctionsService.getCohortFunnel(Number(times[0]), Number(times[1]), Number(times[2]), cohort, end_range, customActive ?? 0)
                        .then((dd) => {
                            res.funnel = dd
                            addCohort(res)
                            setLoading(false)
                        })
                })
        }
    }

    return (
        <Layout fullWidth alignItems='flex-start' gap={8}>
            <Layout justifyContent='flex-start' row style={{ flexWrap: 'wrap', gap: '16px 32px' }} margin={[0, 0, 16, 0]} >
                <Layout row gap={16}>
                    <Text text='Start date:' />
                    <input
                        type='date'
                        onChange={e => setDateString(e.target.value)}
                        min={'2022-01-01'} />
                </Layout>
                <Layout row gap={16}>
                    <Text text='Cohort:' />
                    <select onChange={e => setCohort(e.target.value)}>
                        <option value={'DAY'}>Daily</option>
                        <option value={'WEEK'}>Weekly</option>
                        <option value={'MONTH'}>Monthly</option>
                        <option value={'QUARTER'}>Quarterly</option>
                    </select>
                </Layout>
                <Layout row gap={16}>
                    <Text text='Minimum Submissions:' />
                    <input
                        type='number'
                        onChange={e => setCustomActive(e.target.value)}
                        placeholder='Optional' />
                </Layout>
                <Layout row gap={16}>
                    <Text text='Steps:' />
                    <input
                        type='number'
                        onChange={e => setSteps(e.target.value)}
                        placeholder='Optional' />
                </Layout>
            </Layout>
            <Button text={'Generate'} onClick={handleClick} disabled={loading} />
        </Layout>
    )
}

const Cohort = ({ data, funnel }) => {
    const [currPoint, setCurrPoint] = useState(funnel[0] ?? {})
    const [select, setSelect] = useState(false)

    const handleMouseMove = (e) => {
        const curr = funnel.find(d => d.date === e?.activeLabel)
        setCurrPoint(curr ?? (funnel[0] ?? {}))
        if (curr) setSelect(true)
        else setSelect(false)
    }

    const handleMouseLeave = () => {
        setCurrPoint(funnel[0] ?? {})
        setSelect(false)
    }

    let mainExtension = ''
    let dateExtension = ''
    if (funnel[0]?.dateTime && data.cohort === 'WEEK') {
        mainExtension = ' - '
        const d = new Date(funnel[0].dateTime)
        d.setDate(d.getDate() + 7)
        mainExtension += d.toUTCString().slice(5, 11)
    }
    if (currPoint?.dateTime && data.cohort === 'WEEK') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setDate(d.getDate() + 7)
        dateExtension += d.toUTCString().slice(5, 11)
    }
    if (funnel[0]?.dateTime && data.cohort === 'MONTH') {
        mainExtension = ' - '
        const d = new Date(funnel[0].dateTime)
        d.setMonth(d.getMonth() + 1)
        mainExtension += d.toUTCString().slice(5, 11)
    }
    if (currPoint?.dateTime && data.cohort === 'MONTH') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setMonth(d.getMonth() + 1)
        dateExtension += d.toUTCString().slice(5, 11)
    }
    if (funnel[0]?.dateTime && data.cohort === 'QUARTER') {
        mainExtension = ' - '
        const d = new Date(funnel[0].dateTime)
        d.setMonth(d.getMonth() + 3)
        mainExtension += d.toUTCString().slice(5, 11)
    }
    if (currPoint?.dateTime && data.cohort === 'QUARTER') {
        dateExtension = ' - '
        const d = new Date(currPoint.dateTime)
        d.setMonth(d.getMonth() + 3)
        dateExtension += d.toUTCString().slice(5, 11)
    }

    let mainDate = ''
    let currDate = ''
    if (funnel[0]?.dateTime) {
        mainDate = `${funnel[0].date.split('-')[1]} ${NUM_TO_MONTH[funnel[0].date.split('-')[0]]}`
    }
    if (currPoint?.dateTime) {
        currDate = `${currPoint.date.split('-')[1]} ${NUM_TO_MONTH[currPoint.date.split('-')[0]]}`
    }

    return (
        <Layout classes={[styles.activeCard]} padding={[24, 24, 0, 24]} gap={24}>
            <Layout row fullWidth justifyContent='flex-start' gap={8} alignItems='baseline'>
                <Text text={`${mainDate}${mainExtension},`} />
                <Text text={data.info.find(d => d.title === 'registered').num} type='subTitle' bold />
                <Text text={`Registered Users,`} />
                <Text text={data.info.find(d => d.title === 'premium').num} type='subTitle' bold />
                <Text text={`Premium Users`} />
                <Text text={'(' + ((data.info.find(d => d.title === 'premium').num / data.info.find(d => d.title === 'registered').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />
                <Text text={data.info.find(d => d.title === 'active').num} type='subTitle' bold />
                <Text text={`Active Users`} />
                <Text text={'(' + ((data.info.find(d => d.title === 'active').num / data.info.find(d => d.title === 'registered').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />
            </Layout>
            <Layout fullWidth row justifyContent='flex-start' alignItems='flex-start' gap={16}>
                <Layout style={{ width: '280px' }} alignItems='flex-start' gap={8}>
                    <Layout alignItems='flex-start' gap={2}>
                        <Text text={`Socials`} type='small' color='darkGray' />
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={select ? currPoint.exposureActive.socials : data.info.filter(d => d.title.startsWith('socials/')).reduce((a, b) => a + b.num, 0)} type='normal' bold />
                            {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('socials/')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'registered').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                            <Text text={'/'} type='normal' color='darkGray' />
                            <Text text={select ? currPoint.exposurePremium.socials : data.info?.filter(d => d.title.startsWith('premium/socials/'))?.reduce((a, b) => a + b.num, 0) ?? 0} type='normal' bold color='mainColor' />
                            {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('premium/socials/')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'premium').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                        </Layout>
                    </Layout>
                    <Layout row fullWidth alignItems='flex-start'>
                        <Layout alignItems='flex-start' justifyContent='flex-start' gap={4} style={{ flex: 1 }}>
                            <Layout row gap={4}>
                                <TikTok size={12} />
                                <Text text={select ? currPoint.exposureActive.tiktok : data.info.find(d => d.title === 'socials/tiktok')?.num ?? 0} type='small' />
                                <Text text={'/'} type='small' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.tiktok : data.info.find(d => d.title === 'premium/socials/tiktok')?.num ?? 0} type='small' color='mainColor' />
                            </Layout>
                            <Layout row gap={4}>
                                <Instagram size={12} />
                                <Text text={select ? currPoint.exposureActive.instagram : data.info.find(d => d.title === 'socials/instagram')?.num ?? 0} type='small' />
                                <Text text={'/'} type='small' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.instagram : data.info.find(d => d.title === 'premium/socials/instagram')?.num ?? 0} type='small' color='mainColor' />
                            </Layout>
                            <Layout row gap={4}>
                                <Facebook size={12} />
                                <Text text={select ? currPoint.exposureActive.facebook : data.info.find(d => d.title === 'socials/facebook')?.num ?? 0} type='small' />
                                <Text text={'/'} type='small' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.facebook : data.info.find(d => d.title === 'premium/socials/facebook')?.num ?? 0} type='small' color='mainColor' />
                            </Layout>
                            <Layout row gap={4}>
                                <Twitter size={12} />
                                <Text text={select ? currPoint.exposureActive.twitter : data.info.find(d => d.title === 'socials/twitter')?.num ?? 0} type='small' />
                                <Text text={'/'} type='small' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.twitter : data.info.find(d => d.title === 'premium/socials/twitter')?.num ?? 0} type='small' color='mainColor' />
                            </Layout>
                        </Layout>
                        <Layout alignItems='flex-start' justifyContent='flex-start' gap={4} style={{ flex: 1 }}>
                            <Layout row gap={4}>
                                <Linkedin size={12} />
                                <Text text={select ? currPoint.exposureActive.linkedin : data.info.find(d => d.title === 'socials/linkedin')?.num ?? 0} type='small' />
                                <Text text={'/'} type='small' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.linkedin : data.info.find(d => d.title === 'premium/socials/linkedin')?.num ?? 0} type='small' color='mainColor' />
                            </Layout>
                            <Layout row gap={4}>
                                <Youtube size={12} />
                                <Text text={select ? currPoint.exposureActive.youtube : data.info.find(d => d.title === 'socials/youtube')?.num ?? 0} type='small' />
                                <Text text={'/'} type='small' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.youtube : data.info.find(d => d.title === 'premium/socials/youtube')?.num ?? 0} type='small' color='mainColor' />
                            </Layout>
                            <Layout row gap={4}>
                                <Podcast size={12} />
                                <Text text={select ? currPoint.exposureActive.podcast : data.info.find(d => d.title === 'socials/podcast')?.num ?? 0} type='small' />
                                <Text text={'/'} type='small' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.podcast : data.info.find(d => d.title === 'premium/socials/podcast')?.num ?? 0} type='small' color='mainColor' />
                            </Layout>
                        </Layout>
                    </Layout>
                </Layout>
                <Layout style={{ width: '240px' }} alignItems='flex-start' gap={8}>
                    <Layout alignItems='flex-start' gap={2}>
                        <Text text={`Search Engine`} type='small' color='darkGray' />
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={select ? currPoint.exposureActive.search_engine : data.info.filter(d => d.title.startsWith('search_engine/')).reduce((a, b) => a + b.num, 0)} type='normal' bold />
                            {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('search_engine/')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'registered').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                            <Text text={'/'} type='normal' color='darkGray' />
                            <Text text={select ? currPoint.exposurePremium.search_engine : data.info?.filter(d => d.title.startsWith('premium/search_engine/'))?.reduce((a, b) => a + b.num, 0) ?? 0} type='normal' bold color='mainColor' />
                            {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('premium/search_engine/')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'premium').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                        </Layout>
                    </Layout>
                    <Layout alignItems='flex-start' gap={4}>
                        <Layout row gap={4}>
                            <Google size={12} />
                            <Text text={select ? currPoint.exposureActive.google : data.info.find(d => d.title === 'search_engine/google')?.num ?? 0} type='small' />
                            <Text text={'/'} type='small' color='darkGray' />
                            <Text text={select ? currPoint.exposurePremium.google : data.info.find(d => d.title === 'premium/socials/google')?.num ?? 0} type='small' color='mainColor' />
                        </Layout>
                        <Layout row gap={4}>
                            <Bing size={12} />
                            <Text text={select ? currPoint.exposureActive.bing : data.info.find(d => d.title === 'search_engine/bing')?.num ?? 0} type='small' />
                            <Text text={'/'} type='small' color='darkGray' />
                            <Text text={select ? currPoint.exposurePremium.bing : data.info.find(d => d.title === 'premium/socials/bing')?.num ?? 0} type='small' color='mainColor' />
                        </Layout>
                    </Layout>
                </Layout>
                <Layout style={{ width: '240px' }} alignItems='flex-start'>
                    <Layout alignItems='flex-start' gap={2}>
                        <Text text={`Friends/Family`} type='small' color='darkGray' />
                        <Layout row alignItems='baseline' gap={4}>
                            <Text text={select ? currPoint.exposureActive.referral : data.info.filter(d => d.title.startsWith('referral/')).reduce((a, b) => a + b.num, 0)} type='normal' bold />
                            {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('referral/')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'registered').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                            <Text text={'/'} type='normal' color='darkGray' />
                            <Text text={select ? currPoint.exposurePremium.referral : data.info?.filter(d => d.title.startsWith('premium/referral/'))?.reduce((a, b) => a + b.num, 0) ?? 0} type='normal' bold color='mainColor' />
                            {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('premium/referral/')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'premium').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                        </Layout>
                    </Layout>
                </Layout>
                <Layout style={{ width: '240px' }} alignItems='flex-start' gap={16}>
                    <Layout fullWidth alignItems='flex-start'>
                        <Layout alignItems='flex-start' gap={2}>
                            <Text text={`Other`} type='small' color='darkGray' />
                            <Layout row alignItems='baseline' gap={4}>
                                <Text text={select ? currPoint.exposureActive.other : data.info.filter(d => d.title.startsWith('other')).reduce((a, b) => a + b.num, 0)} type='normal' bold />
                                {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('other')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'registered').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                                <Text text={'/'} type='normal' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.other : data.info?.filter(d => d.title.startsWith('premium/other'))?.reduce((a, b) => a + b.num, 0) ?? 0} type='normal' bold color='mainColor' />
                                {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('premium/other')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'premium').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                            </Layout>
                        </Layout>
                    </Layout>
                    <Layout fullWidth alignItems='flex-start'>
                        <Layout alignItems='flex-start' gap={2}>
                            <Text text={`Undefined`} type='small' color='darkGray' />
                            <Layout row alignItems='baseline' gap={4}>
                                <Text text={select ? currPoint.exposureActive.undefined : data.info.filter(d => d.title.startsWith('undefined')).reduce((a, b) => a + b.num, 0)} type='normal' bold />
                                {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('undefined')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'registered').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                                <Text text={'/'} type='normal' color='darkGray' />
                                <Text text={select ? currPoint.exposurePremium.undefined : data.info?.filter(d => d.title.startsWith('premium/undefined'))?.reduce((a, b) => a + b.num, 0) ?? 0} type='normal' bold color='mainColor' />
                                {select ? null : <Text text={'(' + ((data.info.filter(d => d.title.startsWith('premium/undefined')).reduce((a, b) => a + b.num, 0) / data.info.find(d => d.title === 'premium').num) * 100).toFixed(2) + '%)'} type='small' color='darkGray' />}
                            </Layout>
                        </Layout>
                    </Layout>
                </Layout>
            </Layout>
            <Layout fullWidth row alignItems='flex-start' style={{ flex: 1, height: '100%' }}>
                <Layout alignItems='flex-start' gap={24} style={{ width: '160px', height: '100%' }} padding={[0, 0, 28, 0]}>
                    <Layout alignItems='flex-start' justifyContent='flex-start' gap={8} style={{ flex: 1 }}>
                        <Layout alignItems='flex-start'>
                            <Text text={currPoint.activeUsers} type='title' bold />
                            <Text text={'Active Users'} type='medium' color='darkGray' />
                        </Layout>
                        {
                            data.isCustomActive ?
                                <Layout alignItems='flex-start'>
                                    <Text text={`${currPoint.customActiveUsers} (${currPoint.customPremiumUsersActive})`} type='subTitle' />
                                    <Text text={`Min ${data.customActive} Submissions`} type='small' color='darkGray' />
                                </Layout> :
                                null
                        }
                        <Layout alignItems='flex-start'>
                            <Text text={`${currPoint.premiumUsers} (${currPoint.premiumUsersActive})`} type='medium' />
                            <Text text={'Premium Users'} type='small' color='darkGray' />
                        </Layout>
                    </Layout>
                    {
                        currPoint.date ?
                            <Text text={`${currDate}${dateExtension}`} type='medium' />
                            : null
                    }
                </Layout>

                <ResponsiveContainer
                    width='100%'
                    height={'100%'}>
                    <LineChart
                        data={funnel}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tick={false} />
                        <YAxis dataKey={'activeUsers'} yAxisId={`left`} />
                        <YAxis dataKey={'premiumUsers'} yAxisId={`right`} orientation='right' />
                        <Tooltip active={false} wrapperStyle={{ display: 'none' }} />
                        <Line type="monotone" dataKey="activeUsers" stroke="#8884d8" activeDot={{ r: 8 }} yAxisId={`left`} />
                        {
                            data.isCustomActive ?
                                <Line type="monotone" dataKey="customActiveUsers" stroke="#8884d8" activeDot={{ r: 8 }} yAxisId={`left`} /> :
                                null
                        }
                        <Line type="monotone" dataKey="premiumUsers" stroke="#82ca9d" strokeDasharray="3 4 5 2" yAxisId={`right`} />
                    </LineChart>
                </ResponsiveContainer>
            </Layout>
        </Layout>
    )
}

const Cohorts = () => {
    const [data, setData] = useState([])

    const handleAddCohort = (d) => {
        setData(o => ([d]).concat(o))
    }

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <CreateCohort addCohort={handleAddCohort} />
            <Divider color={'rgba(0, 0, 0, 0.3)'} />
            <Layout fullWidth style={{ height: '100%', overflow: 'auto' }} justifyContent='flex-start' gap={32}>
                {
                    data.map((d, i) => {
                        return (
                            <Cohort data={d.data} funnel={d.funnel} key={`_${i}`} />
                        )
                    })
                }
            </Layout>
        </Layout>
    )
}

export default Cohorts