import React from 'react'

const Tag = ({ size = 24, color = 'black' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.25001 20C6.93334 20 6.67501 19.875 6.47501 19.625C6.27501 19.375 6.21668 19.1 6.30001 18.8L7.00001 16H4.77501C4.44168 16 4.17501 15.871 3.97501 15.613C3.77501 15.3543 3.71668 15.0667 3.80001 14.75C3.85001 14.5167 3.96668 14.3333 4.15001 14.2C4.33334 14.0667 4.54168 14 4.77501 14H7.50001L8.50001 10H5.77501C5.44168 10 5.17501 9.871 4.97501 9.613C4.77501 9.35433 4.71668 9.06667 4.80001 8.75C4.85001 8.51667 4.96668 8.33333 5.15001 8.2C5.33334 8.06667 5.54168 8 5.77501 8H9.00001L9.82501 4.725C9.87501 4.50833 9.98334 4.33333 10.15 4.2C10.3167 4.06667 10.5167 4 10.75 4C11.0667 4 11.325 4.125 11.525 4.375C11.725 4.625 11.7833 4.9 11.7 5.2L11 8H15L15.825 4.725C15.875 4.50833 15.9833 4.33333 16.15 4.2C16.3167 4.06667 16.5167 4 16.75 4C17.0667 4 17.325 4.125 17.525 4.375C17.725 4.625 17.7833 4.9 17.7 5.2L17 8H19.225C19.5583 8 19.825 8.129 20.025 8.387C20.225 8.64567 20.2833 8.93333 20.2 9.25C20.15 9.48333 20.0333 9.66667 19.85 9.8C19.6667 9.93333 19.4583 10 19.225 10H16.5L15.5 14H18.225C18.5583 14 18.825 14.129 19.025 14.387C19.225 14.6457 19.2833 14.9333 19.2 15.25C19.15 15.4833 19.0333 15.6667 18.85 15.8C18.6667 15.9333 18.4583 16 18.225 16H15L14.175 19.275C14.125 19.4917 14.0167 19.6667 13.85 19.8C13.6833 19.9333 13.4833 20 13.25 20C12.9333 20 12.675 19.875 12.475 19.625C12.275 19.375 12.2167 19.1 12.3 18.8L13 16H9.00001L8.17501 19.275C8.12501 19.4917 8.01668 19.6667 7.85001 19.8C7.68334 19.9333 7.48334 20 7.25001 20ZM9.50001 14H13.5L14.5 10H10.5L9.50001 14Z" fill={color}/>
    </svg>
  )
}

export default Tag