import React, { useEffect } from 'react'
import styles from './AIResponses.module.css'
import Layout from '../../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAIResponses } from '../../store/aiFeedbacks/aiFeedbacks.slice'
import { useNavigate, useParams } from 'react-router-dom'
import ResponsePopup from './ResponsePopup'

const AIResponses = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const responses = useSelector(state => state.aiFeedbacks.responses)

    useEffect(() => {
        dispatch(fetchAIResponses())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <ResponsePopup id={id} />
            <table className={styles.table}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.uid}>UID</th>
                    <th className={styles.context}>Context</th>
                    <th className={styles.model}>Model</th>
                    <th className={styles.type}>Type</th>
                    <th className={styles.time}>Time</th>
                </tr>
                {
                    responses.map(d =>
                        <RowView
                            key={d.id}
                            {...d} />
                    )
                }
            </table>
        </Layout>
    )
}

const SECOND = 1000
const MIN = 60 * SECOND
const HOUR = 60 * MIN
const DAY = 24 * HOUR


const RowView = ({ id, timestamp, model, type, user_id, course_id, lesson_id }) => {
    const navigate = useNavigate()
    let lastActivity = ''
    if (timestamp !== undefined) {
        const now = Date.now()
        const diff = now - Date.parse(timestamp.value + '+00:00');

        if (diff < 0) lastActivity = ''
        else if (diff < MIN) lastActivity = 'Just now'
        else if (diff < HOUR) {
            const minutes = Math.floor(diff / MIN)
            if (minutes > 1) lastActivity = `${minutes} Minutes ago`
            else lastActivity = `1 Minute ago`
        }
        else if (diff < DAY) {
            const hours = Math.floor(diff / HOUR)
            if (hours > 1) lastActivity = `${hours} Hours ago`
            else lastActivity = `1 Hour ago`
        }
        else {
            const days = Math.floor(diff / DAY)
            if (days > 1) lastActivity = `${days} Days ago`
            else lastActivity = `1 Day ago`
        }
    }


    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/ai_responses/${id}`) }}>
            <td className={styles.uid}>{user_id}</td>
            <th className={styles.context}>{`${course_id}/${lesson_id}`}</th>
            <td className={styles.model}>{model}</td>
            <td className={styles.type}>{type}</td>
            <td className={styles.time}>{lastActivity}</td>
        </tr>
    )
}

export default AIResponses