import React from 'react'
import styles from './Text.module.css'

const Text = ({ bold = false, color = "black", text = '', center = false, type = 'normal', onClick, overflow, className }) => {
    let style = styles.normal
    switch (type) {
        case 'subTitle':
            style = styles.subTitle
            break
        case 'title':
            style = styles.title
            break
        case 'bigTitle':
            style = styles.bigTitle
            break
        case 'medium':
            style = styles.medium
            break
        case 'small':
            style = styles.small
            break
        case 'smallError':
            style = styles.smallError
            break
        case 'smallSuccess':
            style = styles.smallSuccess
            break
        case 'smallInfo':
            style = styles.smallInfo
            break
        case 'success':
            style = styles.success
            break
        case 'error':
            style = styles.error
            break
        default:
            break
    }
    let textColor = false
    switch (color) {
        case "green":
            textColor = styles.green
            break
        case "red":
            textColor = styles.red
            break
        case "white":
            textColor = styles.white
            break
        case "gray":
            textColor = styles.gray
            break
        case "darkGray":
            textColor = styles.darkGray
            break
        case "mainColor":
            textColor = styles.mainColor
            break
        default:
            break
    }

    return (
        <p
            onClick={onClick}
            className={[style, textColor, bold ? styles.bold : styles.normalWeight, onClick ? styles.link : null, center ? styles.center : null, className].join(' ')}
            style={{
                'overflow': overflow ?? 'hidden !important',
                'textOverflow': overflow ?? 'ellipsis',
                'whiteSpace': overflow ?? 'nowrap',
                'maxWidth': overflow ?? '100%'
            }}>
            {text}
        </p>
    )
}

export default Text
