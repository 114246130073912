import React from 'react'
import Text from '../Text'
import styles from './InputBar.module.css'

const InputBar = ({Icon, size=24, value, hint, onValueChange, onEnterPress, error=false, errorMsg='', fontSize=18, style={}}) => {
    
    const handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            onEnterPress()
        }
    }


    return (
        <div className={[styles.container, error ? styles.error : null].join(' ')} style={style}>
            {Icon ? <Icon size={size} color='black'/> : null}
            <input
                className={styles.input}
                value={value}
                placeholder={hint}
                onKeyDown={handleEnterPress}
                onChange={e => onValueChange(e.target.value)}
                style={{"fontSize": `${fontSize}px`}}    
            />
                
                
            {error && <Text bold text={errorMsg} color='red' type='small'/>}

        </div>
    )
}

export default InputBar
