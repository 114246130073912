import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cross from '../../assets/icons/Cross'
import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { saveCarousel } from '../../store/carousels/carousels.slice'
import { fetchCourses } from '../../store/tagging/tagging.slice'
import styles from './Popup.module.css'


const Popup = ({ id }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const savedData = useSelector(state => id ? state.carousels.data?.find(d => d.id === id) : undefined)
    const courses = useSelector(state => state.tagging.courses)
    const [data, setData] = useState({})
    const [searchedValue, setSearchedValue] = useState('')

    useEffect(() => {
        setData(savedData)
    }, [savedData])

    useEffect(() => {
        dispatch(fetchCourses())
    }, [dispatch])

    const handleSave = () => {
        dispatch(saveCarousel({ id, newData: data }))
    }

    const handleDeleteItem = (id) => {
        setData(o => {
            const newCourses = o.courses.filter(t => t !== id)
            return { ...o, courses: newCourses }
        })
    }

    const handleAddItem = (id) => {
        setData(o => {
            const newCourses = o.courses.concat([id])
            return { ...o, courses: newCourses }
        })
        setSearchedValue('')
    }

    return (
        <Layout classes={[styles.backdrop, id === undefined ? null : styles.display]} alignItems='flex-end' onClick={() => { navigate(`/carousels`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[16]} justifyContent='flex-start' alignItems='flex-start' gap={32}>
                <Layout fullWidth gap={16} alignItems='flex-start'>
                    <Text text='Courses:' />
                    <Layout fullWidth classes={[styles.selectTagContainer]} alignItems='flex-start'>
                        <input
                            value={searchedValue}
                            className={styles.input}
                            onChange={e => setSearchedValue(e.target.value)} />
                        <Layout classes={[styles.tagsSelectionContainer]} alignItems='flex-start' justifyContent='flex-start'>
                            {
                                courses.filter(c =>
                                    !data?.courses?.includes(c.id) &&
                                    (
                                        c.id.toLowerCase().includes(searchedValue.toLowerCase()) ||
                                        c.title.toLowerCase().includes(searchedValue.toLowerCase())
                                    ))
                                    .map(c =>
                                        <Text
                                            key={c.id}
                                            text={c.title}
                                            className={styles.tagSelection}
                                            onClick={() => handleAddItem(c.id)} />)
                            }
                        </Layout>
                    </Layout>
                    <Layout alignItems='flex-start' fullWidth gap={8}>
                        {
                            data?.courses?.map(courseId => {
                                return (
                                    <Layout key={courseId} classes={[styles.tag]} padding={[4, 12]} row gap={8}>
                                        <Text text={courses.find(c => c.id === courseId)?.title} />
                                        <IconButton Icon={Cross} size='tiny' type={'white'} onClick={() => handleDeleteItem(courseId)} />
                                    </Layout>
                                )
                            })
                        }
                    </Layout>
                </Layout>
                <Button
                    text={'Save'}
                    onClick={handleSave} />
            </Layout>
        </Layout>
    )
}

export default Popup