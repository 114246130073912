import React from 'react'

const Course = ({ size, color }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 2H6C4.794 2 3 2.799 3 5V19C3 21.201 4.794 22 6 22H20C20.5523 22 21 21.5523 21 21V21C21 20.4477 20.5523 20 20 20H6.012C5.55 19.988 5 19.806 5 19C5 18.194 5.55 18.012 6.012 18H21V4C21 2.897 20.103 2 19 2ZM19 16H5V5C5 4.194 5.55 4.012 6 4H19V16Z" fill={color} />
        </svg>
    )
}

export default Course