import React from 'react'

const Contact = ({ size = 24, color = "white" }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 11H21V6H14V11ZM17.5 9.75L15 8V7L17.5 8.75L20 7V8L17.5 9.75ZM2 21C1.45 21 0.979333 20.8043 0.588 20.413C0.196 20.021 0 19.55 0 19V5C0 4.45 0.196 3.979 0.588 3.587C0.979333 3.19567 1.45 3 2 3H22C22.55 3 23.021 3.19567 23.413 3.587C23.8043 3.979 24 4.45 24 5V19C24 19.55 23.8043 20.021 23.413 20.413C23.021 20.8043 22.55 21 22 21H2ZM15.9 19H22V5H2V19H2.1C2.8 17.75 3.76667 16.7707 5 16.062C6.23333 15.354 7.56667 15 9 15C10.4333 15 11.7667 15.354 13 16.062C14.2333 16.7707 15.2 17.75 15.9 19ZM9 14C9.83333 14 10.5417 13.7083 11.125 13.125C11.7083 12.5417 12 11.8333 12 11C12 10.1667 11.7083 9.45833 11.125 8.875C10.5417 8.29167 9.83333 8 9 8C8.16667 8 7.45833 8.29167 6.875 8.875C6.29167 9.45833 6 10.1667 6 11C6 11.8333 6.29167 12.5417 6.875 13.125C7.45833 13.7083 8.16667 14 9 14ZM4.55 19H13.45C12.8833 18.3667 12.2127 17.875 11.438 17.525C10.6627 17.175 9.85 17 9 17C8.15 17 7.34167 17.175 6.575 17.525C5.80833 17.875 5.13333 18.3667 4.55 19ZM9 12C8.71667 12 8.47933 11.904 8.288 11.712C8.096 11.5207 8 11.2833 8 11C8 10.7167 8.096 10.479 8.288 10.287C8.47933 10.0957 8.71667 10 9 10C9.28333 10 9.521 10.0957 9.713 10.287C9.90433 10.479 10 10.7167 10 11C10 11.2833 9.90433 11.5207 9.713 11.712C9.521 11.904 9.28333 12 9 12Z" fill={color} />
        </svg>
    )
}

export default Contact